
// material
import {
    Breadcrumbs, Container, Link,
  } from '@mui/material';
  import BuildIcon from '@mui/icons-material/Build';
  import Page from '../components/Page';
import SystemLogs from '../components/SystemLogs/SystemLogsComponent'  

  export default function SystemLog() {
  
    return (
      <Page title="Alerts">
        <Container maxWidth={false}>
          <div role="presentation" style={{ paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit">
                <BuildIcon />
              </Link>
              <Link underline="hover" color="inherit">
                Logs Management
              </Link>
              <Link underline="hover" color="inherit" href="/dashboard/system-logs">
                System Logs
              </Link>
            </Breadcrumbs>
          </div>
  
          <SystemLogs />
  
        </Container>
      </Page>
    );
  }
  