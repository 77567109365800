import axios from 'axios';

export const LinksDataAPI = async (setIspData, setAllIspData, buState) => {
  if (localStorage.getItem('user_map_status') === '1') {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_list_for_mapped_user.php`;
    const payload = JSON.stringify({
      auth_token: localStorage.getItem('auth_token'),
      client_id: localStorage.getItem('cust_id'),
      bu_id: buState,
    });

    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setIspData(response.data.isp_list);
        setAllIspData(response.data.isp_list);
      }
    });
  }
  if (localStorage.getItem('user_map_status') === '-1') {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
      const url = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_list_for_bu.php`;
      const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "bu_id": buState
      });

      return axios
      .post(url, payload)
      .then((response) => {
        if (response.data.code === 1) {
          setIspData(response.data.isp_list);
          setAllIspData(response.data.isp_list);
        } else {
          setIspData([]);
        }
      })
      .catch(() => {
        setIspData([]);
      });
    }

    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem('msp_status') === '1' ? 'get_isp_list_for_msp.php' : 'get_isp_list.php';
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
      const regPayload = JSON.stringify({
        auth_token: localStorage.getItem('auth_token'),
        client_id: localStorage.getItem('cust_id'),
      });
      const mspPayload = JSON.stringify({
        auth_token: localStorage.getItem('auth_token'),
        msp_client_id: localStorage.getItem('cust_id'),
        reg_client_id: localStorage.getItem('reg_cust_id'),
        msp_status: 1,
      });

      const payload = localStorage.getItem('msp_status') === '1' ? mspPayload : regPayload;

      return axios
        .post(baseUrl, payload)
        .then((response) => {
          if (response.data.code === 1) {
            setIspData(response.data.isp_list);
            setAllIspData(response.data.isp_list);
          } else {
            setIspData([]);
          }
        })
        .catch(() => {
          setIspData([]);
        });
    }
  }
};

// API CALL CODE HERE
export const DeleteLinkDataAPI = async (ispWanId, ispPublicIP, setOpen) => {

const deleteBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/delete_isp_info.php`;

  const regPayload = JSON.stringify({
    auth_token: localStorage.getItem('auth_token'),
    client_id: localStorage.getItem('cust_id'),
    isp_wan_id: ispWanId,
    link_ip: ispPublicIP,
  });
  const mspPayload = JSON.stringify({
    auth_token: localStorage.getItem('auth_token'),
    client_id: localStorage.getItem('reg_cust_id'),
    isp_wan_id: ispWanId,
    link_ip: ispPublicIP,
  });
  const payload = localStorage.getItem('msp_status') === '1' ? mspPayload : regPayload;

  return axios.post(deleteBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  });
};
