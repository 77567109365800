import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Chip, Tooltip, tooltipClasses, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import ApplicationMonitorGraphs from './ApplicationMonitorGraphs';
import GetAppPortStateAPICall from './GetAppPortStateAPICall';
import { useGlobalState } from '../../globalHooks/GlobalState';

function parseReason(reason) {
  const result = {};
  reason.split(', ').forEach((part) => {
    const [key, value] = part.split(': ');
    result[key.toLowerCase()] = value === '' ? NaN : parseFloat(value);
  });
  return result;
}

function Row(props) {
  const { row, locationId } = props;
  const [open, setOpen] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[10],
      fontSize: 14,
      arrow: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  }));

  const highCriticalityPorts = row.port_details.filter(
    (port) => port.port_criticality === 'HIGH'
  );

  const reasonData = parseReason(row.reason);
  const getLatencyColor = (latency) => (latency > 120 ? 'red' : 'green');
  const getPacketLossColor = (packetLoss) => (packetLoss > 0 ? 'red' : 'green');
  const getJitterColor = (jitter) => (jitter > 30 ? 'red' : 'green');

  const formatValue = (value, unit) =>
    Number.isNaN(value) ? (
      <span style={{ color: 'red' }}>∞{unit}</span>
    ) : (
      <span style={{ color: getLatencyColor(value) }}>{value}{unit}</span>
    );

  const getLabelColor = (value, getColor) =>
    Number.isNaN(value) ? 'red' : getColor(value);

  return (
    <>
      <TableRow aria-label="collapsible table" style={{ borderCollapse: 'collapse', border: 'none' }} elevation={15}>
        <TableCell style={{ border: 'none' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ border: 'none', textAlign: 'justify' }}>
          {row.app_name}
        </TableCell>

        {row.status === 1 && (
          <TableCell style={{ border: 'none', textAlign: 'justify' }}>
            <LightTooltip
              title={highCriticalityPorts.map(
                (port) => `Port ${port.port_number}: ${port.port_state}`
              ).join(' , ') || 'Ports are not Defined'}
              placement="left-start"
            >
              <Chip label="Good" color="success" style={{ width: '120px' }} />
            </LightTooltip>
          </TableCell>
        )}

        {row.status === 2 && (
          <TableCell style={{ border: 'none', textAlign: 'justify' }}>
            <LightTooltip
              title={highCriticalityPorts.map(
                (port) => `Port ${port.port_number}: ${port.port_state}`
              ).join(' , ') || 'Ports are not Defined'}
              placement="left-start"
            >
              <Chip
                label="Partial Good"
                // color="info"
                sx={{ backgroundColor: 'yellow', color: 'black' }}
                style={{ width: '120px' }}
              />
            </LightTooltip>
          </TableCell>)}

        {row.status === 3 && (
          <TableCell style={{ border: 'none', textAlign: 'justify' }}>
            <LightTooltip
              title={highCriticalityPorts.map(
                (port) => `Port ${port.port_number}: ${port.port_state}`
              ).join(' , ') || 'Ports are not Defined'}
              placement="left-start"
            >
              <Chip
                label="Poor"
                color="warning"
                style={{ width: '120px' }}
              />
            </LightTooltip>
          </TableCell>)}

        {row.status === 4 && (
          <TableCell style={{ border: 'none', textAlign: 'justify' }}>
            <LightTooltip
              title={highCriticalityPorts.map(
                (port) => `Port ${port.port_number}: ${port.port_state}`
              ).join(' , ') || 'Application is Down'}
              placement="left-start"
            >
              <Chip
                label="Bad"
                color="error"
                style={{ width: '120px' }}
              />
            </LightTooltip>
          </TableCell>)}
        {row.status === 5 && (
          <TableCell style={{ border: 'none', textAlign: 'justify' }}>
            <LightTooltip
              title={highCriticalityPorts.map(
                (port) => `Port ${port.port_number}: ${port.port_state}`
              ).join(' , ') || 'Application is Down'}
              placement="left-start"
            >
              <Chip
                label="Failed"
                color="error"
                style={{ width: '120px' }}
              />
            </LightTooltip>
          </TableCell>)}

        <TableCell style={{ border: 'none', textAlign: 'justify' }}>
          {row.analysis}
        </TableCell>
        <TableCell style={{ border: 'none', textAlign: 'justify' }}>
          {row.status === 1 && (
            <Typography>
              <span style={{ color: getLabelColor(reasonData.latency, getLatencyColor) }}>
                Latency: {formatValue(reasonData.latency, 'ms')}
              </span>,
              <span style={{ color: getLabelColor(reasonData['packet loss'], getPacketLossColor) }}>
                Packet loss: {formatValue(reasonData['packet loss'], '%')}
              </span>,
              <span style={{ color: getLabelColor(reasonData.jitter, getJitterColor) }}>
                Jitter: {formatValue(reasonData.jitter, 'ms')}
              </span>
            </Typography>
          )}
          {row.status > 1 && (
            <>
              <Typography>
                <b>Baseline:</b> {row.base_line}
              </Typography>
              <Typography>
                <b>Deviation:</b>
                <span style={{ color: getLabelColor(reasonData.latency, getLatencyColor) }}>
                  Latency: {formatValue(reasonData.latency, 'ms')}
                </span>,
                <span style={{ color: getLabelColor(reasonData['packet loss'], getPacketLossColor) }}>
                  Packet loss: {formatValue(reasonData['packet loss'], '%')}
                </span>,
                <span style={{ color: getLabelColor(reasonData.jitter, getJitterColor) }}>
                  Jitter: {formatValue(reasonData.jitter, 'ms')}
                </span>
              </Typography>
              <Typography>
                <b>Occurrence Time:</b> {row.occurance}
              </Typography>
            </>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.app_name}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <ApplicationMonitorGraphs locationId={locationId} appId={row.app_id} />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ApplicationMonitorTables(props) {

  const { locationId, appType } = props;
  const [saasAppData, setSaasAppData] = useState([]);

  const { globalState, setGlobalState } = useGlobalState();

  const _GetAppPortStateAPICall = () => {
    GetAppPortStateAPICall(locationId, appType, setSaasAppData);
  }

  useEffect(() => {
    _GetAppPortStateAPICall();
    const interval = setInterval(() => {
      _GetAppPortStateAPICall();
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [locationId, appType]);

  useEffect(() => {
    _GetAppPortStateAPICall();
  }, [globalState]);

  return (
    <TableContainer component={Paper} elevation={15} style={{ overflowX: 'hidden' }}>
      <Table aria-label="collapsible table" style={{ borderCollapse: 'collapse', border: 'none' }}>
        <TableHead>
          <TableRow style={{ borderBottom: 'unset' }}>
            <TableCell style={{ border: 'none' }} />
            <TableCell style={{ border: 'none', textAlign: 'justify' }}>Application List</TableCell>
            <TableCell style={{ border: 'none', textAlign: 'justify' }}>Status</TableCell>
            <TableCell style={{ border: 'none', textAlign: 'justify' }}>Analysis</TableCell>
            <TableCell style={{ border: 'none', textAlign: 'justify' }}>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {saasAppData && saasAppData.map((app) => (
            <Row key={app.app_name} row={app} locationId={locationId} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

