import axios from "axios"

const GetEdgeDeviceVendorAndTypesApiCall = async(setVendorList, setTypeList) => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_vendor_and_types.php`;
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
    });
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1)
        {
           setVendorList(response.data.vendor_list);
           setTypeList(response.data.type_list);
        }
        else
        {
            setVendorList([]);
            setTypeList([]);
        }
    })
}

export default GetEdgeDeviceVendorAndTypesApiCall