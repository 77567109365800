import { useState } from 'react';
// @mui
import { Card, Grid, Paper, Box, Typography, CardHeader, Button } from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import 'react-tabs/style/react-tabs.css';
import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import Lottie from 'lottie-react';
import Animation1 from '../../../Assets/75702.json';
// components
import SidewiseSubCards from './SidewiseSubCards';
// sections

// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

const state1 = {
  series: [
    {
      name: 'series1',
      data: [11, 80, 20, 10, 62, 21, 68],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  },
};

const state2 = {
  series: [
    {
      name: 'series1',
      data: [1, 0, 0, 0, 1, 1, 0],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  },
};

export default function SidewiseCard1() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const state0 = {
    series: [
      {
        name: 'series1',
        data: [31, 40, 60, 109, 42, 51, 38],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15}>
          <div className={classes.titleStyle}>
            <Typography>
              Airtel - 23.12.222.22 :{' '}
              <Typography sx={{ color: '#3bcd89' }} component={'span'}>
                UP time
              </Typography>{' '}
              4{' '}
              <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
                days
              </Typography>
              , 18{' '}
              <Typography sx={{ color: '#b0aaaa' }} component={'span'}>
                hours
              </Typography>
            </Typography>
          </div>

          <SidewiseSubCards />
          <Grid container>
            <Grid item xs={8} sm={12} md={12} lg={8} sx={{ px: 2, py: 2 }}>
              <Card variant="outlined" elevation={10}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency" />
                  <Tab label="Jitter" />
                  <Tab label="Up/Down" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box>
                    <CardHeader title="Latency" />
                    <ReactApexChart options={state0.options} series={state0.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <CardHeader title="Jitter" />
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <CardHeader title="Up/Down" />
                    <ReactApexChart options={state2.options} series={state2.series} type="area" height={350} />
                  </Box>
                </TabPanel>
              </Card>
            </Grid>
            <Grid item xs={4} sm={12} md={12} lg={4}>
              <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <Paper elevation={15} sx={{ px: 2, py: 2, height: 465 }}>
                  <Typography variant="h5" gutterBottom color="text.primary">
                    DG rechability
                  </Typography>
                  <Lottie animationData={Animation1} loop autoPlay id="anime1" style={{ height: '320px' }} />
                  <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                    <Button variant="contained" sx={{ fontSize: '1rem' }}>
                      Test Here
                    </Button>
                  </Box>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
