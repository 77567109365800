

import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { divIcon } from 'leaflet';
import DeleteCustomTagDialogApiCall from './DeleteCustomTagDialogApiCall';

// import delete API here


export default function DeleteCustomTagDialog(props) {
    const { data, openDeleteDialog, setOpenDeleteDialog,setDeleteStatus,setDeleteMessage} = props;

    const handleClose =() =>{
        setOpenDeleteDialog(false);
    }

    const handleonDelete =() =>{
        // deleteApi call here
        DeleteCustomTagDialogApiCall(data.tags, data.entity, setDeleteStatus,setDeleteMessage)
        handleClose();
    }
    return(
        <div>
            <Dialog
             open={openDeleteDialog}
             onClose={handleClose}
             fullWidth maxWidth="sm"
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
                    Delete Custom Tag
                </DialogTitle>
                <Divider />
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                        {/* Are You Sure to Delete Custom Tag - {data.tags} ? */}
                        This tag '{data.tags}' may contain data associated with the entity '{data.entity}'. Deleting this tag will also remove the related data.
                        <br /><br />
                          Are you sure you want to delete the custom tag - {data.tags} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleonDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
                </Dialog>
        </div>
    )
}