import axios from 'axios';

// First API call to fetch custom tags data
export const GetSiteCustomTagDetailsApiCall = async (setCustomTagsData,Site ) => {
  const path = localStorage.getItem("msp_status") === "1" ? "get_custom_tags_for_entity_for_msp.php" : "get_custom_tags_for_entity.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "entity" : Site
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
    "entity" : Site
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  try {
    const response = await axios.post(baseUrl, payload);

    if (response.data.code === 1) {
        // console.log("API Response:",  response.data.custom_tags_data);  
      const customTagsData = response.data.custom_tags_data;
      const initializedData = customTagsData.map(field => ({
        ...field,
        defaultValue: field.default_value || '', 
        isMandatory: field.is_mandatory,
        dataType:field.data_type
    }));

    setCustomTagsData(initializedData); 
    console.log("Initialized custom tags data:", initializedData);
  
    } else {
      console.error('Error fetching data:', response.data.message);
    }
  } catch (error) {
    console.error('API call failed:', error);
  }
};
