// @mui
import { Box, Button, Card, CardHeader, Paper, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import _ from "lodash";
// components
import LocationSingleISPData from './LocationSingleISPData';
import timeArray from '../../ISPManagementTable/ISPManagementDashboard/TimeData';


/* eslint-disable eqeqeq */

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});
export default function LocationSpecificData(props) {
  return (
    <>
      
      {props.locationData.isp_wan_ids.map((ispWanId, index) =>
      (
        <LocationSingleISPData ispWanId={ispWanId} key={`${ispWanId}-${index}`} />
      )
      )}
    </>
  );
}
