// import axios from 'axios';

// const GetUtilizationConfigDetails = async (setRows, setFilteredRows, setUtiConfig,buState) => {


//         const path = localStorage.getItem("msp_status") === "1" ? "get_utilization_details_for_msp.php" : "get_utilization_details.php";
//         const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


//         const regPayload = JSON.stringify({
//             "auth_token": localStorage.getItem("auth_token"),
//             "cust_id": localStorage.getItem("reg_cust_id")
//         });

//         const mspPayload = JSON.stringify({
//             "auth_token": localStorage.getItem("auth_token"),
//             "msp_cust_id": localStorage.getItem("cust_id"),
//             "reg_cust_id": localStorage.getItem("reg_cust_id"),
//             "msp_status": 1
//         });

//         const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

//     try {
//         const response = await axios.post(baseUrl, payload);
//         if (response.data.code === 1) {
//             setRows(response.data.data);
//             setFilteredRows(response.data.data);
//             const data = response.data.data
//             if (Array.isArray(data)) {
//                 const utiConfig = data.map(item => ({
//                     location_name: item.location_name,
//                     city: item.city,
//                     util_warn_th: item.util_warn_th,
//                     util_critical_th: item.util_critical_th,
//                 }));
//                 setUtiConfig(utiConfig);
//             } else {

//                 setUtiConfig([]);

//             }
//         } else {
//             setRows([]);
//             setFilteredRows([]);
//             setUtiConfig([]);
//         }
//     } catch (error) {
//         setRows([]);
//         setFilteredRows([]);
//         setUtiConfig([]);
//     }

// }

// export default GetUtilizationConfigDetails;

import axios from 'axios';

const GetUtilizationConfigDetails = async (setRows, setFilteredRows, setUtiConfig, buState) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_utilization_details_for_msp.php" : "get_utilization_details.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("reg_cust_id")
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    try {
        const response = await axios.post(baseUrl, payload);

        if (response.data.code === 1) {
            const data = response.data.data;


            setRows([data]);
            setFilteredRows([data]);


            const utiConfig = {
                util_warn_th: data.util_warn_th || 80,
                util_critical_th: data.util_critical_th || 100,
            };
            setUtiConfig(utiConfig);
        } else {

            setRows([]);
            setFilteredRows([]);
            setUtiConfig([]);
        }
    } catch (error) {
        console.error("API Error:", error);
        setRows([]);
        setFilteredRows([]);
        setUtiConfig([]);
    }
};

export default GetUtilizationConfigDetails;
