import axios from 'axios';

const UpdateRegisteredUserDataAPI = async (email, userName,role, action, setUpdateStatus, setUpdateUserMessage) => {
  const path = localStorage.getItem("msp_status") === "1" ? "file.php" : "update_registered_users.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  // Construct the payload
  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "role":role,
      "email": email,
      "user_name": userName,
      "action": action
    }
  });

  try {
    const response = await axios.post(createBaseURL, payload);
    if (response.data.code === 1) {
      setUpdateStatus(1);
      setUpdateUserMessage(response.data.message);
    } else {
      setUpdateStatus(-1);
      setUpdateUserMessage(response.data.message);
    }
  } catch (error) {
    setUpdateStatus(-1);
    setUpdateUserMessage("An error occurred while updating the user data.");
  }
};

export default UpdateRegisteredUserDataAPI;
