import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import Scrollbar from '../../Scrollbar';
// mock
import devices from '../../../_mock/devices';
import ISPBadTableToolbar from './ISPBadTableToolbar';
import ISPBadTableHead from './ISPBadTableHead';
import { ISPBadTableDataAPI } from '../ISPManagamentRestCalls/ISPBadTableDataAPI'
import { useGlobalState } from '../../../globalHooks/GlobalState';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'Vendor', label:'Vendor', alignRight: false},
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Internal IP', label: 'Internal IP', alignRight: false },
  { id: 'DG IP', label: 'DG IP', alignRight: false },
  { id: 'Uptime', label: 'Up-Time', alignRight: false },
  { id: 'insights', label: 'Co-Pilot Summary', alignRight: false },
  { id: 'View', label:'View', alignRight: false},
];

//----------------------------------------------------------------------
export default function ISPBadTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [badIspData, setBadIspData] = useState([]);
  
  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };


  const _ISPBadTableDataAPI =async()=>{
    await ISPBadTableDataAPI(setBadIspData, buState);
  }
  useEffect(()=>{
    _ISPBadTableDataAPI();
    setInterval(() => {
      _ISPBadTableDataAPI();
    }, 1000 * 60);
  },[globalState, buState]);

  useEffect(()=>{
    _ISPBadTableDataAPI();
  },[])

  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }

  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPBadTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ISPBadTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {badIspData.map((list, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index+1}</TableCell>
                    {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.cust_name}</TableCell> : null }
                    <TableCell>{list.circuit_id}</TableCell>
                    <TableCell>{list.location}</TableCell>
                    <TableCell>{list.vendor}</TableCell>
                    <TableCell>{list.public_ip}</TableCell>
                    <TableCell>{list.internal_ip}</TableCell>
                    <TableCell>{list.default_gateway}</TableCell>
                    <TableCell>{convertMinToHourMinutes(list.up_cnt)}</TableCell>
                    <TableCell>{list.troubleshoot_action=== '' || list.troubleshoot_action=== null ? 'Analysing...' : list.troubleshoot_action}</TableCell>
                    <TableCell><Link to={{pathname: `/dashboard/isp-management/isp-bad/${list.isp_wan_id}`}}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>
                  </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </Grid>
  );
}
