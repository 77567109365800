import axios from 'axios';


const GetTraceRouteAPICall = async(appId, locationId, timeData,  setNodes, setLinks) => {

const url = `${process.env.REACT_APP_API_SERVER_URL}/get_traceroute_data.php`;
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId,
        "app_id": appId,
        "time": timeData
    });
    
    return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
            const data = response.data;
            setNodes(data.nodes);
            setLinks(data.links);
        } 
        else {
            setNodes([]);
            setLinks([]);
        }
    });
}

export default GetTraceRouteAPICall