import Leaflet from 'leaflet';
import axios from 'axios';

function calculateExtremes(markers, setMainBounds) {

    let bounds = {}

    if (markers.length === 0) {
        bounds = Leaflet.latLngBounds([
            Leaflet.latLng(23.63936, 68.14712),
            Leaflet.latLng(28.20453, 97.34466)
        ])
    }
    else {
        let topmost = markers[0].position;
        let bottommost = markers[0].position;
        let leftmost = markers[0].position;
        let rightmost = markers[0].position;

        if (markers.length === 1) {
            bounds = Leaflet.latLngBounds([
                Leaflet.latLng(markers[0].position.lat - 2, markers[0].position.lng - 2),
                Leaflet.latLng(markers[0].position.lat + 2, markers[0].position.lng + 2)
            ]);
        }
        else if (markers.length > 1) {
            markers.forEach(coord => {
                if (coord.position.lat > topmost.lat) topmost = coord.position;
                if (coord.position.lat < bottommost.lat) bottommost = coord.position;
                if (coord.position.lng < leftmost.lng) leftmost = coord.position;
                if (coord.position.lng > rightmost.lng) rightmost = coord.position;
            });
            bounds = Leaflet.latLngBounds([
                Leaflet.latLng(bottommost.lat, leftmost.lng),
                Leaflet.latLng(topmost.lat, rightmost.lng)
            ]);
        }
    }

    setMainBounds(bounds)
}

function setSubMarkersArr(markers, setSubMarksers) {
    const offset = 0.005;
    const allMembers = {};

    // Group all markers by their positions
    markers.forEach((marker) => {
        const members = marker.members.reduce((acc, loc) => {
            const key = `${loc.position.lat}-${loc.position.lng}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(loc);
            return acc;
        }, {});

        // Merge grouped members into allMembers
        Object.keys(members).forEach(key => {
            if (!allMembers[key]) {
                allMembers[key] = [];
            }
            allMembers[key].push(...members[key]);
        });
    });

    // Prepare updated members for state update
    const updatedMembers = [];
    Object.values(allMembers).forEach(group => {
        if (group.length > 1) {
            const angleStep = (2 * Math.PI) / group.length;
            group.forEach((loc, index) => {
                const angle = angleStep * index;
                const latOffset = offset * Math.cos(angle);
                const lngOffset = offset * Math.sin(angle);
                updatedMembers.push({
                    ...loc,
                    position: {
                        'lat': loc.position.lat + latOffset,
                        'lng': loc.position.lng + lngOffset
                    }
                });
            });
        } else {
            updatedMembers.push(group[0]);
        }
    });
    setSubMarksers(updatedMembers);
}

const GetMarkersApiCallV2 = async (selectedGroupIds, setMarkers, setCenter, setStatus, buState, setMainBounds, setSubMarksers) => {
    if (localStorage.getItem("user_map_status") === "1") {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_markers_by_client_for_mapped_user_v2.php`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "group_ids": selectedGroupIds,
            "bu_id": buState
            ,
        });
        return axios.post(mappedUrl, regPayload).then((response) => {
            if (response.data.code === 1) {
                calculateExtremes(response.data.markers, setMainBounds)
                setSubMarkersArr(response.data.markers, setSubMarksers)
                setMarkers(response.data.markers.length > 0 ? response.data.markers : []);
                setCenter(response.data.markers.length > 0 ? response.data.markers[0].position : { lat: 28.63576, lng: 77.22445 })
                setStatus(true)
            }
            else {
                setMarkers([]);
                setCenter({ lat: 28.63576, lng: 77.22445 })
                setStatus(true)
            }

        });
    }
    if (localStorage.getItem("user_map_status") === "-1") {
        if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
            const url = `${process.env.REACT_APP_API_SERVER_URL}/get_markers_by_client_for_bu_v2.php`;
            const payload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "bu_id": buState
            });

            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    calculateExtremes(response.data.markers, setMainBounds)
                    setSubMarkersArr(response.data.markers, setSubMarksers)
                    setMarkers(response.data.markers.length > 0 ? response.data.markers : []);
                    setCenter(response.data.markers.length > 0 ? response.data.markers[0].position : { lat: 28.63576, lng: 77.22445 })
                    setStatus(true)
                }
                else {
                    setMarkers([]);
                    setCenter({ lat: 28.63576, lng: 77.22445 })
                    setStatus(true)
                }
            }).catch(() => {
                setMarkers([]);
            });
        }

        if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
            const path = localStorage.getItem("msp_status") === "1" ? "get_markers_by_client_for_msp_v2.php" : "get_markers_by_client_v2.php";
            const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

            const regPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds
            });

            const mspPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "msp_client_id": localStorage.getItem("cust_id"),
                "reg_client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "msp_status": 1
            });

            const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    calculateExtremes(response.data.markers, setMainBounds)
                    setSubMarkersArr(response.data.markers, setSubMarksers)
                    setMarkers(response.data.markers.length > 0 ? response.data.markers : []);
                    setCenter(response.data.markers.length > 0 ? response.data.markers[0].position : { lat: 28.63576, lng: 77.22445 })
                    setStatus(true)
                }
                else {
                    setMarkers([]);
                    setCenter({ lat: 28.63576, lng: 77.22445 })
                    setStatus(true)
                }
            }).catch(() => {
                setMarkers([]);
            });
        }
    }

}

export default GetMarkersApiCallV2