import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Switch,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Button,
    DialogActions,
} from '@mui/material';

import PauseLinkApiCall from './PauseLinkApiCall';
import UtilizationAlertApiCall from './UtilizationAlert';

export default function NotificationAlertDialog({
    openAlertNotifiDialog,
    setOpenAlertNotifiDialog,
    setAlertNotifyStatus,
    setAlertNotifyMessage,
    alertNotifyLinkIp,
    alertNotifyLinkWanId,
    alertNotifyPauseStatus,  
    alertNotifyUtilAlertStatus 
}) {
    // Local state for pause status
    const [pauseStatus, setPauseStatus] = useState(alertNotifyPauseStatus);
    const [utilAlertStatus, setUtilalertStatus] = useState(alertNotifyUtilAlertStatus)

    const handleUtilizationToggle = async () => {
        const newUtilAlertStatus = utilAlertStatus === 1 ? 0 : 1;
        await UtilizationAlertApiCall(alertNotifyLinkWanId, alertNotifyLinkIp, newUtilAlertStatus, setAlertNotifyStatus, setAlertNotifyMessage)
        setUtilalertStatus(newUtilAlertStatus)
    };


    const handlePauseToggle = async () => {
        const newPauseStatus = pauseStatus === 1 ? 0 : 1;

        // api
        await PauseLinkApiCall(alertNotifyLinkWanId, alertNotifyLinkIp, newPauseStatus, alertNotifyPauseStatus, setAlertNotifyStatus, setAlertNotifyMessage);
        setPauseStatus(newPauseStatus);

    };

    const handleClose = () => {
        setOpenAlertNotifiDialog(false);
    };

    // const handleSave = () => {
    //     setAlertNotifyStatus(1);
    //     setAlertNotifyMessage('Alert settings updated successfully');
    //     handleClose();
    // };

    return (
        <Dialog open={openAlertNotifiDialog} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Alert Notifications for   {alertNotifyLinkIp}</DialogTitle>


            <DialogContent dividers style={{ maxHeight: '300px' }}>
                <List>

                    <ListItem>
                        <ListItemText primary="Utilization Alert" />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                checked={utilAlertStatus === 1}
                                onChange={handleUtilizationToggle}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>


                    <ListItem>
                        <ListItemText primary="Pause Status" />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                checked={pauseStatus === 1}
                                onChange={handlePauseToggle}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </DialogContent>


            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
                {/* <Button onClick={handleSave} color="primary">
          Save
        </Button> */}
            </DialogActions>
        </Dialog>
    );
}

