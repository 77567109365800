import HomeIcon from '@mui/icons-material/Home';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BusinessIcon from '@mui/icons-material/Business';
import LinkIcon from '@mui/icons-material/Link';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppsIcon from '@mui/icons-material/Apps';
import InventoryIcon from '@mui/icons-material/Inventory';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';

const navConfig = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    children: [
      {
        title: 'Overview',
        icon: <DashboardIcon />,
        to: '/dashboard/home',
      },
      {
        title: 'Links',
        icon: <LinkIcon />,
        to: '/dashboard/isp-management',
      },
      {
        title: 'Edge Devices',
        icon: <EdgesensorHighIcon />,
        to: '/dashboard/edge-device-management',
      },
      {
        title: 'Sites',
        icon: <BusinessIcon />,
        to: '/dashboard/ISP-sitewise',
      },
      {
        title: 'App Monitor',
        icon: <AppsIcon />,
        to: '/dashboard/sa-monitor',
      },
      {
        title: 'Insights',
        icon: <InsightsIcon />,
        to: '/dashboard/power-bi-insights',
      },
      {
        title: 'Reports',
        icon: <SummarizeIcon />,
        to: '/dashboard/isp-reports',
      },
      {
        title: 'Inventory',
        icon: <InventoryIcon />,
        to: '/dashboard/link-devices-inventory',
      },
    ]
  },
    {
      title: 'Logs',
      icon: <WebStoriesIcon />,
      children: [
        {
          title: 'Events',
          icon: <CalendarMonthIcon />,
          to: '/dashboard/alerts',
        },
        {
          title: 'Audit Logs',
          icon: <AssessmentIcon />,
          to: '/dashboard/audit-logs',
        },
        {
          title: 'System Logs',
          icon: <SystemUpdateAltIcon />,
          to: '/dashboard/system-logs',
        },

      ]
    }
];

export default navConfig;