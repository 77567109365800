import axios from 'axios';

const GetSiteUploadDownloadGraph = async (locationId, time, setUploadData, setDownloadData, setTimeData) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_site_upload_download.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "location_id": locationId,
    "time": time
  });
 
  // console.log(payload)
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
        setUploadData(response.data.upload_speed_data);
        setDownloadData(response.data.download_speed_data);
        setTimeData(response.data.time_data); 

    } else {
        setUploadData([]);
        setDownloadData([]);
        setTimeData([]);

    }
  });
};

export default GetSiteUploadDownloadGraph;
