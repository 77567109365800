import axios from "axios";



const GetVendorDetailsApiCall = async (setVendors) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_vendor_details_for_msp.php" : "get_vendor_details.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setVendors(response.data.vendors_data);
        }
        else {
            setVendors(response.data.locations[0])
        }
    })
}

export default GetVendorDetailsApiCall;
