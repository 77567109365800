// @mui
import { Box, Button, Card, CardHeader, Paper, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';


import { useLocation } from 'react-router-dom';

import _ from "lodash";
// components
import LocationSingleISPData from './LocationSingleISPData';
// import timeArray from '../../../../filowire-ui-main -13-11-2022/src/components/ISPManagementTable/ISPManagementDashboard/TimeData';
/* eslint-disable eqeqeq */

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});
export default function LocationSpecificDownData(props) {

  const { locationData } = props;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (

    <>
      {locationData.location.isp_wan_ids.map((ispWanId, index) =>
      (
        <LocationSingleISPData ispWanId={ispWanId} key={ispWanId+index} />
      )
      )}
    </>
  );
}
