import React from 'react'
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

import Page from '../components/Page';
// import ProbeComponents from '../components/ProbeComponents/ProbeComponents';
import ProbeComponents from '../components/ProbeComponents/ProbeComponents';

export default function InternalProbes() {
    return (
        <Page title="InternalProbes">
            <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard/">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                        Internal Probes Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/internal-probes">
                        InternalProbes
                    </Link>
                </Breadcrumbs>
            </div>
            <Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper elevation={15}>
                        <ProbeComponents />
                    </Paper>
                </Grid>
            </Grid>

        </Page>
    )
}
