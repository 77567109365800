import React, {  useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import AvgSiteUptimePercentComponent from './components/AvgSiteUptimePercentComponent';
import AvgLinkUptimePercentComponent from './components/AvgLinkUptimePercentComponent';
import SLABreachesComponent from './components/SLABreachesComponent';
import TotalDownTimeComponent from './components/TotalDownTimeComponent';
import LinksComponent from './components/LinksComponent';
import SitesComponent from './components/SitesComponent';
import DropDownGroupComponent from './components/DropDownGroupComponent';
import OsmMapComponentV2 from './components/OsmMapComponentV2';
import OsmMapComponent from './components/OsmMapComponent';
import { useGlobalState } from '../../globalHooks/GlobalState';

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
}

export default function HomeComponent() {

    const currentDate = formatDate(new Date());
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [locationId, setLocationId] = useState("");
    // const [locationId, setLocationId] = useState(-1);
    const [forceGroupDetailsRefresh, setForceGroupDetailsRefresh] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [fullScreenChecking,setFullScreenChecking] = useState(false);
    const [selectedGroupNames, setSelectedGroupNames] = useState([]);
    const custId = localStorage.getItem("cust_id")

    const { buState, setBuState } = useGlobalState();

    const handleDropDownGroupSelectionChange = (newSelectedGroupIds) => {
        setSelectedGroupIds(newSelectedGroupIds);
        setLocationId("");
    };

    useEffect(() => {
        setLocationId("");
    },[buState])

    return (
        <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                {/* {custId === "110" && <OsmMapComponentV2 selectedGroupIds={selectedGroupIds} setLocationId={setLocationId} setSelectedGroupIds={setSelectedGroupIds} setForceGroupDetailsRefresh={setForceGroupDetailsRefresh} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} fullScreenChecking={fullScreenChecking} setFullScreenChecking={setFullScreenChecking} selectedGroupNames={selectedGroupNames} setSelectedGroupNames={setSelectedGroupNames}/>} */}
                {/* {custId !== "110" && <OsmMapComponent selectedGroupIds={selectedGroupIds} setLocationId={setLocationId} setSelectedGroupIds={setSelectedGroupIds} setForceGroupDetailsRefresh={setForceGroupDetailsRefresh} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} fullScreenChecking={fullScreenChecking} setFullScreenChecking={setFullScreenChecking} selectedGroupNames={selectedGroupNames} setSelectedGroupNames={setSelectedGroupNames}/>} */}
                 <OsmMapComponentV2 selectedGroupIds={selectedGroupIds} setLocationId={setLocationId} setSelectedGroupIds={setSelectedGroupIds} setForceGroupDetailsRefresh={setForceGroupDetailsRefresh} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} fullScreenChecking={fullScreenChecking} setFullScreenChecking={setFullScreenChecking} selectedGroupNames={selectedGroupNames} setSelectedGroupNames={setSelectedGroupNames}/>
            </Grid>
            {isFullScreen ? <></> : <>
            <Grid item xs={12} sm={12} md={6} lg={6} data-tut="linkeyetour_home_avgsitelinksla">
                <Grid container spacing={3}>
                    {localStorage.getItem("user_map_status") !== "1" && <Grid item xs={12} sm={12} md={12} lg={12} >
                        <DropDownGroupComponent setSelectedGroupIds={setSelectedGroupIds} currentDate={currentDate} onSelectionChange={handleDropDownGroupSelectionChange} forceGroupDetailsRefresh={forceGroupDetailsRefresh} fullScreenChecking={fullScreenChecking} selectedGroupNames={selectedGroupNames} setSelectedGroupNames={setSelectedGroupNames} isFullScreen={isFullScreen}/>
                    </Grid>}
                    <Grid item xs={6} sm={6} md={6} lg={6} >
                        <AvgSiteUptimePercentComponent selectedGroupIds={selectedGroupIds} locationId={locationId} currentDate={currentDate} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} >
                        <AvgLinkUptimePercentComponent selectedGroupIds={selectedGroupIds} locationId={locationId} currentDate={currentDate} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <SLABreachesComponent selectedGroupIds={selectedGroupIds} locationId={locationId} currentDate={currentDate} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <TotalDownTimeComponent selectedGroupIds={selectedGroupIds} locationId={locationId} currentDate={currentDate} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingBottom: "30px" }} data-tut="linkeyetour_home_bestworstperforminglinkslist">
                <LinksComponent selectedGroupIds={selectedGroupIds} setSelectedGroupIds={setSelectedGroupIds} locationId={locationId} setLocationId={setLocationId} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} data-tut="linkeyetour_home_bestworstperformingsiteslist">
                <SitesComponent selectedGroupIds={selectedGroupIds} setSelectedGroupIds={setSelectedGroupIds} locationId={locationId} setLocationId={setLocationId} />
            </Grid>
            </>
}
        </Grid>
    );
}