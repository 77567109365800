import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, FormHelperText, FormControlLabel, Divider, DialogContentText, FormLabel, Radio, RadioGroup, Snackbar, Alert } from '@mui/material';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import { EdgeDeviceCustumTagApiCall } from './EdgeDeviceCustumTagApiCall';



export default function EdgeDeviceCustumTagDialog(props) {
    // openEdgeDeviceCustumTag={openEdgeDeviceCustumTag} setOpenEdgeDeviceCustumTag={setOpenEdgeDeviceCustumTag} setEdCustumTagStatus={setEdCustumTagStatus} setEdCustumTagMessage={setEdCustumTagMessage}
    const {openEdgeDeviceCustumTag,setOpenEdgeDeviceCustumTag,setEdCustumTagStatus,setEdCustumTagMessage} = props;
    // const [edCustumTagStatus, setEdCustumTagStatus] = useState(null);
    // const [edCustumTagMessage, setEdCustumTagMessage] = useState('');
    const { globalState, setGlobalState } = useGlobalState();
    const [isMandatory, setIsMandatory] = useState(2);
    const [Name, setName] = useState("")
    const [defaultValue, setDefaultValue] = useState("")
    const [isMandatoryHelperText, setIsMandatoryHelperText] = useState('');
    const [NameHelperText, setNameHelperText] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState('')

    const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case "name-input":
                setName(value);
                if (value.trim() === "") {
                    setNameHelperText("Name is required");
                } else {
                    setNameHelperText("");
                }
                break;

            default:
                break;
        }
    };

    const handleSubmit = async (event) => {


        // define validation here
        if (Name === "") {
            setNameHelperText("Name is required");
            return;
        }

        const data = {
            entity: "Device", // fixed to device
            name: Name,
            isMandatory: isMandatory === 2,
            defaultValue: defaultValue || null
        };



        await EdgeDeviceCustumTagApiCall(data, setEdCustumTagStatus, setEdCustumTagMessage);
        setOpenEdgeDeviceCustumTag(false);
        // reset the values
        setName('');
        setIsMandatory(2);
        setDefaultValue('');
    };

    return (
        <>
            <Dialog
                open={openEdgeDeviceCustumTag}
                onClose={() => { setOpenEdgeDeviceCustumTag(false) }}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Device Custom Tag

                    <IconButton onClick={() => { setOpenEdgeDeviceCustumTag(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <form>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1, px: 12}}>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="entity-select-label">Entity</InputLabel>
                                <Select
                                    labelId="entity-select-label"
                                    id="entity-select"
                                    value="Device" // Setting  to device by default
                                    style={{ width: "400px" }}
                                    label="Entity"
                                    disabled
                                >
                                    <MenuItem value="Device">
                                        DEVICE
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    id="name-input"
                                    label="Name"
                                    variant="outlined"
                                    value={Name}
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={NameHelperText}
                                    error={!!NameHelperText}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl component="fieldset" sx={{ m: 1, minWidth: 120, px:1 }}>
                                <FormLabel component="legend">Is Mandatory</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="isMandatory"
                                    name="is-Mandatory"
                                    value={isMandatory}
                                    onChange={(e) => { setIsMandatory(Number(e.target.value)); }}
                                >
                                    <FormControlLabel value={2} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={3} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>

                                <TextField
                                    id="Default-Value"
                                    label="Default Value (optional)"
                                    variant="outlined"
                                    value={defaultValue}
                                    placeholder="Enter Value"
                                    onChange={(e) => setDefaultValue(e.target.value)}

                                    fullWidth
                                    style={{ width: "400px" }} // Adjust the width as needed
                                />
                            </FormControl>
                        </Grid>


                    </Grid>
                </form>
                <DialogActions>
                    <Button onClick={() => { handleSubmit(); }} variant="contained" color="primary">
                        Add
                    </Button>
                    <Button onClick={() => { setOpenEdgeDeviceCustumTag(false); }} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
                
            </Dialog>
        </>
    );
}