import React, { useState, useEffect, useParams } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, Typography, Box, DialogContentText, Link } from '@mui/material';

import CheckEdLanStatusApiCall from './CheckEdLanStatusApiCall';
import CheckEdHealthStatusApiCall from './CheckEdHealthStatusApiCall';
import CheckWanInterfaceStatusApiCall from './CheckWanInterfaceStatusApiCall';
import CheckWanIpReachableStatusApiCall from './CheckWanIpReachableStatusApiCall';
import CheckDgIpReachableStatusApiCall from './CheckDgIpReachableStatusApiCall';
import CheckSiteDownStatusApiCall from './CheckSiteDownStatusApiCall';
import CheckRechabilityOverInternetApiCall from './CheckRechabilityOverInternetApiCall';
import LargeLine from './LargeLine';

export default function TroubleshootComponent(props) {
    let gwInternetTimer;
    const { openTroubleshoot, setOpenTroubleshoot, ispParams } = props;

    const custId = localStorage.getItem("cust_id");


    const [edAddedStatus, setEdAddedStatus] = useState(false);

    const [gwInternetStatus, setGwInternetStatus] = useState(false);
    const [siteDownStatus, setSiteDownStatus] = useState(false);
    const [edLanStatus, setEdLanStatus] = useState(false);
    const [edPortStatus, setEdPortStatus] = useState(false);
    const [edHealthStatus, setEdHealthStatus] = useState(false);
    const [edInterfaceStatus, setEdInterfaceStatus] = useState(false);
    const [wanIpLanStatus, setWanIpLanStatus] = useState(false);
    const [internetRechStatus, setInternetRechStatus] = useState(false);
    const [dgLanStatus, setDgLanStatus] = useState(false);

    const [gotGwInternetStatus, setGotGwInternetStatus] = useState(false);
    const [gotSiteDownStatus, setGotSiteDownStatus] = useState(false);
    const [gotEdLanStatus, setGotEdLanStatus] = useState(false);
    const [gotEdPortStatus, setGotEdPortStatus] = useState(false);
    const [gotEdHealthStatus, setGotEdHealthStatus] = useState(false);
    const [gotEdInterfaceStatus, setGotEdInterfaceStatus] = useState(false);
    const [gotWanIpLanStatus, setGotWanIpLanStatus] = useState(false);
    const [gotInternetRechStatus, setGotInternetRechStatus] = useState(false);
    const [gotDgLanStatus, setGotDgLanStatus] = useState(false);

    const [continueSiteDownStatus, setContinueSiteDownStatus] = useState(false);
    const [continueEdLanStatus, setcontinueEdLanStatus] = useState(false);
    const [continueEdPortStatus, setContinueEdPortStatus] = useState(false)
    const [continueEdHealthStatus, setContinueEdHealthStatus] = useState(false);
    const [continueEdInterfaceStatus, setContinueEdInterfaceStatus] = useState(false);
    const [continueWanIpLanStatus, setContinueWanIpLanStatus] = useState(false);
    const [continueInternetRechStatus, setContinueInternetRechStatus] = useState(false);
    const [continueDgLanStatus, setContinueDgLanStatus] = useState(false);

    const [code, setCode] = useState(-1);

    const [siteDownStatusMessage, setSiteDownStatusMessage] = useState("");
    const [edLanStatusMessage, setEdLanStatusMessage] = useState("");
    const [edPortStatusMessage, setEdPortStatusMessage] = useState("");
    const [edHealthStatusMessage, setEdHealthStatusMessage] = useState("");
    const [edInterfaceMessage, setEdInterfaceMessage] = useState("");
    const [wanIpReachableMessage, setWanIpReachableMessage] = useState("");
    const [dgIpReachableMessage, setDgIpReachableMessage] = useState("");
    const [internetRechStatusMessage, setInternetRechStatusMessage] = useState("");

    const [intDgLatency, setIntDgLatency] = useState("");
    const [intDgPacketLoss, setIntDgPacketLoss] = useState("");

    const [extDgLatency, setExtDgLatency] = useState("");
    const [extDgPacketLoss, setExtDgPacketLoss] = useState("");

    const [detailSiteDownStatusMessage, setDetailSiteDownStatusMessage] = useState("");
    const [detailEdLanStatusMessage, setDetailEdLanStatusMessage] = useState("");
    const [detailEdPortStatusMessage, setDetailEdPortStatusMessage] = useState("");
    const [detailEdHealthStatusMessage, setDetailEdHealthStatusMessage] = useState("");
    const [detailEdInterfaceMessage, setDetailEdInterfaceMessage] = useState("");
    const [detailWanIpReachableMessage, setDetailWanIpReachableMessage] = useState("");
    const [detailDgIpReachableMessage, setDetailDgIpReachableMessage] = useState("");
    const [detailInternetRechStatusMessage, setDetailInternetRechStatusMessage] = useState("");

    const [rcaSiteDownStatusMessage, setRcaSiteDownStatusMessage] = useState("");
    const [rcaEdLanStatusMessage, setRcaEdLanStatusMessage] = useState("");
    const [rcaEdPortStatusMessage, setRcaEdPortStatusMessage] = useState("");
    const [rcaEdHealthStatusMessage, setRcaEdHealthStatusMessage] = useState("");
    const [rcaEdInterfaceMessage, setRcaEdInterfaceMessage] = useState("");
    const [rcaWanIpReachableMessage, setRcaWanIpReachableMessage] = useState("");
    const [rcaDgIpReachableMessage, setRcaDgIpReachableMessage] = useState("");
    const [rcaInternetRechStatusMessage, setRcaInternetRechStatusMessage] = useState("");


    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenTroubleshoot(false);
    }

    const checkDgLanStatus = () => {
        CheckDgIpReachableStatusApiCall(ispParams.isp_wan_id, setDgLanStatus, setGotDgLanStatus, setDgIpReachableMessage, setIntDgLatency, setIntDgPacketLoss, setDetailDgIpReachableMessage, setRcaDgIpReachableMessage);
    }

    useEffect(() => {
        if (continueDgLanStatus) {
            checkDgLanStatus();
        }
    }, [continueDgLanStatus])

    const checkWanIpLanStatus = async () => {
        CheckWanIpReachableStatusApiCall(ispParams.isp_wan_id, setWanIpLanStatus, setGotWanIpLanStatus, setWanIpReachableMessage, setContinueDgLanStatus, setDetailWanIpReachableMessage, setRcaWanIpReachableMessage);
    }

    useEffect(() => {
        if (continueWanIpLanStatus) {
            checkWanIpLanStatus();
        }
    }, [continueWanIpLanStatus])

    const checkEdInterfaceStatus = async () => {
        await CheckWanInterfaceStatusApiCall(ispParams.isp_wan_id, setEdInterfaceStatus, setGotEdInterfaceStatus, setEdInterfaceMessage, setContinueWanIpLanStatus, setDetailEdInterfaceMessage, setRcaEdInterfaceMessage)
    }

    useEffect(() => {
        if (continueEdInterfaceStatus) {
            checkEdInterfaceStatus();
        }
    }, [continueEdInterfaceStatus])

    const checkEdHelathStatus = async () => {
        await CheckEdHealthStatusApiCall(ispParams.isp_wan_id, setEdHealthStatus, setGotEdHealthStatus, setEdHealthStatusMessage, setContinueEdInterfaceStatus)
    }

    const checkEdLanStatus = async () => {
        await CheckEdLanStatusApiCall(ispParams.isp_wan_id, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage)
    }

    useEffect(() => {
        if (continueEdHealthStatus) {
            checkEdHelathStatus();
        }
    }, [continueEdHealthStatus])

    const checkSiteDownStatus = async () => {
        await CheckSiteDownStatusApiCall(ispParams.isp_wan_id, setSiteDownStatus, setGotSiteDownStatus, setSiteDownStatusMessage, setcontinueEdLanStatus, setDetailSiteDownStatusMessage, setRcaSiteDownStatusMessage)
    }

    useEffect(() => {
        if (continueEdLanStatus) {
            checkEdLanStatus();
        }
    }, [continueEdLanStatus])

    const checkRechabilityOverInternet = async () => {
        await CheckRechabilityOverInternetApiCall(ispParams.isp_wan_id, setInternetRechStatus, setGotInternetRechStatus, setInternetRechStatusMessage, setContinueDgLanStatus, setDetailInternetRechStatusMessage, setRcaInternetRechStatusMessage)
    }

    useEffect(() => {
        if (continueEdLanStatus) {
            checkRechabilityOverInternet();
        }
    }, [continueEdLanStatus])

    useEffect(() => {
        gwInternetTimer = setTimeout(() => {
            setGwInternetStatus(ispParams.dg_reach_status === 1);
            setGotGwInternetStatus(true);
            if (ispParams.dg_reach_status === 1) {
                setExtDgLatency(ispParams.dg_latency);
                setExtDgPacketLoss(ispParams.dg_packet_loss);
                setContinueSiteDownStatus(true);
                checkSiteDownStatus();
            }
            setTimeout(() => {
                clearTimeout(gwInternetTimer)
            }, 1000)
        }, 1000);
        // return () => clearTimeout(timer);
    }, []);


    return (
        <Dialog
            open={openTroubleshoot}
            onClose={handleClose}
            maxWidth="xl"
        >
            <DialogTitle>Troubleshoot</DialogTitle>
            <Divider />
            <DialogContent>
                <List
                    sx={{ width: '1200px', maxWidth: '2400px', bgcolor: 'background.paper' }}
                >
                    {
                        ispParams.link_type !== 'Dynamic Broadband' && (
                            <>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ChevronRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Checking WAN-Gateway(PE-IP) Reachability from Internet" />
                                    <ListItemIcon>
                                        <ListItemIcon>
                                            {!gotGwInternetStatus && !gwInternetStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                            {gotGwInternetStatus && gwInternetStatus && <CheckIcon style={{ color: "green" }} />}
                                            {gotGwInternetStatus && !gwInternetStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            {gotGwInternetStatus && gwInternetStatus && <Typography>Gateway Reachable from Internet (Latency: {extDgLatency}ms & Packet Loss: {extDgPacketLoss}%)</Typography>}
                                            {gotGwInternetStatus && !gwInternetStatus && <Typography>Gateway Not Reachable from Internet</Typography>}
                                        </ListItemIcon>
                                    </ListItemIcon>
                                </ListItemButton>
                                <Divider />
                            </>
                        )
                    }

                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking Site Status" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueSiteDownStatus && !gotSiteDownStatus && gotGwInternetStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && !siteDownStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && siteDownStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && (siteDownStatus ? "Site is Down" : "Site is Up")}
                                secondary={
                                    gotSiteDownStatus && siteDownStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking Edge Device Reachability from LAN" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdLanStatus && !gotEdLanStatus && gotSiteDownStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && edLanStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && !edLanStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}

                            </ListItemIcon>

                            <ListItemText
                                primary={continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && (edLanStatus ? edLanStatusMessage : edLanStatusMessage)}
                                secondary={
                                    gotEdLanStatus && !edLanStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />

                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking WAN Port Status on Edge Device" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdPortStatus && !gotEdPortStatus && gotEdLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdPortStatus && gotEdPortStatus && gotEdLanStatus && edPortStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueEdPortStatus && gotEdPortStatus && gotEdLanStatus && !edPortStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>

                            <ListItemIcon>
                                {gotEdPortStatus && gotSiteDownStatus && gotEdLanStatus && `${edLanStatusMessage}`}
                            </ListItemIcon>

                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    {/* <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edge Device Health" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdHealthStatus && !gotEdHealthStatus && gotEdLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdHealthStatus && gotEdHealthStatus && gotEdLanStatus && edHealthStatus &&  <CheckIcon style={{ color: "green" }} /> }
                                {continueEdHealthStatus && gotEdHealthStatus && gotEdLanStatus && !edHealthStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemIcon>
                                {continueEdHealthStatus && gotEdHealthStatus && gotEdLanStatus && `${edHealthStatusMessage}`}
                            </ListItemIcon>
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider /> */}

                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking WAN Interface Status on the Edge Device" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdInterfaceStatus && !gotEdInterfaceStatus && gotEdLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdLanStatus && edInterfaceStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdLanStatus && !edInterfaceStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdLanStatus && (edInterfaceStatus ? edInterfaceMessage : edInterfaceMessage)}
                                secondary={
                                    gotEdInterfaceStatus && !edInterfaceStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    {/* custId !== '91' && */}
                    {/* { (
                        <> */}
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking WAN IP(CE-IP) Configuration on Edge Device" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueWanIpLanStatus && !gotWanIpLanStatus && gotEdInterfaceStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && wanIpLanStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && !wanIpLanStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && (wanIpLanStatus ? wanIpReachableMessage : wanIpReachableMessage)}
                                secondary={
                                    gotWanIpLanStatus && !wanIpLanStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />

                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking Reachability to Internet" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueInternetRechStatus && !gotInternetRechStatus && gotWanIpLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && internetRechStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && !internetRechStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && (internetRechStatus ? internetRechStatusMessage : internetRechStatusMessage)}
                                secondary={
                                    gotInternetRechStatus && !internetRechStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />

                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="WAN-Gateway(CE-IP) Reachability from LAN" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueDgLanStatus && !gotDgLanStatus && gotWanIpLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueDgLanStatus && gotDgLanStatus && gotWanIpLanStatus && dgLanStatus && <CheckIcon />}
                                {continueDgLanStatus && gotDgLanStatus && gotWanIpLanStatus && !dgLanStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={gotDgLanStatus && gotWanIpLanStatus && (dgLanStatus ? `${dgIpReachableMessage} (Latency: ${intDgLatency}ms & Packet Loss: ${intDgPacketLoss}%)` : dgIpReachableMessage)}
                                secondary={
                                    gotWanIpLanStatus && !wanIpLanStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    {/* </>
                    )} */}

                </List>
                <Divider />
                {gotGwInternetStatus && !gwInternetStatus &&
                    <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                        <Typography variant='h6'>
                            Looks like connection is broken in internet cloud. Plz contact service provider.
                        </Typography>
                    </Box>
                }

                {/* For Checking Site Status  */}
                {gotSiteDownStatus && siteDownStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                {rcaSiteDownStatusMessage}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailSiteDownStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} component="div" variant='h6' >
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }





                {/* For Edge Device Reachability */}
                {gotEdLanStatus && !edLanStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                {rcaEdLanStatusMessage}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailEdLanStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }




                {/* For WAN Inteface Status on Edge Device */}
                {gotEdInterfaceStatus && !edInterfaceStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                {/* Wan-Interface on edge device is down. Check cable/interface status. OR {edInterfaceMessage} */}
                                {rcaEdInterfaceMessage}
                            </Typography>
                        </Box>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailEdInterfaceMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </>
                }

                {/* <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {detailEdInterfaceMessage.split('\n').map((line, index) => (
                                <Typography key={index} variant='h6'>
                                    {line}
                                </Typography>
                            ))
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> */}



                {/* for WAN IP Configuration on Edge Devices */}
                {gotWanIpLanStatus && !wanIpLanStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                {rcaWanIpReachableMessage}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailWanIpReachableMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }

                {/* <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {detailWanIpReachableMessage.split('\n').map((line, index) => (
                                <Typography key={index} variant='h6'>
                                    {line}
                                </Typography>
                            ))
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> */}


                {/* For WAN Gateway Reachable From LAN Options */}
                {gotDgLanStatus && !dgLanStatus && (dgIpReachableMessage !== "Invalid Credentials") &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                Link is broken between Edge Device to Wan-Gateway. Please contact service provider.
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailEdInterfaceMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }


                {/* For Internet Reachable  */}
                {gotInternetRechStatus && !internetRechStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography variant='h6'>
                                {rcaInternetRechStatusMessage}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailInternetRechStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }


                {/* <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Detailed Information"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {detailEdInterfaceMessage.split('\n').map((line, index) => (
                                <Typography key={index} variant='h6'>
                                    {line}
                                </Typography>
                            ))
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> */}


                {gotDgLanStatus && !dgLanStatus && (dgIpReachableMessage === "Invalid Credentials") &&
                    <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                        <Typography variant='h6'>
                            Invalid Credentials for Edge Device. Hence, DG Reachability cannot be checked.
                        </Typography>
                    </Box>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
