import axios from 'axios';

// API CALL CODE HERE
const PauseEdgeDeviceApiCall = async (deviceId, deviceIp, pauseStatus, setOpen) => {
  const pauseBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/pause_edge_device.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "device_id": deviceId,
    "device_ip": deviceIp,
    "pause_status": pauseStatus
  });
  return axios.post(pauseBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setOpen(true);
    }
    else{
      setOpen(false);

    }
  }).catch((error)=>{
    setOpen(false);

  })
}

export default PauseEdgeDeviceApiCall;
