import axios from 'axios';

// API CALL CODE HERE

export const CheckOtpStatusApiCall = async (email, code, setLoginStatus) => {

  
const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_otp_trails.php`;

  const payload = JSON.stringify({
      "email": email,
      "code": code,
      "otp_auth_token": localStorage.getItem("otp_auth_token")
  }
  );

  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 1) {
        localStorage.setItem("message", response.data.message);
        localStorage.setItem("updated_at", response.data.updated_at);
        return response.data;
      }
      if (response.data.code === 0) {
        setLoginStatus(-1);
        localStorage.setItem("update_status", false);
      } 

      throw new Error('Limit Exceeded')
      

    }).catch(() => {
      setLoginStatus(-1);
      throw new Error('OTP Failed: network related')

    })
}

