import React from 'react'
import ViewUsersTable from './ViewUsersTable'

export default function ViewUsersComponents() {
  return (
    <>
        <ViewUsersTable />
    </>
  )
}
