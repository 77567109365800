import axios from "axios";


export const EdCard1RestCallForCard = async (deviceId, setEdParams, setUpStatus = null, setGoodStatus = null) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_single_edge_device_present_data_for_msp.php" : "get_single_edge_device_present_data.php";
    const baseURLPresentData = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLPresentData, payload).then((response) => {
        if (response.data.code === 1) {
            setEdParams(response.data.ed_data);
            if (setUpStatus !== null) {
                if (response.data.ed_data.packet_loss === 100) {
                    setUpStatus(false);
                }
                else {
                    setUpStatus(true);
                }

                if (parseFloat(response.data.ed_data.jitter) < 150 && parseFloat(response.data.ed_data.avg_rtt) < 150) {
                    setGoodStatus(true);
                }
                else {
                    setGoodStatus(false);
                }
            }
        }
        else
        {
            setEdParams({});
        }
    });
}

export const EdCard1RestCallForGraphCard = async (deviceId, timeValue, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_edge_device_latency_jitter_for_msp.php" : "get_edge_device_latency_jitter.php";
    const baseURLGraphData = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId,
        "time": timeValue
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId,
        "time": timeValue,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
 
    await axios.post(baseURLGraphData, payload).then((response) => {
        if (response.data.code === 1) {
            setJitterData(response.data.jitter_data);
            setLatencyData(response.data.latency_data);
            setPacketLossData(response.data.packet_loss_data);
            setStatusData(response.data.up_down_status_data);
            setTimeData(response.data.time_data);
            setStatusTimeData(response.data.up_down_time);
            setUpDownSeriesData(response.data.up_down_series_data);
            setOpenBackdrop(false);
        }
        else
        {
            setJitterData([]);
            setLatencyData([]);
            setPacketLossData([]);
            setStatusData([]);
            setTimeData([]);
            setStatusTimeData([]);
            setUpDownSeriesData([]);
            setOpenBackdrop(false);
        }
    }).catch(()=>{
            setJitterData([]);
            setLatencyData([]);
            setPacketLossData([]);
            setStatusData([]);
            setTimeData([]);
            setStatusTimeData([]);
            setUpDownSeriesData([]);
            setOpenBackdrop(false);
    });
}



export const EdCard1RestCallForGraphCardByDate = async (deviceId, date, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_edge_device_latency_jitter_by_date_for_msp.php" : "get_edge_device_latency_jitter_by_date.php";
    const baseURLGraphDataByDate = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId,
        "date": date
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_id": deviceId,
        "date": date,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLGraphDataByDate, payload).then((response) => {
        if (response.data.code === 1) {
            setJitterData(response.data.jitter_data);
            setLatencyData(response.data.latency_data);
            setPacketLossData(response.data.packet_loss_data);
            setStatusData(response.data.up_down_status_data);
            setTimeData(response.data.time_data);
            setStatusTimeData(response.data.up_down_time);
            setUpDownSeriesData(response.data.up_down_series_data);
            setOpenBackdrop(false);
        }
    });
}

