import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    Box,
    OutlinedInput,
    Chip,
    Divider
} from '@mui/material';
import CancelIcon from "@material-ui/icons/Cancel";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';


// Api Import
// import AddNewVendorApiCall from './AddNewVendorApiCall';
import GetTemplateParameterApiCall from '../RestApis/GetTemplateParameterApiCall';
import GetZonesApiCall from '../RestApis/GetZonesApiCall';
import GetLocationsByZones from '../RestApis/GetLocationsByZones';

function getStyles(name, selectedName, theme) {
    return {
        fontWeight:
            selectedName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function AddTemplateDialog(props) {
    const { openAddDialog, setOpenAddDialog } = props;

    const theme = useTheme();

    const [templateName, setTemplateName] = useState("");
    const [zones, setZones] = useState(['All', 'zone1', 'zone2', 'zone-3']);
    const [selectedZones, setSelectedZones] = useState([]);

    const [locations, setLocations] = useState(['All', 'Loc-1', 'Loc-2', 'Loc-3', 'Loc-4']);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [cities, setCities] = useState(['All', 'Bangalore', 'Mumbai', 'Chennai', 'Dehli']);
    const [selectedCities, setSelectedCities] = useState([]);

    const [countries, setCountries] = useState(['All', 'India', 'Bangladesh', 'Shrilanka']);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const [regions, setRegions] = useState(['All', 'South-East', 'South-West', 'Europe', 'Noath-America']);
    const [selectedRegions, setSelectedRegions] = useState([]);

    const [reportTypes, setReportTypes] = useState(['All', 'Vendorwise', 'Linkwise', 'Sitewise']);
    const [selectedReportTypes, setSelectedReportTypes] = useState([]);

    const [frequency, setFrequency] = useState(0);
    const [timeStamp, setTimeStamp] = useState("");
    const [pDt, setPDt] = useState('');
    const [oneTimeStartTime, setOneTimeStartTime] = useState("");
    const [oneTimeEndTime, setOneTimeEndTime] = useState("");
    const [recurFrequency, setRecurFrequency] = useState(0);

    const _GetZones = () => {
        GetZonesApiCall(setZones);
    }

    useEffect(() => {
        const date = new Date();
        const options = { timeZone: 'Asia/Kolkata' };
        const formattedDate = date.toLocaleString('en-US', { ...options, month: '2-digit', day: '2-digit', year: 'numeric' });
        const formattedTime = date.toLocaleString('en-US', { ...options, hour: '2-digit', minute: '2-digit', hour12: true });
        const currentIndianTime = `${formattedDate} ${formattedTime}`;
        setTimeStamp(dayjs(currentIndianTime));

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const dateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
        setPDt(dateTime);

        _GetZones();

    }, [])



    const handleOnZoneChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZones(
            typeof value === 'string' ? value.split(',') : value,
        );
        // setSelectedLocations([]);
        GetLocationsByZones(value, setLocations);

    };

    const handleOnLocationChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedLocations(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOnCityChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCities(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOnCountryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCountries(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOnRegionChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedRegions(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOnReportTypeChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedReportTypes(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleOnClose = () => {
        setOpenAddDialog(false);
    }

    const handleOneTimeStartDateTimeChange = (value) => {
        setTimeStamp(value);
        const date = new Date(value);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const dateTime = `${hours}:${minutes}`;
        setOneTimeStartTime(dateTime);
    }

    const handleOneTimeEndDateTimeChange = (value) => {
        setTimeStamp(value);
        const date = new Date(value);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const dateTime = `${hours}:${minutes}`;
        setOneTimeEndTime(dateTime);
    }

    const handleRecurTimeChange = (value) => {
        const date = new Date(value);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const dateTime = `${hours}:${minutes}`;
        setPDt(dateTime);
    }

    // const handleDeleteLocationChip=(value)=>{
    //     console.log("On Delete Chip")
    //     setSelectedLocations(selectedLocations.filter((item)=>item !== value));
    // }

    const handleDeleteLocationChip = (chipToDelete, event) => {
        event.stopPropagation();
        setSelectedLocations((prevSelectedLocations) =>
            prevSelectedLocations.filter((location) => location !== chipToDelete)
        );
    }


    const handleOnSave = () => {
        // AddNewVendorApiCall(vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setAddVendorStatus);
        handleOnClose();
    }
    return (
        <>
            <Dialog open={openAddDialog} onClose={handleOnClose} fullWidth maxWidth="xl" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    New Template
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Template Name" id="template_name" value={templateName} onChange={(e) => { setTemplateName(e.target.value) }} helperText="" variant="outlined" style={{ width: "730px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-region-label-id">Region</InputLabel>
                                    <Select
                                        labelId="select-region-id"
                                        id="select-region-id"
                                        label="Region"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedRegions}
                                        onChange={handleOnRegionChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {regions.map((region) => (
                                            <MenuItem
                                                key={region}
                                                value={region}
                                                style={getStyles(region, selectedRegions, theme)}
                                            >
                                                {region}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-country-label-id">Country</InputLabel>
                                    <Select
                                        labelId="select-country-id"
                                        id="select-country-id"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedCountries}
                                        onChange={handleOnCountryChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {countries.map((country) => (
                                            <MenuItem
                                                key={country}
                                                value={country}
                                                style={getStyles(country, selectedCountries, theme)}
                                            >
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-zones">Zone</InputLabel>
                                    <Select
                                        labelId="select-zones"
                                        id="demo-multiple-chip"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedZones}
                                        onChange={handleOnZoneChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem key={'All'} value={'All'}>All</MenuItem>
                                        {zones.map((zone) => (
                                            <MenuItem
                                                key={zone.zone_name}
                                                value={zone.zone_name}
                                                style={getStyles(zone, selectedZones, theme)}
                                            >
                                                {zone.zone_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-cities-label-id">Cities</InputLabel>
                                    <Select
                                        labelId="select-cities-id"
                                        id="select-cities-id"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedCities}
                                        onChange={handleOnCityChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {cities.map((city) => (
                                            <MenuItem
                                                key={city}
                                                value={city}
                                                style={getStyles(city, selectedCities, theme)}
                                            >
                                                {city}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-locations-label-id">Sites</InputLabel>
                                    <Select
                                        labelId="select-locations-id"
                                        id="select-locations-id"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedLocations}
                                        onChange={handleOnLocationChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={value}
                                                        clickable
                                                        deleteIcon={
                                                            <CancelIcon
                                                                onMouseDown={(event) => event.stopPropagation()}
                                                            />
                                                        }
                                                        onDelete={(event) => handleDeleteLocationChip(value, event)}
                                                        // onClick={() => console.log("clicked chip")}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {locations.map((location) => (
                                            <MenuItem
                                                key={location}
                                                value={location}
                                                style={getStyles(location, selectedLocations, theme)}
                                            >
                                                {location}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-reportType-label-id">ReportType</InputLabel>
                                    <Select
                                        labelId="select-reportType-id"
                                        id="select-reportType-id"
                                        label="reportType"
                                        sx={{ width: "730px" }}
                                        multiple
                                        value={selectedReportTypes}
                                        onChange={handleOnReportTypeChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {reportTypes.map((reportType) => (
                                            <MenuItem
                                                key={reportType}
                                                value={reportType}
                                                style={getStyles(reportType, selectedReportTypes, theme)}
                                            >
                                                {reportType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="Frequency">Frequency</InputLabel>
                                    <Select
                                        sx={{ width: "725px" }}
                                        labelId="Frequency"
                                        id="frequency-id"
                                        value={frequency}
                                        label="Frequency"
                                        onChange={(e) => { setFrequency(e.target.value) }}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        <MenuItem value={1}>One-Time</MenuItem>
                                        <MenuItem value={2}>Recurring</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {frequency === 1 &&
                                <>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileDateTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    {timeStamp !== "" &&
                                                        <MobileDateTimePicker
                                                            style={{ width: "730px" }}
                                                            defaultValue={dayjs(timeStamp)}
                                                            onChange={(value) => { handleOneTimeStartDateTimeChange(value) }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />}
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={[
                                                    'MobileDateTimePicker',
                                                ]}
                                            >
                                                <DemoItem>
                                                    {timeStamp !== "" &&
                                                        <MobileDateTimePicker
                                                            style={{ width: "730px" }}
                                                            defaultValue={dayjs(timeStamp)}
                                                            onChange={(value) => { handleOneTimeEndDateTimeChange(value) }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />}
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            }

                            {frequency === 2 && <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="Frequency">Frequency</InputLabel>
                                    <Select
                                        sx={{ width: "725px", my: 1, height: "60px" }}
                                        labelId="Frequency"
                                        id="frequency-id"
                                        value={recurFrequency}
                                        label="Frequency"
                                        onChange={(e) => { setRecurFrequency(e.target.value) }}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        <MenuItem value={1}>Every Day</MenuItem>
                                        <MenuItem value={2}>Every Week</MenuItem>
                                        <MenuItem value={3}>Every Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>}

                            {frequency === 2 && <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer
                                        components={[
                                            'MobileDateTimePicker',
                                        ]}
                                    >
                                        <DemoItem>
                                            {timeStamp !== "" && <MobileTimePicker defaultValue={dayjs('0000-00-00T00:00')} onChange={(val) => { handleRecurTimeChange(val) }} />}
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>}

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
