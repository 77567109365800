import axios from 'axios';

// API CALL CODE HERE
const UpdateUserDataApi = async (userId, firstName, lastName, phoneNo, emailId, userName, userRole, setUpdateStatus, setSeverStatus, setEditMSPUserMessage) => {

  const path = "update_user_details.php";
  const updateBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "user_id": userId,
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "user_role": userRole
    }
  });

  return axios.post(updateBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateStatus(1);
      setSeverStatus("success");
      setEditMSPUserMessage(response.data.message)
    }
    else {
      setUpdateStatus(-1);
      setSeverStatus("error");
      setEditMSPUserMessage(response.data.message)
    }
  }).catch((error) => {
    setUpdateStatus(-1);
    setSeverStatus("error");
  })
}

export default UpdateUserDataApi;
