import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import GetUserDetailsApiCall from './GetUserDetailsApiCall';
import UpdateZoneDetailsApiCall from './UpdateZoneDetailsApiCall';

export default function EditZoneDialog(props) {
    const { value, openEditDialog, setOpenEditDialog, setEditStatus, setEditMessage } = props;
    const [zoneName, setZoneName] = useState(value.zone_name);
    const [users, setUsers] = useState([]);
    const [probes, setProbes] = useState([]);
    const [zoneNameHelperText, setZoneNameHelperText] = useState("");

    const [jitter, setJitter] = useState(value.jitter);
    const [latency, setLatency] = useState(value.latency);
    const [packetLoss, setPacketLoss] = useState(value.packet_loss);
    const [latencyHelperText, setLatencyHelperText] = useState("");
    const [jitterHelperText, setJitterHelperText] = useState("");
    const [packetLossHelperText, setpacketLossHelperText] = useState("");

    const latencyRegex = /^\d+(\.\d+)?$/;
    const packetLossRegex = /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
    const zoneNameRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _()-]*(?<![-_( ])$/;

    useEffect(() => {
        GetUserDetailsApiCall(setUsers, setProbes);
    }, [])

    const handleOnClose = () => {
        setOpenEditDialog(false);
    }
    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "zname": if (!e.target.value.match(zoneNameRegex)) {
                setZoneNameHelperText("Enter Valid Zone Name");

            }
            else {
                setZoneNameHelperText("")
            }
                setZoneName(e.target.value);
                break;
            case "latency": if (!e.target.value.match(latencyRegex)) {
                setLatencyHelperText("Enter Latency Correctly");
            }
            else {
                setLatencyHelperText("");

            }
                setLatency(e.target.value);
                break;

            case "jitter": if (!e.target.value.match(latencyRegex)) {
                setJitterHelperText("Enter Jitter Correctly");
            }
            else {
                setJitterHelperText("");

            }
                setJitter(e.target.value);
                break;

            case "packet_loss": if (!e.target.value.match(packetLossRegex)) {
                setpacketLossHelperText("Enter Packet Loss Correctly");
            }
            else {
                setpacketLossHelperText("");

            }
                setPacketLoss(e.target.value);
                break;
            default:
                setZoneName(e.target.value);
                break;
        }
    };

    const handleOnSave = () => {
        if (!zoneName.match(zoneNameRegex)) {
            setZoneNameHelperText("Enter Zone Name")
            return;
        } if (!String(latency).match(latencyRegex)) {
            setLatencyHelperText("Enter Latency Correctly");
            return;
        }

        if (!String(jitter).match(latencyRegex)) {
            setJitterHelperText("Enter Jitter Correctly");
            return;
        }

        if (!String(packetLoss).match(packetLossRegex)) {
            setpacketLossHelperText("Enter Packet Loss Correctly");
            return;
        }
        UpdateZoneDetailsApiCall(value.zone_id, zoneName, jitter, latency, packetLoss, setEditStatus, setEditMessage);
        handleOnClose();
    }
    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Edit Zone
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form style={{ paddingBottom: "30px" }}>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Zone Name" id="zname" value={zoneName} onChange={handleOnChange} helperText={zoneNameHelperText} error={!!zoneNameHelperText} variant="outlined" style={{ width: "600px" }} required />
                            </Grid>
                        </Grid>
                    </form>
                    <Typography variant='h6' sx={{ paddingBottom: "10px" }}>
                        Zone Parameters
                    </Typography>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Latency(in ms)" id="latency" value={latency} onChange={handleOnChange} helperText={latencyHelperText} error={!!latencyHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Jitter(in ms)" id="jitter" value={jitter} onChange={handleOnChange} helperText={jitterHelperText} error={!!jitterHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Packet Loss(in %)" id="packet_loss" value={packetLoss} onChange={handleOnChange} helperText={packetLossHelperText} error={!!packetLossHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
