import { filter } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';
import { useParams, Link } from 'react-router-dom';
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Typography,
  Toolbar,
  OutlinedInput,
  Button,
  InputAdornment
} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

// components
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import ViewLinkTableHead from './ViewLinkTableHead';
import ViewLinkTableToolbar from './ViewLinkTableToolbar';
// mock
import USERLIST from '../../_mock/user';
import { LinksDataAPI, DeleteLinkDataAPI } from './LinksDataAPI'
import BusinessHourDialog from './BusinessHourDialog';
import AddLinkDialog from './AddLinkDialog';
import DeleteLinkDialog from './DeleteLinkDialog';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../globalHooks/GlobalState';
import PauseLinkDailog from './PauseLinkDailog';
import PauseLinkApiCall from './PauseLinkApiCall';
import UtilizationAlertApiCall from './UtilizationAlert';
import UtilizationAlertDailog from './UtilizationAlertDailog';
import NotificationAlertDialog from './NotificationAlertDialog';


const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});

const headCells = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'cust_name', label: 'Customer', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID/Account ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'City', label: 'City', alignRight: false },
  { id: 'IP', label: 'Public IP/CE-IP', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'Vendor_Email', label: 'Vendor Support Email', alignRight: false },
  { id: 'DefaultGateway', label: 'Wan Gateway/PE-IP', alignRight: false },
  { id: 'PromisedUptime', label: 'Promised Uptime', alignRight: false },
  { id: 'ResolutionTime', label: 'Resolution Time', alignRight: false },
  { id: 'LinkType', label: 'Link Type', alignRight: false },
  { id: 'Provisioned Speed', label: 'Provisioned Speed', alignRight: false },
  { id: 'Price', label: 'Price', alignRight: false },
  // {id:'CustomTags', label:'Custom tag',alignRight: false  },
  { id: 'Renewal Date', label: 'Renewal Date', alignRight: false },
  {id:'Pause status', label:'Pause Status',alignRight: false },
  {id:'Utilization Alert Status', label:'Utilization Alert Status',alignRight: false  },
  { id: 'Actions', label: 'Actions', alignRight: false},
];
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));
function EnhancedTableHead({ showCustomer, tagDataKeys }) {
  return (
      <TableHead>
          <TableRow>
              {headCells.slice(0, 14).map((headCell) => (
                  (headCell.id !== 'cust_name' || showCustomer) &&
                  <TableCell
                      sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                      key={headCell.id}
                      align={headCell.numeric ? 'center' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                      {headCell.label}
                  </TableCell>
              ))}
              {tagDataKeys.map(key => (
          <TableCell
              key={key}
              align="left"
              padding="normal"
              sx={{ background: '#f0f0f0', padding: '10px' }}
          >
              {key} 
          </TableCell>
      ))}
      {headCells.slice(14).map((headCell) => (
                  <TableCell
                      sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                      key={headCell.id}
                      align={headCell.numeric ? 'center' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                      {headCell.label}
                  </TableCell>
              ))}
          </TableRow>
      </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  showCustomer: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tagDataKeys: PropTypes.array.isRequired,
};
 function EnhancedTableToolbar({ numSelected, filterName, onFilterName, handleAddClick }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Typography variant='h4'>
        Links
      </Typography>
      <Box
        width="75%"
        display="flex"
        justifyContent="flex-end"
        paddingRight="1px"
      >

        <Box
          width="10px"
        />
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          sx={{ width: "150px", height: "40px", paddingLeft: "20px" }}
          onClick={handleAddClick}>
          Add Link
        </Button>
      </Box>


      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        onKeyDown={onFilterName}
        onKeyUp={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function ViewLinkTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openCustomerWarnDialog, setOpenCustomerWarnDialog] = useState(false);
  const [openPauseDialog, setOpenPauseDialog] = useState(false);
  const [UtilizationAlertDialog, setUtilizationAlertDialog] = useState(false);
  const [pauseValue, setPauseValue] = useState(-1);
  const [UtilizationAlertValue, setUtilizationAlertValue] = useState(-1);

  const [openAlertNotifiDialog, setOpenAlertNotifiDialog] = useState(false);
const [alertNotifyStatus,setAlertNotifyStatus] = useState(0);
const [alertNotifyMessage, setAlertNotifyMessage] = useState("");

  const classes = useStyles();

  const isEngineer = (localStorage.getItem("role") === "2")

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isp, setIsp] = useState({});
  const [ispData, setIspData] = useState([]);
  const [allIspData, setAllIspData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pauseOpen, setPauseOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [UtilizationOpen, setUtilizationOpen] = useState(false);
  const [clickedLocation, setClickedLocation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState(0);
  const [deleteLinkIP, setDeleteLinkIP] = useState("");
  const [deleteLinkWanID, setDeleteLinkWanID] = useState(-1);
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [addLinkStatus, setAddLinkStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [pauseLinkIp, setPauseLinkIp] = useState("");
  const [pauseLinkWanId, setPauseLinkWanId] = useState(-1);
  const [utilizationLinkIp, setUtilizationLinkIp] = useState("");
  const [utilizationLinkWanId, setUtilizationLinkWanId] = useState(-1);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [addLinkMessage, setAddLinkMessage] = useState("");
  const [alertNotifyLinkIp, setAlertNotifyLinkIp] = useState("")
  const [ alertNotifyLinkWanId, setAlertNotifyLinkWanId] = useState(-1)
  const [ alertNotifyPauseStatus, setAlertNotifyPauseStatus] = useState(-1);
  const [alertNotifyUtilAlertStatus, setAlertNotifyUtilAlertStatus] = useState(-1)


  const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddLinkStatus(0);
    setEdit(false);
    setPauseOpen(false);
    setOpen(false);
    setUtilizationOpen(false);
    setOpenAlertNotifiDialog(false);
    setAlertNotifyStatus(0);
  };

  const handleAddClick = () => {

    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenAddLinkDialog(true);
  }
// alert notify-------------
  const handleAlertNotifyDailog = (ispWanId, ispPublicIP, pauseStatus, utilAlertStatus)=>{
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
     setAlertNotifyLinkIp(ispPublicIP);
     setAlertNotifyLinkWanId(ispWanId);
     setAlertNotifyPauseStatus(pauseStatus);
     setAlertNotifyUtilAlertStatus(utilAlertStatus)
    // setAlertNotifyValue(value)
    
     setOpenAlertNotifiDialog(true)
  }

  const handlePauseDialog = (ispWanId, ispPublicIP, value) => {

    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setPauseLinkIp(ispPublicIP);
    setPauseLinkWanId(ispWanId);
    setPauseValue(value)
    setOpenPauseDialog(true);
  }


  const handleDeleteDialog = (ispWanId, ispPublicIP) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setDeleteLinkIP(ispPublicIP);
    setDeleteLinkWanID(ispWanId);
    setOpenDeleteDialog(true);
  }


  const handleUtilizationAlertDialog = (ispWanId, ispPublicIP, value) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    setUtilizationLinkIp(ispPublicIP);
    setUtilizationLinkWanId(ispWanId);
    setUtilizationAlertValue(value)
    setUtilizationAlertDialog(true);
  }


  const handleDelete = async (deleteStatus) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (deleteStatus === true) {
      await DeleteLinkDataAPI(deleteLinkWanID, deleteLinkIP, setOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setOpenDeleteDialog(false);
    setDeleteLinkWanID(-1);
    setDeleteLinkIP("");
  }


  const handlePauseLink = async (pauseStatus) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (pauseStatus === true) {
      await PauseLinkApiCall(pauseLinkWanId, pauseLinkIp, pauseValue, setPauseOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setOpenPauseDialog(false);
    setPauseLinkWanId(-1);
    setPauseLinkIp("");
  }


  const handleUtilizationAlertLink = async (alertStatus) => {
    if (alertStatus === true) {
      await UtilizationAlertApiCall(utilizationLinkWanId, utilizationLinkIp, UtilizationAlertValue, setUtilizationOpen);
      await LinksDataAPI(setIspData, setAllIspData, buState);
    }
    setUtilizationAlertDialog(false);
    setPauseLinkWanId(-1);
    setPauseLinkIp("");
  }


  const handleFilterByName = async (event, searchedVal) => {
    setFilterName(event.target.value);
    setIspData(allIspData);
    const filteredRows = allIspData.filter((row) => {
      const location = row.location ? row.location.toLowerCase() : "";
      const vendor = row.vendor ? row.vendor.toLowerCase() : "";
      const city = row.city ? row.city.toLowerCase() : "";
      const circuitId = row.circuit_id ? row.circuit_id.toLowerCase() : "";
      const linkType = row.link_type ? row.link_type.toLowerCase() : "";
      const publicIp = row.public_ip ? row.public_ip.toLowerCase() : "";

      return location.includes(searchedVal.toLowerCase()) || vendor.includes(searchedVal.toLowerCase())
        || city.includes(searchedVal.toLowerCase()) || circuitId.includes(searchedVal.toLowerCase())
        || linkType.includes(searchedVal.toLowerCase()) || publicIp.includes(searchedVal.toLowerCase());
    });
    setIspData(filteredRows);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;


  const _LinksDataAPI = async () => {
    await LinksDataAPI(setIspData, setAllIspData, buState);
  }

  useEffect(() => {
    _LinksDataAPI();
  }, [globalState, buState,addLinkStatus,alertNotifyStatus]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setClickedLocation(null);
  }



  const getDisplayText = (value) => {
    switch (value) {
      case "Broadband":
        return "Static Broadband";
      case "DBB":
        return "Dynamic Broadband";
      default:
        return value;
    }
  };

  const tagDataKeys = [...new Set(allIspData.flatMap(row => Object.keys(row.tag_data || {})))];
  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <EnhancedTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleAddClick={handleAddClick}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                showCustomer={mspClientId === regClientId && mspStatus === 1}
                // headLabel={TableCell.filter(tableCell => {
                //   return (
                //     (mspClientId === regClientId || tableCell.id !== 'cust_name') &&
                //     (mspStatus !== 0 || tableCell.id !== 'cust_name')
                //   );
                // })}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                tagDataKeys={tagDataKeys} 
              />
              <TableBody>
                {ispData.map((row, index) => {
                  //  const tagDataEntries = Object.entries(row.tag_data);
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                    >
                      <TableCell align="left" >{index + 1}</TableCell>
                      {mspClientId === regClientId && mspStatus === 1 ? <TableCell>{row.cust_name}</TableCell> : null}
                      <TableCell align="left"><Link to={{ pathname: `/dashboard/edit-single-isp/${row.isp_wan_id}` }}>{row.circuit_id}</Link></TableCell>
                      <TableCell align="left">{row.location}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.public_ip}</TableCell>
                      <TableCell align="left">{row.vendor}</TableCell>
                      <TableCell align="left">{row.vendor_email}</TableCell>
                      <TableCell align="left">{row.default_gateway}</TableCell>
                      <TableCell align="left">{row.promised_uptime} %</TableCell>
                      <TableCell align="left">{row.resolution_time} Hrs</TableCell>
                      <TableCell align="left">{getDisplayText(row.link_type)}</TableCell>
                      {/* <TableCell align="left">{row.provisioned_speed} Mbps</TableCell> */}
                      <TableCell align='left'>{row.provisioned_speed ? `${row.provisioned_speed} Mbps` : ''}</TableCell>
                      {/* <TableCell align="left">Rs. {row.price}</TableCell> */}
                      <TableCell align='left'>{row.price ? `Rs. ${row.price}` : ''}</TableCell>
                      
                      {tagDataKeys.map(key => (
                                    <TableCell key={key}>
                                        {row.tag_data[key] || ''} {/* Handle dynamic data */}
                                    </TableCell>
                                ))}
                      <TableCell align="left">{row.renewal_date}</TableCell>
                      <TableCell> {row.pause_status === 1 ? 'Paused' : 'Running'}</TableCell>
                      <TableCell> {row.util_alert_status === 1 ? 'Enabled' : 'Disabled'}</TableCell>
                      <TableCell align="center">
                        <Link to={{ pathname: `/dashboard/edit-single-isp/${row.isp_wan_id}` }}>
                          <IconButton
                            variant="outlined"
                            value={row.isp_wan_id}
                            color="primary"
                            onClick={(event) => {
                              if (globalState === custId && mspStatus === 1) {
                                setOpenWarnDialog(true);
                                event.preventDefault();
                              } else {
                                handleDelete(event);
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Link>
                        {/* {row.device_id !== null && <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>} */}
                        {<IconButton variant="outlined" value={row.isp_wan_id} color="primary" onClick={() => {handleAlertNotifyDailog(row.isp_wan_id, row.public_ip, row.pause_status, row.util_alert_status) }}><EditNotificationsIcon /></IconButton>}
                        {!isEngineer && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handleDeleteDialog(row.isp_wan_id, row.public_ip) }}><DeleteIcon /></IconButton>}
                        {/* {row.pause_status === 0 && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handlePauseDialog(row.isp_wan_id, row.public_ip, 1) }}><StopCircleIcon /></IconButton>} */}
                        {/* {row.pause_status === 1 && <IconButton variant="outlined" value={row.isp_wan_id} color="primary" onClick={() => { handlePauseDialog(row.isp_wan_id, row.public_ip, 0) }}><SmartDisplayIcon /></IconButton>} */}
                        {/* {row.util_alert_status === 0 && <IconButton variant="outlined" value={row.isp_wan_id} color="error" onClick={() => { handleUtilizationAlertDialog(row.isp_wan_id, row.public_ip, 1) }}><NotificationsOffIcon /></IconButton>} */}
                        {/* {row.util_alert_status === 1 && <IconButton variant="outlined" value={row.isp_wan_id} color="primary" onClick={() => { handleUtilizationAlertDialog(row.isp_wan_id, row.public_ip, 0) }}><NotificationsActiveIcon /></IconButton>} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>


          {clickedLocation && <BusinessHourDialog clickedLocation={clickedLocation} openDialog={openDialog} handleClose={handleCloseDialog} isp={isp} />}
          {openAddLinkDialog && <AddLinkDialog openAddLinkDialog={openAddLinkDialog} setOpenAddLinkDialog={setOpenAddLinkDialog} setAddLinkStatus={setAddLinkStatus} setAddLinkMessage={setAddLinkMessage} />}
          {openAlertNotifiDialog && <NotificationAlertDialog openAlertNotifiDialog={openAlertNotifiDialog} setOpenAlertNotifiDialog={setOpenAlertNotifiDialog} setAlertNotifyStatus={setAlertNotifyStatus} setAlertNotifyMessage={setAlertNotifyMessage} alertNotifyLinkIp={alertNotifyLinkIp} alertNotifyLinkWanId={alertNotifyLinkWanId} alertNotifyPauseStatus={alertNotifyPauseStatus} alertNotifyUtilAlertStatus={alertNotifyUtilAlertStatus}/>}
          {openDeleteDialog && <DeleteLinkDialog deleteLinkIP={deleteLinkIP} openDeleteDialog={openDeleteDialog} handleDelete={handleDelete} />}
          {openPauseDialog && <PauseLinkDailog pauseValue={pauseValue} pauseLinkIp={pauseLinkIp} openPauseDialog={openPauseDialog} handlePauseLink={handlePauseLink} />}
          {UtilizationAlertDialog && <UtilizationAlertDailog UtilizationAlertValue={UtilizationAlertValue} UtilizationAlertIp={utilizationLinkIp} UtilizationAlertDialog={UtilizationAlertDialog} handleUtilizationAlertLink={handleUtilizationAlertLink} />}
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}


          {addLinkStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {addLinkMessage}
            </Alert>
          </Snackbar>}

          {addLinkStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
              Error: {addLinkMessage}
            </Alert>
          </Snackbar>}

          {addLinkStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose} key={'Add link'}>
            <Alert onClose={handleSnackClose} severity="warning" sx={{ width: '100%' }}>
              Error: {addLinkMessage}
            </Alert>
          </Snackbar>}

          <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} key={'Delete link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Link Deleted Successfully.
            </Alert>
          </Snackbar>

          <Snackbar open={pauseOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'Pause link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {pauseValue === 1 && <Typography>Link Paused Successfully.</Typography>}
              {pauseValue === 0 && <Typography>Link Resumed Successfully.</Typography>}
            </Alert>
          </Snackbar>

          <Snackbar open={UtilizationOpen} autoHideDuration={6000} onClose={handleSnackClose} key={'set alerts for link'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              {UtilizationAlertValue === 1 && <Typography>Alerts enabled for the link.</Typography>}
              {UtilizationAlertValue === 0 && <Typography>Alerts disabled for the link.</Typography>}
            </Alert>
          </Snackbar>
          {alertNotifyStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {alertNotifyMessage}
                </Alert>
            </Snackbar>}

            {alertNotifyStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {alertNotifyMessage}
                </Alert>
            </Snackbar>}

        </Paper>
      </Grid>
    </>
  );
}
