import { filter } from 'lodash';
import { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';

// components
import ViewLinkTableHead from './ViewLinksInventoryTableHead';
import ViewLinkTableToolbar from './ViewLinksInventoryTableToolbar';
// mock
import USERLIST from '../../_mock/user';
import { LinksDataAPI, DeleteLinkDataAPI } from './LinksDataAPI'
import { useGlobalState } from '../../globalHooks/GlobalState';


// ----------------------------------------------------------------------

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'City', label: 'City', alignRight: false },
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'Vendor_Email', label: 'Vendor Email', alignRight: false },
  { id: 'DefaultGateway', label: 'Default Gateway', alignRight: false },
  { id: 'PromisedUptime', label: 'Promised Uptime', alignRight: false },
  { id: 'ResolutionTime', label: 'Resolution Time', alignRight: false },
  { id: 'LinkType', label: 'Link Type', alignRight: false },
  { id: 'Provisioned Speed', label: 'Provisioned Speed', alignRight: false },
  { id: 'Price', label: 'Price', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewLinksInventoryTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const downloadLink = createRef();

  const classes = useStyles();

  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ispData, setIspData] = useState([]);
  const [allIspData, setAllIspData] = useState([]);

  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = async (event, searchedVal) => {
    setFilterName(event.target.value);
    setIspData(allIspData);
    const filteredRows = allIspData.filter((row) => {
      return (row.location?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
      (row.vendor?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
      (row.circuit_id?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
      (row.public_ip?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
      (row.city?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
      (row.link_type?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false);
    });

    setIspData(filteredRows);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const _LinksDataAPI = async () => {
    await LinksDataAPI(setIspData, setAllIspData, buState);
  }
  useEffect(() => {
    _LinksDataAPI();
  }, [globalState, buState]);

  useEffect(() => {
    createDownLoadData();
  }, [allIspData.length])

  useEffect(() => {
    _LinksDataAPI();
  }, [])

  const createDownLoadData = () => {
    const dataArr = [];
    for (let index = 0; index < allIspData.length; index += 1) {
      const item = {};
      item.circuit_id = allIspData[index].circuit_id;
      item.location = allIspData[index].location;
      item.city = allIspData[index].city;
      item.device_ip = allIspData[index].internal_ip;
      item.vendor = allIspData[index].vendor;
      item.vendor_email = allIspData[index].vendor_email;
      item.default_gateway = allIspData[index].default_gateway;
      item.promised_uptime = allIspData[index].promised_uptime;
      item.resolution_time = allIspData[index].resolution_time;
      item.link_type = allIspData[index].link_type;
      item.provisioned_speed = allIspData[index].provisioned_speed;
      item.price = allIspData[index].price;
      dataArr.push(item);
    }
    setCsvData(dataArr);
  }

  const handleDownloadClick = () => {
    downloadLink.current.link.click();
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <ViewLinkTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleDownloadClick={handleDownloadClick}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <ViewLinkTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {ispData.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                    >
                      <TableCell align="left" >{index + 1}</TableCell>
                      {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{row.cust_name}</TableCell> : null}

                      <TableCell align="left"><Link to={{ pathname: `/dashboard/edit-single-isp/${row.isp_wan_id}` }}>{row.circuit_id}</Link></TableCell>
                      <TableCell align="left">{row.location}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.public_ip}</TableCell>
                      <TableCell align="left">{row.vendor}</TableCell>
                      <TableCell align="left">{row.vendor_email}</TableCell>
                      <TableCell align="left">{row.default_gateway}</TableCell>
                      <TableCell align="left">{row.promised_uptime} %</TableCell>
                      <TableCell align="left">{row.resolution_time} Hrs</TableCell>
                      <TableCell align="left">{row.link_type}</TableCell>
                      <TableCell align="left">{row.provisioned_speed} Mbps</TableCell>
                      <TableCell align="left">Rs.{row.price}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <CSVLink data={csvData} filename={`Link_Details.csv`} ref={downloadLink}>{ }</CSVLink>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
