import axios from 'axios';

// API CALL CODE HERE
const EditSiteGroupApiCall = async (groupId, groupName, groupType, criticality, latency, jitter, packetLoss, warning, critical, setEditStatus ,setEditSiteMessage) => {

// const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/edit_site_group.php`;
const path = localStorage.getItem("msp_status") === "1" ? "edit_site_group_for_msp.php" : "edit_site_group.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "group_data": {
        "group_id": groupId,
        "group_name": groupName,
        "group_type": groupType,
        "criticality": criticality,
        "latency": latency,
        "jitter": jitter,
        "packet_loss": packetLoss,
        "warning_th_value": warning,
        "critical_th_value" : critical
	}
  });
  
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "group_data": {
        "group_id": groupId,
        "group_name": groupName,
        "group_type": groupType,
        "criticality": criticality,
        "latency": latency,
        "jitter": jitter,
        "packet_loss": packetLoss,
        "warning_th_value": warning,
        "critical_th_value" : critical
	}

  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setEditStatus(1);
      setEditSiteMessage(response.data.message)
    }
    else {
      setEditStatus(-1);
      setEditSiteMessage(response.data.message)

    }
  }).catch(()=>{
    setEditStatus(-1);
  })
}

export default EditSiteGroupApiCall;