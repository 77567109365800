import axios from 'axios';

// API CALL CODE HERE
const baseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_licenseType_for_user.php`;

export const GetLicenseTypeApiCall = async (setLicenseTypeData) => {

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id")
  });
  
  return axios.post(baseURL, payload).then((response) => {
    if (response.data.code === 1) {
       
        setLicenseTypeData(response.data.license_data);
    }
    else{
        setLicenseTypeData([]);
    }

  })
}
