
// material
import {
  Breadcrumbs, Container, Link,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
import ZoneLocationMapComponent from '../components/ZoneLocationMapComponents/ZoneLocationMapComponent';

export default function ZoneLocationMapping() {

  return (
    <Page title="ZoneLocationMapping">
      <Container maxWidth={false}>
        <div role="presentation" style={{ paddingBottom: '10px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              <BuildIcon />
            </Link>
            <Link underline="hover" color="inherit">
              Zone Management
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/zone-location-map">
              Zone Location Mapping
            </Link>
          </Breadcrumbs>
        </div>

        <ZoneLocationMapComponent />

      </Container>
    </Page>
  );
}
