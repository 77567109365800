import axios from 'axios';

// API CALL CODE HERE

const DeleteVendorApiCall = async (vendorId, vendorName, setDeleteVendorStatus) => {

  const path = localStorage.getItem("msp_status") === "1" ? "delete_vendor_for_msp.php" : "delete_vendor.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "vendor_id": vendorId,
    "vendor_name": vendorName
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "vendor_id": vendorId,
    "vendor_name": vendorName
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setDeleteVendorStatus(1);
    }
    else {
        setDeleteVendorStatus(-1);
    }
  }).catch(()=>{
    setDeleteVendorStatus(-1);
  })
}

export default DeleteVendorApiCall;
