import { filter } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// components
import Scrollbar from '../../Scrollbar';

import USERLIST from '../../../_mock/user';
// import GetUsersDataAPI from './GetUsersDataAPI'
// import EditUserDialog from './EditUserDialog';
// import DeleteUserDialog from './DeleteUserDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import MspUsersTableToolbar from './MspUsersTableToolbar';
import MspUsersTableHead from './MspUsersTableHead';
import GetMspUsersDetailsApiCall from './GetMspUsersDetailsApiCall';
import AddNewMspuserDialog from './AddNewMspUserDialog';
import DeleteMspUserDialog from './DeleteMspUserDialog';
import EditMspUserDialog from './EditMspUserDialog';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'FirstName', label: 'First Name', alignRight: false },
  { id: 'LastName', label: 'Last Name', alignRight: false },
  { id: 'PhoneNo', label: 'Phone No', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'UserName', label: 'User Name', alignRight: false },
  { id: 'Role', label: 'Role', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];


const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none"
  },
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
}));

export default function MspUsersTable() {

  const {globalState, setGlobalState } = useGlobalState();

  const isEngineer = (localStorage.getItem("role") === "2")
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [allUsersData, setAllUserData] = useState([]);

  const [addStatus, setAddStatus] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [severStatus, setSeverStatus] = useState("");

  const [addMSPUserMessage, setAddMSPUserMessage] = useState("");
  const [editMSPUserMessage, setEditMSPUserMessage] = useState("");

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);

  const [passTooltip, setPassTooltip] = useState("Click to See Password")

  const classes = useStyles();

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateStatus(0);
    setDeleteStatus(0);
    setAddOpen(false);
    setEditOpen(false);
    setDeleteOpen(false);
    setSeverStatus("")
  };

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value)
    const filteredRows = allUsersData.filter((row) => {
      return row.first_name.toLowerCase().includes(searchedVal.toLowerCase())
      ||row.last_name.toLowerCase().includes(searchedVal.toLowerCase())
      ||row.last_name.toLowerCase().includes(searchedVal.toLowerCase())
      ||row.user_name.toLowerCase().includes(searchedVal.toLowerCase())
      ||row.email.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUsersData(filteredRows);
  };

  const _GetUsersData = async () => {
    await GetMspUsersDetailsApiCall(setUsersData, setAllUserData);
  }
  useEffect(() => {
    _GetUsersData();
  }, [addStatus, updateStatus, deleteStatus, globalState]);

  const handleEditClick = (e, user) => {
    setOpenEditDialog(true);
    setEditUser(user);
  }

  const handleDeleteClick = (e, user) => {
    setOpenDeletDialog(true);
    setEditUser(user);
  }

  const handleAddClick=()=>{
    setOpenAddDialog(true)
  }

  const showPassword = (e) => {
    e.target.type = e.target.type === "text" ? "password" : "text";
    setPassTooltip(e.target.type === "text" ? "Click to Hide Password": "Click to See Password")
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <MspUsersTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleAddClick = {handleAddClick}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader>
                <MspUsersTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {usersData.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.first_name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.user_name}</TableCell>
                        {row.role === 0 && <TableCell align="left">Super Admin</TableCell>}
                        {row.role === 1 && <TableCell align="left">Read-Only Admin</TableCell>}
                        {row.role === 2 && <TableCell align="left">Engineer</TableCell>}
                        <TableCell align="left">
                          <Grid direction="row">
                            {isEngineer && "-" }
                            {!isEngineer &&<IconButton variant="outlined" color="primary" onClick={(e) => { handleEditClick(e, row) }}><EditIcon /></IconButton>}
                            {!isEngineer && <IconButton variant="outlined" onClick={(e) => { handleDeleteClick(e, row) }} color="error" ><DeleteIcon /></IconButton>}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {openEditDialog && <EditMspUserDialog user={editUser} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setUpdateStatus={setUpdateStatus} setEditOpen={setEditOpen} setSeverStatus={setSeverStatus} setEditMSPUserMessage={setEditMSPUserMessage} />}
          {openDeleteDialog && <DeleteMspUserDialog user={editUser} openDeleteDialog={openDeleteDialog} setOpenDeletDialog={setOpenDeletDialog} setDeleteStatus={setDeleteStatus} setDeleteOpen={setDeleteOpen} setSeverStatus={setSeverStatus} />} 
          {openAddDialog && <AddNewMspuserDialog openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} setAddStatus={setAddStatus} setAddOpen={setAddOpen} setSeverStatus={setSeverStatus} setAddMSPUserMessage={setAddMSPUserMessage} /> }
          
          <Snackbar open={addOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {addStatus === 1 && <strong>{addMSPUserMessage}</strong>}
              {addStatus === -1 && <strong>Error: {addMSPUserMessage}</strong>}
            </Alert>
          </Snackbar>
          
          
          <Snackbar open={editOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {updateStatus === 1 && <strong>{editMSPUserMessage}</strong>}
              {updateStatus === -1 && <strong>Error: {editMSPUserMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={deleteOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {deleteStatus === 1 && <strong>User Deleted Successfully</strong>}
              {deleteStatus === -1 && <strong>Error in Deleting User</strong>}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </>
  );
}
