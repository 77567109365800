import axios from 'axios';

// API CALL CODE HERE


const GetSiteGroupProbeDataApiCall = async(probeId, setPresentSitegroups, setAvailableSiteGroups, buState) => {

  const path = localStorage.getItem("msp_status") === "1" ? "file.php" : "get_site_group_by_probe.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
 
    if (response.data.code === 1) {
        setAvailableSiteGroups(response.data.probe_site_groups_res.map(group => group.group_name));
      setPresentSitegroups(response.data.spare_site_groups_res.map(group => group.group_name));
    }
    else {
        setAvailableSiteGroups([]);
      setPresentSitegroups([]);
    }
  }).catch(()=>{
    setAvailableSiteGroups([]);
      setPresentSitegroups([]);
  })
}

export default GetSiteGroupProbeDataApiCall;