import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Breadcrumbs, Container, Link, Divider, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import WizardLayout from '../layouts/WizardLayout';

export default function Wizard() {
    const [open, setOpen] = useState(false);

    

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Page title="Wizard">
            <Container maxWidth={false}>
                <Grid>
                    <Grid item container>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <WizardLayout />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
