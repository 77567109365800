import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import DeleteLocationApiCall from './DeleteLocationApiCall';


export default function DeleteLocationDialog(props) {

    const { value, openDeleteDialog, setOpenDeletDialog, setDeleteStatus, setSeverStatus, setDeleteOpen } = props; 

    const handleClose=(e)=>{
        setOpenDeletDialog(false);
    }
    const handleDelete = async () => {
        await DeleteLocationApiCall(value.location_id, value.location_name, setDeleteStatus, setSeverStatus);
        setDeleteOpen(true);
        setOpenDeletDialog(false);
        props.handleDataReLoad();

    }

    return (
        <>
            <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Location
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to Delete {value.location_name} ??
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
