// material
import { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TroubleshootComponent from '../../ISPManagementTable/ISPManagementDashboard/TroubleshootComponents/TroubleshootComponent';
import DowntimeLogo from "../../../Assets/down_time_logo.png";
import UptimeLogo from "../../../Assets/uptime_logo.jpeg";
import BadtimeLogo from "../../../Assets/bad_time_logo.png";
import PacketLossLogo from "../../../Assets/packet_loss_logo.png";
import LatencyLogo from "../../../Assets/latency_logo_2.png";
import JitterLogo from "../../../Assets/jitter_logo.png";
import Troubleshoot from "../../../Assets/troubleshoot.jpg";
import NetWorkSpeed from "../../../Assets/network_speed.png";
import LinkStatus from "../../../Assets/link_status.png";
import LinkType from "../../../Assets/link_type.png";

// components
// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '12px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardsContainer: {
    display: 'flex',
    gap: 16,
    marginRight: '8px',
    flexWrap: 'wrap',
    paddingLeft: '15px',
  },
});

// ----------------------------------------------------------------------

export default function SidewiseSubCards({ ispParams, upStatus, goodStatus }) {
  const classes = useStyles();

  const [openTroubleshoot, setOpenTroubleshoot] = useState(false);
  const [textColor, setTextColor] = useState("");
  const [icon, setIcon] = useState(UptimeLogo);
  const [status, setStatus] = useState("");

  const openTroubleshootDialog = () => {
    setOpenTroubleshoot(true);
  }

  useEffect(()=>{
    
    if(upStatus && goodStatus)
    {
      setTextColor("green");
      setIcon(UptimeLogo);
      setStatus("Up-Good");
    }
    else if(upStatus && !goodStatus)
    {
      setTextColor("#FFA500");
      setIcon(BadtimeLogo);
      setStatus("Up-Bad");
    }
    else{
      setTextColor("red");
      setIcon(DowntimeLogo);
      setStatus("Down");
    }
  },[ispParams])
  
  
  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }

  const getDisplayText = (value) => {
    switch (value) {
      case "Broadband":
        return "Static Broadband";
      case "DBB":
        return "Dynamic Broadband";
      default:
        return value;
    }
  };

  return (
    <>
      <div className={classes.cardsContainer}>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={7}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              {/* <IconButton size="small" sx={{ color: '#F98F00', background: '#FFFFE0', borderRadius: '12px', mr: 2 }}>
                  <DevicesUpIcon />
                </IconButton> */}

                <img src={PacketLossLogo} alt="logo" style={{ width: '60px', marginRight: '20px', height:"50px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Packet Loss
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {ispParams.packet_loss} (%)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              {/* <IconButton size="small" sx={{ color: '#F98F00', background: '#FFFFE0', borderRadius: '12px', mr: 2 }}>
                  <DevicesUpIcon />
                </IconButton> */}

                <img src={LatencyLogo} alt="logo" style={{ width: '40px', marginRight: '20px', height:"40px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Latency
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {ispParams.avg_rtt} (ms)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              {/* <IconButton size="small" sx={{ color: '#F98F00', background: '#FFFFE0', borderRadius: '12px', mr: 2 }}>
                  <LinksUpIcon />
                </IconButton> */}

                <img src={JitterLogo} alt="logo" style={{ width: '40px', marginRight: '20px', height:"40px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Jitter
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {ispParams.jitter} (ms)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              {/* <IconButton size="small" sx={{ color: '#F98F00', background: '#FFFFE0', borderRadius: '12px', mr: 2 }}>
                  <LinksDownIcon />
                </IconButton> */}

                <img src={icon} alt="logo" style={{ width: '50px', marginRight: '20px', height:"40px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  {ispParams.packet_loss === 100 ? "Down Time": "Up Time"}
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {convertMinToHourMinutes(ispParams.packet_loss === 100 ? ispParams.down_cnt: ispParams.up_cnt)}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={LinkStatus} alt="logo" style={{ width: '50px', marginRight: '20px', height:"40px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Status
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {status}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={NetWorkSpeed} alt="logo" style={{ width: '100px', height:"50px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Provisioned Speed
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {ispParams.provisioned_speed} Mbps
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={LinkType} alt="logo" style={{ width: '60px', height:"50px", paddingRight:"5px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Link Type
                </Typography>
                <Typography sx={{ color: textColor }} variant="subtitle1" component="div">
                  {getDisplayText(ispParams.link_type)}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content' }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={Troubleshoot} alt="logo" style={{ width: '50px', marginRight: '20px', height: "50px" }} />
              <div>
                <Typography sx={{ color: 'red' }} variant="subtitle1" component="div">
                  <Button variant="outlined" size="large" sx={{ width: "200px" }} onClick={openTroubleshootDialog}>Troubleshoot</Button>
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
      </div>
      {openTroubleshoot && <TroubleshootComponent openTroubleshoot={openTroubleshoot} setOpenTroubleshoot={setOpenTroubleshoot} ispParams={ispParams}/>}{openTroubleshoot && <TroubleshootComponent openTroubleshoot={openTroubleshoot} setOpenTroubleshoot={setOpenTroubleshoot} ispParams={ispParams}/>}
    </>
  );
}
