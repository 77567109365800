import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; 

export default function CustomDateRangePicker({ onDateChange, startDateValue, endDateValue }) {
  const [startDate, setStartDate] = React.useState(startDateValue ? dayjs(startDateValue) : dayjs());
  const [endDate, setEndDate] = React.useState(endDateValue ? dayjs(endDateValue) : dayjs());
  // Start date
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
    if (endDate && newStartDate > endDate) {
      setEndDate(newStartDate);
    }
    onDateChange(newStartDate, endDate);
  };
  // End date
  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
    if (startDate > newEndDate) {
      setStartDate(newEndDate);
    }
    onDateChange(startDate, newEndDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} />}
        />

        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
          minDate={startDate}
          inputProps={{ readOnly: !startDate }}
        />
      </div>
    </LocalizationProvider>
  );
}
