// @mui
import {
    Breadcrumbs,
    Container,
    Grid,
    Link,
  } from '@mui/material';
  import { useLocation, useParams } from 'react-router-dom';
  // components
  import ISPDown1MainPage from './ISPDown1MainPage';
  // sections
  
  // ----------------------------------------------------------------------
  
  export default function ISPDown1() {
    const { id } = useParams()
    return (
      <>
        <Container maxWidth={false}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard/dashboard">
                NMS
                </Link>
                <Link underline="hover" color="inherit" href="/dashboard/dashboard">
                  Link Management
                </Link>
                <Link underline="hover" color="inherit" href="/dashboard/isp-management">
                Dashboard
                </Link>
                <Link underline="hover" color="inherit" href={`/dashboard/isp-management/isp-down/${id}`}>
                    Link Status
                </Link>
            </Breadcrumbs>
          </div>
  
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={{py:2}}>
            <ISPDown1MainPage />
        </Grid>
  
        </Container>
      </>
    );
  }
  