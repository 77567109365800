import { useEffect, useState } from 'react';

// @mui
import {
  Box, Grid, Paper, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
// import useWindowSize from './useWindowSize';
import { ISPDashboardCardsAPI } from '../ISPManagamentRestCalls/ISPDashboardCardsAPI'
import { useGlobalState } from '../../../globalHooks/GlobalState';

import LinkLogo from '../../../Assets/link_logo.jpg';
import GoodLinkLogo from '../../../Assets/good_link_logo.jpg';
import BadLinkLogo from '../../../Assets/bad_link_logo.jpg';
import DownLinkLogo from '../../../Assets/down_link_logo.jpg';




// ---------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

const MyLinkISPTotal = props => <Link to="/dashboard/isp-management/isp-total" {...props} />
const MyLinkISPGood = props => <Link to="/dashboard/isp-management/isp-good" {...props} />
const MyLinkISPBad = props => <Link to="/dashboard/isp-management/isp-bad" {...props} />
const MyLinkISPFlapping = props => <Link to="/dashboard/isp-management/isp-flapping" {...props} />
const MyLinkISPPaused = props => <Link to="/dashboard/isp-management/isp-paused" {...props} />
const MyLinkISPDown = props => <Link to="/dashboard/isp-management" {...props} />

// function useWindowSize() {
//   // Initialize state with undefined width/height so server and client renders match
//   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
//   const [windowSize, setWindowSize] = useState({
//     width: undefined,
//     height: undefined,
//   });
//   useEffect(() => {
//     // Handler to call on window resize
//     function handleResize() {
//       // Set window width/height to state
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     }
//     // Add event listener
//     window.addEventListener("resize", handleResize);
//     // Call handler right away so state gets updated with initial window size
//     handleResize();
//     // Remove event listener on cleanup
//     return () => window.removeEventListener("resize", handleResize);
//   }, []); // Empty array ensures that effect is only run on mount
//   return windowSize;
// }

export default function ISPDashboardCards() {
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();
  
  const [totalCnt, setTotalCnt] = useState(0);
  const [upGoodCnt, setUpGoodCnt] = useState(0);
  const [upBadCnt, setUpBadCnt] = useState(0);
  const [downCnt, SetDownCnt] = useState(0);
  const [flapCnt, setFlapCnt] = useState(0);
  const [pauseCnt, setPauseCnt] = useState(0);

  const _ISPDashboardCardsAPI = async () => {
    await ISPDashboardCardsAPI(setTotalCnt, setUpGoodCnt, setUpBadCnt, SetDownCnt, setFlapCnt, setPauseCnt, buState);
  }

  useEffect(() => {
    const callApi = () => {
      _ISPDashboardCardsAPI();
    };
    callApi();
    const intervalId = setInterval(() => {
      callApi();
    }, 30 * 1000);
    return () => clearInterval(intervalId);
  }, [globalState, buState]);



  return (
    <>
      <div>
        <Grid container spacing={3} marginTop={2}  >
          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPTotal}>
                <Box className={classes.boxItems}>
                  <img src={LinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h4" component="div">
                    Total Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '1px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {totalCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPGood}>
                <Box className={classes.boxItems}>
                  <img src={GoodLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Good Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '1px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {upGoodCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPBad}>
                <Box className={classes.boxItems}>
                  <img src={BadLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Poor Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {upBadCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPDown}>
                <Box className={classes.boxItems}>
                  <img src={DownLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Down Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {downCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPFlapping}>
                <Box className={classes.boxItems}>
                  <img src={DownLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Flapping Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black', display:"flex" }}>
                  <Typography variant="h2">
                    {flapCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkISPPaused}>
                <Box className={classes.boxItems}>
                  <img src={DownLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Paused Links
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black', display:"flex" }}>
                  <Typography variant="h2">
                    {pauseCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}