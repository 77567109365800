import axios from 'axios';


export const ResetPasswordRestAPICall = async (email,password, firstUsersName, authVal, setErrorMessage) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/update_user_password.php`;


  const payload = JSON.stringify({
    "email": email,
    "password": password,
    "user_name": firstUsersName,
    "auth_token": authVal
  });
  try {
    const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
      localStorage.setItem("update_status", true);
      return response.data;
    }
    if (response.data.code === -1)
    {
      setErrorMessage("Error in updating password. Please check or retry again");
      localStorage.setItem("update_status", false);
    }
    
    throw new Error(response.data.message || 'Forgot Password Failed');
  } catch (error) {
    throw new Error(error.message || 'Forgot Password Failed: network related');
  }
}

