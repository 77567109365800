import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ReadOnlyDashboardSidebar from './ReadOnlyDashboardSidebar'
import DashboardSidebarMSP from './DashboardSidebarMSP';
import DashboardSidebarMappedUser from './DashboardSidebarMappedUser';
import SessionTrack from './SessionTrack';
import { useGlobalState } from '../../globalHooks/GlobalState';
import ChatBotComponent from '../../components/ChatBotComponents/ChatBotComponent';


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 96,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { openFullScreen, setOpenFullScreen } = useGlobalState();

  if (!localStorage.auth_token) {
    window.location.pathname = '/';
  }

  return (
    <RootStyle>
      <SessionTrack />
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      {localStorage.getItem("user_map_status") !== "1" && localStorage.getItem("role") !== "1" && localStorage.getItem("msp_status") === "0" && !openFullScreen && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      {localStorage.getItem("user_map_status") !== "1" && localStorage.getItem("role") === "1" && localStorage.getItem("msp_status") === "0" && !openFullScreen &&  <ReadOnlyDashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      {localStorage.getItem("user_map_status") !== "1" && localStorage.getItem("role") !== "1" && localStorage.getItem("msp_status") === "1" && !openFullScreen && <DashboardSidebarMSP isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      {localStorage.getItem("user_map_status") !== "1" && localStorage.getItem("role") === "1" && localStorage.getItem("msp_status") === "1" && !openFullScreen &&  <ReadOnlyDashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      {localStorage.getItem("user_map_status") === "1" && !openFullScreen && <DashboardSidebarMappedUser isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      
      <MainStyle style={{paddingTop:"180px"}}>
        <Outlet />
      </MainStyle>
      {/* <ChatBotComponent /> */}
    </RootStyle>
  );
}