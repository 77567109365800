import axios from "axios"

const UploadMultipleSiteApiCall = async(parsedData, setOpenMultipleUploadDialog, setSeverStatus, setMultipleSiteUploadStatus,  setMultipleSiteUploadMessage) => {
    const path = localStorage.getItem("msp_status") === "1" ? "push_multiple_loc_edge_device_info_for_msp.php" : "push_multiple_loc_edge_device_info.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const multiSiteData = parsedData.map(site => ({
        "location": site.Location,
        "city": site.City,
        "state": site.State,
        "country": site.Country,
        "device_ip": site.Device_Ip,
        "snmp_str": site.Snmp_String,
        "snmp_version": site.Snmp_Version,
        "ssh_username": site.SSH_Username,
        "password": site.Password,
        "ssh_port": site.SSH_Port,
        "api_key": site.Api_Key,
        "api_port": site.Api_Port,
        "vendor_name": site.Device,
        "type": site.Type
    }));
    
      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "ed_data": multiSiteData
      });
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "ed_data": multiSiteData
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    
    await axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
         setOpenMultipleUploadDialog(false);
        setMultipleSiteUploadStatus(response.data.code);
        setSeverStatus("success");
        setMultipleSiteUploadMessage(response.data.message); 
      } else {
         setOpenMultipleUploadDialog(true);
        setMultipleSiteUploadStatus(response.data.code);
        setSeverStatus("error");
        setMultipleSiteUploadMessage(response.data.message); 
      }
    });
  
}

export default UploadMultipleSiteApiCall