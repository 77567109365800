import axios from "axios"

const UploadEdgeDeviceApiCall = async(parsedData, setOpenUploadDialog, setUploadEds) => {
    const path = localStorage.getItem("msp_status") === "1" ? "push_multiple_edge_device_info_for_msp.php" : "push_multiple_edge_device_info.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    
      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "ed_data": parsedData
      });
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "ed_data": parsedData
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setOpenUploadDialog(false);
            setUploadEds(true);
        }
        else{
          setOpenUploadDialog(true);
          setUploadEds(false);
        }
        
    })
  
}

export default UploadEdgeDeviceApiCall