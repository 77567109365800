import { makeStyles } from '@mui/styles';

const cardStyles = makeStyles({
  boxContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  boxTitleIcon: {
    fontSize: '2rem',
    color: '#81d4fa',
    backgroundColor: '#e1f5fe',
    borderRadius: '5px',
    // marginLeft: '15px',
    marginTop: '5px',
    width: '1.5em',
    height: '1.5em',
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#81d4fa',
      color: '#e1f5fe',
    },
  },
  boxTitle: {
    width: '70%',
    paddingTop: '15px',
    paddingLeft: '10px',
    flexGrow: 1,
  },
});
export default cardStyles;
