import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const CustomerSelectionModal = (props) => {
    const {openCustSelectionModal, setOpencustomerSelectionModal, customers, setSelectedCustId, handleCustomerSelect} = props;
    const [cId, setCID] = useState(-1);
    //   const [imageSrc, setImageSrc] = useState({}); 

//   useEffect(() => {
//     const processLogos = async () => {
//       const imageUrls = {};

//       customers.forEach(customer => {
//         const { id, logo } = customer;

//         try {
//           // Validate and decode base64 string
//           if (logo && /^[A-Za-z0-9+/=]+$/.test(logo)) {
//             const byteCharacters = atob(logo);
//             const byteArray = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
//             const blob = new Blob([byteArray], { type: 'image/png' });
//             imageUrls[id] = URL.createObjectURL(blob);
//           } else {
//             imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//           }
//         } catch (error) {
//           console.error('Failed to decode base64 string:', error);
//           imageUrls[id] = '/path/to/placeholder.png'; // Fallback image
//         }
//       });

//       setImageSrc(imageUrls);
//     };

//     processLogos();
//   }, [customers]);

  const handleCustomerChange = (event) => {
    setCID(event.target.value)
    setSelectedCustId(event.target.value); // Set the customer ID
  };

  const handleSelect = () => {
    handleCustomerSelect();
  };

  return (
    <Modal
      open={openCustSelectionModal}
      onClose={()=>{setOpencustomerSelectionModal(false)}}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography id="modal-title" variant="h6" component="h2">
          Select Customer
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="customer-select-label">Customer</InputLabel>
          <Select
            labelId="customer-select-label"
            id="customer-select"
            value={cId}
            label="Customer"
            onChange={handleCustomerChange}
          >
            <MenuItem key={-1} value={-1}>Select</MenuItem>
            {customers.length > 0 ? (
              customers.map((customer) => (
                <MenuItem key={customer.custId} value={customer.custId}>
                  {customer.custName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No customers available</MenuItem>
            )}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="success"
            variant="text"
            sx={{ mt: 3 }}
            onClick={handleSelect}
            disabled={cId === -1}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerSelectionModal;