import axios from 'axios';

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_license_for_user.php`;

const GetLicenseApiCall = async (setGroups) => {
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id")
   
  });
  return axios.post(createBaseURL, regPayload).then((response) => {
    if (response.data.code === 1 && Array.isArray(response.data.license_data)) {
      setGroups(response.data.license_data);
    } else {
      setGroups([]);
    }
  }).catch(() => {
    setGroups([]);
  })
  
}
export default  GetLicenseApiCall ;