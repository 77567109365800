import axios from 'axios';

// API CALL CODE HERE
export const CreateUserApiCall = async (firstName, lastName, phoneNo, emailId, userName, password, role, setData, setAddUserMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "create_new_user_for_msp.php" : "create_new_user.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "password": password,
      "user_role": role
    }
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
    "user_data": {
      "cust_id": localStorage.getItem("reg_cust_id"),
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "password": password,
      "user_role": role
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setData(1);
      setAddUserMessage(response.data.message)
    }
    else {
      setData(-1);
      setAddUserMessage(response.data.message)
    }
  })
}
