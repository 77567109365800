import axios from 'axios';

// API CALL CODE HERE

const CheckSiteDownStatusApiCall = async(ispWanId, setSiteDownStatus, setGotSiteDownStatus, setSiteDownStatusMessage, setcontinueEdLanStatus, setDetailSiteDownStatusMessage, setRcaSiteDownStatusMessage) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_site_status_by_wan_id.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response)=>{
    if(response.data.site_down_status === 1){
        setSiteDownStatus(true)
        setcontinueEdLanStatus(false)
    }
    else
    {
        setSiteDownStatus(false)
        setcontinueEdLanStatus(true)
    }
    setGotSiteDownStatus(true)
    setSiteDownStatusMessage(response.data.detailed_message)
    setRcaSiteDownStatusMessage(response.data.rca_message)
  })
}

export default CheckSiteDownStatusApiCall;
