import React from 'react'
import { Grid } from '@mui/material';
import EdLogsTable from './EdLogsTable';
import { ISPDashboardCards } from '../ISPManagementTable/ISPManagementDashboard';

export default function EdLogsComponent() {
    return (
        <Grid>
          <Grid item container spacing={3}>
            <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{ py: 1 }} style={{ paddingBottom: '30px' }}>
              <ISPDashboardCards />
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item lg={12} md={12} sm={12}>
              <EdLogsTable />
            </Grid>
          </Grid>
        </Grid>
      );
}
