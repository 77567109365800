import axios from 'axios';
// send tag_id here down
const DeleteCustomTagDialogApiCall = async (tagName, entity, setDeleteStatus, setDeleteMessage) => {

    const path = localStorage.getItem("msp_status") === "1" ? "delete_custom_tags_for_msp.php" : "delete_customtags.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        // send id
        "device_data": {
            "tag_name": tagName,
            "entity": entity
        }
    })
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "device_data": {
            "tag_name": tagName,
            "entity": entity
        }
});
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setDeleteStatus(response.data.code)
            setDeleteMessage(response.data.message)

        }
        else {
            setDeleteStatus(response.data.code)
            setDeleteMessage(response.data.message)


        }
    })

}
export default DeleteCustomTagDialogApiCall;

