import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import GetUserDetailsApiCall from './GetUserDetailsApiCall';
import UpdateBusinessUnitDetailsApiCall from './UpdateBusinessUnitDetailsApiCall';

export default function EditBusinessUnitDialog(props) {
    const { value, openEditDialog, setOpenEditDialog, setEditStatus, setEditMessage } = props;
   
    const [businessUnitName, setBusinessUnitName] = useState(value.bu_name);
    const [BusinessOfficeHelperText, setBusinessOfficeHelperText] = useState("");
    const [businessUnitHeadOffice , setBusinessUnitHeadOffice] = useState(value.bu_head_office);

    const [BusinessUnitHelperText, setBusinessUnitHelperText] = useState("");
    const BusinessUnitRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _()-]*(?<![-_( ])$/;
   

    const handleOnClose = () => {
        setOpenEditDialog(false);
    }
    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "Bname": if (!e.target.value.match(BusinessUnitRegex)) {
                setBusinessUnitHelperText("Enter Valid Name");
            }
            else {
                setBusinessUnitHelperText("")
            }
            setBusinessUnitName(e.target.value);
                break;
                case "Boffice" : 
                if (!e.target.value.match(BusinessUnitRegex)) {
                    setBusinessOfficeHelperText("Enter Valid Head-quarters");
                } else {
                    setBusinessOfficeHelperText("");
                }
                setBusinessUnitHeadOffice(e.target.value); 
                break;

            default:
                setBusinessUnitName(e.target.value);
                break;
        }
    };

    const handleOnSave = () => {
        if (!businessUnitName.match(BusinessUnitRegex)) {
            setBusinessUnitHelperText("Enter Valid Name")
            return;
        }
        UpdateBusinessUnitDetailsApiCall(value.bu_id , businessUnitName ,businessUnitHeadOffice,setEditStatus, setEditMessage)
        handleOnClose();
    }
    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Edit Business Unit
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form style={{ paddingBottom: "30px" }}>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Business Unit Name" id="Bname" value={businessUnitName} onChange={handleOnChange} helperText={BusinessUnitHelperText} error={!!BusinessUnitHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Business Unit Head-quarters" id="Boffice" value={businessUnitHeadOffice} onChange={handleOnChange} helperText={BusinessOfficeHelperText} error={!!BusinessOfficeHelperText} variant="outlined" style={{ width: "400px" }}  />
                            </Grid>
                        </Grid>
                    </form>              
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
