import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DeleteAppPortAPICall } from './DeleteAppPortAPICall';


export default function CancelPortDialog(props) {

    const { port, appId, appType, openCancelDialog, setOpenCancelDialog, setCancelStatus, setCancelOpen, setSeverStatus,setCancelStatusMessage } = props;

    const handleClose=(e)=>{
        setOpenCancelDialog(false);
    }

    const handleDelete=(e)=>{
        DeleteAppPortAPICall(appId, appType, port.port_id, port.port_number, setCancelStatus, setSeverStatus,setCancelStatusMessage);
        setCancelOpen(true);
        setOpenCancelDialog(false);
    }

    return (
        <>
            <Dialog
                open={openCancelDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Port {port.port_number}
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to delete {port.port_number} ??
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

