import axios from 'axios';

// API CALL CODE HERE
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_power_bi_token.php`;
const GetPowerBiToken = async (setToken) => {
  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });
  //  
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setToken(response.data.token);
    }
    else {
      // console.log("Error in Getting Vendors Data");
    }
  })
}

export default GetPowerBiToken;