import React, { useState, useEffect } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, Divider, Typography, CircularProgress } from '@mui/material';

import GetPortAndAuthStatusApiCall from './GetPortAndAuthStatusApiCall';

export default function CheckPortAndAuthStatusDialog(props) {
    const { value, openCheckPortAndAuthStatusDialog, setCheckPortAndAuthStatusDialog } = props;
    const [infoList, setInfoList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetPortAndAuthStatusApiCall(value.device_id, value.device_ip, (data) => {
            setInfoList(data);
            setLoading(false);
        });
    }, [])
    return (
        <>
            <Dialog open={openCheckPortAndAuthStatusDialog} onClose={() => { setCheckPortAndAuthStatusDialog(false) }} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    SSH & SNMP Status
                    <IconButton onClick={() => { setCheckPortAndAuthStatusDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 2, px: 3 }}>
                    {loading ? (
                        <>                        
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <CircularProgress />
                        </div>
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                            {"Checking Edge Device Status..."}
                        </div>
                        </>
                    ) : (
                        <List>
                            {infoList.map((row, index) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <ListItemButton >
                                        <Grid container direction={"column"} spacing={2}>
                                            {row.ssh_port_status === 1 && value.device_vendor_id !== 10 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><CheckIcon style={{ color: 'green' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "green", fontSize: "20px", fontWeight: 'bold' }}>SSH Port is Configured.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.ssh_port_status === 0 && value.device_vendor_id !== 10 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><ClearIcon style={{ color: 'red' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "red", fontSize: "20px", fontWeight: 'bold' }}> SSH Port is Not Configured.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.ssh_port_status !== 0 && row.ssh_port_status !== 1 && row.ssh_auth_status !== 0 && row.ssh_auth_status !== 1 && row.snmp_port_status !== 0 && row.snmp_port_status !== 1 && row.snmp_auth_status !== 0 && row.snmp_auth_status !== 1 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><PriorityHighIcon style={{ color: 'orange' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "orange", fontSize: "20px", fontWeight: 'bold' }}>{row}</ListItemText>
                                                    </div>
                                                </Grid>

                                            )}
                                            {row.ssh_auth_status === 1 && value.device_vendor_id !== 10 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        <ListItemIcon><CheckIcon style={{ color: 'green' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "green", fontSize: "20px", fontWeight: 'bold' }}>SSH Authentication Successful.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.ssh_auth_status === 0 && value.device_vendor_id !== 10 && (

                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><ClearIcon style={{ color: 'red' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "red", fontSize: "20px", fontWeight: 'bold' }}>SSH Authentication Failed.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.snmp_port_status === 1 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><CheckIcon style={{ color: 'green' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "green", fontSize: "20px", fontWeight: 'bold' }}>SNMP Port is Configured.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.snmp_port_status === 0 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><ClearIcon style={{ color: 'red' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "red", fontSize: "20px", fontWeight: 'bold' }}> SNMP Port is Not Configured.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.snmp_auth_status === 1 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><CheckIcon style={{ color: 'green' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "green", fontSize: "20px", fontWeight: 'bold' }}>SNMP Authentication Successful.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                            {row.snmp_auth_status === 0 && (
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ListItemIcon><ClearIcon style={{ color: 'red' }} /></ListItemIcon>
                                                        <ListItemText variant="primary" style={{ color: "red", fontSize: "20px", fontWeight: 'bold' }}>SNMP Authentication Failed.</ListItemText>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ListItemButton>
                                </div>
                            ))}

                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { setCheckPortAndAuthStatusDialog(false) }}>OK</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}








