import axios from "axios"

const GetEdgeDeviceInfoApi = async(locationId, setDeviceId, setDeviceIp, setSnmpStr, setSshUserName, setSshPassword, setApiKey, setSshPort, setApiPort, setDeviceVendor, setDeviceType) => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_info_by_location_id.php`;
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId
    });
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1)
        {
            const deviceInfo = response.data.device_info[0];
            setDeviceId(deviceInfo.device_id);
            setDeviceIp(deviceInfo.device_ip);
            setSnmpStr(deviceInfo.snmp_str);
            setSshUserName(deviceInfo.ssh_username);
            setSshPassword(deviceInfo.ssh_password);
            setApiKey(deviceInfo.api_key);
            setSshPort(deviceInfo.ssh_port);
            setApiPort(deviceInfo.api_port);
            setDeviceVendor(deviceInfo.vendor_id);
            setDeviceType(deviceInfo.type_id);
        }
        else
        {
            // console.log("No data found");
        }
    })
}

export default GetEdgeDeviceInfoApi