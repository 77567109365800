import axios from 'axios';


const GetVendorDetailsApiCall = async (setRows, setFilteredRows) => {
  try {
    let baseUrl;
    let payload;

    if (localStorage.getItem("user_map_status") === "1") {
      baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_vendor_details_for_mapped_user.php`;
      payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
      });
    } else if (localStorage.getItem("user_map_status") === "-1") {
      const path = localStorage.getItem("msp_status") === "1" ? "get_vendor_details_for_msp.php" : "get_vendor_details.php";
      baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
      });
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
      });
      payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    }

    const response = await axios.post(baseUrl, payload);

    if (response.data.code === 1) {
      setRows(response.data.vendors_data);
      setFilteredRows(response.data.vendors_data);
    } else {
      setRows([]);
      setFilteredRows([]);
    }
  } catch (error) {
    setRows([]);
    setFilteredRows([]);
  }
}

export default GetVendorDetailsApiCall;

