import axios from 'axios';

// API CALL CODE HERE

export const UpdateIssueStatusApiCall = async (issueId, setIssueStatus) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/update_notification_status.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "issue_id": issueId
  });
  // console.log(payload)
  await axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
        
        setIssueStatus(true)
    }
    else{
        setIssueStatus(false)
    }
  }).catch(()=>{
        setIssueStatus(false)
  })
}