import axios from 'axios';

export const UpdatePortForAppAPICall = async (appId, appType, portId, portNumber, portType, portCriticality, portFunction, setPortUpdateStatus, setSeverStatus) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/update_port_for_app.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "app_id": appId,
    "app_type": appType,
    "port_id": portId,
    "port_data": {
      "port_number": portNumber,
      "port_type": portType,
      "port_criticality": portCriticality,
      "port_functionality": portFunction
    }
  });


  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setPortUpdateStatus(1);
      setSeverStatus("success");

    } else if (response.data.code === 2) {
      setPortUpdateStatus(2);
      setSeverStatus("error");

    } else {
      setPortUpdateStatus(-1);
      setSeverStatus("error");
    }
  });

}

