import axios from "axios"

const UpdateCustomTagApiCall = async (data, updateddata, setUpdateStatus, setUpdateMessage) => {
    const path = localStorage.getItem("msp_status") === "1" ? "update_custom_tags_for_msp.php" : "update_custom_tags.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "device_data": {
            // "tag_id": tag_id,
            "old_name": data.tags,
            "new_name": updateddata.tags,
            "entity": updateddata.entity,
            "is_mandatory": updateddata.is_mandatory,
            "default_value": updateddata.default_value || null,
            "data_type": updateddata.data_type,
            "values" : updateddata.values || null
}
    });
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "device_data": {
            // "tag_id": tag_id,
            "old_name": data.tags,
            "new_name": updateddata.tags,
            "entity": updateddata.entity,
            "is_mandatory": updateddata.is_mandatory,
            "default_value": updateddata.default_value || null,
            "data_type": updateddata.data_type,
            "values" : updateddata.values || null
        }
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setUpdateStatus(response.data.code);
            setUpdateMessage(response.data.message);
        } else {
            setUpdateStatus(response.data.code);
            setUpdateMessage(response.data.message);
        }
    })
}
export default UpdateCustomTagApiCall