import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { divIcon } from 'leaflet';

import DeleteUtilizationConfigApiCall from './DeleteUtilizationConfigApiCall';

// api import



export default function DeleteUtilizationConfigDialog(props) {
const {data, openDeleteUtilizationDialog, setOpenDeleteUtilizationDialog, setDeleteUtilizationStatus, setDeleteUtilizationMessage} = props;

const handleClose =() =>{
    setOpenDeleteUtilizationDialog(false);
}
const handleonDelete =() =>{
    
     DeleteUtilizationConfigApiCall(data.location_id, setDeleteUtilizationStatus,setDeleteUtilizationMessage)
    handleClose();
}
return(
    <div>
        <Dialog
         open={openDeleteUtilizationDialog}
         onClose={handleClose}
         fullWidth maxWidth="sm"
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
     >
        <DialogTitle id="alert-dialog-title">
                Delete Utilization Config
            </DialogTitle>
            <Divider />
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                    Are You Sure to Delete warning Threshold and Critical Threshold of {data.location_name} ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={handleonDelete} autoFocus>
                    Yes
                </Button>
            </DialogActions>
            </Dialog>
    </div>
)


}