import axios from "axios"

// const AddEdgeDeviceApiCall = async (locationId, hostName, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setAddEdStatus, setAddEdMessage, organisationId, devSerial, snmpVersion, snmpUserName, securityLevelValue, authTypeValue, authPassword, privacyTypeValue, privacyPassword) => {
    const AddEdgeDeviceApiCall = async (deviceData, customFields, setAddEdStatus, setAddEdMessage) => {
    const path = localStorage.getItem("msp_status") === "1" ? "push_edge_device_data_for_msp.php" : "push_edge_device_data.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("reg_cust_id"),
        "device_data": {
            // "location_id": locationId,
            // "host_name": hostName,
            // "device_ip": deviceIp,
            // "snmp_version": snmpVersion,
            // "snmp_str": snmpStr,
            // "snmp_username": snmpUserName,
            // "security_level": securityLevelValue,
            // "auth_type": authTypeValue,
            // "auth_password": authPassword,
            // "privacy_type": privacyTypeValue,
            // "privacy_password": privacyPassword,
            // "ssh_username": sshUserName,
            // "ssh_password": sshPassword,
            // "api_key": apiKey,
            // "ssh_port": sshPort,
            // "api_port": apiPort,
            // "device_vendor": deviceVendor,
            // "device_type": deviceType,
            // "org_id" :organisationId,
            // "device_serial" : devSerial
            ...deviceData,
            ...customFields
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_data": {
            // "location_id": locationId,
            // "host_name": hostName,
            // "device_ip": deviceIp,
            // "snmp_version": snmpVersion,
            // "snmp_str": snmpStr,
            // "snmp_username": snmpUserName,
            // "security_level": securityLevelValue,
            // "auth_type": authTypeValue,
            // "auth_password": authPassword,
            // "privacy_type": privacyTypeValue,
            // "privacy_password": privacyPassword,
            // "ssh_username": sshUserName,
            // "ssh_password": sshPassword,
            // "api_key": apiKey,
            // "ssh_port": sshPort,
            // "api_port": apiPort,
            // "device_vendor": deviceVendor,
            // "device_type": deviceType,
            // "org_id" :organisationId,
            // "device_serial" : devSerial
            ...deviceData,
            ...customFields
        }
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setAddEdStatus(1);
            setAddEdMessage(response.data.message)

        }
        else {
            setAddEdStatus(-1);
            setAddEdMessage(response.data.message)
        }
    })

}

export default AddEdgeDeviceApiCall