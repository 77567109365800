import axios from 'axios';

// API CALL CODE HERE


const UpdateSiteGroupsProbeDataApiCall = async (probeId, allottedSiteGroups, spareSiteGroups, setUpdateStatus, setAddLocationMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "file.php" : "update_probe_id_of_site_group.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
    "alloted_site_group": allottedSiteGroups,
    "spare_site_group": spareSiteGroups
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
    "alloted_site_group": allottedSiteGroups,
    "spare_site_group": spareSiteGroups
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateStatus(1);
      setAddLocationMessage(response.data.Message)
    }
    else {
      setUpdateStatus(-1);
      setAddLocationMessage(response.data.Message)

    }
  }).catch(() => {
    setUpdateStatus(-1);
  })
}

export default UpdateSiteGroupsProbeDataApiCall;
