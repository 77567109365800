import axios from 'axios';

// API CALL CODE HERE

const UpdateBusinessUnitDetailsApiCall = async (BusinessId, BusinessUnitName,businessUnitHeadOffice, setEditStatus, setEditMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_business_unit_for_msp.php" : "update_business_unit.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "business_unit_data": {
      "bu_id": BusinessId,
      "bu_name": BusinessUnitName,
      "bu_head_office" : businessUnitHeadOffice
  }
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "business_unit_data": {
      "bu_id": BusinessId,
      "bu_name": BusinessUnitName,
      "bu_head_office" : businessUnitHeadOffice
  }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setEditStatus(1);
      setEditMessage(response.data.message)
    }
    else {
      setEditStatus(-1);
      setEditMessage(response.data.message)
    }
  }).catch((error) => {
    setEditStatus(-1);
    setEditMessage(error.response.data.message)
  })
}

export default UpdateBusinessUnitDetailsApiCall;