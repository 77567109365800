import axios from 'axios';

// API CALL CODE HERE
const base64ToBlob = (base64, mimeType) => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: mimeType });
};

const DownloadReportApiCall = async (path, file) => {
    const basepath = localStorage.getItem("msp_status") === "1" ? "download_report_for_msp.php" : "download_report.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${basepath}`;
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "file": path
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "file": path
    })

    // console.log(payload)
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            // Convert Base64 encoded data to a Blob
            const base64Response = response.data.data;
            const blob = base64ToBlob(base64Response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

            // Create a URL for the file
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', file); // Set the file name for download
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            URL.revokeObjectURL(fileURL);
        } else {
            console.error('Error: ', response.data.message);
        }
    }).catch((error) => {
        console.error('Error downloading the file:', error);
    })

}

export default DownloadReportApiCall;



