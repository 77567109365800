import axios from 'axios';

const GetMspUsersDetailsApiCall = async (setRows, setFilteredRows) => {

  const path = "get_msp_users_details_for_msp.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id")
  });

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setRows(response.data.user_data);
      setFilteredRows(response.data.user_data);
    }
    else {
      // console.log("Error in Getting Vendors Data");
    }
  })
}

export default GetMspUsersDetailsApiCall;
