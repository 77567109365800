import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Papa from "papaparse";
import { CSVLink } from "react-csv";

// material
import {
  Breadcrumbs, Container, Link, Grid, Button, Modal, Box, Fade, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Backdrop, Alert,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@mui/icons-material/Build';

// components
import Page from '../components/Page';
import { UploadMultipleISPCall } from './UploadMultipleISPAPICall';
import { useGlobalState } from '../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../components/WarningComponents/SelectCustomerWarningDialog';

// mock
// ----------------------------------------------------------------------
const allowedExtensions = ["csv"];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#D0D0D0',
          },
          '&.MuiTableRow-head': {
            backgroundColor: '#D0D0D0',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: 'Black',
          backgroundColor: '#D0D0D0',
          fontSize: '15px'
        },
      },
    },
  },
});

export default function UploadMultipleISP() {

  const { globalState, setGlobalState } = useGlobalState();
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const csvData = [
    ["Circuit_ID", "CE-IP", "PE-IP", "Link_Type", "WAN_Private_IP", "Provisioned_Speed(in Mbps)", "Price", "Renewal_Date(YYYY-MM-DD)", "Vendor_Name", "Vendor_Support_Email", "Vendor_Escalation_Mail_Id", "Promised_Uptime(%)", "Resolution_Time(in hrs)", "Site_Name", "City", "State", "Country", "Manager_Name", "Manager_Contact", "Manager_Email"],
    ["ID123", "1.1.1.1", "1.1.1.1", "ILL", "1.1.1.1", 100, 2000, "2024-01-01", "ABC", "support@abc.com", "escalate@abc.com", 99, 1, "NewYork", "NewYork", "NewYork", "US", "Ron", "9449441234", "ron@filoffee.ai"],
    ["ID123", "2.2.2.2", "2.2.2.2", "Broadband", "2.2.2.2", 100, 3000.5, "2024-01-01", "xyz", "support@xyz.com", "escalate@xyz.com", 99, 1, "Sanjose", "sanjose", "California", "US", "Rob", "9449441234", "rob@filoffee.ai"]
  ];

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState(0);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [fileValid, setFileValid] = useState(true);
  const [resetKey, setResetKey] = useState(0);
  const handleClose = () => {
    setOpen(false);
    setFile("");
    setIsFileSelected(false);
    setResetKey(prevKey => prevKey + 1);
  };

  const circuitIdRegex = /^[A-Za-z0-9_\-!@#$%^&*()+=<>?{}[\]\\|~`"'.,;: ]+$/;
  const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const linkTypeRegex = /^[a-zA-Z0-9\s]+$/;
  const provisionedSpeedRegex = /^\d+$/;
  const priceRegex = /^\d+(?:\.\d{1,2})?$/;
  const renewalDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const vendorNameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
  const promisedUptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
  const resolutionTimeRegex = /^([01]?[0-9]|2[0-4])$/;
  const locationRegex = /^[a-zA-Z\s]+$/;
  const contactNoRegex = /^\d{10}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;

  const navigate = useNavigate();

  const onWarningClose = () => {
    navigate('/dashboard/upload-isp-csv');
  }

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
    }
  }, [globalState])

  const handleFileChange = (e) => {
    setError("");
    setIsFileSelected(true);
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      setFile(inputFile);
    }
  };

  const handleParse = async () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
    }
    else {
      if (!file) return setError("Enter a valid file");
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true });
        const parsedData = csv?.data;
        setValidationError(false);

        parsedData.forEach(row => {
          Object.entries(row).forEach(([key, value]) => {
            if (
              !value.trim() ||
              (key === 'Circuit_ID' && !circuitIdRegex.test(value.trim())) ||
              (key === 'CE-IP' && !ipRegex.test(value.trim())) ||
              (key === 'PE-IP' && !ipRegex.test(value.trim())) ||
              (key === 'Link_Type' && !linkTypeRegex.test(value.trim())) ||
              (key === 'WAN_Private_IP' && !ipRegex.test(value.trim())) ||
              (key === 'Provisioned_Speed(in Mbps)' && !provisionedSpeedRegex.test(value.trim())) ||
              (key === 'Price' && !priceRegex.test(value.trim())) ||
              (key === 'Renewal_Date(YYYY-MM-DD)' && !renewalDateRegex.test(value.trim())) ||
              (key === 'Vendor_Name' && !vendorNameRegex.test(value.trim())) ||
              (key === 'Vendor_Support_Email' && !emailRegex.test(value.trim())) ||
              (key === 'Vendor_Escalation_Mail_Id' && !emailRegex.test(value.trim())) ||
              (key === 'Promised_Uptime(%)' && !promisedUptimeRegex.test(value.trim())) ||
              (key === 'Resolution_Time(in hrs)' && !resolutionTimeRegex.test(value.trim())) ||
              (key === 'Site_Name' && !locationRegex.test(value.trim())) || 
              (key === 'City' && !locationRegex.test(value.trim())) ||
              (key === 'State' && !locationRegex.test(value.trim())) ||
              (key === 'Country' && !locationRegex.test(value.trim())) ||
              (key === 'Manager_Name' && !nameRegex.test(value.trim())) ||
              (key === 'Manager_Contact' && !contactNoRegex.test(value.trim())) ||
              (key === 'Manager_Email' && !emailRegex.test(value.trim()))
            ) {
              setValidationError(true);
              setData(parsedData);
            }
          });
        });

        if (validationError) return;

        setData(parsedData);
        setOpen(true);

      };
      reader.readAsText(file);
    }

  };


  const handleOk = () => {

    UploadMultipleISPCall(data, setStatus);
    setOpen(false);
  }

  return (
    <Page title="Import/Upload CSV">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <BuildIcon />
            </Link>
            <Link underline="hover" color="inherit" >
              Link Management
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/upload-isp-csv">
              Upload CSV
            </Link>
          </Breadcrumbs>
        </div>
        <h3>ADD ISP</h3>
        <br />

        <Grid container direction={"column"} data-tut="linkeyetour_home_addmultiplelinks">
          <Grid item>
            <input
              onChange={handleFileChange}
              id="csvInput"
              name="file"
              type="File"
              key={resetKey}
            /> Sample CSV: <CSVLink data={csvData} filename={'isp_format.csv'}>Download</CSVLink>
          </Grid><br />
          <Grid item>
            <Button style={{ width: "280px", height: "50px" }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleParse} disabled={!isFileSelected}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={{ ...style, maxHeight: 800 }}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Links Preview
              </Typography>

              {data.length > 0 ? (
                <TableContainer component={Paper} sx={{ marginTop: '5%', maxHeight: 400, overflow: 'auto', scrollbarWidth: 'thin' }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <ThemeProvider theme={theme}>
                        <TableRow sx={{ backgroundColor: '#505050 !important' }}>
                          {Object.keys(data[0]).map((key, index) => (
                            <TableCell key={index}>{key}</TableCell>
                          ))}
                        </TableRow>
                      </ThemeProvider>
                    </TableHead>
                    <TableBody>
                      {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {Object.entries(row).map(([key, cell], cellIndex) => {
                            const isValid =
                              (key === 'Circuit_ID' && circuitIdRegex.test(cell.trim())) ||
                              (key === 'CE-IP' && ipRegex.test(cell.trim())) ||
                              (key === 'PE-IP' && ipRegex.test(cell.trim())) ||
                              (key === 'Link_Type' && linkTypeRegex.test(cell.trim())) ||
                              (key === 'WAN_Private_IP' && ipRegex.test(cell.trim())) ||
                              (key === 'Provisioned_Speed(in Mbps)' && provisionedSpeedRegex.test(cell.trim())) ||
                              (key === 'Price' && priceRegex.test(cell.trim())) ||
                              (key === 'Renewal_Date(YYYY-MM-DD)' && renewalDateRegex.test(cell.trim())) ||
                              (key === 'Vendor_Name' && vendorNameRegex.test(cell.trim())) ||
                              (key === 'Vendor_Support_Email' && emailRegex.test(cell.trim())) ||
                              (key === 'Vendor_Escalation_Mail_Id' && emailRegex.test(cell.trim())) ||
                              (key === 'Promised_Uptime(%)' && promisedUptimeRegex.test(cell.trim())) ||
                              (key === 'Resolution_Time(in hrs)' && resolutionTimeRegex.test(cell.trim())) ||
                              (key === 'Site_Name' && locationRegex.test(cell.trim())) || 
                              (key === 'City' && locationRegex.test(cell.trim())) ||
                              (key === 'State' && locationRegex.test(cell.trim())) ||
                              (key === 'Country' && locationRegex.test(cell.trim())) ||
                              (key === 'Manager_Name' && nameRegex.test(cell.trim())) ||
                              (key === 'Manager_Contact' && contactNoRegex.test(cell.trim())) ||
                              (key === 'Manager_Email' && emailRegex.test(cell.trim())) 
                            return (
                              <TableCell key={cellIndex}>
                                {isValid ? cell : <span style={{ color: 'red', fontSize: '15px' }}>Detail Missing</span>
                                }
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>



                  </Table>
                </TableContainer>
              ) : (
                <Typography sx={{ mt: 20, color: 'red', fontSize: '20px', textAlign: 'center' }}>
                  No data available in the uploaded file.
                </Typography>
              )}
              <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button onClick={handleClose} sx={{ marginRight: 2 }}>Cancel</Button>
                <Button onClick={handleOk} variant="contained" color="primary" disabled={validationError || data.length === 0}>Ok</Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
      <br /><br />
      {status === 1 && <Alert severity="success">ISP Info Uploaded</Alert>}
      {status === -1 && <Alert severity="error">Error in uploading.. check CSV/Contact Admin</Alert>}
      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
    </Page>
  );
}

