import {
    Breadcrumbs, Container, Link, Divider
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import ViewInventoryComponents from '../components/ViewInventory/ViewInventoryComponents';

export default function LinksDevicesInventory() {

    return (
        <Page title="LinksInventory">
            <Container maxWidth={false}>
                <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard/home">
                            <HomeOutlinedIcon />
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/dashboard/link-devices-inventory"
                        >
                            Inventory
                        </Link>
                    </Breadcrumbs>
                </div>
                <Divider/>
                <ViewInventoryComponents />
            </Container>
        </Page>
    );
}
