const timeArray = [
    // { label: "Last 5 mins", value: "5 MINUTE" },
    // { label: "Last 15 mins", value: "15 MINUTE" },
    // { label: "Last 30 mins", value: "30 MINUTE" },
    { label: "Last 1 Hours", value: "1 HOUR" },
    { label: "Last 6 Hours", value: "6 HOUR" },
    { label: "Last 12 Hours", value: "12 HOUR" },
    { label: "Last 24 Hours", value: "24 HOUR" }
  ]

  export default timeArray;