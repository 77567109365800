import React, { useState, useEffect } from 'react'
import PropTypes, { number } from 'prop-types';
import { Paper, Typography, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GetTopBestWorstSitesApiCall from '../ApiCalls/GetTopBestWorstSitesApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';


const useStyles = makeStyles({
    boxPadding: {
        padding: '25px',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 15,
    },
    cellStyles:{
        fontSize: '16px',
        minHeight: '30px'
    }
});

const headCells = [
    {
        id: 'siteName',
        numeric: false,
        disablePadding: false,
        label: 'Site Name',
    },
    {
        id: 'avgUptime',
        numeric: false,
        disablePadding: false,
        label: 'Uptime(in %)',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: '#f0f0f0', }}>
                <TableCell sx={{ mx: 1 }}>
                    Sl.No
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function SitesComponent(props) {
    const { locationId, setLocationId, selectedGroupIds, setSelectedGroupIds } = props;
    const classes = useStyles();
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const [bestSites, setBestSites] = useState([]);
    const [worstSites, setWorstSites] = useState([]);

    const [bestSiteCnt, setBestSiteCnt] = useState([]);
    const [worstSiteCnt, setWorstSiteCnt] = useState([]);
    
    const _GetTopBestWorstSitesApiCall = async () => {
        GetTopBestWorstSitesApiCall(locationId, selectedGroupIds, setBestSites, setWorstSites, worstSiteCnt, setWorstSiteCnt, bestSiteCnt, setBestSiteCnt, buState, setBuState);
    }

    useEffect(() => {
        _GetTopBestWorstSitesApiCall();
    }, [locationId, selectedGroupIds, globalState, buState])

    // useEffect(() => {
    //     _GetTopBestWorstSitesApiCall();
    // }, [globalState])


    return (

        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={8}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
                            Best Performing Sites
                        </Typography>
                    </div>
                    <Divider />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            style={{ tableLayout: 'auto' }}

                        >
                            <EnhancedTableHead
                                numSelected={bestSites.length}
                                rowCount={bestSites.length}
                            />
                            <TableBody>
                                {bestSites.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 2 }} className={classes.cellStyles}>
                                                {index + 1}
                                            </TableCell>

                                            <TableCell align="left" className={classes.cellStyles}>{row.location_name}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.uptime_percent}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {bestSiteCnt.map((number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 2 }} className={classes.cellStyles}>
                                                {""}
                                            </TableCell>

                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={8}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
                            Worst Performing Sites
                        </Typography>
                    </div>
                    <Divider />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            style={{ tableLayout: 'auto' }}
                        >
                            <EnhancedTableHead
                                numSelected={worstSites.length}
                                rowCount={worstSites.length}
                            />
                            <TableBody>
                                {worstSites.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 1 }} className={classes.cellStyles}>
                                                {index + 1}
                                            </TableCell>

                                            <TableCell align="left" className={classes.cellStyles}>{row.location_name}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.uptime_percent}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {worstSiteCnt.map((number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 2 }} className={classes.cellStyles}>
                                                {""}
                                            </TableCell>

                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    )
}
