import axios from 'axios';

export const AddApplicationAPICall = async (selectedGroupIds, AppUrl, AppName, AppType, ports,  setAddAppStatus, setSeverStatus, protocol, portNumber,setAppStatusMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "push_application_details_for_msp.php" : "push_application_details.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  let defaultMonitoringType;
  let defaultPortNumber;
  if (AppType === 1) {
    defaultMonitoringType = 1;
    defaultPortNumber = 443;
  } else if (AppType === 0) {
    defaultMonitoringType = protocol;
    defaultPortNumber = portNumber;
  }
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "group_id": selectedGroupIds,
    "app_data": {
      "app_url": AppUrl,
      "app_name": AppName,
      "app_type": AppType,
      "default_monitoring_type": defaultMonitoringType,
      "port_number": defaultPortNumber,
      "port_data": ports
    }
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "group_id": selectedGroupIds,
    "app_data": {
      "app_url": AppUrl,
      "app_name": AppName,
      "app_type": AppType,
      "default_monitoring_type": defaultMonitoringType,
      "port_number": defaultPortNumber,
      "port_data": ports
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  // return axios.post(baseUrl, payload).then((response) => {
  //   if (response.data.code === 1) {
  //     setAddAppStatus(1);
  //     setSeverStatus("success");
  //     setAppStatusMessage(response.data.message);
  //   } else if(response.data.code === -1)  {
  //     setAddAppStatus(-1);
  //     setSeverStatus("error");
  //     setAppStatusMessage(response.data.message);
  //     console.log("Set Snackbar message (error):", response.data.message); 
  //   }
  // }).catch((error) => {
  //   setAddAppStatus(-1);
  //   setSeverStatus("error");
  //   console.error("Error in API call:", error);
  // });


  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setAddAppStatus(1);
      setSeverStatus("success");
      setAppStatusMessage(response.data.message);
      
    } else if(response.data.code === -1)  {
      setAddAppStatus(-1);
      setSeverStatus("error");
      setAppStatusMessage(response.data.message);
      
    }
  }).catch((error) => {
    setAddAppStatus(-1);
    setSeverStatus("error");
    setAppStatusMessage(error.response.data.message);
    console.error("Error in API call:", error);
   });
    


  


  
}
