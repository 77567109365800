// @mui
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField } from '@mui/material';

export default function DeleteLinkDialog(props) {
  const { deleteLinkIP, openDeleteDialog, handleDelete } = props;

  return (
    <>
      <Dialog open={openDeleteDialog} onClose={()=>{handleDelete(false)}} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Link: {deleteLinkIP}
          <IconButton onClick={()=>{handleDelete(false)}} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3, px: 3 }}>
          <div>
            <div style={{ display: 'flex', margin:"20px" }}>
              <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to delete link ? </strong></Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleDelete(false)}}>Cancel</Button>
          <Button onClick={()=>{handleDelete(true)}} variant="contained">ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
