import axios from "axios"


// const UpdateLocationApi = async (locationId, prevLocationName, locationName, businessHrStart, businessHrEnd, city, state, country, remarks, managerId, managerName, managerContact, managerEmail, esc1Name, esc1Email, esc1Contact, esc1Threshold, esc2Name, esc2Email, esc2Contact, esc2Threshold, esc3Name, esc3Email, esc3Contact, esc3Threshold, latency, jitter, packetLoss, criticality, warning, critical, pauseStatus, setUpdateStatus, setSeverStatus, setUpdateMessage, setOpen) => {
    const UpdateLocationApi = async (locationData, customFields,setUpdateStatus, setSeverStatus, setUpdateMessage, setOpen)=> {
    const path = localStorage.getItem("msp_status") === "1" ? "update_location_info_for_msp.php" : "update_location_info.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "location_data": {
            // "location_id": locationId,
            // "prev_location_name": prevLocationName,
            // "present_location_name": locationName,
            // "business_hr_start": businessHrStart,
            // "business_hr_end": businessHrEnd,
            // "city": city,
            // "state": state,
            // "country": country,
            // "remarks": remarks,
            // "manager_id": managerId,
            // "manager_name": managerName,
            // "manager_contact": managerContact,
            // "manager_email": managerEmail,

            // "esc1_name": esc1Name,
            // "esc1_email": esc1Email,
            // "esc1_contact": esc1Contact,
            // "esc1_threshold": esc1Threshold,

            // "esc2_name": esc2Name,
            // "esc2_email": esc2Email,
            // "esc2_contact": esc2Contact,
            // "esc2_threshold": esc2Threshold,

            // "esc3_name": esc3Name,
            // "esc3_email": esc3Email,
            // "esc3_contact": esc3Contact,
            // "esc3_threshold": esc3Threshold,

            // "latency": latency,
            // "jitter": jitter,
            // "packet_loss": packetLoss,
            // "criticality": criticality,

            // "warning_th_value": warning,
            // "critical_th_value": critical,

            // "pause_Status": pauseStatus,
            ...locationData,
            ...customFields
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "location_data": {
            // "location_id": locationId,
            // "prev_location_name": prevLocationName,
            // "present_location_name": locationName,
            // "business_hr_start": businessHrStart,
            // "business_hr_end": businessHrEnd,
            // "city": city,
            // "state": state,
            // "country": country,
            // "remarks": remarks,
            // "manager_id": managerId,
            // "manager_name": managerName,
            // "manager_contact": managerContact,
            // "manager_email": managerEmail,

            // "esc1_name": esc1Name,
            // "esc1_email": esc1Email,
            // "esc1_contact": esc1Contact,
            // "esc1_threshold": esc1Threshold,

            // "esc2_name": esc2Name,
            // "esc2_email": esc2Email,
            // "esc2_contact": esc2Contact,
            // "esc2_threshold": esc2Threshold,

            // "esc3_name": esc3Name,
            // "esc3_email": esc3Email,
            // "esc3_contact": esc3Contact,
            // "esc3_threshold": esc3Threshold,

            // "latency": latency,
            // "jitter": jitter,
            // "packet_loss": packetLoss,
            // "criticality": criticality,

            // "warning_th_value": warning,
            // "critical_th_value": critical,

            // "pause_Status": pauseStatus,
            ...locationData,
            ...customFields
        }
    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    // return axios.post(baseUrl, payload).then((response) => {
    //     console.log("MMM",response )
    //     if (response.data.code === 1) {
    //         setUpdateStatus(1);
    //         setSeverStatus("success");
    //         setUpdateMessage(response.data.message)
    //         setOpen(true);  
    //     }
    //     else {
    //         setUpdateStatus(-1);
    //         setSeverStatus("error");
    //         setUpdateMessage(response.data.message)
    //         setOpen(true);  
    //     }
    // }).catch(()=>{
    //     setUpdateStatus(-1);
    //         setSeverStatus("error");
    //         setUpdateMessage("Error in Updation")
    // })
    try {
     const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
          setUpdateStatus(1);
          setSeverStatus("success");
          setUpdateMessage(response.data.message);
        } else {
          setUpdateStatus(-1);
          setSeverStatus("error");
          setUpdateMessage(response.data.message);
        }
        setOpen(true);
} catch (error) {
        setUpdateStatus(-1);
        setSeverStatus("error");
        setUpdateMessage("An error occurred while updating.");
        setOpen(true);
      }
}

export default UpdateLocationApi;