import axios from 'axios';

export const ISPDashboardCardsAPI = async (setTotalCnt, setUpGoodCnt, setUpBadCnt, SetDownCnt, setFlapCnt, setPauseCnt, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_link_count_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        const ispdata = response.data.link_cnt_data;
        setTotalCnt(ispdata.total_cnt);
        setUpGoodCnt(ispdata.good_cnt);
        setUpBadCnt(ispdata.bad_cnt);
        SetDownCnt(ispdata.down_cnt);
        setFlapCnt(ispdata.flap_cnt);
        setPauseCnt(ispdata.pause_cnt)
      }
    })
  }

  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_link_count_new_for_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState
      });

      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          const ispdata = response.data.link_cnt_data;
          setTotalCnt(ispdata.total_cnt);
          setUpGoodCnt(ispdata.good_cnt);
          setUpBadCnt(ispdata.bad_cnt);
          SetDownCnt(ispdata.down_cnt);
          setFlapCnt(ispdata.flap_cnt);
          setPauseCnt(ispdata.pause_cnt)
        }
        else {
          setTotalCnt(0);
          setUpGoodCnt(0);
          setUpBadCnt(0);
          SetDownCnt(0);
          setFlapCnt(0);
          setPauseCnt(0);
        }
      }).catch(() => {
        setTotalCnt(0);
        setUpGoodCnt(0);
        setUpBadCnt(0);
        SetDownCnt(0);
        setFlapCnt(0);
        setPauseCnt(0);
      })
    }


    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_link_count_new_for_msp.php" : "get_link_count_new.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          const ispdata = response.data.link_cnt_data;
          setTotalCnt(ispdata.total_cnt);
          setUpGoodCnt(ispdata.good_cnt);
          setUpBadCnt(ispdata.bad_cnt);
          SetDownCnt(ispdata.down_cnt);
          setFlapCnt(ispdata.flap_cnt);
          setPauseCnt(ispdata.pause_cnt)
        }
        else {
          setTotalCnt(0);
          setUpGoodCnt(0);
          setUpBadCnt(0);
          SetDownCnt(0);
          setFlapCnt(0);
          setPauseCnt(0);
        }
      }).catch(() => {
        setTotalCnt(0);
        setUpGoodCnt(0);
        setUpBadCnt(0);
        SetDownCnt(0);
        setFlapCnt(0);
        setPauseCnt(0);
      })
    }
  }
}


