import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, Divider, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import AddBusinessHourApiCall from './AddBusinessHourApiCall';
import GetBusinessHrsApiCall from './GetBusinessHrsApiCall';

export default function AddBusinessHoursDialog(props) {

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));
    const { value, openBusinesHourDialog, setOpenBusinessHourDialog, setAddBusinessHrStatus, setOpenBusnessHrAlert, setSeverStatus } = props;
    const [selectedValue, setSelectedValue] = useState(0);
    const [hrs, setHrs] = useState([]);
    const [hrsStatus, setHrsStatus] = useState(false);

    const [monChecked, setMonChecked] = useState(false);
    const [monStartTime, setMonStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [monEndTime, setMonEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [tueChecked, setTueChecked] = useState(false);
    const [tueStartTime, setTueStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [tueEndTime, setTueEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [wedChecked, setWedChecked] = useState(false);
    const [wedStartTime, setWedStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [wedEndTime, setWedEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [thuChecked, setThuChecked] = useState(false);
    const [thuStartTime, setThuStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [thuEndTime, setThuEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [friChecked, setFriChecked] = useState(false);
    const [friStartTime, setFriStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [friEndTime, setFriEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [satChecked, setSatChecked] = useState(false);
    const [satStartTime, setSatStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [satEndTime, setSatEndTime] = useState(new Date().setHours(17, 0, 0, 0));

    const [sunChecked, setSunChecked] = useState(false);
    const [sunStartTime, setSunStartTime] = useState(new Date().setHours(9, 0, 0, 0));
    const [sunEndTime, setSunEndTime] = useState(new Date().setHours(17, 0, 0, 0));
    const [businessHrAlert, setBusnessHrAlert] = useState(false);

    useEffect(() => {
        GetBusinessHrsApiCall(value.location_id, setHrs, setHrsStatus);
    }, [])


    useEffect(() => {

        setSelectedValue("1");

        for (let index = 0; index < hrs.length; index += 1) {
            const val = hrs[index];

            if (val.week_day === "0") {
                setMonChecked(true);
                setMonStartTime(convertTime(val.business_hr_start));
                setMonEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "1") {
                setTueChecked(true);
                setTueStartTime(convertTime(val.business_hr_start));
                setTueEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "2") {
                setWedChecked(true);
                setWedStartTime(convertTime(val.business_hr_start));
                setWedEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "3") {
                setThuChecked(true);
                setThuStartTime(convertTime(val.business_hr_start));
                setThuEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "4") {
                setFriChecked(true);
                setFriStartTime(convertTime(val.business_hr_start));
                setFriEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "5") {
                setSatChecked(true);
                setSatStartTime(convertTime(val.business_hr_start));
                setSatEndTime(convertTime(val.business_hr_end));
            }
            if (val.week_day === "6") {
                setSunChecked(true);
                setSunStartTime(convertTime(val.business_hr_start));
                setSunEndTime(convertTime(val.business_hr_end));
            }
        }
    }, [hrsStatus])


    const correctFormat = (inputTime) => {
        let formattedTime;

        if (inputTime instanceof Date) {
            const hours = inputTime.getHours();
            const minutes = inputTime.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
        } else {
            const date = new Date(parseInt(inputTime, 10));
            formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        }
        return formattedTime;
    };

    const convertTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        return new Date().setHours(hours, minutes, 0, 0)
    }

    const handleOnSave = () => {

        let data = [];
        if (selectedValue === "0") {
            data = [
                {
                    "day": "0", // Monday
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "1",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "2",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "3",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "4",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "5",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
                {
                    "day": "6",
                    "start": moment("00:00 am", ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment("11:59 pm", ['h:mma', 'h:mmA']).format('HH:mm'),
                },
            ]
        }
        else {
            if (!(monChecked || tueChecked || wedChecked || thuChecked || friChecked || satChecked || sunChecked)) {
                setBusnessHrAlert(true);
                return;
            }
            if (monChecked) {
                const monData = {
                    "day": "0",
                    "start": moment(correctFormat(monStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(monEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(monData)
            }
            if (tueChecked) {
                const tueData = {
                    "day": "1",
                    "start": moment(correctFormat(tueStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(tueEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(tueData)
            }
            if (wedChecked) {
                const wedData = {
                    "day": "2",
                    "start": moment(correctFormat(wedStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(wedEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(wedData)
            }
            if (thuChecked) {
                const thuData = {
                    "day": "3",
                    "start": moment(correctFormat(thuStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(thuEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(thuData)
            }
            if (friChecked) {
                const friData = {
                    "day": "4",
                    "start": moment(correctFormat(friStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(friEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(friData)
            }
            if (satChecked) {
                const satData = {
                    "day": "5",
                    "start": moment(correctFormat(satStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(satEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(satData)
            }
            if (sunChecked) {
                const sunData = {
                    "day": "6",
                    "start": moment(correctFormat(sunStartTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                    "end": moment(correctFormat(sunEndTime), ['h:mma', 'h:mmA']).format('HH:mm'),
                }
                data.push(sunData)
            }
        }
        AddBusinessHourApiCall(data, value.location_id, setAddBusinessHrStatus, setSeverStatus);
        setOpenBusinessHourDialog(false);
        setOpenBusnessHrAlert(true);
    }

    const handleChange = (event) => {
        if (event.target.value === "1") {
            setSelectedValue("1");
        }
        else {
            setSelectedValue("0");
        }

    }

    return (
        <>
            <Dialog open={openBusinesHourDialog} onClose={() => { setOpenBusinessHourDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Add Business Hours
                    <IconButton onClick={() => { setOpenBusinessHourDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 2, px: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ marginTop: "10px" }}>
                            Select Business Hour :
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedValue}
                                name="radio-buttons-group"
                                sx={{ paddingBottom: "20px" }}
                                onChange={handleChange}
                            >
                                <FormControl>
                                    <FormControlLabel value="0" control={<Radio />} label="24Hrs X 7 Days" />
                                    <FormControlLabel value="1" control={<Radio />} label="Custom Days" />
                                </FormControl>
                            </RadioGroup>

                            {selectedValue === "1" && <Paper sx={{ width: '100%' }} elevation={15}>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={monChecked}
                                                onChange={() => { setMonChecked(!monChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Monday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={monStartTime}
                                                label={"From"}
                                                onChange={(time) => { setMonStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={monEndTime}
                                                label={"To"}
                                                onChange={(time) => { setMonEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={tueChecked}
                                                onChange={() => { setTueChecked(!tueChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Tuesday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={tueStartTime}
                                                label={"From"}
                                                onChange={(time) => { setTueStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={tueEndTime}
                                                label={"To"}
                                                onChange={(time) => { setTueEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={wedChecked}
                                                onChange={() => { setWedChecked(!wedChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Wednesday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={wedStartTime}
                                                label={"From"}
                                                onChange={(time) => { setWedStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={wedEndTime}
                                                label={"To"}
                                                onChange={(time) => { setWedEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={thuChecked}
                                                onChange={() => { setThuChecked(!thuChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Thursday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={thuStartTime}
                                                label={"From"}
                                                onChange={(time) => { setThuStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={thuEndTime}
                                                label={"To"}
                                                onChange={(time) => { setThuEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={friChecked}
                                                onChange={() => { setFriChecked(!friChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Friday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={friStartTime}
                                                label={"From"}
                                                onChange={(time) => { setFriStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={friEndTime}
                                                label={"To"}
                                                onChange={(time) => { setFriEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={satChecked}
                                                onChange={() => { setSatChecked(!satChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Saturday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={satStartTime}
                                                label={"From"}
                                                onChange={(time) => { setSatStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={satEndTime}
                                                label={"To"}
                                                onChange={(time) => { setSatEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                                <Grid container spacing={2} alignItems="center" paddingLeft={2} paddingBottom={2}>
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={sunChecked}
                                                onChange={() => { setSunChecked(!sunChecked) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Sunday"
                                        />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={sunStartTime}
                                                label={"From"}
                                                onChange={(time) => { setSunStartTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                value={sunEndTime}
                                                label={"To"}
                                                onChange={(time) => { setSunEndTime(time) }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography>
                                            8 Hrs
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Paper>}
                        </Grid>
                        <Snackbar open={businessHrAlert} autoHideDuration={6000} onClose={() => setBusnessHrAlert(false)} >
                            <Alert severity="error" sx={{ width: '100%' }}>
                                <strong>Please Select At least 1 Checkbox</strong>
                            </Alert>
                        </Snackbar>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenBusinessHourDialog(false) }}>Cancel</Button>
                    <Button onClick={handleOnSave} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
