// material
import { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TroubleshootComponent from '../TroubleshootComponents/TroubleshootComponent';

import UptimeLogo from "../../../../Assets/uptime_logo.jpeg";
import PacketLossLogo from "../../../../Assets/packet_loss_logo.png";
import LatencyLogo from "../../../../Assets/latency_logo_2.png";
import JitterLogo from "../../../../Assets/jitter_logo.png";
import Troubleshoot from "../../../../Assets/troubleshoot.jpg";
import NetWorkSpeed from "../../../../Assets/network_speed.png";
import LinkStatus from "../../../../Assets/link_status.png";
import LinkType from "../../../../Assets/link_type.png";

// components
// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '12px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardsContainer: {
    display: 'flex',
    gap: 16,
    marginRight: '8px',
    flexWrap: 'wrap',
    paddingLeft: '15px',
  },
});

// ----------------------------------------------------------------------

export default function ISPGood1SubCards({ ispParams }) {
  const classes = useStyles();
  const [openTroubleshoot, setOpenTroubleshoot] = useState(false);

  const openTroubleshootDialog=()=>{
    setOpenTroubleshoot(true);
  }

  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }


  return (
    <>
      <div className={classes.cardsContainer}>
        <Paper sx={{ minWidth: 'fit-content', paddingRight:"100px" }} elevation={7}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
      
              <img src={PacketLossLogo} alt="logo" style={{ width: '60px', marginRight: '20px', height:"50px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Packet Loss
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {ispParams.packet_loss} (%)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content', paddingRight:"100px" }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={LatencyLogo} alt="logo" style={{ width: '40px', marginRight: '20px', height:"40px"}} />

              <div>
                <Typography variant="subtitle1" component="div">
                  Latency
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {ispParams.latency} (ms)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content', paddingRight:"100px" }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
   
              
              <img src={JitterLogo} alt="logo" style={{ width: '40px', marginRight: '20px', height:"40px"}} />

              <div>
                <Typography variant="subtitle1" component="div">
                  Jitter
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {ispParams.jitter} (ms)
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
      
        <Paper sx={{ minWidth: 'fit-content', paddingRight:"100px" }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <img src={LinkStatus} alt="logo" style={{ width: '50px', marginRight: '20px', height:"40px"}} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Status
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  UP-Good
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        
     
      </div>
    </>
  );
}
