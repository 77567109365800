import axios from 'axios';

// API CALL CODE HERE

const UpdateZoneDetailsApiCall = async (zoneId, zoneName, jitter, latency, packetLoss, setEditStatus, setEditMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_zone_for_msp.php" : "update_zone.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "zone_data": {
      "zone_id": zoneId,
      "zone_name": zoneName,
      "jitter": jitter,
      "latency": latency,
      "packet_loss": packetLoss
    }
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "zone_data": {
      "zone_id": zoneId,
      "zone_name": zoneName,
      "jitter": jitter,
      "latency": latency,
      "packet_loss": packetLoss
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setEditStatus(1);
      setEditMessage(response.data.message)
    }
    else {
      setEditStatus(-1);
      setEditMessage(response.data.message)
    }
  }).catch((error) => {
    setEditStatus(-1);
    setEditMessage(error.response.data.message)
  })
}

export default UpdateZoneDetailsApiCall;