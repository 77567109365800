import axios from "axios"


const GetEdgeDevicesDetailsApiCall = async (setLocations, setFilteredRows, setLocIdNames,buState) => {
    if (localStorage.getItem("user_map_status") === "1") {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client_for_mapped_user.php`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "bu_id" :buState
        });
        return axios.post(mappedUrl, regPayload).then((response) => {
            if (response.data.code === 1) {
                setLocations(response.data.location_data);
                setFilteredRows(response.data.location_data);
                const filteredData = response.data.location_data.filter(obj => obj.device_ip === null);
                const locIdNames = response.data.location_data.map(item => ({
                    location_id: item.location_id,
                    location_name: item.location_name,
                    device_id: item.device_id,
                }));
                setLocIdNames(locIdNames);
            }

        });
    }
    if (localStorage.getItem("user_map_status") === "-1") {
        if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
            const url = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client_for_bu.php`;
            const payload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "bu_id": buState
            });
            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    setLocations(response.data.location_data);
                    setFilteredRows(response.data.location_data);
                    const filteredData = response.data.location_data.filter(obj => obj.device_ip === null);
                    const locIdNames = response.data.location_data.map(item => ({
                        location_id: item.location_id,
                        location_name: item.location_name,
                        device_id: item.device_id,
                    }));
                    setLocIdNames(locIdNames);

                } else {
                    setLocations([]);
                    setFilteredRows([]);
                    setLocIdNames([]);
                }
            })
        }
        if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
            const path = localStorage.getItem("msp_status") === "1" ? "get_locations_by_client_for_msp.php" : "get_locations_by_client.php";
            const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

            const regPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id")
            });

            const mspPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "msp_client_id": localStorage.getItem("cust_id"),
                "reg_client_id": localStorage.getItem("reg_cust_id"),
                "msp_status": 1
            });

            const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

            return axios.post(baseUrl, payload).then((response) => {
                if (response.data.code === 1) {

                    setLocations(response.data.location_data);
                    setFilteredRows(response.data.location_data);
                    const filteredData = response.data.location_data.filter(obj => obj.device_ip === null);
                    const locIdNames = response.data.location_data.map(item => ({
                        location_id: item.location_id,
                        location_name: item.location_name,
                        device_id: item.device_id,
                    }));
                    setLocIdNames(locIdNames);

                } else {
                    setLocations([]);
                    setFilteredRows([]);
                    setLocIdNames([]);
                }
            })
        }
    }
}
export default GetEdgeDevicesDetailsApiCall