import { useEffect, useState } from 'react';

// @mui
import {
  Box, Grid, Paper, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
// import useWindowSize from './useWindowSize';
import EdgeDeviceDashboardCardsAPI from '../EdgeDevicesManagamentRestCalls/EdgeDeviceDashboardCardsAPI'
import { useGlobalState } from '../../../globalHooks/GlobalState';

import LinkLogo from '../../../Assets/link_logo.jpg';
import GoodLinkLogo from '../../../Assets/good_link_logo.jpg';
import BadLinkLogo from '../../../Assets/bad_link_logo.jpg';
import DownLinkLogo from '../../../Assets/down_link_logo.jpg';

import EdgeDeviceLogo from '../../../Assets/edge_device.jpg';

// ---------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

const MyLinkEdTotal = props => <Link to="/dashboard/edge-device-management/edge-device-total" {...props} />
const MyLinkEdGood = props => <Link to="/dashboard/edge-device-management" {...props} />
const MyLinkEdBad = props => <Link to="/dashboard/edge-device-management/edge-device-bad" {...props} />
const MyLinkEdDown = props => <Link to="/dashboard/edge-device-management/edge-device-down" {...props} />
const MyLinkEdPaused = props => <Link to="/dashboard/edge-device-management/edge-device-paused" {...props} />

export default function EdgeDeviceDashBaordCards() {
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();
  const [totalCnt, setTotalCnt] = useState(0);
  const [upGoodCnt, setUpGoodCnt] = useState(0);
  const [upBadCnt, setUpBadCnt] = useState(0);
  const [downCnt, SetDownCnt] = useState(0);
  const [pauseCnt, setPauseCnt] = useState(0);

  const _EdgeDeviceDashboardCardsAPI = async () => {
    await EdgeDeviceDashboardCardsAPI(setTotalCnt, setUpGoodCnt, setUpBadCnt, SetDownCnt, setPauseCnt, buState);
  }

  useEffect(() => {
    _EdgeDeviceDashboardCardsAPI();
    setInterval(() => {
      _EdgeDeviceDashboardCardsAPI();
    }, 61 * 1000);
  }, [globalState , buState]);


  return (
    <>
      <div>
        <Grid container spacing={3} marginTop={2}  >
          <Grid item xs={3}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkEdTotal}>
                <Box className={classes.boxItems}>
                  <img src={EdgeDeviceLogo} alt="logo" style={{ width: '60px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h4" component="div">
                    Total Edge Devices
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '10px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {totalCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkEdGood}>
                <Box className={classes.boxItems}>
                  <img src={GoodLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Good Edge Devices
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '1px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {upGoodCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkEdDown}>
                <Box className={classes.boxItems}>
                  <img src={DownLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Down Edge Devices
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {downCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper elevation={15} sx={{ px: 2, py: 2 }}>
              <Box className={classes.boxPadding} component={MyLinkEdPaused}>
                <Box className={classes.boxItems}>
                  <img src={DownLinkLogo} alt="logo" style={{ width: '50px', height:"50px"}} />
                  <Typography sx={{ color: '#9e9e9e', marginLeft: '14px' }} variant="h5" component="div">
                    Paused Edge Devices
                  </Typography>
                </Box>
                <Box sx={{ marginTop: '8px', marginLeft: '8px', color: 'black' }}>
                  <Typography variant="h2" component="div">
                    {pauseCnt}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

        </Grid>
      </div>
    </>
  );
}