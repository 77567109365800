import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ResetPasswordRestAPICall } from './ResetPasswordRestAPICall';

import { SelectUsersRestAPICall } from './SelectUsersRestAPICall';
// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();

  const [errorCode, setErrorCode] = useState();
  const [email, setEmail] = useState("");
  const [authVal, setAuthVal] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const ResetSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  // for calling setusers api
  const [users, setUsers] = useState([]);
  const [firstUsersName, setFirstUsersName] = useState("");

  const handleChange = (e, value) => {
    if(value !== null)
    {
      // setUsers(value);
      setFirstUsersName(value?.name ?? "");
    }
    else
    {
      setFirstUsersName(users[0].name);
    }
    
  };
  

  const fetchUsers = async (authVal) => {
    const fetchedUsers = await SelectUsersRestAPICall(authVal, setEmail, setUsers, setFirstUsersName, setErrorCode);
  };

  // username password part
  const defaultValues = {
    password: '',
    confirmpassword: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });
  
  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;
  
  const onSubmit = async () => {
    const { password } = getValues();
    const formData = { email, password }; // get email from state object and password from form data
    
    try {
      await ResetPasswordRestAPICall(formData.email, formData.password, firstUsersName, authVal, setErrorMessage); // pass email as first argument
      navigate('/dashboard/dashboard', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const params = new URL(currentUrl).searchParams;
    const authValue = params.get('auth');

    if (authValue) {
      setAuthVal(authValue);
      fetchUsers(authValue);
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <>
      {errorCode !== -1 && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>

        <Autocomplete
            id="users-select-demo"
            sx={{ width: '100%', margin: '2 auto', mt: 2, height: '80px' }}
            options={users}
            autoHighlight
            onChange={handleChange}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => <li {...props}>{option.name}</li>}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={firstUsersName}
                label="Select User"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-user', // disable autocomplete and autofill
                }}
              />
            )}
          />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            name="confirmpassword"
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

        </Stack>

        {errorMessage !== '' && <p style={{paddingLeft:"20px", paddingTop:"20px", paddingBottom:"20px",color:"red"}}>{errorMessage}</p>}
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{marginTop:'30px'}}>
          Submit
        </LoadingButton>
      </FormProvider>
      }
      {errorCode === -1 && <p style={{paddingLeft:"20px", paddingTop:"20px", paddingBottom:"20px",color:"red"}}>{"Access Denied"}</p>}
    </>
  );
}