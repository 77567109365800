import axios from "axios"

const GetBusinessHrsApiCall = async(locationId, setHrs, setHrsStatus) => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_business_hr_by_location.php`;
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "location_id": locationId,
    });
    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setHrs(response.data.hrs);
            setHrsStatus(true);
        }
        else
        {
            setHrs([]);
        }
       
    }).catch(()=>{
        setHrs([]);
    })
  
}

export default GetBusinessHrsApiCall