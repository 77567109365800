// @mui
import {Grid} from '@mui/material';
import CustomersTable from './Components/CustomersTable';

export default function CustomersComponents() {
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12}>
        <CustomersTable />
      </Grid>
    </Grid>
  );
}
