import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Autocomplete, TextField } from '@mui/material';
import HeatMap from "react-heatmap-grid";
import { Bar } from "react-chartjs-2";
import { useNavigate } from 'react-router-dom';
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { ISPLocationsDataAPICall } from "../../pages/SitewiseRestAPICalls";
import ApplicationMonitorTables from './ApplicationMonitorTables';
import { useGlobalState } from '../../globalHooks/GlobalState';
import CheckAgentAssignedForLocationAPICall from './CheckAgentAssignedForLocationAPICall';
import { GetSiteDataApiCall } from './GetSiteDataApiCall';
import { GetApplicationSiteWiseAPICall } from './ApplicationManagementComponents/GetApplicationSiteWiseAPICall';
import { GetSiteWiseApplicationAPICall } from './ApplicationManagementComponents/GetSiteWiseApplicationAPICall';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function SAMonitorDashboard(props) {
  const chartComponentRef = useRef();

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const [locationData, setLocationData] = useState(null);
  const [ispLocations, setIspLocations] = useState([]);
  const [firstLocationName, setFirstLocationName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [agentStatus, setAgentStatus] = useState(0);
  const [locationNames, setLocationNames] = useState([]);
  const [inputData, setInputData] = useState([]);
  const [appData, setAppData] = useState([])
  const [appCode, setAppCode] = useState();
const [warning, setWarning] = useState("")
const [critical, setCritical]= useState("")

  const handleChange = (e, value) => {
    setLocationData(value);
    setLocationId(value ? value.location_id : null);
    setFirstLocationName(value ? value.location : " ");
    setSelectedLocation(value);
    if (value && value.location_id !== undefined) {
        navigate(`/dashboard/app-monitor-dashboard/${value.location_id}`);
    }
};

  const _ISPLocationsDataAPICall = async () => {
    GetSiteDataApiCall(setIspLocations, setLocationData, setFirstLocationName, setLocationId, buState, setWarning, setCritical);
  }

  const _CheckAgentAssignedForLocationAPICall = async () => {
    CheckAgentAssignedForLocationAPICall(locationId, setAgentStatus);
  }

  useEffect(() => {
    _ISPLocationsDataAPICall();
    _CheckAgentAssignedForLocationAPICall();
    setFirstLocationName("");
    setSelectedLocation(null);
  }, [globalState, buState]);

  useEffect(() => {
    _CheckAgentAssignedForLocationAPICall();
  }, [locationId]);

  useEffect(() => {
    setFirstLocationName("")
    setLocationData("");
    setLocationId("")
  }, [firstLocationName]);

  useEffect(() => {
    const names = inputData.map(item => item.location_name);
    setLocationNames(names);
  }, [inputData]);

  useEffect(() => {
    GetApplicationSiteWiseAPICall(setInputData, buState);
    const names = inputData.map(item => item.location_name);
    setLocationNames(names);
    GetSiteWiseApplicationAPICall(setAppData, setAppCode, buState)
  }, [])

  useEffect(() => {
    GetApplicationSiteWiseAPICall(setInputData, buState);
    const names = inputData.map(item => item.location_name);
    setLocationNames(names);
    GetSiteWiseApplicationAPICall(setAppData, setAppCode, buState)
  }, [globalState])

  useEffect(() => {
    GetApplicationSiteWiseAPICall(setInputData, buState);
    const names = inputData.map(item => item.location_name);
    setLocationNames(names);
    GetSiteWiseApplicationAPICall(setAppData, setAppCode, buState)
  }, [buState])


  const navigate = useNavigate();
  const siteLength = locationNames.length;
  const numCols = siteLength > 10 ? 18 : 10;
  const numRows = Math.ceil(siteLength / numCols);

  const xLabels = new Array(numCols).fill("");
  const yLabels = new Array(numRows).fill("");

  const datas = new Array(numRows)
    .fill(0)
    .map(() => new Array(numCols).fill(null));
  const siteIds = [...new Set(inputData.map(item => item.location_id))];

  const statuses = inputData.map(item => item.status);

  const sortedInputData = [...inputData].sort((a, b) => {
    const order = { 5: 1, 4: 2, 3: 3, 2: 4, 1: 5, 0: 6 };
    return order[a.status] - order[b.status];
  });
  const sortedLocationNames = sortedInputData.map(item => item.location_name);
  if (sortedLocationNames) {
    sortedLocationNames.forEach((item, index) => {
      const row = Math.floor(index / numCols);
      const col = index % numCols;
      if (!datas[row]) {
        datas[row] = [];
      }
      datas[row][col] = item;
    });
  }

  const sortedSiteIds = sortedInputData.map(item => item.location_id);
  const handleCellClick = (x, y) => {
    const index = x + y * numCols;
    if (index < sortedSiteIds.length) {
      const siteId = sortedSiteIds[index];
      const inputDataItem = sortedInputData.find(item => item.location_id === siteId);
      if (siteId !== undefined && inputDataItem && inputDataItem.status !== 5 && inputDataItem.status !== 0) {
        navigate(`/dashboard/app-monitor-dashboard/${siteId}`);
      }
    }
  };

  const flatData = datas.flat().slice(0, siteLength);
  const heatmapData = new Array(numRows)
    .fill(0)
    .map((_, rowIndex) =>
      flatData.slice(rowIndex * numCols, (rowIndex + 1) * numCols)
    );

  const series = appData;

  const colors = {
    1: "#4CBB17",
    2: "yellow",
    3: "orange",
    4: "red",
  };


  const getDisplayName = (app) => app.split('_')[0];


  const CustomLegend = ({ chart }) => {
    const legendItems = [
      { label: "Good", color: colors[1] },
      { label: "Partial Good", color: colors[2] },
      { label: "Poor", color: colors[3] },
      { label: "Bad", color: colors[4] },
    ];
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        {legendItems.map((item, index) => (
          <div
            key={index}
            style={{ marginRight: 20, display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                backgroundColor: item.color,
                width: 20,
                height: 20,
                marginRight: 6,
                border: "2px solid black"
              }}
            > </div>
            <span style={{ fontWeight: "bold" }}>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };
  const Legend = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: "20px 20px" }}>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "#4CBB17", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Good</span>
        </div>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "yellow", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Partial Good</span>
        </div>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "orange", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Poor</span>
        </div>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "red", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Bad</span>
        </div>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "#6CB4EE", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Agent Down</span>
        </div>
        <div style={{ marginRight: "10px" }}>
          <div style={{ width: "20px", height: "20px", backgroundColor: "#B2BEB5", display: "inline-block", marginRight: "6px", border: "2px solid black" }}> </div>
          <span style={{ fontWeight: "bold" }}>Not Monitored</span>
        </div>
      </div>
    );
  };
  // =================
  const categories = appData
    ? Object.keys(appData).map((key) => key.split("_")[0])
    : [];

  const originalKeys = appData
    ? Object.keys(appData) : {};
  const seriesData = [
    {
      name: "Good",
      data: originalKeys.map((key) =>
        appData[key][1] === 0 ? null : appData[key][1]
      ),
      sitesKey: "good_sites",
      color: "#4CBB17",
      legendIndex: 0,
    },
    {
      name: "Partial Good",
      data: originalKeys.map((key) =>
        appData[key][2] === 0 ? null : appData[key][2]
      ),
      sitesKey: "partial_good_sites",
      color: "yellow",
      legendIndex: 1,
    },
    {
      name: "Poor",
      data: originalKeys.map((key) =>
        appData[key][3] === 0 ? null : appData[key][3]
      ),
      sitesKey: "poor_sites",
      color: "orange",
      legendIndex: 2,
    },
    {
      name: "Bad",
      data: originalKeys.map((key) =>
        appData[key][4] === 0 ? null : appData[key][4]
      ),
      sitesKey: "bad_sites",
      color: "red",
      legendIndex: 3,
    },
  ];
  const nameToKeyMap = originalKeys.reduce((acc, key) => {
    acc[key.split("_")[0]] = key;
    return acc;
  }, {});
  const tooltipFormatter = function (context) {
    if (!context || !context.series || !context.series.name) {
      return '';
    }
    let tooltip = `${context.series.name} : `;
    const originalKey = nameToKeyMap[context.x];
    const sites = appData[originalKey][context.series.userOptions.sitesKey];

    if (sites.length > 0) {
      tooltip += sites.join("<br/>");
    } else {
      tooltip += "No site";
    }
    return tooltip;
  };
  const dataLabelFormatter = function (context) {
    return context.y === 0.5 ? "0" : context.y;
  };


  const filterZeroData = (seriesData) => {
    return seriesData.filter(series => {
      series.data = series.data.filter(point => point !== 0);
      return series.data.length > 0;
    });
  };

  const filteredSeriesData = filterZeroData(seriesData);

  const options = {
    chart: {
      type: "bar",
      height: '100%',
      backgroundColor: "transparent",
    },
    title: null,
    xAxis: {
      categories,
      title: null,
      labels: {
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          color: '#333',
        },
      }
    },
    yAxis: {
      min: 0,
      title: null,

      labels: {
        enabled: false,
      },
      scrollbar: {
        enabled: true,
        showFull: false
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {

      series: {
        stacking: "normal",
        // pointPadding: 0.1,
        groupPadding: 0.2,
        borderWidth: 1,
        // borderRadius: 4, 
        dataLabels: {
          enabled: true,
          formatter() {
            return dataLabelFormatter(this);
          },

          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            textOutline: "none",
            color: "#000",
          },
        },
        states: {
          inactive: {
            enabled: true
          }
        },
      },
    },
    tooltip: {
      formatter() {
        return tooltipFormatter(this);
      },
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
    series: seriesData.sort((a, b) => b.legendIndex - a.legendIndex),
  };
  const height = siteLength > 10 ? 75 : 120;
  const mL = siteLength > 10 ? '0%' : '5%';
  const truncateName = (name) => {
    const maxheight = height === 120 ? 9 : 5;
    return name && name.length > maxheight ? `${name.substring(0, maxheight)}...` : name;
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '20px', marginBottom: '20px', flex: '1' }}>
        <Autocomplete
          id="location-select-demo"
          sx={{ width: '50%', mt: 2, mr: 22 }}
          options={ispLocations}
          autoHighlight
          onChange={handleChange}
          value={selectedLocation}
          getOptionLabel={(option) => option.location || ""}
          renderOption={(props, option, { selected }) => <li {...props}>{option.location}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose Site"
              placeholder={firstLocationName}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </div>
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item style={{ height: '50vh', width: '100%' }}>
          <Card sx={{ height: '100%', overflow: 'auto', scrollbarWidth: 'thin' }}>
            <CardContent style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>
                  SiteWise Application Performance
                </Typography>
                <Legend />
              </div>
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', marginTop: '2%', height: '100%', marginLeft: mL, scrollbarWidth: 'thin',
                '::-webkit-scrollbar': {
                  width: '0.01px',
                  height: '0.01px'
                },
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#888'
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                }
              }}>
                <style>
                  {`
              @keyframes flicker-border {
               0%, 100% { border-color: red; }
              50% { border-color: #6CB4EE; }
                }
                  .flicker-border {
                  animation: flicker-border 1s infinite;
                }
                  @keyframes flicker-green {
                  0%, 100% { border-color: green; }
                  50% { border-color: #4CBB17; }
                }
                .flicker-green {
                  animation: flicker-green 1s infinite;
                }
                   @keyframes flicker-orange {
                  0%, 100% { border-color: #FF5733; }
                  50% { border-color: orange; }
                }
                  .flicker-orange{
                    animation: flicker-orange 1s infinite;
                  }
                  @keyframes flicker-red {
                  0%, 100% { border-color: #8B0000; }
                  50% { border-color: red; }
                }
                  .flicker-red{
                    animation: flicker-red 1s infinite;
                  }
                     @keyframes flicker-yellow {
                  0%, 100% { border-color: yellow; }
                  50% { border-color: #FFBF00; }
                }
                .flicker-yellow {
                  animation: flicker-yellow 1s infinite;
                }
              `}
                </style>
                {inputData.length === 0 && locationNames.length === 0 ? (
                  <Typography variant="h4" style={{ color: 'red' }}>
                    Agents are not configured
                  </Typography>
                ) : (

                  <HeatMap
                    xLabels={xLabels}
                    yLabels={yLabels}
                    xLabelsLocation={"bottom"}
                    xLabelWidth={60}
                    data={datas}
                    squares
                    height={height}
                    onClick={handleCellClick}
                    cellStyle={(background, value) => {
                      if (!value) {
                        return {
                          background: "#fff",
                          fontSize: "13px",
                          color: "black",
                        };
                      }

                      let backgroundColor;
                      let borderStyle = "none";
                      let flickerClass = "";

                      const data = inputData.find((item) => item.location_name === value);
                      switch (data?.status) {
                        case 0:
                          backgroundColor = "#B2BEB5";
                          break;
                        case 1:
                          backgroundColor = "#4CBB17";
                          flickerClass = "flicker-green";
                          borderStyle = "4px solid green";
                          break;
                        case 2:
                          backgroundColor = "yellow";
                          flickerClass = "flicker-yellow";
                          borderStyle = "4px solid yellow";
                          break;
                        case 3:
                          backgroundColor = "orange";
                          flickerClass = "flicker-orange";
                          borderStyle = "4px solid #FF5733"
                          break;
                        case 4:
                          backgroundColor = "red";
                          borderStyle = "4px solid #8B0000";
                          flickerClass = "flicker-red";
                          break;
                        case 5:
                          backgroundColor = "#6CB4EE";
                          borderStyle = "4px solid red";
                          flickerClass = "flicker-border";
                          break;
                        default:
                          backgroundColor = "#9c9393";
                      }
                      return {
                        background: backgroundColor,
                        fontSize: "11.5px",
                        color: "black",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.22)",
                        borderRadius: "10px",
                        boxSizing: "border-box",
                        marginLeft: "6px",
                        marginBottom: "6px",
                        border: borderStyle,
                        animation: flickerClass ? `${flickerClass} 1s infinite` : "none",
                      };
                    }}
                    cellRender={(value) =>
                      value ? (
                        <span style={{ fontSize: "15px", fontWeight: 'bold' }}>{truncateName(value, height)}</span>
                      ) : null
                    }
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item style={{ width: '100%', height: '100%' }}>
          <Card sx={{ minHeight: '50vh', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <CardContent style={{ flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', scrollbarWidth: 'thin' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>
                  Application Performance
                </Typography>
                <CustomLegend />
              </div>
              {appData && appData.length === 0 ? (
                <Typography variant="h4" style={{ color: 'red', textAlign: 'center', marginTop: '5%' }}>
                  {appCode === 2 ? "Applications are not configured " : "Applications are not configured "}
                </Typography>
              ) : (
                <div style={{ height: '800px', overflowY: 'auto', marginTop: '2%', scrollbarWidth: 'thin' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartComponentRef}
                    {...props}
                    containerProps={{ style: { flex: 1, minHeight: 0, overflowY: 'auto' } }}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}