import React from 'react'
import ViewAgentsTable from './ViewAgentsTable'

export default function ViewAgentsComponents() {
  return (
    <>
        <ViewAgentsTable />
    </>
  )
}
