import axios from 'axios';

const GetSLABreachesCountsApiCall = async(locationId, selectedGroupIds, setTTRBreaches, setUptimeBreaches, buState,setSlaData) => {

    if (localStorage.getItem("user_map_status") === "1") {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_sla_breaches_count_for_mapped_user.php`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "group_ids": selectedGroupIds,
            "location_id": locationId,
            "bu_id": buState
        });
        return axios.post(mappedUrl, regPayload).then((response) => {
            if (response.data.code === 1) {
                setTTRBreaches(response.data.ttr_breaches);
                setUptimeBreaches(response.data.uptime_breaches);
                setSlaData(response.data.sla_data);
            }
            else {
                setTTRBreaches(0);
                setUptimeBreaches(0);
                setSlaData([]);
            }
        });
    }
    if (localStorage.getItem("user_map_status") === "-1") {
        if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
            const url = `${process.env.REACT_APP_API_SERVER_URL}/get_sla_breaches_count_for_bu.php`;
            const payload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "location_id": locationId,
                "bu_id": buState
            });

            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    setTTRBreaches(response.data.ttr_breaches);
                    setUptimeBreaches(response.data.uptime_breaches);
                    setSlaData(response.data.sla_data);
                }
                else {
                    setTTRBreaches(0);
                    setUptimeBreaches(0);
                    setSlaData([]);
                }
            });
        }

        if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
            const path = localStorage.getItem("msp_status") === "1" ? "get_sla_breaches_count_for_msp.php" : "get_sla_breaches_count.php";
            const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

            const regPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "location_id": locationId
            });

            const mspPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "msp_client_id": localStorage.getItem("cust_id"),
                "reg_client_id": localStorage.getItem("reg_cust_id"),
                "msp_status": 1,
                "location_id": locationId,
                "group_ids": selectedGroupIds,
            });
            
            const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
            
            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    setTTRBreaches(response.data.ttr_breaches);
                    setUptimeBreaches(response.data.uptime_breaches);
                    setSlaData(response.data.sla_data);
                }
                else {
                    setTTRBreaches(0);
                    setUptimeBreaches(0);
                    setSlaData([]);
                }
            });
        }
    } 
}

export default GetSLABreachesCountsApiCall