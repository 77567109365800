
// material
import {
    Breadcrumbs, Container, Link,
  } from '@mui/material';
  import BuildIcon from '@mui/icons-material/Build';
  import UtilizationConfigCompo from '../components/UtilizationConfigComponents/UtilizationConfigCompo';
  import Page from '../components/Page';
  
  
  export default function UtilizationConfig() {
  
    return (
      <Page title="System-Alerts-Email-Config">
        <Container maxWidth={false}>
  
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit">
                <BuildIcon />
              </Link>
              <Link underline="hover" color="inherit">
                Alert Config
              </Link>
              <Link underline="hover" color="inherit" href="utilization-config">
                Utilization Config
              </Link>
            </Breadcrumbs>
          </div>
  
          <UtilizationConfigCompo />
  
        </Container>
      </Page>
    );
  }
  