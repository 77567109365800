import axios from 'axios';

// API CALL CODE HERE

export const GetNotificationsApiCall = async (setNotifiCount, setNotifications) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_notifications.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id")
  });
//   console.log(payload)
  await axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
        setNotifiCount(response.data.issues.length)
        setNotifications(response.data.issues)
    }
    else{
        setNotifiCount(0)
        setNotifications([])
    }
  }).catch(()=>{
        setNotifiCount(0)
        setNotifications([])
  })
}