import axios from 'axios';

// API CALL CODE HERE

const CheckEdLanStatusApiCall2 = async (ispWanId, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage,fwsetEdLanStatusMessage,fwsetDetailEdLanStatusMessage,fwsetRcaEdLanStatusMessage, setContinueEdInterfaceStatus,setGreenAuth,setRedFailAuth,setRedFailAuths) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_edge_device_authentication_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 2) {
      setEdAddedStatus(false)
      setGotEdLanStatus(true)
      setGotEdPortStatus(false);
    
      setEdLanStatusMessage(response.data.message)
    setDetailEdLanStatusMessage(response.data.detailed_message);
    setRcaEdLanStatusMessage(response.data.rca_message)
      setCode(response.data.code)

      setContinueEdPortStatus(false);
      return
    }
    
    if ( response.data.authentication_status === 1 && response.data.code === 1) {
      setEdPortStatus(true)
      setContinueEdHealthStatus(true)
      setContinueEdPortStatus(true);
      setContinueEdInterfaceStatus(true);
      setGreenAuth(true)
    }
    else if(response.data.authentication_status === -1 && response.data.code === 1){
      setEdPortStatus(true)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(true);
      setContinueEdInterfaceStatus(true);
      setRedFailAuth(true)
    }
    else if(response.data.authentication_status === -1 && response.data.code === -1){
      setEdPortStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
      setContinueEdInterfaceStatus(false);
      setRedFailAuths(true);
    }
        else {
      setEdPortStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
      setContinueEdInterfaceStatus(false);
    }

   
    
    setGotEdPortStatus(true);
    setEdLanStatusMessage(response.data.authentication_message)
    setDetailEdLanStatusMessage(response.data.authentication_detailed_message);
    setRcaEdLanStatusMessage(response.data.authentication_rca_message)
  
  })
}

export default CheckEdLanStatusApiCall2;
