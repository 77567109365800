import React, { useState } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AddNewMspUserApiCall from './AddNewMspUserApiCall';
import Iconify from '../../Iconify';


export default function AddNewMspuserDialog(props) {
    const { openAddDialog, setOpenAddDialog, setAddStatus, setAddOpen, setSeverStatus, setAddMSPUserMessage } = props

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setpassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [role, setRole] = useState(0);

    const [phoneNoHelperText, setPhoneNoHelperText] = useState("");
    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [userRoleHelperText, setUserRoleHelperText] = useState("");
    const [emailIdHelperText, setEmailIdHelperText] = useState("");
    const [passHelperText, setPassHelperText] = useState("");
    const [cpassHelperText, setCpassHelperText] = useState("");
    const [userNameHelperText, setUserNameHelperText] = useState("");

    const [passFieldType, setPassFieldType] = useState("password");
    const [cassFieldType, setCPassFieldType] = useState("password");

    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setCShowPassword] = useState(false);


    const handleOnClose = () => {
        setOpenAddDialog(false);
    }

    const firstNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const usernameRegex = /^[^\s]+$/;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[\W_]).{8,}$/;

    const handleAddClick = (e) => {
        if (!firstName.match(firstNameRegex)) {
            setFirstNameHelperText("First Name Should be Valid");
            return;
        }

        if (!phoneNo.match(phoneRegex)) {
            setPhoneNoHelperText("Phone Number should be Valid");
            return;
        }

        if (!userName.match(usernameRegex)) {
            setUserNameHelperText("User Name should be Valid");
            return;
        }

        if (role === "") {
            setUserRoleHelperText("Role Cannot be Empty");
            return;
        }

        if (!emailId.match(emailRegex)) {
            setEmailIdHelperText("Email ID should be Valid");
            return;
        }

        if (!password.match(passwordRegex)) {
            setPassHelperText("Password must contain at least 8 characters, one capital letter, one number and one special character");
            return;
        }

        if (password !== cpassword) {
            setCpassHelperText("Password Should Match");
            return;
        }

        AddNewMspUserApiCall(firstName, lastName, phoneNo, emailId, userName, password, role, setAddStatus, setAddOpen, setSeverStatus, setAddMSPUserMessage)
        setOpenAddDialog(false);
    }

    const handleRoleChange = (e) => {
        setRole(e.target.value)
    }

    const handleFNameChange = (e) => {
        if (!e.target.value.match(firstNameRegex)) {
            setFirstNameHelperText("First Name Should be Valid");
        }
        else {
            setFirstNameHelperText("");

        }
        setFirstName(e.target.value);
    }

    const handleLNameChange = (e) => {
        if (!e.target.value.match(firstNameRegex)) {
            setLastNameHelperText("Last Name Should be Valid");
        }
        else {
            setLastNameHelperText("");

        }
        setLastName(e.target.value);
    }

    const handlePhonNoChange = (e) => {
        if (!e.target.value.match(phoneRegex)) {
            setPhoneNoHelperText("Phone Number should be Valid");
        }
        else {
            setPhoneNoHelperText("");

        }
        setPhoneNo(e.target.value);
    }

    const handleEmailChange = (e) => {
        if (!e.target.value.match(emailRegex)) {
            setEmailIdHelperText("Enter Valid Email ID");
        }
        else {
            setEmailIdHelperText("");

        }
        setEmailId(e.target.value);
    }

    const handleUserNameChange = (e) => {
        if (!e.target.value.match(usernameRegex)) {
            setUserNameHelperText("Username Should be Valid");
        }
        else {
            setUserNameHelperText("");

        }
        setUserName(e.target.value);
    }

    const handlePasswordChange = (e) => {
        if (!e.target.value.match(passwordRegex)) {
            setPassHelperText("Password must contain at least 8 characters, one capital letter, one number and one special character");
        }
        else {
            setPassHelperText("");

        }
        setpassword(e.target.value);
    }

    const handleCpasswordChange = (e) => {
        if (e.target.value !== password) {
            setCpassHelperText("Password Should Match");
        }
        else {
            setCpassHelperText("");
        }
        setCpassword(e.target.value);
    }

    return (
        <>
            <Dialog open={openAddDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add New MSP User
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form onSubmit={handleAddClick} >
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="First Name" id="fname" value={firstName} helperText={firstNameHelperText} error={firstNameHelperText} onChange={handleFNameChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Last Name" id="lname" value={lastName} helperText={lastNameHelperText} error={lastNameHelperText} onChange={handleLNameChange} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Phone No" id="phoneno" value={phoneNo} helperText={phoneNoHelperText} error={phoneNoHelperText} onChange={handlePhonNoChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Email ID" id="emailid" value={emailId} helperText={emailIdHelperText} error={emailIdHelperText} onChange={handleEmailChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="User Name" id="uname" value={userName} helperText={userNameHelperText} error={userNameHelperText} onChange={handleUserNameChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="user-role-lable">User Role</InputLabel>
                                    <Select
                                        labelId="user-role-id"
                                        id="user-role"
                                        label="User Role"
                                        style={{ width: "400px" }}
                                        value={role}
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value={0}>Super Admin</MenuItem>
                                        <MenuItem value={1}>Read Only Admin</MenuItem>
                                        <MenuItem value={2}>Engineer</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="password" helperText={passHelperText} error={passHelperText} label="Password" id="password" variant="outlined" style={{ width: "400px" }} onChange={handlePasswordChange} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="password" label="Confirm Password" id="confpassword" helperText={cpassHelperText} error={cpassHelperText} variant="outlined" style={{ width: "400px" }} onChange={handleCpasswordChange} required />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleAddClick}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
