import axios from 'axios';


const ChangePasswordRestAPICall = async (oldPassword, newPassword, confirmPassword, setUpdateStatus, setSeverStatus, setErrorMessage) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/reset_password.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id"),
    "old_password": oldPassword,
    "new_password": newPassword,
    "confirm_password": confirmPassword
  });

    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setUpdateStatus(1);
        setSeverStatus("success");
        setErrorMessage(response.data.message);

      }  else if (response.data.code === 2) {
        setUpdateStatus(2);
        setSeverStatus("error");
        setErrorMessage(response.data.message);
        
      } else {
        setUpdateStatus(-1);
        setSeverStatus("error");
        setErrorMessage(response.data.message);
      }
    }).catch(()=>{
      setUpdateStatus(-1);
    })
  }

  export default ChangePasswordRestAPICall;