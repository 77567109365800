import axios from 'axios';

const DeleteUtilizationConfigApiCall = async (locationId, setDeleteUtilizationStatus, setDeleteUtilizationMessage) => {
    

    const path = localStorage.getItem("msp_status") === "1" ? "delete_utilization_threshold_values_for_msp.php" : "delete_utilization_threshold_values.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

   
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "data": {
            "location_id": locationId
        }
    }
    );

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "data": {
            "location_id": locationId
        }
    }
    );
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setDeleteUtilizationStatus(response.data.code)
            setDeleteUtilizationMessage(response.data.message)

        }
        else {
            setDeleteUtilizationStatus(response.data.code)
            setDeleteUtilizationMessage(response.data.message)


        }
    })

}

export default DeleteUtilizationConfigApiCall;
