import axios from 'axios';

export const DeleteAppPortAPICall = async (appId, appType, portId, portNumber, setCancelStatus, setSeverStatus,setCancelStatusMessage) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/delete_app_port.php`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "app_id": appId,
        "app_type": appType, 
        "port_id": portId,
        "port_number": portNumber,
    });
  
  
    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setCancelStatus(1);
        setSeverStatus("success");
        setCancelStatusMessage(response.data.message);
      }
      else {
        setCancelStatus(-1);
        setSeverStatus("error");
        setCancelStatusMessage(response.data.message);

      }
    })
  }
  