import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";

// material
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
// mock
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import navConfig from '../../Assets/NavConfigPro';
import './sidebarprocopy.css'
import '../../Assets/Prosidebar.css';


const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};



export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const [nav, setNavigation] = useState(
    navConfig.map(i => {
      i.open = i.children.findIndex(j => j.to === window.location.pathname) !== -1 ? true : undefined
      return i;
    }))

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  
  const onOpenChange = (open, item) => {
    setNavigation(_nav => (_nav.map(i => {
      if (i.title === item.title) {
        i.open = !open
      }
      return i;
    })
    ))
  }
  // ||  i.children.findIndex(j => j.to === window.location.pathname) !== -1;

  const renderSubSubMenuItem = (children) => (
    children.map((item, index) => (
      <MenuItem key={item.to + index} icon={item.icon} active={window.location.pathname === item.to}>
        {item.title}
        <NavLink to={item.to} />
      </MenuItem>
    ))
  )


  const renderSubMenuItem = (children) => (
    <Menu>
      {children.map((item, index) => (
        <SubMenu
          key={item.title + index}
          open={item.open}
          onOpenChange={(e) => onOpenChange(e, item)}
          icon={item.icon}
          title={item.title}
        >
          {item.children && renderSubSubMenuItem(item.children)}
        </SubMenu>
      )
      )}
    </Menu>
  )


  const renderMenuItem = (children) => (
    children.map((item, index) => (
      <>
  {item.children ? (
    renderSubMenuItem(item.children)
  ) : (
    (() => {
      if (item.title === "Wifi" && ["1644", "220", "1873", "16", "1792", "1428"].includes(localStorage.getItem("user_id"))) {
        return (
          <MenuItem
            key={item.to + index}
            icon={item.icon}
            active={window.location.pathname === item.to}
          >
            {item.title}
            <NavLink to={item.to} />
          </MenuItem>
        );
      } if (item.title !== "Wifi") {
        return (
          <MenuItem
            key={item.to + index}
            icon={item.icon}
            active={window.location.pathname === item.to}
          >
            {item.title}
            <NavLink to={item.to} />
          </MenuItem>
        );
      }
      return null;
    })()
  )}
</>

    ))
  )

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 3, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <ProSidebar id='header'>
        <Menu>
          {navConfig.map((item, index) => (
            <SubMenu
              key={item.title + index}
              open={item.open}
              // onOpenChange={(e) => onOpenChange(e, item)}
              icon={item.icon}
              title={item.title}
            >
              {renderMenuItem(item.children)}
            </SubMenu>
          )
          )}
        </Menu>
      </ProSidebar>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, backgroundColor: 'rgb(250 251 252)' },
          }}
        >
          <Scrollbar>
            {renderContent}
          </Scrollbar>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}