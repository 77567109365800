// @mui
import { Breadcrumbs, Container, Link, Grid } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
// components
import ViewLocationsComponent from '../components/LocationComponents/ViewLocationComponents/ViewLocationsComponent';
import Page from '../components/Page';


export default function ViewLocations(props) {
  
  return (
    <Page title="Sitewise">
      <Container maxWidth={false}>
        <div role="presentation" style={{paddingBottom: '20px'}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <BuildIcon />
            </Link>
            <Link underline="hover" color="inherit">
              Site Management
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/view-locations">
              Manage Sites
            </Link>
          </Breadcrumbs>
        </div>
        <ViewLocationsComponent />
      </Container>
    </Page>
  );
}
