import axios from 'axios';


const renameFields = (data) => {
    return data.map(item => ({
        circuit_id: item.Circuit_ID,
        public_ip: item["CE-IP"],       
        default_gateway: item["PE-IP"],      
        link_type: item.Link_Type,
        internal_ip: item.WAN_Private_IP,
        provisioned_speed: item["Provisioned_Speed(in Mbps)"],
        price: item.Price,
        renewal_date: item["Renewal_Date(YYYY-MM-DD)"],      
        vendor_name: item.Vendor_Name,
        vendor_email: item.Vendor_Support_Email,
        escalation_mail_id: item.Vendor_Escalation_Mail_Id,
        promised_uptime: item["Promised_Uptime(%)"],        
        resolution_time: item["Resolution_Time(in hrs)"],   
        location: item.Site_Name,      
        city: item.City,
        state: item.State,
        country: item.Country,
        manager_name: item.Manager_Name,
        manager_contact: item.Manager_Contact,
        manager_email: item.Manager_Email
    }));
}


export const UploadMultipleISPCall = async (data, setStatus) => {

    const path = localStorage.getItem("msp_status") === "1" ? "push_multiple_isp_wan_info_by_client_for_msp.php" : "push_multiple_isp_wan_info_by_client.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const renamedData = renameFields(data);

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_arr": renamedData
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_arr": renamedData
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    
    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setStatus(1);
        }
        else{
            setStatus(-1);
        }
    }).catch(()=>{
        setStatus(-1);
    })
}