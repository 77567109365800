import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Divider, Box, Card, CardContent, IconButton, List } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HomeIcon from '@mui/icons-material/Home';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@mui/styles';
import Leaflet from "leaflet"
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, CircleMarker, Popup, useMapEvents } from "react-leaflet"
// import MarkerClusterGroup from 'react-leaflet-cluster';

import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"
import markerRetina from "leaflet/dist/images/marker-icon-2x.png"

import GetMarkersApiCallV2 from "../ApiCalls/GetMarkersApiCallV2";
import { useGlobalState } from '../../../globalHooks/GlobalState';
import orangeMarker from '../../../Assets/orange_marker.png'
import redMarker from '../../../Assets/red_marker.png'
import greenMarker from '../../../Assets/green_marker.png'

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

const defaultIcon = new Leaflet.Icon({
  iconUrl: markerIcon,
  iconSize: [20, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
})

const greenIcon = new Leaflet.Icon({
  iconUrl: greenMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
})

const orangeIcon = new Leaflet.Icon({
  iconUrl: orangeMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const redIcon = new Leaflet.Icon({
  iconUrl: redMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: "10px",
  },
});

const OsmMapComponent = (props) => {
  const { setLocationId, selectedGroupIds, setSelectedGroupIds, setForceGroupDetailsRefresh, isFullScreen, setIsFullScreen, fullScreenChecking, setFullScreenChecking, selectedGroupNames, setSelectedGroupNames } = props;
  const [locationName, setLocationName] = useState("");
  const mapRef = useRef();
  const classes = useStyles();
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({})
  const [status, setStatus] = useState(false);
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();
  const { buName, setBuName } = useGlobalState();
  const [selectedBuId, setSelectedBuId] = useState(-1);
  const [mapDisply, setMapDisplay] = useState(0)
  const [zoom, setZoom] = useState(8)
  const [currentZoom, setCurrentZoom] = useState(5)
  const [subMarkers, setSubMarksers] = useState([]);

  const [mainBounds, setMainBounds] = useState(null)
  const [subBounds, setSubBounds] = useState(null);
  const [homeClicked, setHomeClicked] = useState(false);
  const [screenChange, setScreenChanges] = useState(0);
  
  // const zoom = 5;
  const containerStyle = {
    width: isFullScreen ? '210%' : '100%',
    height: isFullScreen ? '1500px' : '780px',
  }

  const _GetMarkers = () => {
    GetMarkersApiCallV2(selectedGroupIds, setMarkers, setCenter, setStatus, buState, setMainBounds, setSubMarksers);
  }

  useEffect(() => {
    setZoom(8)
    setCurrentZoom(8)
    setMapDisplay(0)
    _GetMarkers();
  }, [selectedGroupIds, status, globalState, buState, isFullScreen]);

  useEffect(() => {
    setStatus(false)
  }, [localStorage.getItem("reg_cust_id")])

  // const mapClicked = async (event) => {
  //   setHomeClicked(!homeClicked)
  // }

  useEffect(() => {
    setSelectedGroupIds([])
  }, [globalState, buState])

  // function calculateExtremes(markers, setBounds) {

  //   let topmost = markers[0].position;
  //   let bottommost = markers[0].position;
  //   let leftmost = markers[0].position;
  //   let rightmost = markers[0].position;

  //   markers.forEach(coord => {
  //     if (coord.position.lat > topmost.lat) topmost = coord.position;
  //     if (coord.position.lat < bottommost.lat) bottommost = coord.position;
  //     if (coord.position.lng < leftmost.lng) leftmost = coord.position;
  //     if (coord.position.lng > rightmost.lng) rightmost = coord.position;
  //   });
  //   setBounds(Leaflet.latLngBounds([
  //     Leaflet.latLng(bottommost.lat, leftmost.lng),
  //     Leaflet.latLng(topmost.lat, rightmost.lng)
  //   ]))
  // }

  // const markerClicked = (marker, index) => {
  //   // setHomeClicked(!homeClicked);
  //   // setMapDisplay(1)
  //   setZoom(10)
  //   setCurrentZoom(10)

  //   const offset = 0.005;
  //   const members = marker.members.reduce((acc, loc) => {
  //     const key = `${loc.position.lat}-${loc.position.lng}`;
  //     if (!acc[key]) {
  //       acc[key] = [];
  //     }
  //     acc[key].push(loc);
  //     return acc;
  //   }, {});
  //   const updatedMembers = [];

  //   Object.values(members).forEach(group => {
  //     if (group.length > 1) {
  //       const angleStep = (2 * Math.PI) / group.length;
  //       group.forEach((loc, index) => {
  //         const angle = angleStep * index;
  //         const latOffset = offset * Math.cos(angle);
  //         const lngOffset = offset * Math.sin(angle);
  //         updatedMembers.push({
  //           ...loc,
  //           position: {
  //             'lat': loc.position.lat + latOffset,
  //             'lng': loc.position.lng + lngOffset
  //           }
  //         });
  //       });
  //     } else {
  //       updatedMembers.push(group[0]);
  //     }
  //   });

  //   // if (updatedMembers.length > 1) {
  //   //   calculateExtremes(updatedMembers, setSubBounds)
  //   // } else if (updatedMembers.length === 1) {
  //   //   setSubBounds(Leaflet.latLngBounds([
  //   //     Leaflet.latLng(updatedMembers[0].position.lat - 0.1, updatedMembers[0].position.lng - 0.1),
  //   //     Leaflet.latLng(updatedMembers[0].position.lat + 0.1, updatedMembers[0].position.lng + 0.1)
  //   //   ]))
  //   // } else {
  //   //   setSubBounds(Leaflet.latLngBounds([
  //   //     Leaflet.latLng(40.712, -74.227),  
  //   //     Leaflet.latLng(40.774, -74.125)   
  //   //   ]));
  //   // }
  // }

  // const markerZoomed = () => {
  //   setMapDisplay(1)
  //   setZoom(10)
  //   setCurrentZoom(10)

  //   if (subMarkers.length > 1) {
  //     setCenter(subMarkers[0].position);
  //     calculateExtremes(subMarkers, setSubBounds)
  //   } else if (subMarkers.length === 1) {
  //     setSubBounds(Leaflet.latLngBounds([
  //       Leaflet.latLng(subMarkers[0].position.lat - 0.1, subMarkers[0].position.lng - 0.1),
  //       Leaflet.latLng(subMarkers[0].position.lat + 0.1, subMarkers[0].position.lng + 0.1)
  //     ]))
  //   } else {
  //     setSubBounds(Leaflet.latLngBounds([
  //       Leaflet.latLng(40.712, -74.227),  // Example default coordinates
  //       Leaflet.latLng(40.774, -74.125)   // Example default coordinates
  //     ]));
  //   }

  // }

  // const subMarkerClicked = (marker, index) => {
  //   setLocationId(marker.location_id);
  //   setLocationName(marker.location_name);
  // }


  useEffect(() => {
    setLocationName("");
  }, [buName]);

  const markerDragEnd = (event, index) => {
  }

  const onHomeClick = () => {
    setHomeClicked(!homeClicked);
    setLocationId("");
    setSelectedGroupIds([]);
    setLocationName("");
    setBuName("");
    setFullScreenChecking(true);
    setForceGroupDetailsRefresh((prev) => prev + 1);
    const marker = mapRef.current;
    if (marker) {
      marker.closePopup();
    }
    if (isFullScreen) {
      setSelectedGroupNames([]);
    }
    setMapDisplay(0);
    setZoom(8)
    setCurrentZoom(8)
  }

  useEffect(() => {
    if (buState !== selectedBuId) {
      setSelectedBuId(buState);
    }
    if (buState === -1) {
      setBuName("");
    }
    setHomeClicked(!homeClicked);
  }, [buState]);


  const handleFullScreen = () => {
    setHomeClicked(!homeClicked)
    const mapContainer = document.getElementsByClassName("leaflet-container")[0];
    if (!isFullScreen) {
      mapContainer.style.width = "210%";
      mapContainer.style.height = "1500px";
      mapContainer.style.zIndex = "1000";
    } else {
      mapContainer.style.width = "100%";
      mapContainer.style.height = "780px";
    }
    const marker = mapRef.current;
    marker.invalidateSize()
    setIsFullScreen(!isFullScreen);
    setFullScreenChecking(false);
    // setSubBounds(mainBounds)
  };

  const createClusterCustomIcon = (cluster) => {
    return Leaflet.divIcon({
      html: `<div style="background-color: rgba(255, 255, 255, 0.6); border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;">
               <div style="width: 30px; height: 30px; border-radius: 50%; background-color: #f28a00;"></div>
             </div>`,
      className: 'custom-marker-cluster',
      iconSize: Leaflet.point(40, 40, true),
    });
  };

  // useEffect(() => {
  //   if (mapRef.current) {
  //     mapRef.current.fitBounds(mainBounds);
  //   }
  // }, [mainBounds])

  // const ZoomControl = () => {
  //   const map = useMapEvents({
  //     zoomend: () => {
  //       const newZoom = mapRef.current.getZoom();
  //       if (newZoom > currentZoom) {
  //         if (newZoom >= 8) {
  //           markerZoomed()
  //         }
  //       } else if (newZoom < currentZoom) {
  //         if (newZoom <= 8) {
  //           setMapDisplay(0)
  //         }
  //       }
  //       setCurrentZoom(newZoom);
  //     }
  //   });
  // }
  return (
    <Paper elevation={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1} style={{ width: isFullScreen ? '210%' : '100%', backgroundColor: '#ffffff', zIndex: isFullScreen ? "1000" : 'inherit', position: 'relative', transition: "width 0.1s, height 0.1s" }}>
        <Box flex="1">
          <div className={classes.titleStyle} >
            <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
              Sites-Geo Map {buName !== "" ? `/ ${buName} ` : ""}
            </Typography>
          </div>
        </Box>
        <Box className={classes.titleStyle}  >
          <IconButton variant="outlined" color="primary" onClick={onHomeClick}><HomeIcon /></IconButton>
        </Box>
        <Box className={classes.titleStyle}>
          <IconButton variant="outlined" color="primary" onClick={handleFullScreen} >
            {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>
        </Box>
      </Box>
      <Divider />
      {status && <MapContainer
        key={`${screenChange}-${homeClicked}-${mainBounds._northEast.lat}-${mainBounds._northEast.lng}-${mainBounds._southWest.lat}-${mainBounds._southWest.lng}`}
        style={containerStyle}
        bounds={mainBounds}
        boundsOptions={{ padding: [0, 0]}}
        // center={center}
        // zoom={2}
        whenCreated={mapInstance => { mapRef.current = mapInstance; }}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        />
        {/* <ZoomControl /> */}

        <MapClickHandler setHomeClicked={setHomeClicked}/>
        {/* <MarkerClusterGroup 
          // showCoverageOnHover={false}
          // iconCreateFunction={createClusterCustomIcon}
        > */}
          {markers.map((marker, index) => (
            <MainMarkerContent
              key={index}
              position={marker.position}
              members={marker.members}
              draggable={marker.draggable}
              // onMarkerClick={event => markerClicked(marker, index)}
              onDragEnd={event => markerDragEnd(event, index)}
            />
          ))}
        {/* </MarkerClusterGroup> */}
        {/* {mapDisply === 1 && subMarkers.map((marker, index) => (
          <SubMarkerContent
            key={index}
            locationId={marker.location_id}
            locationName={marker.location_name}
            status={marker.status}
            ispsUp={marker.isps_up}
            ispsDown={marker.isps_down}
            isps={marker.isp_wan_ids}
            position={marker.position}
            draggable={marker.draggable}
            onMarkerClick={event => subMarkerClicked(marker, index)}
            onDragEnd={event => markerDragEnd(event, index)}
          />
        ))} */}
      </MapContainer>}
    </Paper>
  );
};

function MapClickHandler({ setHomeClicked }) {
  const [clickTimeout, setClickTimeout] = useState(null);

  useMapEvents({
    dblclick: () => {
      if (clickTimeout) {
        clearTimeout(clickTimeout);
        setClickTimeout(null);
      }
    },
    click: () => {
      if (!clickTimeout) {
        const timeout = setTimeout(() => {
          setHomeClicked(homeClicked => !homeClicked); 
          setClickTimeout(null);
        }, 300);
        setClickTimeout(timeout);
      }
    }
  });

  return null;
}

const MapContent = ({ onClick }) => {
  const map = useMapEvents({
    click: event => onClick(event)
  })
  return null;
}

const MainMarkerContent = (props) => {
  const markerRef = useRef();
  const navigate = useNavigate();
  const { locationId, position, members, draggable, onDragEnd } = props;

  const getMarkerColor = (members) => {
    if (members.some(member => member.isps_up === 0 && member.isps_down !== 0 && member.pause_status !== "0")) {
      return '#FF5733'; // Red
    }

    if (members.some(member => member.isps_up !== 0 && member.isps_down !== 0)) {
      
      const badCnt = members.filter(member => member.isps_up !== 0 && member.isps_down !== 0 && member.isps_up !== -1 && member.isps_down !== -1 && member.pause_status !== "0").length
      const pauseCnt = members.filter(member => member.pause_status === "0" || (member.isps_up === -1 && member.isps_down === -1)).length
      // console.log(members, badCnt, pauseCnt)
      if(badCnt === 0){
        return '#008000'; // Green
      }
      
      return '#FFBF00'; // Yellow
    }

    return '#008000'; // Green
  }

  const getBubbleRadius = () => {
    // if (members.length < 2)
    //   return 6;
    if (members.length < 5)
      return 12;
    if (members.length >= 5 && members.length < 10)
      return 18
    if (members.length >= 10)
      return 22
  }

  const handleForwardClick = (loc) => {
    const locationData = {
      "location": loc.location_name,
      "location_id": loc.location_id,
      "isp_wan_ids": loc.isp_wan_ids
    }

    if (loc.isps_up === 0 && loc.isps_down !== 0) {
      navigate("/dashboard/site-down", { state: { location: locationData } })
    }
    else {
      navigate("/dashboard/site-up", { state: { location: locationData } })
    }
  };

  const getCardBackgroundColor = (loc) => {
    if(loc.pause_status === "0" || (loc.isps_up === -1 && loc.isps_down === -1)){
      return "#D3D3D3";
    }
    if (loc.isps_up !== 0 && loc.isps_down === 0) {
      return '#C8E6C9'; // green
    } if (loc.isps_up !== 0 && loc.isps_down !== 0) {
      return '#FFBF00'; // yellow
    } if (loc.isps_up === 0 && loc.isps_down !== 0) {
      return '#FF5733'; // red
    }
  };

  return <CircleMarker
    key={`marker-${members.map(member => member.location_id).join('-')}`}
    center={position}
    color={getMarkerColor(members)}  // Circle outline color
    fillColor={getMarkerColor(members)}  // Fill color
    fillOpacity={0.7}  // Fill opacity
    radius={getBubbleRadius()}  // Radius in pixels
    eventHandlers={{
      // click: event => onMarkerClick(event),
      dragend: () => onDragEnd(markerRef.current.getLatLng()),
    }}
    ref={markerRef}
  >
    <Popup>
      <Paper sx={{
        maxHeight: '60vh',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',  // Narrow width for the scrollbar
          height: '4px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'  // Optional: Change track background color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',  // Scroll thumb color
          borderRadius: '2px'  // Optional: Adds rounded corners to the scroll thumb
        }
      }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingRight: "10px", paddingLeft: "10px" }}>
          {members.map((loc, index) => (
            <Card key={index} sx={{ marginBottom: 2, backgroundColor: getCardBackgroundColor(loc) }}>
              <CardContent sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}>
                <Typography variant="h6">
                  {loc.location_name}
                </Typography>
                <Divider />
                <Typography variant="body2" color="textSecondary" sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '8px'
                }}>
                  Links UP: {loc.isps_up === -1 ? 0: loc.isps_up}
                  <IconButton onClick={() => handleForwardClick(loc)} size="small" aria-label="open site">
                    <LaunchIcon />
                  </IconButton>
                </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '8px' }} color="textSecondary">
                  Links Down: {loc.isps_down === -1 ? 0: loc.isps_down}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </List>
      </Paper>
    </Popup>
  </CircleMarker>
}

const SubMarkerContent = (props) => {
  const markerRef = useRef();
  const navigate = useNavigate();
  const { locationId, locationName, status, ispsUp, ispsDown, isps, position, draggable, onMarkerClick, onDragEnd } = props;

  const handleForwardClick = () => {
    const locationData = {
      "location": locationName,
      "location_id": locationId,
      "isp_wan_ids": isps
    }

    if (ispsUp === 0 && ispsDown !== 0) {
      navigate("/dashboard/site-down", { state: { location: locationData } })
    }
    else {
      navigate("/dashboard/site-up", { state: { location: locationData } })
    }


  };

  function StatusIcon() {
    if (ispsDown === 0 && ispsUp !== 0) {
      return greenIcon
    }
    if (ispsDown !== 0 && ispsUp !== 0) {
      return orangeIcon;
    }
    if (ispsDown !== 0 && ispsUp === 0) {
      return redIcon;
    }
  }

  function getMarkerColor() {
    if (ispsDown === 0 && ispsUp !== 0) {
      return '#008000'
    }
    if (ispsDown !== 0 && ispsUp !== 0) {
      return '#FFBF00';
    }
    if (ispsDown !== 0 && ispsUp === 0) {
      return '#FFBF00';
    }
  }
  return <Marker
    key={`${locationId}-${locationName}-${position.lat}-${position.lng}`}
    position={position}
    icon={StatusIcon()}
    draggable={draggable}
    eventHandlers={{
      click: event => onMarkerClick(event),
      dragend: () => onDragEnd(markerRef.current.getLatLng()),
    }}
    ref={markerRef}
  >
    <Popup>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div>
          <p><b>{locationName}</b></p>
          <Divider />
          <p>Links UP: {ispsUp}</p>
          <p>Links Down: {ispsDown}</p>
        </div>
        <Divider />
        <div style={{ display: 'flex' }} >
          <Typography>
            Open Site
          </Typography>
          <IconButton onClick={handleForwardClick} aria-label="forward">
            <LaunchIcon />
          </IconButton>
        </div>
      </div>
    </Popup>

  </Marker>
}

export default OsmMapComponent;