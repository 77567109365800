import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  Divider,
} from '@mui/material';
import isoFile from  "../../Assets/ISO/Linkeye-ISP-Local-Probev1.0.zip"; 
import docFile from "../../Assets/ISO/Linkeye Local Probe Deployment Document.pdf";
import { CustomCustIdRestApiCall } from './CustomCustIdRestApiCall';


const DownloadISOForm = () => {
  const customerId = localStorage.getItem('cust_id');
  const [customCustomerId, setCustomCustomerId] = useState('');

  // Call the API to get the custom customer ID when the customer ID is available
  useEffect(() => {
    if (customerId) {
      CustomCustIdRestApiCall(customerId)
        .then(response => setCustomCustomerId(response.custom_cust_id))
        .catch(error => console.error(error));
        // console.log(customCustomerId);
    }
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    const downloadLink = document.createElement('a');
    downloadLink.href = isoFile;
    downloadLink.download = 'Linkeye-ISP-Local-Probev1.0.zip';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box p={2} display="flex" justifyContent="center">
      <form onSubmit={handleSubmit}  data-tut="linkeyetour_home_connecttolocalprobe">
        <h2>Download OVF</h2>
        <Box display="flex" alignItems="center" mb={2}>
          <Button variant="contained" size='large' sx={{ width: '100%' }} type="submit">
            Download
          </Button>
          <TextField
            label="File Name"
            sx={{ width: '200%', ml: 2 }}
            defaultValue="Linkeye-ISP-Local-Probev1.0.zip"
            disabled
          />
        </Box>
        <Divider />
        <Box mt={2}>
          <FormHelperText sx={{ fontSize: '1.2rem', marginTop: 3 }}>
            Customer ID: {customCustomerId}
          </FormHelperText>
        </Box>
        <Box mt={2}>
          <FormHelperText sx={{ fontSize: '1.2rem', marginTop: 3 }}>
            Follow the instructions in below file to install the probe
          </FormHelperText>
          <Box mt={2}>
            <a href={docFile} download>
            Probe Installation Document
            </a>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default DownloadISOForm;
