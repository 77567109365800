import axios from 'axios';

// API CALL CODE HERE
const GetUsersDataAPI = async (setUsersData, setAllUserData) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_users_details_for_msp.php" : "get_users_details.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id")
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  // console.log(createBaseURL)
  // console.log(payload)

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUsersData(response.data.user_data);
      setAllUserData(response.data.user_data);
    }
    else {
      // console.log("Error in Getting User Data");
    }
  })
}

export default GetUsersDataAPI;
