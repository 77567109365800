// import React from 'react';
// import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
// import FolderIcon from '@mui/icons-material/Folder';
// import DownloadISOForm from './DownloadISOForm';

// const LocalProbeConnect = () => {
//   return (
//     <div>
//       <List>
//         <ListItem button>
//           <ListItemIcon>
//             <FolderIcon />
//           </ListItemIcon>
//           <ListItemText primary="Connect Local Probe" />
//         </ListItem>
//         <ListItem button>
//           <ListItemIcon>
//             <SettingsIcon />
//           </ListItemIcon>
//           <ListItemText primary="Settings" />
//         </ListItem>
//       </List>
//       <DownloadISOForm />
//     </div>
//   );
// };

// export default LocalProbeConnect; 

import React from 'react';
import { List, ListItemButton, ListItemIcon } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadISOForm from '../components/IsoDownloadComponents/DownloadIsoForm';

const DownloadIso = () => {
  return (
    <div>
      <List>
        <ListItemButton>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <DownloadISOForm />
        </ListItemButton>
      </List>
    </div>
  );
};

export default DownloadIso;