import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Snackbar,
} from '@mui/material';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import UpdateProbeMappingDataApiCall from './UpdateProbeMappingDataApiCall';
import UpdateZoneProbeDataApiCall from './UpdateZoneProbeDataApiCall';
import UpdateBusinessUnitsProbeDataApiCall from './UpdateBusinessUnitsProbeDataApiCall';
import UpdateSiteGroupsProbeDataApiCall from './UpdateSiteGroupsProbeDataApiCall';
import GetProbeDetailsApiCall from './GetProbeDetailsApiCall';
import GetSiteLocationsDataApiCall from './GetSiteLocationsDataApiCall';
import GetBusinessUnitsDataApiCall from './GetBusinessUnitsDataApiCall';
import GetSiteGroupProbeDataApiCall from './GetSiteGroupProbeDataApiCall';
import GetZonesProbeDataApiCall from './GetZonesProbeDataApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';

function MapComponents() {
  const custId = parseInt(localStorage.getItem('cust_id'), 10);
  const mspStatus = parseInt(localStorage.getItem('msp_status'), 10);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [siteGroups, setSiteGroups] = useState([]);
  const [probeId, setProbeId] = useState(-1);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [addLocationmessage, setAddLocationMessage] = useState('');
  const [addZonemessage, setAddZoneMessage] = useState('');
  const [addBusinessUnitMessage, setAddBusinessUnitMessage] = useState('');
  const [addSiteGroupMessage, setAddSiteGroupMessage] = useState('');

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [showDroppable, setShowDroppable] = useState(true);

  const [selectedEntity, setSelectedEntity] = useState('Locations');

  const handleProbeChange = (e) => {
    const selectedProbeId = e.target.value;
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
    }

    setProbeId(selectedProbeId);
  };

  const handleEntityChange = (e) => {
    setSelectedEntity(e.target.value); // Set the selected entity
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateStatus(0);
  };

  const updateProbe = () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    const entityApiMap = {
      Locations: UpdateProbeMappingDataApiCall,
      'Sites Groups': UpdateSiteGroupsProbeDataApiCall,
      Zones: UpdateZoneProbeDataApiCall,
      'Business Units': UpdateBusinessUnitsProbeDataApiCall,
    };

    const updateApiCall = entityApiMap[selectedEntity];

    if (updateApiCall) {
      updateApiCall(probeId, right, left, setUpdateStatus, setAddLocationMessage);
    } else {
      console.error('No API call found for selected entity');
    }
  };

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      setShowDroppable(false);
      return;
    }

    GetProbeDetailsApiCall(setSiteGroups);
    setShowDroppable(true);
  }, [globalState]);

  useEffect(() => {
    if (siteGroups.length > 0) {
      setProbeId(siteGroups[0].group_id);
    }
  }, [siteGroups, globalState]);

  // useEffect(() => {
  //   if (probeId !== -1) {
  //     GetSiteLocationsDataApiCall(probeId, setLeft, setRight, buState);
  //   }
  // }, [probeId, globalState, buState]);

  useEffect(() => {
    if (probeId !== -1) {
      // Entity-to-API mapping
      const entityApiMap = {
        Locations: GetSiteLocationsDataApiCall,
        'Sites Groups': GetSiteGroupProbeDataApiCall,
        Zones: GetZonesProbeDataApiCall,
        'Business Units': GetBusinessUnitsDataApiCall,
      };

      const apiCall = entityApiMap[selectedEntity];

      if (apiCall) {
        apiCall(probeId, setLeft, setRight, buState);
      } else {
        console.error('No API call found for selected entity');
      }
    }
  }, [probeId, selectedEntity, buState]);

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const sourceList = source.droppableId === 'Available' ? left : right;
    const destinationList = destination.droppableId === 'Allocated' ? right : left;

    const draggedItem = sourceList[source.index];

    sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, draggedItem);

    setLeft(left.slice());
    setRight(right.slice());
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, bgcolor: '#EEEBEB' }} elevation={15}>
        <Grid container>
          <Grid item xs={3} sm={3} md={3} lg={3} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
            <div>
              <Typography variant="h4">
                <strong>Probe Mapping</strong>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={8} sm={8} md={8} lg={8} sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <FormControl sx={{ width: '45%' }}>
                <InputLabel id="select-entity">Select Entity</InputLabel>
                <Select
                  labelId="select-entity"
                  id="select-entity"
                  label="Select Entity"
                  value={selectedEntity}
                  onChange={handleEntityChange} // Trigger the entity change
                >
                  <MenuItem value="Locations">Locations</MenuItem>
                  <MenuItem value="Sites Groups">Sites-Groups</MenuItem>
                  <MenuItem value="Zones">Zones</MenuItem>
                  <MenuItem value="Business Units">Business Units</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: '40%' }}>
                <InputLabel id="demo-simple-select-label">Select Probe</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Probe"
                  onChange={handleProbeChange}
                  value={probeId}
                >
                  <MenuItem value={-1}>NOT SELECTED</MenuItem>
                  {siteGroups.map((row, index) => (
                    <MenuItem value={row.server_id}>{row.server_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
            <Button variant="contained" size="large" onClick={updateProbe}>
              Update
            </Button>
          </Grid>
        </Grid>

        <Divider />

        {showDroppable && (
          <div style={{ display: 'flex', justifyContent: 'center', height: '100%', overflowY: 'auto' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <DragAndDropGroup availableItems={left} allocatedItems={right} entityName={selectedEntity} />
            </DragDropContext>
          </div>
        )}

        {updateStatus === 1 && (
          <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert
              onClose={handleSnackClose}
              severity="success"
              sx={{ width: '100%', backgroundColor: '#00b100', color: 'white' }}
            >
              {addLocationmessage}
            </Alert>
          </Snackbar>
        )}

        {updateStatus === -1 && (
          <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
              Error: {addLocationmessage}
            </Alert>
          </Snackbar>
        )}

        {openWarnDialog && (
          <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />
        )}
      </Paper>
    </Box>
  );
}
const DragAndDropGroup = ({ availableItems, allocatedItems, entityName }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
      {/* Available Items */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 8 }}>
        <h2>Available {entityName}</h2>
        <Droppable droppableId="Available">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                padding: 10,
                width: 350,
                minHeight: 500,
                overflowY: 'auto',
                maxHeight: 500,
              }}
            >
              {availableItems.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: 16,
                        margin: '0 0 8px 0',
                        minHeight: '50px',
                        backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                        color: 'white',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

      {/* Allocated Items */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 8 }}>
        <h2>Allocated {entityName}</h2>
        <Droppable droppableId="Allocated">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                padding: 10,
                width: 350,
                minHeight: 500,
                overflowY: 'auto',
                maxHeight: 500,
              }}
            >
              {allocatedItems.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: 16,
                        margin: '0 0 8px 0',
                        minHeight: '50px',
                        backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                        color: 'white',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default MapComponents;
