import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Paper, Typography, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GetTopBestWorstLinksApiCall from '../ApiCalls/GetTopBestWorstLinksApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';


const useStyles = makeStyles({
    boxPadding: {
        padding: '25px',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 15,
    },
    cellStyles:{
        fontSize: '16px',
    }
});

const headCells = [
    {
        id: 'ipAddress',
        numeric: false,
        disablePadding: false,
        label: 'IP Address',
    },
    {
        id: 'siteName',
        numeric: false,
        disablePadding: false,
        label: 'Site Name',
    },
    {
        id: 'Vendor',
        numeric: false,
        disablePadding: false,
        label: 'Vendor',
    },
    {
        id: 'avgUptime',
        numeric: false,
        disablePadding: false,
        label: 'Uptime(in %)',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: '#f0f0f0', }}>
                <TableCell sx={{ mx: 1 }}>
                    Sl.No
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        sx={{ mx: 1 }}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function LinksComponent(props) {
    const { locationId, setLocationId, selectedGroupIds, setSelectedGroupIds } = props;
    const classes = useStyles();
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const [bestLinks, setBestLinks] = useState([]);
    const [worstLinks, setWorstLinks] = useState([]);

    const [bestLinkCnt, setBestLinkCnt] = useState([]);
    const [worstLinkCnt, setWorstLinkCnt] = useState([]);

    const _GetTopBestWorstLinksApiCall = async () => {
        GetTopBestWorstLinksApiCall(locationId, selectedGroupIds, setBestLinks, setWorstLinks, bestLinkCnt, setBestLinkCnt, worstLinkCnt, setWorstLinkCnt, buState);
    }

    useEffect(() => {
        _GetTopBestWorstLinksApiCall();
    }, [locationId, selectedGroupIds, globalState, buState ])

    // useEffect(() => {
    //     _GetTopBestWorstLinksApiCall();
    // }, [globalState])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={8}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
                            Best Performing Links
                        </Typography>
                    </div>
                    <Divider />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            style={{ tableLayout: 'auto' }}

                        >
                            <EnhancedTableHead
                                numSelected={bestLinks.length}
                                rowCount={bestLinks.length}
                            />
                            <TableBody>
                                {bestLinks.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 1 }} className={classes.cellStyles}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.public_ip}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.location}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.vendor}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.uptime_percent}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {bestLinkCnt.map((number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 1 }} className={classes.cellStyles}>
                                                {""}
                                            </TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={8}>
                    <div className={classes.titleStyle}>
                        <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
                            Worst Performing Links
                        </Typography>
                    </div>
                    <Divider />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            style={{ tableLayout: 'auto' }}
                        >
                            <EnhancedTableHead
                                numSelected={worstLinks.length}
                                rowCount={worstLinks.length}
                            />
                            <TableBody>
                                {worstLinks.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 1 }} className={classes.cellStyles}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.public_ip}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.location}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.vendor}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{row.uptime_percent}%</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {worstLinkCnt.map((number) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className='rowHeight55px'
                                        >
                                            <TableCell sx={{ mx: 1 }} className={classes.cellStyles}>
                                                {""}
                                            </TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                            <TableCell align="left" className={classes.cellStyles}>{""}</TableCell>
                                        </TableRow>
                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    )
}
