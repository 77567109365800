import React from 'react';
// material
import {
    Link,
    Breadcrumbs,
    Paper,
    Grid
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
import CreateUserForm from '../components/UserComponents/CreateUserForm';


export default function CreateNewUser() {
    return (
        <Page title="Create New User">
            <div role="presentation" style={{ cursor: 'pointer', padding: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                        User Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/create-new-user">
                        Add New User
                    </Link>
                </Breadcrumbs>
            </div>
            <Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper elevation={15}>
                        <CreateUserForm />
                    </Paper>
                </Grid>
            </Grid>


        </Page>
    );

}
