import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// material
import {
  Grid,
  Table,
  Button,
  ButtonGroup,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';


// components
import Scrollbar from '../../Scrollbar';
import AlertsTableHead from './AlertsTableHead';
import AlertsTableToolbar from './AlertsTableToolbar';
import { AlertAPICall } from './AlertAPICall';
import { SiteAlertsApiCall } from './SiteAlertsApiCall';
import { AgentAlertApiCall } from './AgentAlertApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import { AppAlertApiCall } from './AppAlertApiCall';
import { EdLogsAPICall } from './EdLogsAPICall';
// mock
import USERLIST from '../../../_mock/user';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';




const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


const TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'circuitid', label: 'Circuit ID', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'publicIp', label: 'Public IP', alignRight: false },
  { id: 'vendor', label: 'Vendor', alignRight: false },
  { id: 'criticality', label: 'Criticality', alignRight: false },
  { id: 'alertDetails', label: 'Alert Details', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  { id: 'ticketStatus', label: 'Ticket Status', alignRight: false },

];

const SITE_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'criticality', label: 'Criticality', alignRight: false },
  { id: 'alertDetails', label: 'Alert Details', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  { id: 'ticketStatus', label: 'Ticket Status', alignRight: false },
];

const APP_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'Application Name', label: 'Application Name', alignRight: false },
  { id: 'Location', label: 'Location', alignRight: false },
  { id: 'Details', label: 'Details', alignRight: false },
  { id: 'Criticality', label: 'Criticality', alignRight: false },
  { id: 'ticketStatus', label: 'Ticket Status', alignRight: false },
];

const AGENT_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'Agent Serial Number', label: 'Agent Serial Number', alignRight: false },
  { id: 'Location', label: 'Location', alignRight: false },
  { id: 'Details', label: 'Details', alignRight: false },
  { id: 'ticketStatus', label: 'Ticket Status', alignRight: false },
];

const EDGE_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', numeric: true, disablePadding: false },
  { id: 'locationName', label: 'Location Name', numeric: false, disablePadding: false },
  { id: 'deviceIP', label: 'Device IP', numeric: false, disablePadding: false },
  { id: 'deviceType', label: 'Device Type', numeric: false, disablePadding: false },
  { id: 'deviceVendor', label: 'Device Vendor', numeric: false, disablePadding: false },
  { id: 'details', label: 'Details', numeric: false, disablePadding: false },
  { id: 'criticality', label: 'Criticality', numeric: false, disablePadding: false },
  { id: 'updatedAt', label: 'Updated At', numeric: false, disablePadding: false, },

]
export default function AlertsTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [linkAlerts, setLinkAlerts] = useState([]);
  const [allLinkAlerts, setAllLinkAlerts] = useState([]);

  const [siteAlerts, setSiteAlerts] = useState([]);
  const [allSiteAlerts, setAllSiteAlerts] = useState([]);

  const [appAlerts, setAppAlerts] = useState([]);
  const [allappAlerts, setAllappAlerts] = useState([]);

  const [agentAlerts, setAgentAlerts] = useState([]);
  const [allAgentAlerts, setAllAgentAlerts] = useState([]);

  const [edgeAlerts, setEdgeAlerts] = useState([]);
  const [allEdgeAlerts, setAllEdgeAlerts] = useState([])


  const [linkVariant, setLinkVariant] = useState("contained");

  const [siteVariant, setSiteVariant] = useState("outlined");

  const [appVariant, setAppVariant] = useState("outlined");

  const [agentVariant, setAgentVariant] = useState("outlined");

  const [edgeVariant, setEdgeVariant] = useState("outlined");

  const [showLinkEvents, setShowLinkEvents] = useState(true);

  const [tableTitle, setTableTitle] = useState("Link Events");
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
const [openWarnDialog, setOpenWarnDialog] = useState(false);


  const _AlertAPICall = async () => {

    await AlertAPICall(setLinkAlerts, setAllLinkAlerts, buState);
    await SiteAlertsApiCall(setSiteAlerts, setAllSiteAlerts, buState);
    await AppAlertApiCall(setAppAlerts, setAllappAlerts, buState);
    await AgentAlertApiCall(setAgentAlerts, setAllAgentAlerts, buState);
    await EdLogsAPICall(setEdgeAlerts, setAllEdgeAlerts, buState);
  }

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    _AlertAPICall();
    // setInterval(() => {
    //   _AlertAPICall();
    //   setFilterName("");
    // }, 60 * 1000);
  }, [globalState, buState]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }

    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllLinkAlerts(allLinkAlerts);
    const filteredRows = allLinkAlerts.filter((row) => {
      return row.circuit_id.toLowerCase().includes(searchedVal.toLowerCase()) || row.location.toLowerCase().includes(searchedVal.toLowerCase()) || row.public_ip.toLowerCase().includes(searchedVal.toLowerCase()) || row.vendor.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setLinkAlerts(filteredRows);
  };

  const handleSitesFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllSiteAlerts(allSiteAlerts);
    const filteredRows = allSiteAlerts.filter((row) => {
      return row.location.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setSiteAlerts(filteredRows);
  };


  const handleApplicationFilterByName = (event, searchedVal) => {
    setFilterName(searchedVal);
    // If the search value is empty, show all data
    if (!searchedVal) {
      setAppAlerts(allappAlerts);
    } else {
      // Filter the data based on the search value
      const filteredRows = allappAlerts.filter((row) => {
        return (
          row.app_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.location_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.details.toLowerCase().includes(searchedVal.toLowerCase())
        );
      });
      setAppAlerts(filteredRows);
    }
  };


  const handleAgentsFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllAgentAlerts(allAgentAlerts);
    const filteredRows = allAgentAlerts.filter((row) => {
      return (
        row.agent_serial_number.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.location_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.details.toLowerCase().includes(searchedVal.toLowerCase())
      );
      
    });
    setAgentAlerts(filteredRows);
  };

  const handleEdgeDeviceFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllEdgeAlerts(allEdgeAlerts);
    const filteredRows = allEdgeAlerts.filter((row) => {
      return row.location_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.device_ip.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.type.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.vendor_name.toLowerCase().includes(searchedVal.toLowerCase());;
    });
    setEdgeAlerts(filteredRows);
  };


  const handleButtonClick = (e) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (e.target.id === "linkButton") {
    
      setLinkVariant("contained");
      setSiteVariant("outlined");
      setAppVariant("outlined");
      setAgentVariant("outlined");
      setEdgeVariant("outlined")
      setShowLinkEvents(true);
      setTableTitle("Link Events");
      AlertAPICall(setLinkAlerts, setAllLinkAlerts, buState);
    }
    if (e.target.id === "siteButton") {
      setLinkVariant("outlined");
      setSiteVariant("contained");
      setAppVariant("outlined");
      setAgentVariant("outlined");
      setEdgeVariant("outlined")
      setShowLinkEvents(false);
      setTableTitle("Site Events");
      SiteAlertsApiCall(setSiteAlerts, setAllSiteAlerts, buState);
    }
    if (e.target.id === "appButton") {
      setLinkVariant("outlined");
      setSiteVariant("outlined");
      setAppVariant("contained");
      setAgentVariant("outlined");
      setEdgeVariant("outlined")
      setShowLinkEvents(2);
      setTableTitle("Application Events");
      AppAlertApiCall(setAppAlerts, setAllappAlerts, buState);
    }
    if (e.target.id === "agentButton") {
      setLinkVariant("outlined");
      setSiteVariant("outlined");
      setAppVariant("outlined");
      setAgentVariant("contained");
      setEdgeVariant("outlined")
      setShowLinkEvents(3);
      setTableTitle("Agent Events");
      AgentAlertApiCall(setAgentAlerts, setAllAgentAlerts, buState);
    }
    if (e.target.id === "edgeButton") {
      setLinkVariant("outlined");
      setSiteVariant("outlined");
      setAppVariant("outlined");
      setAgentVariant("outlined");
      setEdgeVariant("contained")
      setShowLinkEvents(4);
      setTableTitle("Edge Device Events");
      EdLogsAPICall(setEdgeAlerts, setAllEdgeAlerts, buState)
    }
    setFilterName("");
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <ButtonGroup
              size="large"
              sx={{
                width: "100%", // Consider adjusting this to 100% for better layout control
                justifyContent: "flex-end", // Align items to the right
                height: "100%"
              }}
            >
              <Button id="linkButton" variant={linkVariant} onClick={handleButtonClick}>Link Events</Button>
              <Button id="siteButton" variant={siteVariant} onClick={handleButtonClick}>Site Events</Button>
              <Button id="appButton" variant={appVariant} onClick={handleButtonClick}>Application Events</Button>
              <Button id="agentButton" variant={agentVariant} onClick={handleButtonClick}>Agent Events</Button>
              <Button id="edgeButton" variant={edgeVariant} onClick={handleButtonClick}>Edge Device Events</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <br/>
      
        {!(globalState === custId && mspStatus === 1) && showLinkEvents === true && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={linkAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {linkAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.circuit_id}</TableCell>
                        <TableCell align="left">{row.location}</TableCell>
                        <TableCell align="left">{row.public_ip}</TableCell>
                        <TableCell align="left">{row.vendor}</TableCell>

                        {row.criticality === 1 && <TableCell align="left"><Chip label="Info" color="success" /></TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Alert" color="warning" /></TableCell>}
                        {row.criticality === 3 && <TableCell align="left"><Chip label="Critical" color="error" /></TableCell>}

                        <TableCell align="left">{row.details}</TableCell>
                        <TableCell align="left">{row.ent}</TableCell>

                        {row.criticality === 1 && <TableCell align="left">{""}</TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}
                        {row.criticality === 3 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}

        {!(globalState === custId && mspStatus === 1) && showLinkEvents === false && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleSitesFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={SITE_TABLE_HEAD}
                  rowCount={siteAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {siteAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.location}</TableCell>

                        {row.criticality === 1 && <TableCell align="left"><Chip label="Info" color="success" /></TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Alert" color="warning" /></TableCell>}
                        {row.criticality === 3 && <TableCell align="left"><Chip label="Critical" color="error" /></TableCell>}

                        <TableCell align="left">{row.details}</TableCell>
                        <TableCell align="left">{row.ent}</TableCell>

                        {row.criticality === 1 && <TableCell align="left">{""}</TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}
                        {row.criticality === 3 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}

         {!(globalState === custId && mspStatus === 1) && showLinkEvents === 2 && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleApplicationFilterByName(e, e.target.value) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={APP_TABLE_HEAD}
                // rowCount={appAlerts.length}
                // onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {appAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.app_name}</TableCell>
                        <TableCell align="left">{row.location_name}</TableCell>
                        <TableCell align="left">{row.details}</TableCell>
                        {/* <TableCell align="left">{row.vendor}</TableCell> */}

                        {row.criticality === 1 && <TableCell align="left"><Chip label="Info" color="success" /></TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Alert" color="warning" /></TableCell>}
                        {row.criticality === 3 && <TableCell align="left"><Chip label="Critical" color="error" /></TableCell>}

                        {/* <TableCell align="left">{row.details}</TableCell> */}
                        {/* <TableCell align="left">{row.ent}</TableCell> */}

                        {/* {row.smtp_status === 0  && <TableCell align="left">{" "}</TableCell>}
                            {row.smtp_status === 1 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>} */}
                        {row.smtp_status === 0 && <TableCell align="left">{" "}</TableCell>}
                        {(row.smtp_status === 1 && row.criticality === 1) && <TableCell align="left">{" "}</TableCell>}
                        {(row.smtp_status === 1 && row.criticality !== 1) && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}

        {!(globalState === custId && mspStatus === 1) && showLinkEvents === 3 && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleAgentsFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={AGENT_TABLE_HEAD}
                  rowCount={agentAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {agentAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.agent_serial_number}</TableCell>
                        <TableCell align="left">{row.location_name}</TableCell>
                        <TableCell align="left">{row.details}</TableCell>

                        {row.smtp_status === 0 && <TableCell align="left">{" "}</TableCell>}
                        {row.smtp_status === 1 && <TableCell align="left"><Chip label="Ticket Created" color="info" /></TableCell>}

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}


        {!(globalState === custId && mspStatus === 1) && showLinkEvents === 4 && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleEdgeDeviceFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={EDGE_TABLE_HEAD}
                  rowCount={edgeAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {edgeAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.location_name}</TableCell>
                        <TableCell align="left">{row.device_ip}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.vendor_name}</TableCell>
                        <TableCell align="left">{row.details}</TableCell>
                        {row.criticality === 1 && <TableCell align="left"><Chip label="Info" color="info" /></TableCell>}
                        {row.criticality === 2 && <TableCell align="left"><Chip label="Critical" color="error" /></TableCell>}
                        <TableCell align="left">{row.ent}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}
        {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

      </Grid>
    </>
  );
}
