// @mui
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField } from '@mui/material';

// Import API call
import DeleteSiteGroupApiCall from '../DeleteSiteGroupApiCall';


export default function DeleteSiteGroupDialog(props) {
  const { group, openDeleteDialog, setOpenDeleteDialog, setDeleteStatus } = props;

  const handleClose=()=>{
    setOpenDeleteDialog(false);
  }
  const handleDelete=()=>{
    DeleteSiteGroupApiCall(group.group_id, group.group_name, setDeleteStatus)
    handleClose();
  }

  return (
    <>
      <Dialog open={openDeleteDialog} onClose={()=>{handleDelete(false)}} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Delete Site Group
          <IconButton onClick={()=>{handleDelete(false)}} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3, px: 3 }}>
          <div>
            <div style={{ display: 'flex', margin:"20px" }}>
              <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to delete site group - {group.group_name} ? </strong></Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} variant="contained">ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
