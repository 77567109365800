import React, { useState, useEffect } from 'react';

import {
    Grid,
    TextField,
    Button,
    Alert,
    FormHelperText
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

import { CreateUserApiCall } from './CreateUserApiCall';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';


export default function CreateUserForm() {
    const [data, setData] = useState(0);

    const { globalState, setGlobalState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [role, setRole] = useState(0);
    const [addUserMessage, setAddUserMessage] = useState("");


    const [emailIdHelperText, setEmailIdHelperText] = useState("");
    const [phoneNoHelperText, setPhoneNoHelperText] = useState("");
    const [userRoleHelperText, setUserRoleHelperText] = useState("");
    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [userNameHelperText, setUserNameHelperText] = useState("");
    const [mpasswordHelperText, setMpasswordHelperText] = useState("");

    const navigate = useNavigate();


    const firstNameRegex = /^[A-Za-z][A-Za-z-_\d ]*$/;
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const usernameRegex = /^[^\s]+$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()@#$%^:;-_&+=!])[A-Za-z\d@():;-_#$%^&+=!]{8,}$/;


    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "fname": if (!e.target.value.match(firstNameRegex)) {
                setFirstNameHelperText("First Name Should be Valid");
            }
            else {
                setFirstNameHelperText("");

            }
                setFirstName(e.target.value);
                break;

            case "lname": if (!e.target.value.match(firstNameRegex)) {
                setLastNameHelperText("Last Name Should be Valid");
            }
            else {
                setLastNameHelperText("");

            }
                setLastName(e.target.value);
                break;

            case "phoneno": if (!e.target.value.match(phoneRegex)) {
                setPhoneNoHelperText("Phone Number should be Valid");
            }
            else {
                setPhoneNoHelperText("");

            }
                setPhoneNo(e.target.value);
                break;

            case "emailid": if (!e.target.value.match(emailRegex)) {
                setEmailIdHelperText("Enter Valid Email ID");
            }
            else {
                setEmailIdHelperText("");

            }
                setEmailId(e.target.value);
                break;

            case "uname": if (!e.target.value.match(usernameRegex)) {
                setUserNameHelperText("Username Should be Valid");
            }
            else {
                setUserNameHelperText("");

            }
                setUserName(e.target.value);
                break;


            case "password": if (!e.target.value.match(passwordRegex)) {
                setPasswordHelperText("Password must contain at least 8 characters, one capital letter, one number and one special character");
            }
            else {
                setPasswordHelperText("");

            }
                setPassword(e.target.value);
                break;


            case "confpassword": if (!e.target.value.match(passwordRegex)) {
                setMpasswordHelperText("Password should match");
            }
            else {
                setMpasswordHelperText("");

            }
                setConfPassword(e.target.value);
                break;

            default: setConfPassword(e.target.value);
                break;
        }
    }

    const handleOnSubmit = (e) => {
        if(globalState === custId && mspStatus === 1)
    {
      setOpenWarnDialog(true);
    }
        e.preventDefault();
        if (!firstName.match(firstNameRegex)) {
            setFirstNameHelperText("First Name Should be Valid");
            return;
        }

        if (!phoneNo.match(phoneRegex)) {
            setPhoneNoHelperText("Phone Number should be Valid");
            return;
        }


        if (!emailId.match(emailRegex)) {
            setEmailIdHelperText("Invalid email address");
            return;
        }


        if (!userName.match(usernameRegex)) {
            setUserNameHelperText("User Name should be Valid");
            return;
        }

        if (role === "") {
            setUserRoleHelperText("Role Cannot be Empty");
            return;
        }

        if (!password.match(passwordRegex)) {
            setPasswordHelperText("Password must contain at least 8 characters, one capital letter, one number and one special character");
            return;
        }

        if (password !== confPassword) {
            setMpasswordHelperText("Password should match");
            return;
        }

        CreateUserApiCall(firstName, lastName, phoneNo, emailId, userName, password, role, setData, setAddUserMessage);

    }

    const handleOnReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(input => {
            input.value = "";
        });
        setFirstName("");
        setLastName("");
        setPhoneNo("");
        setUserName("");
        setPassword("");
        setConfPassword("");
        setRole(0);
        setFirstNameHelperText("");
        setLastNameHelperText("");
        setPhoneNoHelperText("");
        setUserNameHelperText("");
        setPasswordHelperText("");
        setMpasswordHelperText("");

    }

    const handleRoleChange = (e) => {
        setRole(e.target.value)
    }

    const onWarningClose = () => {
        navigate('/dashboard/view-isp');
    }


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
        }
    }, [globalState])

    return (
        <div style={{ padding: "50px" }}>
            <h3>Add New User</h3>
            {data === 1 && <Alert severity="success">{addUserMessage}</Alert>}
            {data === -1 && <Alert severity="error">Error: {addUserMessage}</Alert>}
            <br />
            <form onSubmit={handleOnSubmit} >
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <TextField label="First Name" id="fname" helperText={firstNameHelperText} error={!!firstNameHelperText} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Last Name" id="lname" helperText={lastNameHelperText} error={!!lastNameHelperText} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Phone No" id="phoneno" variant="outlined" style={{ width: "400px" }} helperText={phoneNoHelperText} error={!!phoneNoHelperText} onChange={handleOnChange} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Email ID" id="emailid" helperText={emailIdHelperText} error={emailIdHelperText} style={{ width: "400px" }} onChange={handleOnChange} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="User Name" helperText={userNameHelperText} error={userNameHelperText} id="uname" variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                    </Grid>



                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role-lable" required>User Role</InputLabel>
                            <Select
                                labelId="user-role"
                                id="user-role"
                                label="User Role"
                                style={{ width: "400px" }}
                                value={role}
                                onChange={handleRoleChange}
                                helperText={<FormHelperText error>{userRoleHelperText}</FormHelperText>}
                                required="true"
                            >
                                <MenuItem value={0}>Super Admin</MenuItem>
                                <MenuItem value={1}>Read Only Admin</MenuItem>
                                <MenuItem value={2}>Engineer</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField type="password" helperText={passwordHelperText} error={passwordHelperText} label="Password" id="password" variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="password" label="Confirm Password" id="confpassword" helperText={mpasswordHelperText} error={mpasswordHelperText} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                    </Grid>
                </Grid>
                <br />
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Button style={{ width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnSubmit}>
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button style={{ background: "red", width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnReset}>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </form>
            {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
        </div>
    )

}
