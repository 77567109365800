// material
import {
  Breadcrumbs, Container, Link,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import AlertEmailConfigComp from '../components/AlertEmailConfigComponents/AlertEmailConfigComp';
import Page from '../components/Page';


export default function AlertEmailConfig() {

  return (
    <Page title="Alerts-Email-Config">
      <Container maxWidth={false}>

        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <BuildIcon />
            </Link>
            <Link underline="hover" color="inherit">
              Alert Config
            </Link>
            <Link underline="hover" color="inherit" href="alert-email-config">
              Alert Email Config
            </Link>
          </Breadcrumbs>
        </div>

        <AlertEmailConfigComp />

      </Container>
    </Page>
  );
}
