import { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
// components

import Scrollbar from '../../Scrollbar';
// mock
import devices from '../../../_mock/devices';
import ISPDownTableToolbar from './EdDownTableToolbar';
import ISPDownTableHead from './EdDownTableHead ';
import { EdDownTableDataAPI } from '../EdgeDevicesManagamentRestCalls/EdDownTableDataAPI';
import TroubleshootInsightsDialog from './DialogComponents/TroubleshootInsightsDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'deviceIp', label: 'Device IP', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'vendor', label: 'Vendor', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'device Status', label: 'Device Status', alignRight: false },
  { id: 'DownTime', label: 'DownTime', alignRight: false },
  { id: 'View', label: 'View', alignRight: false },
];

export default function EdDownTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [downEdData, setDownEdData] = useState([]);
  const [openInsightDialog, setOpenInsightDialog] = useState(false);
  const [value, setValue] = useState(null);
  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")

  const onInsightClick=(e, val)=>{
    setValue(val);
    setOpenInsightDialog(true);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const calculateDifference = (inputDate) => {
    const startDate = new Date(inputDate);
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(now - startDate);

    // Convert milliseconds to days, hours, minutes
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);

    return `${days} days, ${hours} hours, ${minutes} minutes`;
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const _EdDownTableDataAPI = async () =>{
    await EdDownTableDataAPI(setDownEdData,buState);
  }
  useEffect(()=>{
    _EdDownTableDataAPI();
    setInterval(() => {
      _EdDownTableDataAPI();
    }, 1000 * 60);
  },[globalState,buState]);

  useEffect(()=>{
    _EdDownTableDataAPI();

  },[])
  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPDownTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ISPDownTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}         
                       rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
              {downEdData.map((list, index) => (
                <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.customer_name}</TableCell> : null }
                    <TableCell>{list.device_ip}</TableCell>
                    <TableCell>{list.location_name}</TableCell>
                    <TableCell>{list.vendor_name}</TableCell>
                    <TableCell>{list.type}</TableCell>
                  
                    {list.status==="1" && <TableCell>{"Up"}</TableCell>}
                    {list.status==="0" && <TableCell>{"Down"}</TableCell>}
                    <TableCell>{calculateDifference(list.last_ent)}</TableCell>
                    <TableCell><Link to={{pathname: `/dashboard/isp-management/ed-down/${list.device_id}`}}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </Grid>
  );
}
