import axios from 'axios';

export const UpdateApplicationDeatilsAPICall = async (appId, previousAppName, appType, AppUrl, AppName, selectedGroupIds, setUpdateStatus, setSeverStatus,setUpdateStatusMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_application_details_for_msp.php" : "update_application_details.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "prev_app_name": previousAppName,
        "app_id": appId,
        "app_type": appType, 
        "app_url": AppUrl,
        "app_name": AppName,
        "group_id": selectedGroupIds
    });

    const mspPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "msp_client_id": localStorage.getItem("cust_id"),
      "reg_client_id": localStorage.getItem("reg_cust_id"),
      "prev_app_name": previousAppName,
      "app_id": appId,
      "app_type": appType, 
      "app_url": AppUrl,
      "app_name": AppName,
      "group_id": selectedGroupIds
  });
  
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setUpdateStatus(1);
        setSeverStatus("success");
        setUpdateStatusMessage(response.data.message)
      }
      else {
        setUpdateStatus(-1);
        setUpdateStatusMessage(response.data.message)
        setSeverStatus("error");
      }
    })
  }
  