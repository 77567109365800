import axios from 'axios';

// API CALL CODE HERE
const DeleteCustomerApiCall = async (custId, custName, setDeleteStatus) => {
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/delete_customer_for_msp.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "cust_id": custId,
    "cust_name": custName
  });
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setDeleteStatus(1);
    }
    else if(response.data.code === 2){
        setDeleteStatus(2);
    }else{
      setDeleteStatus(-1);
    }
  }).catch(()=>{
    setDeleteStatus(-1);
  })
}

export default DeleteCustomerApiCall;
