import React from 'react'
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

import Page from '../components/Page';
import EdgeDeviceComponents from '../components/EdgeDeviceComponents/EdgeDeviceComponents'

export default function EdgeDevices() {
  return (
    <Page title="EdgeDevices">
      <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <BuildIcon />
          </Link>
          <Link underline="hover" color="inherit">
            EdgeDevices Management
          </Link>
          <Link underline="hover" color="inherit" href="/dashboard/view-edge-devices">
            EdgeDevices
          </Link>
        </Breadcrumbs>
      </div>
      <Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={15}>
            <EdgeDeviceComponents />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  )
}
