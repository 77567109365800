import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function MonthYearPicker(props) {
  const { setMonthYear, monthYear } = props;
  const [value, setValue] = useState("");

  useEffect(()=>{
    setValue(monthYear);
  },[monthYear])
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DatePicker
          views={['year', 'month']}
          label="Select Year and Month"
          minDate={dayjs('2022-01-01')}
          maxDate={dayjs('2025-12-31')}
          value={dayjs(value)}
          onChange={(newValue) => {
            setValue(newValue);
            const date = new Date(newValue);
            const month = date.toLocaleString("en-IN", { month: "2-digit" });
            const year = date.toLocaleString("en-IN", { year: "numeric" });
            setMonthYear(`${year}-${month}`)
          }}
          localeText={{fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MM' : 'MM')}}
          renderInput={(params) => <TextField {...params} helperText={null} />}       
        />
      </Stack>
    </LocalizationProvider>
  );
}