import axios from 'axios';

// API CALL CODE HERE

const GetTroubleshootInfoApiCall = async(ispWanId, setInfoList,setRcaData) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_link_troubleshoot_info.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });
  return axios.post(baseUrl, payload).then((response)=>{
    if(response.data.code === 1){
        const str = response.data.info.slice(0, -1);
        setInfoList(str.split('/'));
        setRcaData(response.data.rca_message);
    }
  })
}

export default GetTroubleshootInfoApiCall;
