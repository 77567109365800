import { filter } from 'lodash';
import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// components
import Scrollbar from '../../Scrollbar';
import USERLIST from '../../../_mock/user';
import ViewAgentsTableHead from './ViewAgentsTableHead';
import ViewAgentsTableToolbar from './ViewAgentsTableToolbar';

import GetRaspiAgentDataAPICall from './GetRaspiAgentDataAPICall';
import DeleteAgentDetailsDialog from './DeleteAgentDetailsDialog';
import AddNewRaspiAgentsDialog from './AddNewRaspiAgentDialog';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import EditAgentDialog from './EditAgentDialog';

const TABLE_HEAD = [
  { id: 'no', label: 'Sr.No.', alignRight: false },
  { id: 'custName', label: 'Customer', alignRight: false },
  { id: 'serial number', label: 'Agent Serial Number', alignRight: false },
  { id: 'mac address', label: 'Mac Address', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none"
  },
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
}));

export default function ViewAgentsTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const {buState , setBuState} = useGlobalState();

  
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

  const isEngineer = (localStorage.getItem("role") === "2")
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');

  const [addAgentOpen, setAddAgentOpen] = useState(false);
  const [editAgentOpen, setEditAgentOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [editAgent, setEditAgent] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [filteredAgentData, setFilteredAgentData] = useState([]);

  const [addAgentStatus, setAddAgentStatus] = useState(0);
  const [editAgentStatus, setEditAgentStatus] = useState(0);

  const [deleteStatus, setDeleteStatus] = useState(0);
  const [severStatus, setSeverStatus] = useState("");

  const [addRaspiMessage, setAddRaspiMessage] = useState("");
  const [editRaspiMessage, setEditRaspiMessage] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [openAddRaspiDialog, setOpenAddRaspiDialog] = useState(false);
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const classes = useStyles();

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddAgentStatus(0)
    setEditAgentStatus(0)
    setDeleteStatus(0);
    setDeleteOpen(false);
    setAddAgentOpen(false);
    setEditAgentOpen(false);
    setSeverStatus("")
  };

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value)
    const filteredRows = agentData.filter((row) => {
    const serialNumber = row.raspi_serial_number ? row.raspi_serial_number.toLowerCase() : " ";
    const macAddress = row.raspi_mac_address? row.raspi_mac_address.toLowerCase() : " ";
    const location = row.location_name? row.location_name.toLowerCase() : " ";
      return (serialNumber.includes(searchedVal.toLowerCase()) || 
      macAddress.includes(searchedVal.toLowerCase()) ||
      location.includes(searchedVal.toLowerCase())
      )
    });
    setFilteredAgentData(filteredRows);
  };

  useEffect(()=>{
    if(agentData){
      setFilteredAgentData(agentData)
    }
  },[agentData])

  const _GetRaspiAgentDataAPICall = async () => {
    await GetRaspiAgentDataAPICall(setAgentData,buState);
  }

  useEffect(() => {
    _GetRaspiAgentDataAPICall();
  }, [addAgentStatus, deleteStatus,editAgentStatus, globalState,buState]);

  // edit and delete app and port
  const handleAddAgentClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }

    setOpenAddRaspiDialog(true);
    setEditAgent(row);
  }
  const handleDeleteClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenDeletDialog(true);
    setEditAgent(row);
  }

  const handleEditClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenEditDialog(true);
     setEditAgent(row);
  }
  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <ViewAgentsTableToolbar
            // tableHeading={'Users'}
            handleAddAgentClick={handleAddAgentClick}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader>
                <ViewAgentsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD.filter(tableCell => {
                    return (
                      (mspClientId === regClientId || tableCell.id !== 'custName') &&
                      (mspStatus !== 0 || tableCell.id !== 'custName')
                    );
                  })}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={(e) => { handleFilterByName(e,filterName) }}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredAgentData.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        {mspClientId === regClientId && mspStatus === 1 ? <TableCell>{row.cust_name}</TableCell> : null}
                        <TableCell align="left">{row.raspi_serial_number}</TableCell>
                        <TableCell align="left">{row.raspi_mac_address}</TableCell>
                        <TableCell align="left">{row.location_name}</TableCell>
                        {row.raspi_status === 1 && <TableCell align="left">UP</TableCell>}
                        {row.raspi_status !== 1 && <TableCell align="left">DOWN</TableCell>}
                        <TableCell align="left">
                          <Grid direction="row">
                            {isEngineer && "-"}
                            {!isEngineer  && <IconButton variant="outlined" color="primary" onClick={(e) => { handleEditClick(e, row) }}><EditIcon /></IconButton>}
                            {!isEngineer && <IconButton variant="outlined" onClick={(e) => { handleDeleteClick(e, row) }} color="error" ><DeleteIcon /></IconButton>}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {openAddRaspiDialog && <AddNewRaspiAgentsDialog openAddRaspiDialog={openAddRaspiDialog} setOpenAddRaspiDialog={setOpenAddRaspiDialog} setAddAgentStatus={setAddAgentStatus} setAddAgentOpen={setAddAgentOpen} setSeverStatus={setSeverStatus} setAddRaspiMessage={setAddRaspiMessage} />}
          {openDeleteDialog && <DeleteAgentDetailsDialog agent={editAgent} openDeleteDialog={openDeleteDialog} setOpenDeletDialog={setOpenDeletDialog} setDeleteStatus={setDeleteStatus} setDeleteOpen={setDeleteOpen} setSeverStatus={setSeverStatus} />}
          {openEditDialog && <EditAgentDialog openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setEditAgentStatus ={setEditAgentStatus} setEditAgentOpen={setEditAgentOpen} setSeverStatus={setSeverStatus}  setEditRaspiMessage={setEditRaspiMessage} agent={editAgent}/>}
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
          <Snackbar open={addAgentOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {addAgentStatus === 1 && <strong>{addRaspiMessage}</strong>}
              {addAgentStatus === -1 && <strong>Error: {addRaspiMessage}</strong>}
            </Alert>
          </Snackbar>
          <Snackbar open={editAgentOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {editAgentStatus === 1 && <strong>{editRaspiMessage}</strong>}
              {editAgentStatus === -1 && <strong>Error: {editRaspiMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={deleteOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {deleteStatus === 1 && <strong>Agent Deleted Successfully</strong>}
              {deleteStatus === -1 && <strong>Error in Deleting Agent</strong>}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </>
  );
}
