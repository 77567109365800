import axios from 'axios';

// API CALL CODE HERE


const UpdateProbeMappingDataApiCall = async (probeId, allottedLocations, spareLocations, setUpdateStatus, setAddLocationMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_probe_id_of_locations_for_msp.php" : "update_probe_id_of_locations.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
    "alloted_locs": allottedLocations,
    "spare_locs": spareLocations
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
    "alloted_locs": allottedLocations,
    "spare_locs": spareLocations
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateStatus(1);
      setAddLocationMessage(response.data.Message)
    }
    else {
      setUpdateStatus(-1);
      setAddLocationMessage(response.data.Message)

    }
  }).catch(() => {
    setUpdateStatus(-1);
  })
}

export default UpdateProbeMappingDataApiCall;
