import axios from 'axios';

const GetZoneDetailsApiCall = async (setZones) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_zone_details.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  return axios.post(baseUrl, payload).then((response) => {
     
    if (response.data.code === 1) {
      setZones(response.data.zone_data);
      
      
    } else {
      console.error("Failed to fetch zone details");
    }
  }).catch((error) => {
    console.error("Error in fetching zone details:", error);
  });
};

export default GetZoneDetailsApiCall;
