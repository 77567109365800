import React from 'react'
import { Grid } from '@mui/material'


import SiteGroupsTableComponent from './Components/SiteGroupsTableComponent'

export default function () {
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} sx={{paddingTop:"20px"}}> 
      <SiteGroupsTableComponent />
    </Grid>
  )
}
