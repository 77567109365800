import React, { useEffect, useState } from "react";
import {
    Paper,
    Box,
    Typography,
    Divider,
    FormControl,
    FormControlLabel,
    Switch,
    FormGroup,
    Select,
    MenuItem,
    Grid
} from '@mui/material';

import DonutChart from "../DonutChart";
import TopSingleTalkerComponent from "./TopSingleTalkerComponent";
import GetTopInterfaceUsageExporterAPICall from "./GetTopInterfaceUsageExporterAPICall";

export default function TopTalkersInfoComponent(props) {
    const { deviceIps } = props;
    const [time, setTime] = useState(1);
    const [interfaceUsageData, setInterfaceUsageData] = useState([]);

    const [showPie, setShowPie] = useState(true);

    const [inBoundWanArr, setInBoudWanArr] = useState([]);
    const [OutBoundWanArr, setOutBoudWanArr] = useState([]);
    const [talkers, setTalkers] = useState([]);


    const _GetTopInterfaceUsageExporterAPICall = () => {
        GetTopInterfaceUsageExporterAPICall([deviceIps], time, setInterfaceUsageData, setInBoudWanArr, setOutBoudWanArr, setTalkers);
    }

    const maxInbound = Math.max(...interfaceUsageData.map(domain => domain.inbound), 0);
    const maxOutbound = Math.max(...interfaceUsageData.map(domain => domain.outbound), 0);

    useEffect(() => {
        _GetTopInterfaceUsageExporterAPICall();
    }, [deviceIps, time]);

    return (
        <>
            <Paper elevation={15}>

                <Box sx={{ height: "800px", overflowY: "auto" }} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={{ padding: "1rem" }} variant="h6">Top Talkers Bandwidth Utilization</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch defaultChecked
                                        onChange={() => { setShowPie(!showPie) }}
                                    />
                                }
                                label="Pie" />
                        </FormGroup>
                        <FormControl sx={{ paddingTop: "1rem", paddingLeft: "5rem" }}>
                            <Select
                                value={time}
                                sx={{
                                    maxHeight: "40px",
                                    minWidth: '10rem',
                                    marginRight: "1rem",
                                    border: "none",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    }
                                }}
                                onChange={(e) => { setTime(e.target.value) }}
                            >
                                <MenuItem value={-1} style={{ height: '50px' }}>Select</MenuItem>
                                <MenuItem value={1} style={{ height: '50px' }}>Last 1 Hour</MenuItem>
                                <MenuItem value={6} style={{ height: '50px' }}>Last 6 Hour</MenuItem>
                                <MenuItem value={12} style={{ height: '50px' }}>Last 12 Hour</MenuItem>
                                <MenuItem value={24} style={{ height: '50px' }}>Last 24 Hour</MenuItem>
                                {/* <MenuItem value={168} style={{ height: '50px' }}>Last 7 Days</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider />
                    <Grid container>
                    {!showPie && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ paddingBottom: '2rem' }}>
                                {interfaceUsageData.length > 0 ? interfaceUsageData.map((data, index) => {
                                    const normalizedInbound = (data.inbound / maxInbound) * 100;
                                    const normalizedOutbound = (data.outbound / maxOutbound) * 100;

                                    return (
                                        <TopSingleTalkerComponent
                                            key={index} // Prefer a more unique key if available
                                            ip={data.interface}
                                            inbound={data.inbound}
                                            outbound={data.outbound}
                                            inboundMarkerValue={Math.ceil(normalizedInbound)}
                                            outboundMarkerValue={Math.ceil(normalizedOutbound)}
                                        />
                                    );
                                }) : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingBottom: '2rem', paddingTop: '1rem', border: 1 }}>
                                {interfaceUsageData.length > 0 ? <DonutChart label={"InBoud Usage"} dseries={inBoundWanArr} dLabels={talkers} directValue/> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingBottom: '2rem', paddingTop: '1rem', border: 1 }}>
                                {interfaceUsageData.length > 0 ?  <DonutChart label={"OutBoud Usage"} dseries={OutBoundWanArr} dLabels={talkers} directValue/> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                    </Grid> 
                </Box>
            </Paper>
        </>
    );
}
