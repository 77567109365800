import axios from 'axios';

// API CALL CODE HERE

const GetBusinessDetailsApiCall = async (setBuCustomers) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_business_unit.php`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  return axios.post(createBaseURL, regPayload).then((response) => {
    if (response.data.code === 1) {
        setBuCustomers(response.data.business_unit);
    }
    else {

      setBuCustomers([]);
    }
  })
}

export default GetBusinessDetailsApiCall;
