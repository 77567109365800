// @mui
import {
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ISPDown1Card1 from './ISPDown1Card1';
import timeArray from '../TimeData';
// components
// ----------------------------------------------------------------------

export default function ISPDown1MainPage() {
  return (
    <>
      <Grid container spacing={1} sx={{ py: 1 }}>
        <ISPDown1Card1 />
      </Grid>
    </>
  );
}
