import React, { useState, useEffect } from 'react'
import {
    Grid,
    TextField,
    Button,
    IconButton,
    Alert,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { CreateCustomerApiCall } from './CreateCustomerApiCall';


export default function AddCustomerDialogComponent(props) {
    const { openAddCustomerDialog, setOpenAddCustomerDialog, setCreateStatus } = props;

    const [customerName, setCustomerName] = useState("");
    const [customerNameHelperText, setCustomerHelperText] = useState("");

    const [ customerAddress, setCustomerAddress] = useState("");
    const [ customerAddressHelperText, setCustomerAddressHelperText ] = useState(""); 

    const [ customerContactNo, setCustomerContactNo] = useState("");
    const [ customerContactNoHelperText, setCustomerContactNoHelperText ] = useState(""); 

    const [ customerContactEMail, setCustomerContactEMail] = useState("");
    const [ customerContactEMailHelperText, setCustomerContactEMailHelperText ] = useState(""); 

    const customerNameRegex =/^[a-zA-Z][a-zA-Z0-9()-\s]*[A-Za-z\d]$/;
    const customerAddressRegex =/^(?=.*[A-Za-z0-9])[A-Za-z0-9 ,:]+$/;
    const customerContactRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const _CreateCustomerCall=()=>{
        CreateCustomerApiCall(customerName, customerAddress, customerContactNo, customerContactEMail, setCreateStatus)
    }


    const handleOnClose=()=>{
        setOpenAddCustomerDialog(false);
    }

    const handleOnSave=()=>{
        if(!customerName.match(customerNameRegex)){
            setCustomerHelperText("Customer Name Should be Valid");
            return;
        }
        if(!customerAddress.match(customerAddressRegex)){
            setCustomerAddressHelperText("Customer Address Should be Valid");
            return;
        }
        if(!customerContactNo.match(customerContactRegex)){
            setCustomerContactNoHelperText("Customer Contact Should be Valid");
            return;
        }
        if(!customerContactEMail.match(emailRegex)){
            setCustomerContactEMailHelperText("Customer Email Id should be Valid");
            return;
        }
        _CreateCustomerCall();
        setOpenAddCustomerDialog(false);
    }

    const validateAndSetTextField=(e)=>{
        switch (e.target.id) {
            case "customer_name":   if (!e.target.value.match(customerNameRegex)) {
                                setCustomerHelperText("Customer Name Should be Valid");
                            }
                            else
                            {
                                setCustomerHelperText("");
                                
                            }
                            setCustomerName(e.target.value);
                            break;
            case "customer_address":   if (!e.target.value.match(customerAddressRegex)) {
                                setCustomerAddressHelperText("Customer Address Should be Valid");
                            }
                            else
                            {
                                setCustomerAddressHelperText("");
                                
                            }
                            setCustomerAddress(e.target.value);
                            break;
            case "customer_contact_no":   if (!e.target.value.match(customerContactRegex)) {
                                setCustomerContactNoHelperText("Customer Contact Should be Valid");
                            }
                            else
                            {
                                setCustomerContactNoHelperText("");
                                
                            }
                            setCustomerContactNo(e.target.value);
                            break;
            case "customer_email":   if (!e.target.value.match(emailRegex)) {
                                setCustomerContactEMailHelperText("Customer Email Id Should be Valid");
                            }
                            else
                            {
                                setCustomerContactEMailHelperText("");
                                
                            }
                            setCustomerContactEMail(e.target.value);
                            break;
            default: break;

        }
    }

    return (
        <div style={{ padding: "50px" }}>
            <Dialog open={openAddCustomerDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add Customer
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='h5'>
                                    Customer Information
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Customer Name"
                                    id="customer_name"
                                    value={customerName}
                                    helperText={customerNameHelperText}
                                    error={customerNameHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={validateAndSetTextField}
                                    required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Customer Address"
                                    id="customer_address"
                                    value={customerAddress}
                                    helperText={customerAddressHelperText}
                                    error={customerAddressHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={validateAndSetTextField}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Customer Contact No"
                                    id="customer_contact_no"
                                    value={customerContactNo}
                                    helperText={customerContactNoHelperText}
                                    error={customerContactNoHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={validateAndSetTextField}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Customer Email"
                                    id="customer_email"
                                    value={customerContactEMail}
                                    helperText={customerContactEMailHelperText}
                                    error={customerContactEMailHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={validateAndSetTextField}
                                    required />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
