import axios from 'axios';

const AddTemplateApiCall = async (reportData, setOpenSnackBar, setAddReportStatus, setAddTemplateStatus, setAddTemplateMessage) => {
  
// API calling
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/add_template_details_report.php`;

    const payload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("cust_id"),
      "report_data": reportData,
    });

    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setOpenSnackBar(1);
        setAddReportStatus(1);
        setAddTemplateStatus(1)
        setAddTemplateMessage("Report request created. Notitfied once report is ready")
      }
      else {
        setOpenSnackBar(-1);
        setAddReportStatus(0);
        setAddTemplateStatus(0)
        setAddTemplateMessage("Error in creating report")
      }
    })
  }
  
  export default AddTemplateApiCall;