import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import DeleteEdgeDeviceApiCall from './DeleteEdgeDeviceApiCall';


export default function DeleteEdgeDeviceDialog(props) {

    const { value, openDeleteEdDialog, setOpenDeleteEdDialog, setDeleteEdStatus, setSeverStatus, setOpenDeleteEd } = props; 


    const handleClose=(e)=>{
        setOpenDeleteEdDialog(false);
    }

    const handleDelete = async () => {
       await DeleteEdgeDeviceApiCall(value.device_ip, setDeleteEdStatus, setSeverStatus);
        setOpenDeleteEd(true);
        setOpenDeleteEdDialog(false);
        props.handleDataReLoad();

    }

    return (
        <>
            <Dialog
                open={openDeleteEdDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Edge Device
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to Delete Edge Device - {value.device_ip} ??
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
