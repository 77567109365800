import { useState, useEffect, forwardRef, useRef, createRef } from 'react';
// material
import {
  Grid,
  Table,
  FormControlLabel,
  Alert,
  TextField,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Toolbar,
  Paper,
  Button,
  CircularProgress,
  Backdrop,
  IconButton
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Scrollbar from '../Scrollbar';
import AlertsIMTableHead from './AlertsIMTableHead';
import { UpdateImAlertSettingsAPICall } from './UpdateImAlertSettingsAPICall';
import { GetImConfig } from './GetImConfig';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';
import { DeleteImAlertSettingApi } from './DeleteImAlertSettingApi';


const TABLE_HEAD = [
  { id: 'IM', label: 'IM App', align: 'center' },
  { id: 'Phone/Webhook', label: 'Phone No/Webhook', align: 'center' },
  { id: 'info_alerts', label: 'Info Alerts (Link Up/Restored Alerts)', align: 'center' },
  { id: 'warn_alerts', label: 'Warning Alerts (Link Bad Alerts)', align: 'center' },
  { id: 'critical_alerts', label: 'Critical Alerts (Link Down Alerts)', align: 'center' },
  { id: 'system_alerts', label: 'System Alerts (Device/Probe Alerts)', align: 'center' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

// ----------------------------------------------------------------------
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function IMConfigComp() {
  const classes = useStyles();

  const { globalState, setGlobalState } = useGlobalState();
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [waInfoAlertChecked, setWaInfoAlertChecked] = useState(false);
  const [waWarnAlertChecked, setWaWarnAlertChecked] = useState(false);
  const [waCrticalAlertChecked, setWaCrticalAlertChecked] = useState(false);
  const [waSystemAlertChecked, setWaSystemAlertChecked] = useState(false);

  const [teamsInfoAlertChecked, setTeamsInfoAlertChecked] = useState(false);
  const [teamsWarnAlertChecked, setTeamsWarnAlertChecked] = useState(false);
  const [teamsCrticalAlertChecked, setTeamsCrticalAlertChecked] = useState(false);
  const [teamsSystemAlertChecked, setTeamsSystemAlertChecked] = useState(false)

  const [waConfigId, setWaConfigId] = useState(0);
  const [teamsConfigId, setTeamsConfigId] = useState(0);

  const [waNumbers, setWaNumbers] = useState("");
  const [teamsWebHook, setTeamsWebHook] = useState("");

  // const [updateStatus, setUpdateStatus] = useState(false)
  const [errorCode, setErrorCode] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [phoneNosHelperText, setPhoneNosHelperText] = useState('');
  const [webHookHelperText, setWebHookHelperText] = useState('');

  const phoneNosRegex = /^(\d{10})(\s*,\s*\d{10})*$/;

  const validateNumbers = (value) => {
    if (!phoneNosRegex.test(value)) {
      setPhoneNosHelperText('Please enter only phone numbers');
    } else {
      setPhoneNosHelperText('');
    }
  };

  const validateWebHook = (value) => {
    if (value === "") {
      setWebHookHelperText('Please enter webhook helper text');
    } else {
      setWebHookHelperText('');
    }
  };

  const saveImConfig = () => {
    if (!phoneNosRegex.test(waNumbers) && teamsWebHook === "") {
      if (!phoneNosRegex.test(waNumbers)) {
        setPhoneNosHelperText('Please enter only phone numbers');
        return;
      }
      if (teamsWebHook === "") {
        setWebHookHelperText('Please enter webhook helper text');
        return;
      }
    }

    if (waNumbers !== "" && (!waInfoAlertChecked && !waWarnAlertChecked && !waCrticalAlertChecked && !waSystemAlertChecked)) {
      setPhoneNosHelperText('Please select checkbox');
      return
    }

    if (teamsWebHook !== "" && (!teamsInfoAlertChecked && !teamsWarnAlertChecked && !teamsCrticalAlertChecked && !teamsSystemAlertChecked)) {
      setWebHookHelperText('Please select checkbox');
      return
    }

    if (waNumbers === "" && (waInfoAlertChecked || waWarnAlertChecked || waCrticalAlertChecked || waSystemAlertChecked)) {
      setPhoneNosHelperText('Please enter numbers');
      return
    }

    if (teamsWebHook === "" && (teamsInfoAlertChecked || teamsWarnAlertChecked || teamsCrticalAlertChecked || teamsSystemAlertChecked)) {
      setWebHookHelperText('Please enter web hook');
      return
    }


    const wConfig = {
      "im_name": "WhatsApp",
      "phone_nos": waNumbers,
      "info_alerts": waInfoAlertChecked ? 1 : 0,
      "warn_alerts": waWarnAlertChecked ? 1 : 0,
      "critical_alerts": waCrticalAlertChecked ? 1 : 0,
      "system_alerts": waSystemAlertChecked ? 1 : 0
    }

    const teamsConfig = {
      "im_name": "Teams",
      "web_hook": teamsWebHook,
      "info_alerts": teamsInfoAlertChecked ? 1 : 0,
      "warn_alerts": teamsWarnAlertChecked ? 1 : 0,
      "critical_alerts": teamsCrticalAlertChecked ? 1 : 0,
      "system_alerts": teamsSystemAlertChecked ? 1 : 0
    }

    UpdateImAlertSettingsAPICall(wConfig, teamsConfig, setErrorCode, setUpdateStatus, setErrorMessage, setSeverity)
    setPhoneNosHelperText("")
    setWebHookHelperText("")
  }

  const _GetPresentSettings = async () => {
    await GetImConfig(setWaConfigId, setTeamsConfigId, setWaNumbers, setWaInfoAlertChecked, setWaWarnAlertChecked, setWaCrticalAlertChecked,
      setWaSystemAlertChecked, setTeamsWebHook, setTeamsInfoAlertChecked, setTeamsWarnAlertChecked, setTeamsCrticalAlertChecked, setTeamsSystemAlertChecked, setOpenBackdrop)
  }

  const deleteImConfig = async (app, configId) => {
    await DeleteImAlertSettingApi(configId, setUpdateStatus, setErrorMessage, setSeverity);
    if (app === "WhatsApp") {
      setWaNumbers("");
      setWaInfoAlertChecked(false);
      setWaWarnAlertChecked(false);
      setWaCrticalAlertChecked(false);
      setWaSystemAlertChecked(false);
    }
    if (app === "Teams") {
      setTeamsWebHook("");
      setTeamsInfoAlertChecked(false);
      setTeamsWarnAlertChecked(false);
      setTeamsCrticalAlertChecked(false);
      setTeamsSystemAlertChecked(false);
    }
  }

  const resetAll = () => {
    setWaNumbers("");
    setWaInfoAlertChecked(false);
    setWaWarnAlertChecked(false);
    setWaCrticalAlertChecked(false);
    setWaSystemAlertChecked(false);

    setTeamsWebHook("");
    setTeamsInfoAlertChecked(false);
    setTeamsWarnAlertChecked(false);
    setTeamsCrticalAlertChecked(false);
    setTeamsSystemAlertChecked(false);
  }

  useEffect(() => {
    resetAll();
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      setOpenBackdrop(false);
      // return;
    }
    _GetPresentSettings();
  }, [globalState, updateStatus])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography position='absolute'>Loading Settings</Typography>
      </Backdrop>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: 1 }}>
              <div className={classes.titleStyle} >
                <Typography variant='h4'>
                  IM Settings
                </Typography>
              </div>
            </Grid>

            {(globalState !== custId || mspStatus !== 1) && <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className={classes.titleStyle}>
                <Button variant="contained" startIcon={<SaveIcon />} onClick={saveImConfig}>
                  Save Settings
                </Button>
              </div>
            </Grid>}
          </Grid>

          {(globalState !== custId || mspStatus !== 1) &&
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <AlertsIMTableHead
                    headLabel={TABLE_HEAD}
                  />
                  <TableBody>
                    <TableRow hover>
                      <TableCell align='center'>WhatsApp</TableCell>
                      <TableCell align='center'>
                        <TextField
                          id="whatsapp_nos"
                          label="Whats App Numbers Here..."
                          variant="standard"
                          helperText={phoneNosHelperText}
                          error={!!phoneNosHelperText}
                          fullWidth
                          value={waNumbers}
                          onChange={(e) => {
                            setWaNumbers(e.target.value);
                            validateNumbers(e.target.value);
                          }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="whatsapp_info_alerts"
                              checked={waInfoAlertChecked}
                              onClick={() => {
                                setWaInfoAlertChecked(!waInfoAlertChecked);
                                if (waNumbers !== "") {
                                  setPhoneNosHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="whatsapp_warning_alerts"
                              checked={waWarnAlertChecked}
                              onClick={() => {
                                setWaWarnAlertChecked(!waWarnAlertChecked)
                                if (waNumbers !== "") {
                                  setPhoneNosHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="whatsapp_critical_alerts"
                              checked={waCrticalAlertChecked}
                              onClick={() => {
                                setWaCrticalAlertChecked(!waCrticalAlertChecked)
                                if (waNumbers !== "") {
                                  setPhoneNosHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="whatsapp_system_alerts"
                              checked={waSystemAlertChecked}
                              onClick={() => {
                                setWaSystemAlertChecked(!waSystemAlertChecked);
                                if (waNumbers !== "") {
                                  setPhoneNosHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton onClick={() => deleteImConfig("WhatsApp", waConfigId)}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell align='center'>Microsoft Teams</TableCell>
                      <TableCell align='center'>
                        <TextField
                          id="teams_webhooks"
                          label="Webhook Here..."
                          variant="standard"
                          fullWidth
                          value={teamsWebHook}
                          helperText={webHookHelperText}
                          error={!!webHookHelperText}
                          onChange={(e) => {
                            setTeamsWebHook(e.target.value)
                            validateWebHook(e.target.value)
                          }
                          }
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="teams_info_alerts"
                              checked={teamsInfoAlertChecked}
                              onClick={() => {
                                setTeamsInfoAlertChecked(!teamsInfoAlertChecked);
                                if (teamsWebHook !== "") {
                                  setWebHookHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="teams_warning_alerts"
                              checked={teamsWarnAlertChecked}
                              onClick={() => {
                                setTeamsWarnAlertChecked(!teamsWarnAlertChecked);
                                if (teamsWebHook !== "") {
                                  setWebHookHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="teams_critical_alerts"
                              checked={teamsCrticalAlertChecked}
                              onClick={() => {
                                setTeamsCrticalAlertChecked(!teamsCrticalAlertChecked);
                                if (teamsWebHook !== "") {
                                  setWebHookHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox id="teams_system_alerts"
                              checked={teamsSystemAlertChecked}
                              onClick={() => {
                                setTeamsSystemAlertChecked(!teamsSystemAlertChecked);
                                if (teamsWebHook !== "") {
                                  setWebHookHelperText("")
                                }
                              }}
                            />
                          }
                          sx={{ my: 1 }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton onClick={() => deleteImConfig("Teams", teamsConfigId)}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          }
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={() => { setOpenWarnDialog(false) }} />}

          <Snackbar
            open={updateStatus}
            autoHideDuration={6000}
            onClose={() => {
              setUpdateStatus(false);
              setErrorCode(0);
              setErrorMessage("");
            }
            }
            key={'bottom + right'}>
            <Alert
              onClose={() => {
                setUpdateStatus(false)
                setErrorCode(0);
                setErrorMessage("");
              }}
              severity={severity}
              sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </>
  );
}
