import { createContext, useContext, useState } from 'react';

// Create a context
const GlobalStateContext = createContext();

// Create a provider component
export function GlobalStateProvider({ children }) {
  const [globalState, setGlobalState] = useState(/* initial state */);
  const [buState, setBuState] = useState(-1);
  const [buName,setBuName] = useState("");
  const [openFullScreen, setOpenFullScreen] =  useState(false);

  return (
    <>
      <GlobalStateContext.Provider value={{ globalState, setGlobalState, buState, setBuState , buName, setBuName, openFullScreen, setOpenFullScreen}}>
        {children}
      </GlobalStateContext.Provider>
    </>
  );
}

// Create a custom hook to access the context
export function useGlobalState() {
  return useContext(GlobalStateContext);
}
