import axios from 'axios';

// API CALL CODE HERE
const convertMinToHourMinutes = (minutes) => {
  const d = Math.round(Math.floor(minutes / (24 * 60)));
  const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
  const m = Math.round((minutes % (24 * 60)) % 60);
  if (minutes === '0') {
    return 'No Impact';
  }

  return `${d}days, ${h}hours, ${m}minutes`;
};

export const SitewiseParamsAPICall = async (locationId, setLocation, setAvgUptime, setSiteUptimePercent, setAvgTimeToRestore, setSlaBreaches, setBusinessHourImpact, setDownTime, setMonth, setSiteStatus, setEdStatus) => {
  
  const path = localStorage.getItem("msp_status") === "1" ? "get_single_site_card_info_for_msp.php" : "get_single_site_card_info.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "location_id": locationId
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "location_id": locationId,
    "msp_status": 1
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
 
  // console.log(payload)
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      const ispdata = response.data.site_data;
      // console.log(ispdata)
      setLocation(ispdata.location);
      setAvgUptime(ispdata.avgUptime);
      setSiteUptimePercent(ispdata.uptimePercent);
      setAvgTimeToRestore(convertMinToHourMinutes(ispdata.avgTimeToRestore));
      setSlaBreaches(ispdata.slaBreaches);
      setBusinessHourImpact(convertMinToHourMinutes(ispdata.businessHourImpact));
      setMonth(ispdata.month);
      setDownTime(ispdata.down_time);
      setSiteStatus(ispdata.location_status)
      setEdStatus(ispdata.ed_status)
    } else {
      setLocation(0);
      setAvgUptime(0);
      setSiteUptimePercent(0);
      setAvgTimeToRestore(0);
      setSlaBreaches(0);
      setBusinessHourImpact(0);
      setMonth('');
      setSiteStatus(-1)
      setEdStatus(-1)
    }
  });
};
