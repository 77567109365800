import axios from 'axios';

// API CALL CODE HERE

export const ValidateMspRegisteredCustomer = async (logout) => {
  
  const path = "validate_reg_customer_for_msp_customer.php"
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const payload =  JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1
  });

  await axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 0 || response.data.code === -1) {
      logout();
    }
  }).catch(()=>{
    logout();
  })
}