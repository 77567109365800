import axios from 'axios';

// API CALL CODE HERE

const UpdateBusinessUnitDataApiCall = async (BuId, allottedLocations, spareLocations, setUpdateStatus,setAddZoneMessage) => {

const path = localStorage.getItem("msp_status") === "1" ? "update_business_unit_id_for_msp.php" : "update_business_unit_id.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "bu_id": BuId,
    "alloted_locs": allottedLocations,
    "spare_locs": spareLocations
  });
  const mspPayload = 
  JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "bu_id": BuId,
    "alloted_locs": allottedLocations,
    "spare_locs": spareLocations
  })
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {

    if (response.data.code === 1) {
      setUpdateStatus(1);
      setAddZoneMessage(response.data.Message)
    }
    else {
      setUpdateStatus(-1);
      setAddZoneMessage(response.data.Message)

    }
  }).catch((error)=>{
    setUpdateStatus(-1);
    setAddZoneMessage(error.response.data.Message)

  })
}

export default UpdateBusinessUnitDataApiCall;
