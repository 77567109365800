import axios from 'axios';


export const CustomCustIdRestApiCall = async (customerId) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_custom_cust_id.php`;

  const payload = JSON.stringify({
    "cust_id": customerId,
  });

  try {
    const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
        
        return response.data;
    }
    throw new Error(response.data.message || 'Custom customer ID retrieval failed');
  } catch (error) {
    throw new Error(error.message || 'Custom customer ID retrieval failed: network related');
  }

}

