import axios from 'axios';

// API CALL CODE HERE

const CheckDgIpReachableStatusApiCall = async(ispWanId, setDgLanStatus, setGotDgLanStatus, setDgIpReachableMessage, setIntDgLatency, setIntDgPacketLoss, setDetailDgIpReachableMessage, setRcaDgIpReachableMessage,setCheckStates) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_dg_reachability_from_edge_device_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });
 
  return axios.post(baseUrl, payload).then((response)=>{
    if(response.data.code === 1){
        setIntDgLatency(response.data.latency)
        setIntDgPacketLoss(response.data.packet_loss)
        setDgLanStatus(true)
        setCheckStates(true)
    }
    else
    {
        setDgLanStatus(false)
    }
    setGotDgLanStatus(true)
    setIntDgLatency(response.data.latency)
    setIntDgPacketLoss(response.data.packet_loss)
    setDgIpReachableMessage(response.data.message)
    setDetailDgIpReachableMessage(response.data.detailed_message) 
    setRcaDgIpReachableMessage(response.data.rca_message)
  })
}

export default CheckDgIpReachableStatusApiCall;
