import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useGlobalState } from '../../globalHooks/GlobalState';
import { ValidateMspRegisteredCustomer } from './ValidateMspRegisteredCustomer';

function SessionTrack() {
    const { globalState, setGlobalState } = useGlobalState();
    const navigate = useNavigate();
    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'role'   || e.key === 'user_id' || e.key === 'auth_token' || e.key === 'reg_cust_id' || e.key === 'cust_id' || e.key === 'msp_cust_id' || e.key === 'name') {
                if (localStorage.getItem("msp_status") === "1" && e.key === 'reg_cust_id') {
                    setGlobalState(parseInt(localStorage.getItem('reg_cust_id'), 10))
                    ValidateMspRegisteredCustomer(logoutUser);
                }
                else
                {
                    logoutUser();
                }
                
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const logoutUser = () => {
        localStorage.clear()
        navigate('/', { replace: true });
        window.location.reload();
    };
}

export default SessionTrack;
