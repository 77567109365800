// material
import { Grid, Paper } from '@mui/material';

// components
import PbReport from './Components/PB_Report';


// ----------------------------------------------------------------------


export default function PowerBiInsightsComponent() {
  return (
    <Grid>
      <Grid item container>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={30}>
            <PbReport />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
