// @mui
import {Grid} from '@mui/material';
import ISPDashboardCards from '../ISPManagementTable/ISPManagementDashboard/ISPDashboardCards';
import ViewWifiTable from './ViewWifiTable'

export default function ViewWifiComponents() {
  return (
    <>
       {/* <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }}>
                <ISPDashboardCards />
            </Grid>
        </Grid> */}

        <Grid container sx={{paddingTop:'30px'}}>
            <Grid item lg={12} md={12} sm={12}>
                <ViewWifiTable />
            </Grid>
        </Grid>
    </>
  );
}
