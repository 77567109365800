import axios from 'axios';

const GetSitesAvgUptimeInPercentApiCall = async (locationId, selectedGroupIds, setUptime, buState) => {
    if (localStorage.getItem("user_map_status") === "1") {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_site_avg_uptime_in_percent_for_mapped_user.php`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "group_ids": selectedGroupIds,
            "location_id": locationId,
            "bu_id": buState
        });
        return axios.post(mappedUrl, regPayload).then((response) => {
            if (response.data.code === 1) {
                setUptime(response.data.avg_uptime);
            }
        })
    }
    if (localStorage.getItem("user_map_status") === "-1") {
        if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
            const url = `${process.env.REACT_APP_API_SERVER_URL}/get_site_avg_uptime_in_percent_for_bu.php`;
            const payload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "location_id": locationId,
                "bu_id": buState
            });

            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    setUptime(response.data.avg_uptime);
                }
                else {
                    setUptime(0)
                }
            });

        }

    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
        const path = localStorage.getItem("msp_status") === "1" ? "get_site_avg_uptime_in_percent_for_msp.php" : "get_site_avg_uptime_in_percent.php";
        const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "group_ids": selectedGroupIds,
            "location_id": locationId
        });

        const mspPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "msp_client_id": localStorage.getItem("cust_id"),
            "reg_client_id": localStorage.getItem("reg_cust_id"),
            "msp_status": 1,
            "group_ids": selectedGroupIds,
            "location_id": locationId
        });

        const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
        
        return axios.post(url, payload).then((response) => {
            if (response.data.code === 1) {
                setUptime(response.data.avg_uptime);
            }
            else {
                setUptime(0)
            }
        });
    }
}
}

export default GetSitesAvgUptimeInPercentApiCall