import { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link as DownloadLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Snackbar, Alert } from '@mui/material';
import Link from '@mui/material/Link';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import Account from "../../Assets/avatar_1.jpg";

import LogOutRestAPICall from './LogOutRestAPICall';
import ChangePasswordDetails from './ChangePasswordDetailsDialog';

export default function AccountPopover({setUpdateStatus, setErrorMessage}) {
  const anchorRef = useRef(null);

  const userName = localStorage.getItem("name");

  const [open, setOpen] = useState(null);
  const [logoutStatus, setLogOutStatus] = useState(false);

  const [editUser, setEditUser] = useState(null);
  
  const [severStatus, setSeverStatus] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const _LogOutRestAPICall = async () => {
    await LogOutRestAPICall(setLogOutStatus);
  };

  const handleLogout = async(event) => {
    event.preventDefault();
    await _LogOutRestAPICall();
    navigate("/logout", { replace: true });
  };

  const handleEditClick = (e) => {
    setEditUser({});
    setOpenEditDialog(true);
  }



  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'relative',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              boxShadow: '10px 2px 5px rgba(0, 0, 0, 0.2)',
            },
          }),
        }}
      >
        <Avatar src={Account} marginLeft="10px" alt="Jay" />

      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={(e) => { handleEditClick(e) }}>
            Change Password
        </MenuItem>
        
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }}  onClick={handleLogout}>
          <Link style={{ textDecoration: 'none', color: 'black',  }}>
            <strong>Logout</strong>
          </Link>
        </MenuItem>

      {openEditDialog && <ChangePasswordDetails user={editUser} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setUpdateStatus={setUpdateStatus} setOpen={setOpen} setSeverStatus={setSeverStatus} setErrorMessage={setErrorMessage} />}
        <Divider sx={{ borderStyle: 'dashed' }} />
      </MenuPopover>

      
    </>
  );
}
