// @mui
import {
    Grid,
  } from '@mui/material';
import EdDownTable from './EdDownTable';
  // components
  // ----------------------------------------------------------------------
  
  export default function ISPDown() {
    return (
      <>
           <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
             <EdDownTable />
           </Grid>
      </>
    );
  }
  