import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    List,
    Typography,
    CardHeader,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Divider,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    Snackbar
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Components
import GetAllBusinessUnitDetailsApiCall from './GetAllBusinessUnitDetailsApiCall';
import GetBusinessUnitLocationsDataApiCall from './GetBusinessUnitLocationsDataApiCall';
import UpdateBusinessUnitDataApiCall from './UpdateBusinessUnitDataApiCall';

import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function MapComponents() {
    const { globalState, setGlobalState } = useGlobalState();
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [zones, setZones] = useState([]);
    const [zoneId, setZoneId] = useState(-1);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [changesMade, setChangesMade] = useState(false);
    const [addZoneMessage,setAddZoneMessage] = useState("");
    const [openWarnDialog, setOpenWarnDialog] = useState(false);


    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };


    const handleDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const sourceList = source.droppableId === 'Available' ? left : right;
        const destinationList = destination.droppableId === 'Allocated' ? right : left;

        const draggedItem = sourceList[source.index];

        sourceList.splice(source.index, 1);
        destinationList.splice(destination.index, 0, draggedItem);

        setLeft(left.slice());
        setRight(right.slice());

        setChangesMade(true);
    };
  
    
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changesMade) {
                const message = "Are you sure you want to leave without updating?";
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesMade]);
    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 600,
                    height: 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    const handleZoneChange = (e) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setZoneId(e.target.value);
        GetBusinessUnitLocationsDataApiCall(e.target.value, setLeft, setRight)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateStatus(0);
    };

    const updateZone = () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        UpdateBusinessUnitDataApiCall(zoneId, right, left, setUpdateStatus,setAddZoneMessage);
    }

    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        GetAllBusinessUnitDetailsApiCall(setZones);
    }, [])


    useEffect(() => {
        if (zones.length > 0 ) {
            setZoneId(zones[0].bu_id);
        }
    }, [zones, globalState]);

    useEffect(() => {
        if (zoneId !== -1) {
            GetBusinessUnitLocationsDataApiCall(zoneId, setLeft, setRight);
        }
    }, [zoneId,globalState]);

    useEffect(() => {
        GetAllBusinessUnitDetailsApiCall(setZones);
      }, [globalState]);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, bgcolor: '#EEEBEB' }} elevation={15}>
                <Grid container>
                    <Grid item xs={3} sm={3} md={3} lg={3} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <div>
                            <Typography variant='h4'>
                                <strong>Business Unit-Location Mapping</strong>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} sx={{ paddingTop: 2, paddingBottom: 2 }} >
                        <Box sx={{ width: "100%", display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <FormControl sx={{ width: "40%" }}>
                                <InputLabel id="demo-simple-select-label">Select Business Unit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Business Unit"
                                    onChange={handleZoneChange}
                                    value={zoneId}
                                >
                                    {zones.map((row, index) => (
                                        <MenuItem value={row.bu_id}>{row.bu_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <Button variant="contained" size="large" onClick={updateZone}>Update</Button>
                    </Grid>
                </Grid>
                <Divider />
                <div key={1} style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {[left, right].map((zones, idx) => (
                            <DragAndDropZone key={idx} zones={zones} isAvailable={idx === 0} />
                        ))}
                    </DragDropContext>
                </div>

                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

                { updateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                        {addZoneMessage}
                    </Alert>
                </Snackbar>}
                {updateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                        Error:{addZoneMessage}
                    </Alert>
                </Snackbar>}
            </Paper>
        </Box>

    );
}
const DragAndDropZone = ({ zones, isAvailable }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{isAvailable ? 'Available Locations' : 'Allocated Locations'}</h2>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={isAvailable ? 'Available' : 'Allocated'}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                padding: 10,
                                width: 350,
                                minHeight: 500,  // Set an upper limit for height
                                overflowY: 'auto', // Add this line for vertical scrolling
                                maxHeight: 500  // Set the maximum height
                            }}
                        >
                            {zones.map((item, index) => (
                                <Draggable key={item.location_id.toString()} draggableId={item.location_id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                color: 'white',
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            {item.location_name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};