import axios from 'axios';

// API CALL CODE HERE
export const SystemLogsAPICall = async (setLogs) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_system_actions_for_msp.php" : "get_system_actions.php";
  const logsBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id")
  });


  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": localStorage.getItem("msp_status")
  });


  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(logsBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setLogs(response.data.system_data);
    }
    else{
        setLogs([]);
    }
  }).catch((error) => {
    setLogs([]);

  })
}
