import axios from 'axios';

export const AgentAlertApiCall = async (setAgentAlerts, setAllAgentAlerts, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_agent_alerts_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setAgentAlerts(response.data.alert_data);
        setAllAgentAlerts(response.data.alert_data);
      }
    })
  }

  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
      const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_agent_alerts_for_bu.php`;
      const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "bu_id": buState
      });

      return axios.post(alertBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setAgentAlerts(response.data.alert_data);
          setAllAgentAlerts(response.data.alert_data);
        }
        else {
          setAgentAlerts([]);
        }
      })
    }

    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "getAgentAlerts_for_msp.php" : "getAgentAlerts.php";

      const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });


      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

      return axios.post(alertBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setAgentAlerts(response.data.alert_data);
          setAllAgentAlerts(response.data.alert_data);
        }
        else {
          setAgentAlerts([]);
        }
      })
    }
  }
}
