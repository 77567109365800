import React, { useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import UpdateUserDataApi from './UpdateUserDataApi';
import Iconify from '../Iconify';


export default function EditUserDialog(props) {
    const { user, openEditDialog, setOpenEditDialog, setUpdateStatus, setOpen, setSeverStatus, setUpdateUserMessage } = props

    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [phoneNo, setPhoneNo] = useState(user.phone);
    const [emailId, setEmailId] = useState(user.email);
    const [userName, setUserName] = useState(user.user_name);
    const [role, setRole] = useState(user.role);

    const [phoneNoHelperText, setPhoneNoHelperText] = useState("");
    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [emailIdHelperText, setEmailIdHelperText] = useState("");
    const [passHelperText, setPassHelperText] = useState("");
    const [cpassHelperText, setCpassHelperText] = useState("");
    const [userNameHelperText, setUserNameHelperText] = useState("");

    const [passFieldType, setPassFieldType] = useState("password");
    const [cassFieldType, setCPassFieldType] = useState("password");

    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setCShowPassword] = useState(false);


    const handleOnClose = () => {
        setOpenEditDialog(false);
    }

    const firstNameRegex = /^[A-Za-z][A-Za-z-_\d ]*$/
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const usernameRegex = /^[^\s]+$/;


    const handleUpdateClick = (e) => {

        if (firstName === "") {
            setFirstNameHelperText("First Name Cannot be empty");
            return;
        }

        if (!phoneNo.match(phoneRegex)) {
            setPhoneNoHelperText("Phone Number should be Valid");
            return;
        }

        if (!emailId.match(emailRegex)) {
            setEmailIdHelperText("Invalid email address");
            return;
        }

        if (!userName.match(usernameRegex)) {
            setUserNameHelperText("User Name should be Valid");
            return; 
        }

        UpdateUserDataApi(user.user_id, firstName, lastName, phoneNo, emailId, userName, role, setUpdateStatus, setSeverStatus, setUpdateUserMessage );
        setOpen(true);
        setOpenEditDialog(false);
    }

    const handleRoleChange = (e) => {
        setRole(e.target.value)
    }

    const handleFNameChange = (e) => {
        if (!e.target.value.match(firstNameRegex)) {
            setFirstNameHelperText("First Name Should be Valid");
        }
        else
        {
            setFirstNameHelperText("");
            
        }
        setFirstName(e.target.value);
    }

    const handleLNameChange = (e) => {
        if (!e.target.value.match(firstNameRegex)) {
            setLastNameHelperText("Last Name Should be Valid");
        }
        else
        {
            setLastNameHelperText("");
            
        }
        setLastName(e.target.value);
    }

    const handlePhonNoChange = (e) => {
        if (!e.target.value.match(phoneRegex)) {
            setPhoneNoHelperText("Phone Number should be Valid");
        }
        else
        {
            setPhoneNoHelperText("");
            
        }
        setPhoneNo(e.target.value);
    }

    const handleEmailChange = (e) => {
        if (!e.target.value.match(emailRegex)) {
            setEmailIdHelperText("Enter Valid Email ID");
        }
        else
        {
            setEmailIdHelperText("");
            
        }
        setEmailId(e.target.value);
    }
    const handleUserNameChange = (e) => {
        if (!e.target.value.match(usernameRegex)) {
            setUserNameHelperText("Username Should be Valid");
        }
        else
        {
            setUserNameHelperText("");
            
        }
        setUserName(e.target.value);
    }

    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Update User
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="First Name" id="fname" value={firstName} helperText={firstNameHelperText} error={firstNameHelperText} onChange={handleFNameChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Last Name" id="lname" value={lastName} helperText={lastNameHelperText} error={lastNameHelperText} onChange={handleLNameChange} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Phone No" id="phoneno" value={phoneNo} helperText={phoneNoHelperText} error={phoneNoHelperText} onChange={handlePhonNoChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Email ID" id="emailid" value={emailId} helperText={emailIdHelperText} error={emailIdHelperText} onChange={handleEmailChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="User Name" id="uname" value={userName} helperText={userNameHelperText} error={userNameHelperText} onChange={handleUserNameChange} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="user-role-lable">User Role</InputLabel>
                                    <Select
                                        labelId="user-role-id"
                                        id="user-role"
                                        label="User Role"
                                        style={{ width: "400px" }}
                                        value={role}
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value={0}>Super Admin</MenuItem>
                                        <MenuItem value={1}>Read Only Admin</MenuItem>
                                        <MenuItem value={2}>Engineer</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleUpdateClick}>Update</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
