// @mui
import {
    Grid,
} from '@mui/material';

// components
import TopAppsInfoComponent from './TopAppsComponents/TopAppsInfoComponent';
import TopTalkersInfoComponent from './TopTalkersComponents/TopTalkersInfoComponent';
import TopDomainsInfoComponent from './TopDomainsComponents/TopDomainsInfoComponent';

export default function EdgeDeviceInfo(props) {
    const {deviceIps} = props;
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}  xl={12} sx={{paddingBottom:"20px"}}>
                    <TopAppsInfoComponent deviceIps={deviceIps}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddingBottom:"20px"}}>
                    <TopTalkersInfoComponent deviceIps={deviceIps} />
                </Grid>
                <Grid item xs={12} sm={12}  md={12} lg={12} xl={12}>
                    <TopDomainsInfoComponent deviceIps={deviceIps} />
                </Grid>
            </Grid>
           

        </>
    );
}