import React, { useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    FormHelperText,

} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { UpdatePortForAppAPICall } from './UpdatePortForAppAPICall';
import Iconify from '../../Iconify';


export default function EditPortDialog(props) {
    const { port, appId, appType, openPortEditDialog, setOpenPortEditDialog, setPortUpdateStatus, setEditPortOpen, setSeverStatus } = props;

    const [portType, setPortType] = useState(port.port_type);
    const [portNumber, setPortNumber] = useState(port.port_number);
    const [portCriticality, setPortCriticality] = useState(port.criticality);
    const [portFunction, setPortFunction] = useState(port.functionality);

    const [portNumberHelperText, setPortNumberHelperText] = useState("");
    const [portFunctionHelperText, setPortFunctionHelperText] = useState("");

    const portNoRegex = /^(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
    const portFunctionRegex = /^[^"'-]*[A-Za-z0-9]$/;


    const handleOnClose = () => {
        setOpenPortEditDialog(false);
    }
    const appurlRegex = /^[a-zA-Z][a-zA-Z0-9\s]*[A-Za-z\d]$/;
    const usernameRegex = /^(?!.{51})(?![ -])[A-Za-z\d!@#$%&*_()+,\-.:;<=>?@]+[A-Za-z\d]$/;

    const handleUpdateClick = (e) => {

        if (!String(portNumber).match(portNoRegex)) {
            setPortNumberHelperText("Port Number Should be Valid");
            return;
        }
        // if (!portFunction.match(portFunctionRegex)) {
        //     setPortFunctionHelperText("Port Type should be Valid");
        //     return;
        // }

        UpdatePortForAppAPICall(appId, appType, port.port_id, portNumber, portType, portCriticality, portFunction, setPortUpdateStatus, setSeverStatus);
        setEditPortOpen(true);
        setOpenPortEditDialog(false);
    }

    const handlePortTypeChange = (e) => {
        setPortType(e.target.value)
    };


    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "port_number":
                if (!e.target.value.match(portNoRegex)) {
                    setPortNumberHelperText("Port Number Should be Valid");
                } else {
                    setPortNumberHelperText("");
                }
                setPortNumber(e.target.value);
                break;

            case "port_function":
                if (!e.target.value.match(portFunctionRegex)) {
                    setPortFunctionHelperText("Port Function Should be Valid");
                } else {
                    setPortFunctionHelperText("");
                }
                setPortFunction(e.target.value);
                break;

            default:
                setPortNumber(e.target.value);
                break;
        }
    };

    return (
        <>
            <Dialog open={openPortEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Update Ports
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: "1px" }}>

                        <Grid item xs={6}>
                            <TextField
                                label="Port Number"
                                id="port_number"
                                variant="outlined"
                                style={{ width: '400px' }}
                                value={portNumber}
                                helperText={portNumberHelperText}
                                error={!!portNumberHelperText}
                                onChange={handleOnChange}
    
                                required />
                        </Grid>


                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="type-label" required>
                                    Port Type
                                </InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="porttype"
                                    label="Port Type"
                                    style={{ width: "400px" }}
                                    value={portType}
                                    onChange={handlePortTypeChange}
                                >
                                    <MenuItem value="TCP">TCP</MenuItem>
                                    <MenuItem value="UDP">UDP</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="criticality-label" required>
                                    Port Criticality
                                </InputLabel>
                                <Select
                                    labelId="criticality-label"
                                    id="port_criticality"
                                    label="Port Criticality"
                                    style={{ width: "400px" }}
                                    value={portCriticality}
                                    onChange={(e) => setPortCriticality(e.target.value)}
                                >
                                    <MenuItem value="HIGH">High</MenuItem>
                                    <MenuItem value="LOW">Low</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Port Function"
                                id="port_function"
                                variant="outlined"
                                style={{ width: '400px' }}
                                value={portFunction}
                                helperText={portFunctionHelperText}
                                error={!!portFunctionHelperText}
                                onChange={handleOnChange}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleUpdateClick}>Update</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
