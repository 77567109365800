// @mui
import {
  Grid,
} from '@mui/material';
import ISPFlapping1Card1 from './ISPFlapping1Card1';

export default function ISPFlapping1MainPage() {
  return (
    <>
      <Grid container spacing={1} sx={{ py: 1 }}>
        <ISPFlapping1Card1 />
      </Grid>
    </>
  );
}
