import React from 'react'
import UtilizationTable from './Components/UtilizationTable'

export default function UtilizationConfigCompo() {
  return (
      < UtilizationTable/>
    // <>
    //   Hi Ganesh
    // </>
  )
}