import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    Typography,
    Divider,
    Alert,
    FormHelperText

} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import ChangeProbeStatusApiCall from './ChangeProbeStatusApiCall';

export default function EnableDisableDialog(props) {
    const { value, openEnableDisableDialog, setOpenEnableDisableDailog, setEnableDisableStatus, setEnableDisbaleMessage, setSnackOpenStatus} = props;

    const handleSubmit=()=>{
        ChangeProbeStatusApiCall(value.status, value.server_unique_id, value.server_name, setEnableDisableStatus, setEnableDisbaleMessage)
        setOpenEnableDisableDailog(false);
        setSnackOpenStatus(true);
    }
  
    return (
      <>
        <Dialog open={openEnableDisableDialog} onClose={()=>{setOpenEnableDisableDailog(false)}} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Probe Name: {value.server_name}
            <IconButton onClick={()=>{setOpenEnableDisableDailog(false)}}  sx={{ float: 'right' }}>
              <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ py: 3, px: 3 }}>
            <div>
              <div style={{ display: 'flex', margin:"20px" }}>
              {value.status === "1" && <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to disable Probe - {value.server_name} ? </strong></Typography> }
              {value.status !== "1" && <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to Resume Location - {value.server_name} ? </strong></Typography> }
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setOpenEnableDisableDailog(false)}}>Cancel</Button>
            <Button onClick={()=>{handleSubmit()}} variant="contained">oK</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
