import axios from 'axios';

// API CALL CODE HERE
// await UtilizationAlertApiCall(alertNotifyLinkWanId, alertNotifyLinkIp, newUtilAlertStatus, setAlertNotifyStatus, setAlertNotifyMessage)
// const pauseBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/link_utilization_alert.php`;

const path = localStorage.getItem("msp_status") === "1" ? "link_utilization_alert_for_msp.php" : "link_utilization_alert.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
const UtilizationAlertApiCall = async (alertNotifyLinkWanId, alertNotifyLinkIp, newUtilAlertStatus, setAlertNotifyStatus, setAlertNotifyMessage) => {
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": alertNotifyLinkWanId,
    "link_ip": alertNotifyLinkIp,
    "alert_status": newUtilAlertStatus
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "isp_wan_id": alertNotifyLinkWanId,
    "link_ip": alertNotifyLinkIp,
    "alert_status":newUtilAlertStatus
  });
  // return axios.post(pauseBaseURL, payload).then((response) => {
  //   if (response.data.code === 1) {
  //     setOpen(true);
  //   }
  //   else{
  //     console.log("Error in enabling alert for the link");
  //   }
  // }).catch(()=>{
  //   console.log("Error in enabling alert for the link");
  // })
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setAlertNotifyStatus(response.data.code);
            setAlertNotifyMessage(response.data.message);
        } else {
            setAlertNotifyStatus(response.data.code);
            setAlertNotifyMessage(response.data.message);
        }
    }).catch(()=>{
      setAlertNotifyStatus(-1);
      setAlertNotifyMessage("Error in Setting")
    })
}

export default UtilizationAlertApiCall;
