import React, { useState, useEffect } from 'react';
// material
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Alert,
  Typography,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BuildIcon from '@mui/icons-material/Build';
import { createFilterOptions } from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import { ISPLocationsDataAPICall } from "./SitewiseRestAPICalls"
import { PushSingleISPInfo, GetSingleISPInfo } from "./ISPAddingAPICall"
import GetVendorDetailsApiCall from './ApiCalls/GetVendorDetailsApiCall';
import linkType from "../Assets/LinkTypes";
import { useGlobalState } from '../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../components/WarningComponents/SelectCustomerWarningDialog';


const filter = createFilterOptions();

export default function AddSingleISP() {

  const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const { buName, setBuName } = useGlobalState();

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);


  const [ispLocations, setIspLocations] = useState([{}]);
  const [value, setValue] = useState(null);
  const [circuitId, setCircuitId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [defaultGateway, setDefaulGateway] = useState("");
  const [publicIp, setPublicIp] = useState("");
  const [internalIp, setInternalIp] = useState("");
  const [promiseUptime, setPromisedUptime] = useState("");
  const [resolutionTime, setResolutionTime] = useState("");
  const [data, setData] = useState(0);
  const [link, setLink] = useState("");
  const [price, setPrice] = useState("");
  const [provisionedSpeed, setProvisionedSpeed] = useState("");
  const [city, setCity] = useState("");
  const [cityStatus, setCityStatus] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendorIndex, setSelectedVendorIndex] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState(-1);
  const [vendorDisable, setVendorDisable] = useState(false);
  const [renewalDate, setRenewalDate] = useState(null);

  const [internalIpLabelShrink, setInternalIpLabelShrink] = useState(false);
  const [circuitIdHelperText, setCircuitIdHelperText] = useState("");
  const [publicIpHelerText, setPublicIpHelerText] = useState("");
  const [InternalIpHelerText, setInterIpHelerText] = useState("");
  const [provisionedSpeedHelperText, setProvisionedSpeedHelperText] = useState("");
  const [cityHelperText, setCityHelperText] = useState("");
  const [priceHelperText, setPriceHelperText] = useState("");
  const [DefaultGatewayHelperText, setDefaultGatewayHelperText] = useState("");
  const [vendorHelperText, setVendorHelperText] = useState("")
  const [vendorNameHelperText, setVendorNameHelperText] = useState("");
  const [vendorEmailHelperText, setVendorEmailHelperText] = useState("");
  const [promiseUptimeHelperText, setPromisedUptimeHelperText] = useState("");
  const [resolutionTimeHelperText, setResolutionTimeHelperText] = useState("");
  const [addLinkMessage, setAddLinkMessage] = useState("");
  const [linkHelperText, setLinkHelperText] = useState("")
  const [siteHelperText, setSiteHelperText] = useState("")
  const [alertOpen, setAlertOpen] = useState(true);
  const [renewalHelperText, setRenewalHelperText] = useState("")
  const [warning, setWarning] = useState("")
const [critical, setCritical]= useState("")


  const navigate = useNavigate();

  const errorRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const provisionedSpeedRegex = /^\d+$/;
  const cityRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
  // const priceRegex = /^\d+$/;
  // const circuitIdRegex = /^[^\s].*$/;
  const priceRegex = /^(?=.*[1-9])\d+(,\d+)?(\.\d+)?$/;
  const circuitIdRegex = /^[A-Za-z0-9_\-!@#$%^&*()+=<>?{}[\]\\|~`"'.,;: ]*$/;
  const IpRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const mailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
  const uptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
  const hourRegex = /^([01]?[0-9]|2[0-4])$/;
  const nameRegex = /^(?![0-9])[A-Za-z0-9,_]*(\([A-Za-z0-9,_]*\))*[A-Za-z0-9]*$/;
  const dateRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])[-/]\d{4}$/;
  const [error, setError] = useState(false);
  const [linkError, setLinkError] = useState(false)
  const [Renewalerror, setRenewalError] = useState(false);
  const [locationData, setLocationdata] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [firstLocationName, setFirstLocationName] = useState("");
  const buStatus = parseInt(localStorage.getItem("bu_status"), 10);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const _ISPLocationsDataAPICall = async () => {
    ISPLocationsDataAPICall(setIspLocations,setLocationdata, setFirstLocationName, setLocationId, buState, setWarning, setCritical);
    GetVendorDetailsApiCall(setVendors);
  }

  const onWarningClose = () => {
    navigate('/dashboard/add-single-isp');
  }

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    _ISPLocationsDataAPICall();
  }, [globalState, buState])

  const handleOnChange = (e) => {
    switch (e.target.id) {
      case "publicip": if (!e.target.value.match(errorRegex)) {
        setInternalIpLabelShrink(true);
        setPublicIpHelerText("Enter Valid IP Address")

      }
      else {
        setPublicIpHelerText("");
        setInterIpHelerText("");

      }
        setPublicIp(e.target.value);
        setInternalIp(e.target.value);
        break;

      case "internal_ip": if (!e.target.value.match(errorRegex)) {
        setInternalIpLabelShrink(true)

        setInterIpHelerText("Enter Valid IP Address")
      }
      else {
        setInterIpHelerText("");
      }
        setInternalIp(e.target.value);
        break;

      case "circuitid": if (!e.target.value.match(circuitIdRegex)) {
        setCircuitIdHelperText("Enter Valid Circuit ID")
      }
      else {
        setCircuitIdHelperText("");
      }
        setCircuitId(e.target.value);
        break;

      case "defaultgateway": if (!e.target.value.match(IpRegex)) {
        setDefaultGatewayHelperText("Enter valid Gateway")
      }
      else {
        setDefaultGatewayHelperText("")
      }
        setDefaulGateway(e.target.value)
        break;
      case "provisioned_speed": if (!e.target.value.match(provisionedSpeedRegex)) {
        setProvisionedSpeedHelperText("Enter Valid Provisioned Speed")
      }
      else {
        setProvisionedSpeedHelperText("");
      }
        setProvisionedSpeed(e.target.value)
        break;

      case "price": if (!e.target.value.match(priceRegex)) {
        setPriceHelperText("Enter Valid Value")
      }
      else {
        setPriceHelperText("")

      }
        setPrice(e.target.value)
        break;

      case "vendorname": if (!e.target.value.match(nameRegex)) {
        setVendorNameHelperText("Enter Valid Vendor Name")
      }
      else {
        setVendorNameHelperText("")
      }
        setVendorName(e.target.value);
        break;

      case "vendoremail": if (!e.target.value.match(mailRegex)) {
        setVendorEmailHelperText("Enter Valid Mail Id")
      }
      else {
        setVendorEmailHelperText("")
      }
        setVendorEmail(e.target.value);
        break;

      case "promised_uptime": if (!e.target.value.match(uptimeRegex)) {
        setPromisedUptimeHelperText("Enter Value only between 0-100")
      }
      else {
        setPromisedUptimeHelperText("");
      }
        setPromisedUptime(e.target.value);
        break;

      case "resoluation_time": if (!e.target.value.match(uptimeRegex)) {
        setResolutionTimeHelperText("Enter Resolution Time")
      }
      else {
        setResolutionTimeHelperText("");
      }
        setResolutionTime(e.target.value);
        break;
      case "location": if (value === null || !value.location) {
        setSiteHelperText("Select Location")
      }
      else {
        setSiteHelperText("")
      }
        setValue(e.target.value)
        break;

      case "link-type-id": if (link === "" || link === null) {
        setLinkError(true)
        setLinkHelperText("Select Link Type ")
      }
      else {
        setLinkHelperText("")
        setLinkError(false)
      }
        setLink(e.target.value)
        break
      default: setProvisionedSpeed(e.target.value)
        break;

    }

    const getDisplayText = (value) => {
      switch (value) {
        case "Broadband":
          return "Corporate BB";
        case "DBB":
          return "Dynamic Broadband";
        default:
          return value;
      }
    };
  


  }

  const formattedRenewalDate = renewalDate instanceof Date ? renewalDate : new Date(renewalDate);

  const handleOnSubmit = async () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
  }

    if (!publicIp.match(errorRegex)) {
      setPublicIpHelerText("Enter Valid IP Address")
    }
    else if (!internalIp.match(errorRegex)) {
      setInterIpHelerText("Enter Valid IP Address")
    }
    else if (!circuitId.match(circuitIdRegex)) {
      setCircuitIdHelperText("Enter Valid Circuit ID")
    }

    else if (value === null || !value.location) {
      setSiteHelperText("Select Location")
    }
    else if (selectedVendorIndex === undefined || selectedVendorIndex === null || selectedVendorIndex === "") {
      setError(true);
      setVendorHelperText("Select Vendor")
    }

    else if (vendorName.trim().length === 0) {
      setVendorNameHelperText("Enter Vendor name")
    }
    else if (!vendorEmail.match(mailRegex)) {
      setVendorEmailHelperText("Enter Valid mail id")
    }
    else if (!defaultGateway.match(IpRegex)) {
      setDefaultGatewayHelperText("Enter valid Gateway")
    }
    else if (!String(promiseUptime).match(uptimeRegex)) {
      setPromisedUptimeHelperText("Enter Value only between 0-100")
    }
    else if (!String(resolutionTime).match(uptimeRegex)) {
      setResolutionTimeHelperText("Enter Value Only between 0-24")
    }

    else if (link === "" || link === null) {
      setLinkError(true)
      setLinkHelperText("Select Link Type")
    }

    else if (!String(provisionedSpeed).match(provisionedSpeedRegex)) {
      setProvisionedSpeedHelperText("Enter Valid Provisioned Speed")

    }
    else if (!String(price).match(priceRegex)) {
      setPriceHelperText("Enter Valid Value")
    }
    else if (renewalDate === undefined || renewalDate === null || renewalDate === "Invalid Date") {
      setRenewalError(true);
      setRenewalHelperText("Select a Date")
    }
    else if (selectedVendorIndex === -2) {
      setError(true)
      setVendorHelperText("Select Vendor")
      setData(-2);
    }
    else if(buStatus === 1 && buState !== -1){
      setConfirmOpen(true);
    }
    else {
      setRenewalError(false);
      await PushSingleISPInfo(publicIp, internalIp, circuitId, value.location, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, price, city, renewalDate, setData, setAddLinkMessage);
      setError(false);
      setLinkError(false)
    }

  }

  const handleConfirm = () => {
    PushSingleISPInfo(publicIp, internalIp, circuitId, value.location, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, price, city, renewalDate, setData, setAddLinkMessage);
    setConfirmOpen(false);
}
const handleCancel = () => {
    setConfirmOpen(false);
}
  const handleOnCancel = () => {
    navigate('/dashboard/view-isp');
  }


  const handleLinkTypeChange = (e) => {
    const newValue = e.target.value;
    setLink(newValue);
    if (newValue === "" || newValue === null) {
      setLinkError(true);
      setLinkHelperText("Select Link Type");
    } else {
      setLinkHelperText("");
      setLinkError(false);
    }
  };



  const handleVendorChange = (e) => {
    setSelectedVendorIndex(e.target.value);
    if (e.target.value >= 0) {
      setSelectedVendorId(vendors[e.target.value].vendor_id);
      setVendorName(vendors[e.target.value].vendor_name);
      setVendorEmail(vendors[e.target.value].helpdesk_email);
      setResolutionTime(vendors[e.target.value].resolution_time);
      setPromisedUptime(vendors[e.target.value].promised_uptime);
      setVendorDisable(true);
      setVendorHelperText("")
      setVendorNameHelperText("")
      setVendorEmailHelperText("")
      setPromisedUptimeHelperText("")
      setResolutionTimeHelperText("")
      setError(false)
    }
    else {
      setSelectedVendorId(-1);
      setVendorDisable(false);
      setVendorName("");
      setVendorEmail("");
      setResolutionTime("");
      setPromisedUptime("");
    }
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  // Auto-close alert after 6 seconds when data changes
  useEffect(() => {
    if (data !== 0) {
      setAlertOpen(true); // Set alert open when data changes
      const timer = setTimeout(() => {
        setAlertOpen(false); // Close the alert after 6 seconds
      }, 6000);
      // Clean up the timer when the component is unmounted or data changes
      return () => clearTimeout(timer);
    }
  }, [data]);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const getDisplayText = (value) => {
    switch (value) {
      case "Broadband":
        return "Static Broadband";
      case "DBB":
        return "Dynamic Broadband";
      default:
        return value;
    }
  };
  return (
    <Page title="Add Single ISP">
      <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <BuildIcon />
          </Link>
          <Link underline="hover" color="inherit">
            Link Management
          </Link>
          <Link underline="hover" color="inherit" href="/dashboard/add-single-isp">
            Add Link
          </Link>
        </Breadcrumbs>
      </div>
      <br />
      <Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={15}>
            <div style={{ padding: "50px" }}>
              <h3>ADD Link</h3>
              {data === 1 && alertOpen && <Alert severity="success" onClose={handleAlertClose}>{addLinkMessage}</Alert>}
              {data === -1 && alertOpen && <Alert severity="error" onClose={handleAlertClose}>Error:{addLinkMessage}</Alert>}
              {data === 2 && alertOpen && <Alert severity="warning" onClose={handleAlertClose}>Link Data Already Exist</Alert>}
              {data === -2 && alertOpen && <Alert severity="warning" onClose={handleAlertClose}>Select New Vendor/Existing Vendor</Alert>}
              <br />
              <form>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    { /* eslint-disable-next-line */}
                    <TextField label="Public IP" id="publicip" value={publicIp} helperText={publicIpHelerText} error={!!publicIpHelerText} inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
                  </Grid>
                  <Grid item xs={6}>
                    { /* eslint-disable-next-line */}
                    <TextField label="Internal IP" id="internal_ip" value={internalIp} helperText={InternalIpHelerText} error={!!InternalIpHelerText} InputLabelProps={{ shrink: internalIpLabelShrink }} inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Circuit ID" id="circuitid" variant="outlined" style={{ width: "400px" }} helperText={circuitIdHelperText} error={!!circuitIdHelperText} onChange={handleOnChange} required />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setCity("");
                        setCityStatus(false);
                        if (typeof newValue === 'string') {
                          setValue({
                            location: newValue,
                          });

                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setValue({
                            location: newValue.inputValue,
                          });
                        } else {
                          setValue(newValue);
                          setCity(newValue.city);
                          setCityStatus(true);
                        }
                        // Validate the "Site" field here
                        if (newValue === null || !newValue.location) {
                          setSiteHelperText("Select Location");
                        } else {
                          setSiteHelperText("");
                        }
                      }}

                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      // id="free-solo-with-text-demo"
                      options={ispLocations}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue ;
                        }
                        // Regular option
                        return option.location || "No location assigned";
                      }}
                      renderOption={(props, option) => <li {...props}>{option.location|| "No location assigned" }</li>}
                      sx={{ width: 250 }}
                      location
                      renderInput={(params) => (
                        <TextField {...params} label="Site" id="location" required style={{ width: "400px" }} helperText={siteHelperText} error={!!siteHelperText} onChange={handleOnChange} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={error}>
                      <InputLabel id="Vendor-label">Vendor*</InputLabel>
                      <Select
                        labelId="Vendor-label"
                        id="Vendor*"
                        style={{ width: "400px" }}
                        label="Vendor*"
                        value={selectedVendorIndex}
                        onChange={handleVendorChange}
                      >
                        <MenuItem value={-2}>Select Vendor</MenuItem>
                        {vendors.map((val, index) => (
                          <MenuItem value={index}>{val.vendor_name}</MenuItem>
                        ))}
                        <MenuItem value={-1}>New Vendor</MenuItem>
                      </Select>
                      <FormHelperText>{error ? vendorHelperText : ""}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Vendor Name" id="vendorname" value={vendorName} variant="outlined" style={{ width: "400px" }} type="text" required onChange={handleOnChange} disabled={vendorDisable} helperText={vendorNameHelperText} error={!!vendorNameHelperText} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Vendor E-Mail" id="vendoremail" value={vendorEmail} variant="outlined" type={"email"} style={{ width: "400px" }} required onChange={handleOnChange} helperText={vendorEmailHelperText} error={!!vendorEmailHelperText} disabled={vendorDisable} />
                  </Grid>
                  <Grid item xs={6}>
                    { /* eslint-disable-next-line */}
                    <TextField label="Default Gateway" id="defaultgateway" inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} helperText={DefaultGatewayHelperText} error={!!DefaultGatewayHelperText} required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Promised Uptime(in %)" id="promised_uptime" value={promiseUptime} type="number" InputProps={{ inputProps: { min: 0, max: 100 } }} variant="outlined" style={{ width: "400px" }} required onChange={handleOnChange} disabled={vendorDisable} helperText={promiseUptimeHelperText} error={!!promiseUptimeHelperText} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Resolution Time(in Hours)" id="resoluation_time" value={resolutionTime} type="number" variant="outlined" style={{ width: "400px" }}  onChange={handleOnChange} disabled={vendorDisable} helperText={resolutionTimeHelperText} error={!!resolutionTimeHelperText} required/>
                  </Grid>
                  <Grid item xs={6} >
                    <FormControl fullWidth error={linkError}>
                      <InputLabel required id="link-type" >Link Type</InputLabel>
                      <Select
                        labelId="link-type-label"
                        id="link-type-id"
                        label="Link Type"
                        style={{ width: "400px" }}
                        value={link}
                        onChange={handleLinkTypeChange}
                      >
                         {linkType.map((value, index) => (
                          <MenuItem value={value}>{getDisplayText(value)}</MenuItem>
                        ))}

                      </Select>
                      {linkError && <Typography variant="caption" color="error">{linkHelperText}</Typography>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Provisioned Speed(in MBps)" id="provisioned_speed" variant="outlined" type="number" style={{ width: "400px" }} helperText={provisionedSpeedHelperText} error={provisionedSpeedHelperText} required onChange={handleOnChange} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="Price" id="price" value={price} variant="outlined" type="text" style={{ width: "400px" }} helperText={priceHelperText} error={priceHelperText} required onChange={handleOnChange} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField label="City" id="city" value={city} variant="outlined" type="text" style={{ width: "400px" }} required onChange={handleOnChange} helperText={cityHelperText} error={cityHelperText} disabled={cityStatus} />
                  </Grid>

                  <Grid item xs={6} >
                    <LocalizationProvider dateAdapter={AdapterDayjs} error={Renewalerror} required>
                      <DemoContainer components={['DatePicker']} >
                        <DatePicker
                          label="Renewal Date*"
                          value={renewalDate}
                          sx={{ width: "400px" }}
                          inputFormat="MM/DD/YYYY"
                          required
                          onChange={(newValue) => {
                            setRenewalDate(newValue);
                            setRenewalError(false);
                          }}
                          renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} inputProps={{ readOnly: true }} error={Renewalerror} helperText={renewalHelperText} />}
                        />
                      </DemoContainer>
                      {Renewalerror && <Typography variant="caption" color="error">{renewalHelperText}</Typography>}
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <br />
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Button style={{ width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnSubmit}>
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button style={{ background: "red", width: "400px", height: "50px", }} variant="contained" sx={{ fontSize: '1rem' }} onClick={handleOnCancel}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <br />
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
      <Dialog
                open={confirmOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Warning !!!
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{`Are you sure to Add Link for business unit - ${buName}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
    </Page>
  );

}
