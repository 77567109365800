import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MS_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_MS_AUTHORITY}`,
    redirectUri: `${process.env.REACT_APP_MS_REDIRECT_URL}`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // set to true if using IE
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

