import axios from 'axios';

// API CALL CODE HERE
const GetTopAppsUsageExporterAPICall = async (deviceIps, time, setAppsUsageData, setInBoudWanArr, setOutBoudWanArr, setApplicationsArr) => {
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_application_exporter.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "device_ips": deviceIps,
    "time": time
  });
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAppsUsageData(response.data.application_usage);

      const data = response.data.application_usage.slice(1);
      const inboundWanArr = [];
      const outboundWanArr = [];
      const applicationsArr = [];

      data.forEach(item => {
        inboundWanArr.push(item.inbound_wan);
        outboundWanArr.push(item.outbound_wan);
        applicationsArr.push(item.application);
      });

      setInBoudWanArr(inboundWanArr)
      setOutBoudWanArr(outboundWanArr)
      setApplicationsArr(applicationsArr)
    }
    else {
      setAppsUsageData([]);
      setInBoudWanArr([]);
      setOutBoudWanArr([]);
      setApplicationsArr([]);
    }
  })
}

export default GetTopAppsUsageExporterAPICall;