import axios from 'axios';

// API CALL CODE HERE
const UpdateOnboardStatusAPICall = async (setUpdateOnboardStatus) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/update_onboard_status.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id"),
    "onboard_status": "1"
  }); 
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        localStorage.setItem("onboard_status", 1);
        setUpdateOnboardStatus(1);
    }
    else {
        setUpdateOnboardStatus(-1);
    }
  }).catch(()=>{
    setUpdateOnboardStatus(-1);
  })
}

export default UpdateOnboardStatusAPICall;
