import { filter } from 'lodash';
import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Button,
  ButtonGroup,
  TableHead,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// components
import Scrollbar from '../../Scrollbar';
import USERLIST from '../../../_mock/user';
import SaasApplicationTableToolbar from './SaasApplicationTableToolbar';
import SaasApplicationTableHead from './SaasApplicationTableHead';
import GetApplicationTableData from './GetApplicationTableData';
import AddPortsDialog from './AddPortsDialog';
import EditSaasDialog from './EditSaasDialog';
import DeleteSaasDialog from './DeleteSaasDialog';
import CancelPortDialog from './CancelPortDialog';
import EditPortDialog from './EditPortDialog';
import AddNewApplicationDialog from './AddNewApplicationDialog';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// import { Search } from '@mui/icons-material';

const TABLE_HEAD = [
  { id: 'no', label: ' Click for Port Details', alignRight: false },
  { id: 'no', label: 'Sr.No.', alignRight: false },
  { id: 'custName', label: 'Customer', alignRight: false },
  { id: 'host name', label: 'Host', alignRight: false },
  { id: 'app name', label: 'Application Name', alignRight: false },
  { id: 'group name', label: 'Group/s', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none"
  },
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
}));

export default function SaasApplicationTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

  const isEngineer = (localStorage.getItem("role") === "2")
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');

  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [editPortOpen, setEditPortOpen] = useState(false);
  const [addAppOpen, setAddAppOpen] = useState(false);

  const [editApp, setEditApp] = useState(null);
  const [editPort, setEditPort] = useState(null);
  const [appId, setAppId] = useState(0);
  const [appData, setAppData] = useState([]);
  const [allAppsData, setAllAppData] = useState([]);
  const [appType, setAppType] = useState(1);

  const [addStatus, setAddStatus] = useState(0);
  const [addStatusMessage, setAddStatusMessage] = useState("");

  const [updateStatus, setUpdateStatus] = useState(0);
  const [updateStatusMessage, setUpdateStatusMessage] = useState("");

  const [deleteStatus, setDeleteStatus] = useState(0);
  const [deleteStatusMessage, setDeleteStatusMessage] = useState("");

  const [cancelStatus, setCancelStatus] = useState(0);
  const [cancelStatusMessage, setCancelStatusMessage] = useState("");

  const [portUpdateStatus, setPortUpdateStatus] = useState(0);
  const [addAppStatus, setAddAppStatus] = useState(0);
  const [addAppStatusMessage,setAppStatusMessage] = useState("");
  const [severStatus, setSeverStatus] = useState("");
  

  const [saasVariant, setSaasVariant] = useState('contained');
  const [internalVariant, setInternalVariant] = useState('outlined');
  const [buttonClicked, setButtonClicked] = useState(false);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openPortEditDialog, setOpenPortEditDialog] = useState(false);
  const [openAddAppDialog, setOpenAddAppDialog] = useState(false);
  const [filteredAppData, setFilteredAppData] = useState([]);

  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const [expandedRows, setExpandedRows] = useState([]);
  const toggleRow = (rowIndex) => {
    if (expandedRows.includes(rowIndex)) {
      // Row is currently expanded, so close it
      setExpandedRows(expandedRows.filter((row) => row !== rowIndex));
    } else {
      // Row is currently closed, so expand it
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  const classes = useStyles();

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAddStatus(0)
    setUpdateStatus(0);
    setDeleteStatus(0);
    setCancelStatus(0);
    setPortUpdateStatus(0);
    setOpen(false);
    setDeleteOpen(false);
    setAddOpen(false);
    setCancelOpen(false);
    setEditPortOpen(false);
    setAddAppOpen(false);
    setSeverStatus("")
  };
  useEffect(() => {
    if (appData) {
      setFilteredAppData(appData)
    }
  }, [appData])

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    const filteredRows = appData.filter((row) => {
      const AppUrl = row.app_url ? row.app_url.toLowerCase() : " ";
      const AppName = row.app_name ? row.app_name.toLowerCase() : " ";
      const GroupName = row.group_name.join(",") ? row.group_name.join(",") : "";
      return (
        AppUrl.includes(searchedVal.toLowerCase()) ||
        AppName.includes(searchedVal.toLowerCase()) ||
        GroupName.includes(searchedVal.toLowerCase())
      );
    });
    setFilteredAppData(filteredRows);
  };
  const _GetApplicationTableData = async () => {
    await GetApplicationTableData(appType, setAppData,buState);

  }

  const handleButtonClick = (e) => {
    if (e.target.id === "saasButton") {
      setSaasVariant('contained');
      setInternalVariant('outlined');
      setAppType(1);

    }
    if (e.target.id === "internalButton") {
      setSaasVariant('outlined');
      setInternalVariant('contained');
      setAppType(0);
    }
    setButtonClicked(true);
  }

  useEffect(() => {
    _GetApplicationTableData();
  }, [addStatus, updateStatus, deleteStatus, cancelStatus, portUpdateStatus, addAppStatus, globalState,buState]);

  useEffect(() => {
    _GetApplicationTableData();
  }, [addAppStatus]);

  useEffect(() => {
    _GetApplicationTableData();
  }, [appType]);

  // edit and delete app and port
  const handleAddClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenAddDialog(true);
    setEditApp(row);
  }
  const handleEditClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }

    setOpenEditDialog(true);
    setEditApp(row);
  }
  const handleDeleteClick = (e, row) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenDeletDialog(true);
    setEditApp(row);
  }
  const handleCancelClick = (e, port, appId) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setAppId(appId);
    setOpenCancelDialog(true);
    setEditPort(port);
  }
  const handlePortEditClick = (e, port, appId) => {
    setAppId(appId);
    setOpenPortEditDialog(true);
    setEditPort(port);
  }
  const handleAddAppClick = () => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenAddAppDialog(true);
  }

  
  

  return (
    <>
      <Grid item xs={12} sm={2} md={2} lg={2} sx={{ py: 2 }}>
        <ButtonGroup size="large" sx={{ width: "20%" }}>
          <Button id="saasButton" variant={saasVariant} sx={{ width: "100%", transition: "transform 0.2s ease" }} onClick={handleButtonClick}>
            SaaS Applications
          </Button>
          <Button id="internalButton" variant={internalVariant} sx={{ width: "100%", transition: "transform 0.2s ease" }} onClick={handleButtonClick}>Internal Applications</Button>
        </ButtonGroup>
      </Grid>

      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <SaasApplicationTableToolbar
            // tableHeading={'Apps'}
            handleAddAppClick={handleAddAppClick}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="collapsible table" style={{ borderCollapse: 'collapse', border: 'none' }}>
                <SaasApplicationTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD.filter(tableCell => {
                    return (
                      (mspClientId === regClientId || tableCell.id !== 'custName') &&
                      (mspStatus !== 0 || tableCell.id !== 'custName')
                    );
                  })}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={(e) => { handleFilterByName(e, filterName) }}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredAppData && filteredAppData.map((row, index) => {
                    const portData = row.port_data || []; 
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          component={Paper}
                          elevation={15}
                          role="checkbox"
                        >
                          <TableCell>
                            <IconButton
                              onClick={() => toggleRow(index)}
                            >
                              {expandedRows.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell align="left">{index + 1}</TableCell>
                          {mspClientId === regClientId && mspStatus === 1 ? <TableCell>{row.cust_name}</TableCell> : null}
                          <TableCell align="left">{row.app_url}</TableCell>
                          <TableCell align="left">{row.app_name}</TableCell>
                          <TableCell align="left">{row.group_name.join(', ')}</TableCell>
                          <TableCell align="left">
                            <Grid direction="row">
                              {isEngineer && "-"}
                              {!isEngineer && appType === 0 && <IconButton variant="outlined" onClick={(e) => { handleAddClick(e, row) }} color="primary" ><AddCircleIcon /></IconButton>}
                              {!isEngineer && <IconButton variant="outlined" color="primary" onClick={(e) => { handleEditClick(e, row) }}><EditIcon /></IconButton>}
                              {!isEngineer && <IconButton variant="outlined" onClick={(e) => { handleDeleteClick(e, row) }} color="error" ><DeleteIcon /></IconButton>}
                            </Grid>
                          </TableCell>
                        </TableRow>
                        {/* {expandedRows.includes(index) && portData.length > 0 && (
                          <TableRow key={`collapsible-${index}`}>
                            <TableCell colSpan={10} style={{ border: 'none' }}>
                              <TableContainer component={Paper} variant="outlined" elevation={20}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left">No.</TableCell>
                                      <TableCell align="left">Port Number</TableCell>
                                      <TableCell align="left">Port Type</TableCell>
                                      <TableCell align="left">Criticality</TableCell>
                                      <TableCell align="left">Functionality</TableCell>
                                      <TableCell align="left">Port Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {portData.map((port, portIndex) => (
                                      <TableRow key={portIndex} >
                                        <TableCell style={{ border: 'none' }} align="left">{portIndex + 1}</TableCell>
                                        <TableCell style={{ border: 'none' }} align="left">{port.port_number}</TableCell>
                                        <TableCell style={{ border: 'none' }} align="left">{port.port_type}</TableCell>
                                        <TableCell style={{ border: 'none' }} align="left">{port.criticality}</TableCell>
                                        <TableCell style={{ border: 'none' }} align="left">{port.functionality}</TableCell>

                                        <TableCell style={{ border: 'none' }} align="left">
                                          {!isEngineer && appType === 0 && <IconButton variant="outlined" onClick={(e) => { handlePortEditClick(e, port, row.app_id) }} color="primary" >
                                            <EditIcon />
                                          </IconButton>
                                          }
                                          {!isEngineer && appType === 0 && <IconButton variant="outlined" onClick={(e) => { handleCancelClick(e, port, row.app_id) }} color="error" >
                                            <DoDisturbOnIcon />
                                          </IconButton>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        )} */}
                        {expandedRows.includes(index) && (
                          <TableRow key={`collapsible-${index}`}>
                            <TableCell colSpan={10} style={{ border: 'none' }}>
                              <TableContainer component={Paper} variant="outlined" elevation={20}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left">No.</TableCell>
                                      <TableCell align="left">Port Number</TableCell>
                                      <TableCell align="left">Port Type</TableCell>
                                      <TableCell align="left">Criticality</TableCell>
                                      <TableCell align="left">Functionality</TableCell>
                                      {appType === 0 && (
                                        <TableCell align="left">Port Actions</TableCell>
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {portData.length > 0 ? (
                                      portData.map((port, portIndex) => (
                                        <TableRow key={portIndex}>
                                          <TableCell style={{ border: 'none' }} align="left">{portIndex + 1}</TableCell>
                                          <TableCell style={{ border: 'none' }} align="left">{port.port_number}</TableCell>
                                          <TableCell style={{ border: 'none' }} align="left">{port.port_type === 0?"TCP":"UDP"}</TableCell>
                                          <TableCell style={{ border: 'none' }} align="left">{port.criticality}</TableCell>
                                          <TableCell style={{ border: 'none' }} align="left">{port.functionality}</TableCell>
                                          {appType === 0 && (
                                          <TableCell style={{ border: 'none' }} align="left">
                                            {/* {!isEngineer && appType === 0 && (
                                              <IconButton variant="outlined" onClick={(e) => { handlePortEditClick(e, port, row.app_id) }} color="primary">
                                                <EditIcon />
                                              </IconButton>
                                            )} */}
                                            {!isEngineer && appType === 0 && (
                                              <IconButton variant="outlined" onClick={(e) => { handleCancelClick(e, port, row.app_id) }} color="error">
                                                <DeleteIcon />
                                              </IconButton>
                                            )}
                                          </TableCell>
                                          )}
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow>
                                        <TableCell colSpan={6} align="center" style={{ color: 'red' }}>No Port Details Available</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {openAddAppDialog && <AddNewApplicationDialog openAddAppDialog={openAddAppDialog} setOpenAddAppDialog={setOpenAddAppDialog} setAddAppStatus={setAddAppStatus} setAddAppOpen={setAddAppOpen} setSeverStatus={setSeverStatus} setAppStatusMessage={setAppStatusMessage} />}
          {openAddDialog && <AddPortsDialog app={editApp} appType={appType} openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} setAddStatus={setAddStatus} setAddOpen={setAddOpen} setSeverStatus={setSeverStatus} setAddStatusMessage={setAddStatusMessage}/>}
          {openEditDialog && <EditSaasDialog app={editApp} appType={appType} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setUpdateStatus={setUpdateStatus} setOpen={setOpen} setSeverStatus={setSeverStatus} setUpdateStatusMessage={setUpdateStatusMessage} />}
          {openDeleteDialog && <DeleteSaasDialog app={editApp} appType={appType} openDeleteDialog={openDeleteDialog} setOpenDeletDialog={setOpenDeletDialog} setDeleteStatus={setDeleteStatus} setDeleteOpen={setDeleteOpen} setSeverStatus={setSeverStatus} setDeleteStatusMessage={setDeleteStatusMessage} />}
          {openCancelDialog && <CancelPortDialog port={editPort} appId={appId} appType={appType} openCancelDialog={openCancelDialog} setOpenCancelDialog={setOpenCancelDialog} setCancelStatus={setCancelStatus} setCancelOpen={setCancelOpen} setSeverStatus={setSeverStatus} setCancelStatusMessage={setCancelStatusMessage}/>}
          {openPortEditDialog && <EditPortDialog port={editPort} appId={appId} appType={appType} openPortEditDialog={openPortEditDialog} setOpenPortEditDialog={setOpenPortEditDialog} setPortUpdateStatus={setPortUpdateStatus} setEditPortOpen={setEditPortOpen} setSeverStatus={setSeverStatus} />}

          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

          <Snackbar open={addAppOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {addAppStatus === 1 && <strong>{addAppStatusMessage}</strong>}
              {addAppStatus === -1 && <strong>{addAppStatusMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={addOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {addStatus === 1 && <strong>{addStatusMessage}</strong>}
              {addStatus === -1 && <strong>{addStatusMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {updateStatus === 1 && <strong>{updateStatusMessage}</strong>}
              {updateStatus === -1 && <strong>{updateStatusMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={deleteOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {deleteStatus === 1 && <strong>{deleteStatusMessage}</strong>}
              {deleteStatus === -1 && <strong>{deleteStatusMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={cancelOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {cancelStatus === 1 && <strong>{cancelStatusMessage}</strong>}
              {cancelStatus === -1 && <strong>{cancelStatusMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={editPortOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {portUpdateStatus === 1 && <strong>Port Updated Successfully</strong>}
              {portUpdateStatus === -1 && <strong>Error in Updating Port</strong>}
              {portUpdateStatus === 2 && <strong>No changes are made to update</strong>}

            </Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </>
  );
}
