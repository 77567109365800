import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useGlobalState } from '../../../globalHooks/GlobalState';
import { ISPLocationsDataAPICallForAgent } from './ISPLocationsDataAPICallForAgent';
import EditRaspiAgentAPICall from './EditRaspiAgentAPICall';

export default function EditAgentDialog(props) {
    const { openEditDialog, setOpenEditDialog, setEditAgentStatus, setEditAgentOpen, setSeverStatus, setEditRaspiMessage, agent } = props;

    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const [locationData, setLocationdata] = useState(null);
    const [value, setValue] = useState(agent.location_id);
    const [ispLocations, setIspLocations] = useState([]);
    const [firstLocationName, setFirstLocationName] = useState("");
    const [locationId, setLocationId] = useState(agent.location_id);
    const [selectedLocation, setSelectedLocation] = useState(agent.location_id);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [serialNumber, setSerialNumber] = useState(agent.raspi_serial_number);
    const [macAddress, setMacAddress] = useState(agent.raspi_mac_address);
    const [serialNumberHelperText, setSerialNumberHelperText] = useState("");
    const [macAddressHelperText, setMacAddressHelperText] = useState("");
    const [siteHelperText, setSiteHelperText] = useState("");

    const [warning, setWarning] = useState("")
    const [critical, setCritical] = useState("")
    const prevLocationId = agent.location_id;
    const serialRegex = /^RSPI[A-Za-z0-9]+$/;
    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "serial_number":
                if (!e.target.value.match(serialRegex)) {
                    setSerialNumberHelperText("Serial Number should be valid");
                } else {
                    setSerialNumberHelperText("");
                }
                setSerialNumber(e.target.value);
                break;

            case "mac_address":
                if (!e.target.value.match(macRegex)) {
                    setMacAddressHelperText("Mac Address Should be Valid");
                } else {
                    setMacAddressHelperText("");
                }
                setMacAddress(e.target.value);
                break;

            default:
                setMacAddress(e.target.value);
                break;
        }
    };

    const handleOnSubmit = (e) => {
        if (!serialNumber.match(serialRegex)) {
            setSerialNumberHelperText("Serial Number should be valid");
            return;
        }
        if (!macAddress.match(macRegex)) {
            setMacAddressHelperText("Mac Address Should be Valid");
            return;
        }
        if (selectedLocation === null) {
            setSiteHelperText("Please Select Site");
            return;
        }

        EditRaspiAgentAPICall(prevLocationId, locationId, serialNumber, macAddress, setEditAgentStatus, setSeverStatus, setEditRaspiMessage);
        setEditAgentOpen(true);
        setOpenEditDialog(false);
    };

    const handleLocationChange = (event) => {
        const selectedLocation = event.target.value;
        setSelectedLocation(selectedLocation);
        setLocationId(selectedLocation);
        setSiteHelperText("")
    };

    // const _ISPLocationsDataAPICall = async () => {
    //     const locations = await ISPLocationsDataAPICall(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState);
    //     console.log("here");
    //     if (!selectedLocation && locations.length > 0) {
    //         console.log("here again");
    //         setSelectedLocation(locations[0].location_id);
    //         setLocationId(locations[0].location_id);
    //     }
    // }

    useEffect(() => {
        ISPLocationsDataAPICallForAgent(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState, setWarning, setCritical)
    }, [globalState, buState]);

    const handleOnCancel = () => {
        setOpenEditDialog(false);
    };

    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
        }
    }, [globalState]);

    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnCancel} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title">
                <DialogTitle sx={{ py: 2 }}>
                    Edit Agent
                    <IconButton sx={{ float: 'right' }} onClick={handleOnCancel}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form onSubmit={handleOnSubmit}>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Serial Number"
                                    id="serial_number"
                                    helperText={serialNumberHelperText}
                                    error={!!serialNumberHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange}
                                    required
                                    value={serialNumber}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="MAC Address"
                                    id="mac_address"
                                    helperText={macAddressHelperText}
                                    error={!!macAddressHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange}
                                    required
                                    value={macAddress}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="location-select-label" required>
                                        Select Site
                                    </InputLabel>
                                    <Select
                                        labelId="location-select-label"
                                        id="location-select"
                                        label="Select Site"
                                        style={{ width: '100%', mt: 2 }}
                                        value={selectedLocation}
                                        error={siteHelperText}
                                        onChange={handleLocationChange}
                                        displayEmpty
                                    >
                                        {ispLocations.map(option => (
                                            <MenuItem key={option.location_id} value={option.location_id}>
                                                {option.location || "No location assigned"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {selectedLocation === null && (
                                        <FormHelperText error>
                                            {siteHelperText}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleOnCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleOnSubmit} disabled={!selectedLocation}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
