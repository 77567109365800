import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// material
import {
  Grid,
  Table,
  Button,
  ButtonGroup,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';


// components
import Scrollbar from '../../Scrollbar';
import AlertsTableHead from './DecommisionedTableHead';
import AlertsTableToolbar from './DecommisionedToolbar';
import { LinkAPICall } from './LinkAPICall';
import { SiteAlertsApiCall } from './SiteAlertsApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import { EdLogsAPICall } from './EdLogsAPICall';
// mock
import USERLIST from '../../../_mock/user';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';



const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


const TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'circuitid', label: 'Circuit ID', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'publicIp', label: 'Public IP', alignRight: false },
  { id: 'internalIp', label: 'Internal IP', alignRight: false },
  { id: 'vendor', label: 'Vendor', alignRight: false },
  { id: 'defaultGateway', label: 'Default Gateway', alignRight: false },
  { id: 'linkType', label: 'Link Type', alignRight: false },
  { id: 'provisionedSpeed', label: 'Provisioned Speed', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'Deleted at', label: 'Deleted At', alignRight: false }
];

const SITE_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'manager name', label: 'Manager Name', alignRight: false },
  { id: 'manager contact', label: 'Manager Contact', alignRight: false },
  { id: 'manager email', label: 'Manager Email Id', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'State', label: 'State', alignRight: false },
  { id: 'Country', label: 'Country', alignRight: false },
  { id: 'Deleted at', label: 'Deleted At', alignRight: false }

];


const EDGE_TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', numeric: true, disablePadding: false },
  { id: 'deviceIP', label: 'Device IP', numeric: false, disablePadding: false },
  { id: 'Host name', label: 'Host Name', numeric: false, disablePadding: false },
  { id: 'deviceSerial', label: 'Device Serial', numeric: false, disablePadding: false },
  { id: 'vendor name', label: 'Vendor Name', numeric: false, disablePadding: false },
  { id: 'type', label: 'Type', numeric: false, disablePadding: false },
  { id: 'Deleted at', label: 'Deleted At', alignRight: false }
]
export default function DecommisionedTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [linkAlerts, setLinkAlerts] = useState([]);
  const [allLinkAlerts, setAllLinkAlerts] = useState([]);

  const [siteAlerts, setSiteAlerts] = useState([]);
  const [allSiteAlerts, setAllSiteAlerts] = useState([]);


  const [edgeAlerts, setEdgeAlerts] = useState([]);
  const [allEdgeAlerts, setAllEdgeAlerts] = useState([])


  const [linkVariant, setLinkVariant] = useState("contained");

  const [siteVariant, setSiteVariant] = useState("outlined");

  const [edgeVariant, setEdgeVariant] = useState("outlined");

  const [showLinkEvents, setShowLinkEvents] = useState(true);

  const [tableTitle, setTableTitle] = useState("Decommissioned Links");
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
const [openWarnDialog, setOpenWarnDialog] = useState(false);


  const _AlertAPICall = async () => {

    await LinkAPICall(setLinkAlerts, setAllLinkAlerts, buState);
    await SiteAlertsApiCall(setSiteAlerts, setAllSiteAlerts, buState);
    await EdLogsAPICall(setEdgeAlerts, setAllEdgeAlerts, buState);
  }

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    _AlertAPICall();
    setInterval(() => {
      _AlertAPICall();
      setFilterName("");
    }, 60 * 1000);
  }, [globalState, buState]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }

    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllLinkAlerts(allLinkAlerts);
    const filteredRows = allLinkAlerts.filter((row) => {
      return row.location.toLowerCase().includes(searchedVal.toLowerCase()) || row.public_ip.toLowerCase().includes(searchedVal.toLowerCase()) || row.vendor.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setLinkAlerts(filteredRows);
  };

  const handleSitesFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllSiteAlerts(allSiteAlerts);
  
    const filteredRows = allSiteAlerts.filter((row) => {
      const location = row.location_name || ''; 
      return location.toLowerCase().includes(searchedVal.toLowerCase());
    });
  
    setSiteAlerts(filteredRows);
  };

  const handleEdgeFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setAllEdgeAlerts(allEdgeAlerts)
    const filteredRows = allEdgeAlerts.filter((row) => {
      return (
        row.device_ip.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.vendor_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
          row.type.toLowerCase().includes(searchedVal.toLowerCase())
      );
      
    });
    setEdgeAlerts(filteredRows)
  };


  const handleButtonClick = (e) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return;
    }
    if (e.target.id === "linkButton") {
      setLinkVariant("contained");
      setSiteVariant("outlined");
      setEdgeVariant("outlined")
      setShowLinkEvents(true);
      setTableTitle("Decommissioned Links");
      LinkAPICall(setLinkAlerts, setAllLinkAlerts, buState);
    }
    if (e.target.id === "siteButton") {
      setLinkVariant("outlined");
      setSiteVariant("contained");
      
      setEdgeVariant("outlined")
      setShowLinkEvents(false);
      setTableTitle("Decommissioned Sites");
      SiteAlertsApiCall(setSiteAlerts, setAllSiteAlerts, buState);
    }
    
    if (e.target.id === "edgeButton") {
      setLinkVariant("outlined");
      setSiteVariant("outlined");
      
      setEdgeVariant("contained")
      setShowLinkEvents(1);
      setTableTitle("Decommissioned Edge Device ");
      EdLogsAPICall(setEdgeAlerts, setAllEdgeAlerts, buState)
    }
    setFilterName("");
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <ButtonGroup
              size="large"
              sx={{
                width: "100%", 
                justifyContent: "flex-end", 
                height: "100%"
              }}
            >
              <Button id="linkButton" variant={linkVariant} onClick={handleButtonClick}>Decommissioned Link </Button>
              <Button id="siteButton" variant={siteVariant} onClick={handleButtonClick}>Decommissioned Site </Button>
              <Button id="edgeButton" variant={edgeVariant} onClick={handleButtonClick}>Decommissioned Edge Device </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <br/>
      
        {!(globalState === custId && mspStatus === 1) && showLinkEvents === true && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={linkAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {linkAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.circuit_id}</TableCell>
                        <TableCell align="left">{row.location}</TableCell>
                        <TableCell align="left">{row.public_ip}</TableCell>
                        <TableCell align="left">{row.internal_ip}</TableCell>
                        <TableCell align="left">{row.vendor}</TableCell>
                        <TableCell align="left">{row.default_gateway}</TableCell>
                        <TableCell align="left">{row.link_type}</TableCell>
                        <TableCell align="left">{row.provisioned_speed}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.ent}</TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}

        {!(globalState === custId && mspStatus === 1) && showLinkEvents === false && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleSitesFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={SITE_TABLE_HEAD}
                  rowCount={siteAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {siteAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.location_name}</TableCell>
                        <TableCell align="left">{row.manager_name}</TableCell>
                        <TableCell align="left">{row.manager_contact}</TableCell>
                        <TableCell align="left">{row.manager_email}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{row.state}</TableCell>
                        <TableCell align="left">{row.country}</TableCell>
                        <TableCell align="left">{row.ent}</TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}


        {!(globalState === custId && mspStatus === 1) && showLinkEvents === 1 && <Paper elevation={15}>
          <AlertsTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={tableTitle}
            onFilterName={(e) => { handleEdgeFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <AlertsTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={EDGE_TABLE_HEAD}
                  rowCount={edgeAlerts.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                  {edgeAlerts.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="left">{row.device_ip}</TableCell>
                        <TableCell align="left">{row.host_name}</TableCell>
                        <TableCell align="left">{row.device_Serial}</TableCell>
                        <TableCell align="left">{row.vendor_name}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>    
                        <TableCell align="left">{row.ent}</TableCell>
                    
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>}
        {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

      </Grid>
    </>
  );
}
