import React, { useState, useRef, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import { Paper, Stack, Typography, colors, FormControl, Radio, FormLabel, RadioGroup, FormControlLabel, Box, Grid, Card } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import merge from 'lodash/merge';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

import { BaseOptionChart } from '../chart';
import timeArray from './TimeData';
import GetSiteUploadDownloadGraph from './GetSiteUploadDownloadGraph';

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});


const SpeedTest = (props) => {

  const locationId = props.locationId;

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [uploadData, setUploadData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [dataLabelsEnabled, setDataLabelsEnabled] = useState(false);

  const [timeStamp, setTimeStamp] = useState("");
  const [uplaod, setUpload] = useState("");
  const [download, setDownload] = useState("");

  const intervalRef = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTimeChange = (event, newTimeValue) => {
    intervalRef.time = newTimeValue;
    const selectedTime = event.target.value;
    if (selectedTime === "1 HOUR") {
      setDataLabelsEnabled(true);
    } else {
      setDataLabelsEnabled(false);
    }

    _GetSiteUploadDownloadGraph();
  };

  const _GetSiteUploadDownloadGraph = async () => {
    await GetSiteUploadDownloadGraph(locationId, intervalRef.time, setUploadData, setDownloadData, setTimeData);
  }

  useEffect(() => {
    intervalRef.time = timeArray[2].value;
    _GetSiteUploadDownloadGraph();
  }, []);

  useEffect(() => {
    _GetSiteUploadDownloadGraph();
  }, [timeData.length]);

  useEffect(() => {
    _GetSiteUploadDownloadGraph();
  }, [props.locationId]);





  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const state0 = [
    {
      name: 'Upload',
      type: 'area',
      fill: 'gradient',
      data: uploadData,
    },
    {
      name: 'Download',
      type: 'line',
      fill: 'solid',
      data: downloadData,
    },
  ];

  const chartLabels = timeData;

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {
      bar: { columnWidth: '16%' },
      line: {
        markers: {
          size: 8,
        },
      },
    },
    fill: { type: state0.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: "time",
      tickAmount: 12
    },
    yaxis: {
      title: {
        text: 'Mbps'
      },
    },
    dataLabels: {
      enabled: dataLabelsEnabled,
      formatter: (val) => {
        const value = `${val.toFixed(0)} Mbps`;
        return value;
      },
      offsetY: -10,
      style:
      {
        fontSize: '15px',
        // colors: ["#32CD32"]
      }
    },
    tooltip: {
      enabled: true,
    },
    markers: dataLabelsEnabled ? {
      size: 8,
      hover: {
        sizeOffset: 4
      }
    } : {},
  });


  return (
    <Paper
      elevation={15}
      sx={{
        p: 3,
      }}
    >

      <div style={{ display: 'flex', gap: '30px', marginTop: '20px', marginBottom: '20px' }}>
        <Paper sx={{ minWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <AccessAlarmsIcon style={{ width: '50px', height: '40px', marginRight: '15px', color: "orange" }} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Time Stamp
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {timeData && timeData[timeData.length - 1]}
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <UploadIcon style={{ width: '50px', height: '40px', marginRight: '15px', color: "green" }} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Upload Speed
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {uploadData && uploadData[uploadData.length - 1]} Mbps
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
        <Paper sx={{ minWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
          <Box className={classes.boxPadding}>
            <Box className={classes.boxItems}>
              <DownloadIcon style={{ width: '50px', height: '40px', marginRight: '15px', color: "blue" }} />
              <div>
                <Typography variant="subtitle1" component="div">
                  Download Speed
                </Typography>
                <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                  {downloadData && downloadData[downloadData.length - 1]} Mbps
                </Typography>
              </div>
            </Box>
          </Box>
        </Paper>
      </div>

      <div className={classes.titleStyle}>
        <FormLabel id="demo-row-radio-buttons-group-label">Select Time</FormLabel>
        <Grid container>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={intervalRef.time ? intervalRef.time : timeArray[0].value}
              onChange={handleTimeChange}
            >

              {timeArray.map((data, index) =>
              (
                <FormControlLabel value={data.value} control={<Radio />} label={data.label} />
              )
              )}
              <FormControlLabel style={{ display: 'none' }} value={"-1"} control={<Radio />} label={"Custom Date"} disabled />
            </RadioGroup>
          </Grid>
          {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid> */}
        </Grid>
      </div>

      <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2, py: 2 }}>
          <Card variant="outlined" elevation={15}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Upload / Download Speed" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box paddingX={2}>
                <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
              </Box>
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SpeedTest;
