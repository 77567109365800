
// material
import {
    Breadcrumbs, Container, Link,
  } from '@mui/material';
  import BuildIcon from '@mui/icons-material/Build';
  import Page from '../components/Page';
  import LogsComponent from '../components/LogsComponents/LogsComponent';
  
  export default function AuditLogs() {
  
    return (
      <Page title="Alerts">
        <Container maxWidth={false}>
          <div role="presentation" style={{ paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit">
                <BuildIcon />
              </Link>
              <Link underline="hover" color="inherit">
                Logs Management
              </Link>
              <Link underline="hover" color="inherit" href="/dashboard/audit-logs">
                Audit Logs
              </Link>
            </Breadcrumbs>
          </div>
  
          <LogsComponent />
  
        </Container>
      </Page>
    );
  }
  