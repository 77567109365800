import React, { useState } from 'react';
// @mui
import {
  Breadcrumbs,
  Container,
  Link,
  Button
} from '@mui/material';
import AddchartIcon from '@mui/icons-material/Addchart';
import MainScreenComponent from '../components/NewReportComponents/MainScreenComponent';

// components
import Page from '../components/Page';

export default function NewReports() {

    return (
      <Page title="Report Details">
        <Container maxWidth={false}>
  
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard/reports">
              <AddchartIcon />
              </Link>
              <Link underline="hover" color="inherit" href="/dashboard/reports">
                Reports
              </Link>
              <Link underline="hover" color="inherit" href="/dashboard/reports">
              Report Details
              </Link>
            </Breadcrumbs>
          </div>
  
          
        <MainScreenComponent/>
  
        </Container>
      </Page>
    );
}

