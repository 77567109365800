import React from "react";
import {
    Box,
    Typography,
    Chip,
    Grid,
    LinearProgress
} from '@mui/material';

const customStylesLeft = {
    '& .MuiLinearProgress-bar': {
        backgroundColor: "#1B5E20",
    },
    transform: "scaleX(-1)",
    marginRight: "0.5rem"
};

const customStylesRight = {
    '& .MuiLinearProgress-bar': {
        backgroundColor: "#FB8C00",
    },
};


export default function TopSingleTalkerComponent(props) {
    const { ip, inbound, outbound,  inboundMarkerValue, outboundMarkerValue } = props;
    return (
        <>
            <Grid container sx={{ paddingLeft: "1rem", paddingTop: "1rem" }}>
                <Grid item lg={3} sm={3} xs={3} md={3} xl={3}>
                    <Typography variant="h8">{ip}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} xs={3} md={3} xl={3}>
                    <Box sx={{ width: "100%", textAlign: "right", paddingRight: "4vh" }}>
                        <Typography variant="h8">Inbound: {inbound} GB</Typography>
                    </Box>
                </Grid>

                <Grid item lg={4} sm={4} xs={4} md={4} xl={4}>
                    <Box sx={{ width: "100%", textAlign: "left", paddingLeft: "2vh" }}>
                        <Typography variant="h8" >Outbound: {outbound} GB</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ paddingLeft: "1rem", paddingRight: "1rem", paddingTop: "1rem" }}>
                <Grid item lg={6} sm={6} xs={6} md={6} xl={6}>
                    <LinearProgress variant="determinate" value={inboundMarkerValue} sx={customStylesLeft} />
                </Grid>
                <Grid item lg={6} sm={6} xs={6} md={6} xl={6}>
                    <LinearProgress variant="determinate" value={outboundMarkerValue} sx={customStylesRight} />
                </Grid>
            </Grid>
        </>
    )

}