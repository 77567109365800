import axios from 'axios';

export const AddNewPortAPICall = async (appId, appType, portNumber, portType, portCriticality, portFunction, setAddStatus, setSeverStatus,setAddStatusMessage) => {
  const path = localStorage.getItem("msp_status") === "1" ? "push_new_port_for_app_for_msp.php" : "push_new_port_for_app.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "app_id": appId,
    "app_type": appType,
    "port_data": {
      "port_number": portNumber,
      "port_type": portType,
      "port_criticality": portCriticality,
      "port_functionality": portFunction
    }
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "app_id": appId,
    "app_type": appType,
    "port_data": {
      "port_number": portNumber,
      "port_type": portType,
      "port_criticality": portCriticality,
      "port_functionality": portFunction
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setAddStatus(1);
      setSeverStatus("success");
      setAddStatusMessage(response.data.message);
    }
    else {
      setAddStatus(-1);
      setSeverStatus("error");
      setAddStatusMessage(response.data.message);

    }
  })
}
