import axios from 'axios';

const UtilizationAlertApiCall = async (alertNotifyLocationId, alertNotifyLocationName, newUtilAlertStatus, setAlertNotifyStatus, setAlertNotifyMessage) => {
  const path = localStorage.getItem("msp_status") === "1" ? "site_utilization_alert_for_msp.php" : "site_utilization_alert.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "location_id": alertNotifyLocationId,
    "location_name": alertNotifyLocationName,
    "alert_status": newUtilAlertStatus
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "location_id": alertNotifyLocationId,
    "location_name": alertNotifyLocationName,
    "alert_status": newUtilAlertStatus
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(baseUrl, payload).then((response) => {
  
  if (response.data.code === 1) {
    setAlertNotifyStatus (response.data.code);
      setAlertNotifyMessage(response.data.message);
  } else {
    setAlertNotifyStatus(response.data.code);
      setAlertNotifyMessage(response.data.message);
  }
}).catch(()=>{
  setAlertNotifyStatus(-1);
  setAlertNotifyMessage("No Links Available to this Site")
})
}
export default UtilizationAlertApiCall;

