
// material
import {
  Breadcrumbs, Container, Link,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import IMConfigComp from '../components/IMConfigComponents/IMConfigComp';
import Page from '../components/Page';


export default function AlertEmailConfig() {

  return (
    <Page title="Alerts-IM-Config">
      <Container maxWidth={false}>

        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <BuildIcon />
            </Link>
            <Link underline="hover" color="inherit" href="alert-im-config">
              IM Config
            </Link>
          </Breadcrumbs>
        </div>

        <IMConfigComp />

      </Container>
    </Page>
  );
}