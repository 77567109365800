import axios from 'axios';

// API CALL CODE HERE

export const GetMspMappedCustomersDetailsApiCall = async (setCustomers) => {
  const baseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_customer_details_by_msp_cust_id.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id")
  });
  
  return axios.post(baseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setCustomers(response.data.cust_data);
    }
    else{
        setCustomers([]);
    }
  })
}
