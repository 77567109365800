
// material
import {
  Breadcrumbs, Container, Link,Divider
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import AlertComponents from '../components/AlertsComponents/AlertsComponents';

export default function Alerts() {

  return (
    <Page title="Alerts">
      <Container maxWidth={false}>
        <div role="presentation" style={{ paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/alerts">
              Alerts
            </Link>
          </Breadcrumbs>
        </div>
        <Divider />
          <AlertComponents />
      </Container>
    </Page>
  );
}
