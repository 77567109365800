import React from 'react'
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
import BusinessLocationMapping from '../components/BusinessUnitLocationMappingComponents/BusinessUnitLocationMapComponent'

export default function BusinessUnitLocationMapping() {
    return (
        <Page title="BusinessUnit">
            <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '10px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/business-unit">
                        Business Unit Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/business-unit-location-map">
                    Business Unit Location Mapping
                    </Link>
                </Breadcrumbs>
            </div>
            <BusinessLocationMapping />
        </Page>
    )
}
