import axios from 'axios';

const GetGroupsApiCall = async (setGroups) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_site_groups_details.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  try {
    const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
      setGroups(response.data.groups_data);
    } else {
      console.error("Failed to fetch groups:", response.data.message);
    }
  } catch (error) {
    console.error("Error in fetching groups:", error);
  }
};

export default GetGroupsApiCall;
