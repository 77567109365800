import axios from 'axios';

const getCustomTagsApiCall = async (setRows, setFilteredRows, setCustomTag) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_custom_tags_for_msp.php" : "get_custom_tags.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
    });
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
        
      });
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    try {
        const response = await axios.post(baseUrl, payload);

        if (response.data.code === 1) {
            

            setRows(response.data.data);
            setFilteredRows(response.data.data);
            
            const data = response.data.data 
            if (Array.isArray(data)) {
                const customTag = data.map(item => ({
                    entity: item.entity,
                    tags: item.tags,
                    is_mandatory: item.is_mandatory,
                    default_value: item.default_value,
                    data_type: item.data_type
                }));

                setCustomTag(customTag);
            } else {
                
                setCustomTag([]);
            }
        } else {
            setRows([]);
            setFilteredRows([]);
            setCustomTag([]);
        }
    } catch (error) {
        console.error('Error fetching custom tags:', error);
        setRows([]);
        setFilteredRows([]);
        setCustomTag([]);
    }
};

export default getCustomTagsApiCall;
