import axios from 'axios';

const GetSaasAppDropdownListAPICall = async(setSaasAppData) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_saas_dropdown_list_msp.php" : "get_saas_dropdown_list.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id")
  });


  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            const appdata = response.data.saas_app_data;
            setSaasAppData(appdata);
        }
        else {
            setSaasAppData([]);
        }
    });
}

export default GetSaasAppDropdownListAPICall