import axios from "axios";

const UpdateUtilizationConfigApiCall = async (updateData, setUpdateUtilizationStatus, setUpdateUtilizationMessage) => {

    

    const path = localStorage.getItem("msp_status") === "1" ? "utilization_config_for_msp.php" : "utilization_config.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "data": {      
        "criticalThresholdValue": updateData.criticalThreshold, 
        "warningThresholdValue": updateData.warningThreshold  
    }
});

const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_cust_id": localStorage.getItem("cust_id"),
    "reg_cust_id": localStorage.getItem("reg_cust_id"),
    "data": {      
        "criticalThresholdValue": updateData.criticalThreshold, 
        "warningThresholdValue": updateData.warningThreshold  
    }

});

const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setUpdateUtilizationStatus(response.data.code);
            setUpdateUtilizationMessage(response.data.message);
        } else {
            setUpdateUtilizationStatus(response.data.code);
            setUpdateUtilizationMessage(response.data.message);
        }
    })
}
export default UpdateUtilizationConfigApiCall;