import axios from 'axios';

export const ISPFlappingTableApiCall = async (setFlapplingData, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_flapping_links_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("cust_id"),
      "reg_client_id": localStorage.getItem("reg_cust_id"),
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setFlapplingData(response.data.flap_data);
      }
    })
  }


  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_flapping_links_for_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState
      });

      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setFlapplingData(response.data.flap_data);
        }
        else {
          setFlapplingData([]);
        }
      }).catch(() => {
        setFlapplingData([]);
      })
    }


    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_flapping_links_for_msp.php" : "get_flapping_links.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setFlapplingData(response.data.flap_data);
        }
        else {
          setFlapplingData([]);
        }
      }).catch(() => {
        setFlapplingData([]);
      })
    }
  }
}