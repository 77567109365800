// @mui
import {
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link,
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import ISPDashboardCards from '../components/ISPManagementTable/ISPManagementDashboard/ISPDashboardCards';

// components
import Page from '../components/Page';  
// sections


// ----------------------------------------------------------------------

export default function Overview(props) {
  return (
    <Page title="Overview">
      
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/isp-management">
              Links
            </Link>
          </Breadcrumbs>
        </div>
        <Divider/>
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }} data-tut="linkeyetour_home_totalgoodbaddownlinks">
          <ISPDashboardCards />
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Outlet />
        </Grid>

      </Container>
    </Page>
  );
}
