import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ChangePasswordRestAPICall from './ChangePasswordRestAPICall';

export default function ChangePasswordDetailsDialog(props) {
  const { user, openEditDialog, setOpen, setOpenEditDialog, setUpdateStatus, setSeverStatus, setErrorMessage } = props;


  const [oldPassword, setOldPassword] = useState(user.old_password);
  const [newPassword, setNewPassword] = useState(user.new_password);
  const [confirmPassword, setConfirmPassword] = useState(user.confirm_password);

  const [oldPasswordHelperText, setOldPasswordHelperText] = useState('');
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()@#$%^:;&+=!])[A-Za-z\d@():;#$%^&+=!]{8,}$/;
  const oldPassRegex = /^\S+$/;


  const handleOnClose = () => {
    setOpenEditDialog(false);
    setOpen(null);
  };

  const handleSaveClick = () => {

    if (newPassword !== confirmPassword) {
      setConfirmPasswordHelperText("Password Did not match");
      return;
    }
    setOpen(null);
    setOpenEditDialog(false);
    ChangePasswordRestAPICall(oldPassword, newPassword, confirmPassword, setUpdateStatus, setSeverStatus, setErrorMessage);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'oldPassword':
        if (!value.match(oldPassRegex)) {
          setOldPasswordHelperText("Enter Old Password Correctly");
        }
        else {
          setOldPasswordHelperText("");
        }
        setOldPassword(value);
        break;

      case 'newPassword':
        if (!value.match(passwordRegex)) {
          setNewPasswordHelperText("Password must contain at least 8 characters, one letter, one number and one special character");
        }
        else {
          setNewPasswordHelperText("");

        }
        setNewPassword(value);
        break;

      case 'confirmPassword':
        if (!value.match(passwordRegex)) {
          setConfirmPasswordHelperText("Password must contain at least 8 characters, one letter, one number and one special character");
        }
        else {
          setConfirmPasswordHelperText("");

        }
        setConfirmPassword(value);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title">
        <DialogTitle sx={{ py: 2 }}>
          Change Password
          <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3, px: 3 }}>
          <form>
            {/* <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}> */}
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} md={8} sx={{ marginTop: 2 }}>

                <TextField
                  label="Old Password"
                  id="oldpassword"
                  name="oldPassword"
                  type={showOldPassword ? 'text' : 'password'}
                  value={oldPassword}
                  helperText={oldPasswordHelperText}
                  error={!!oldPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  // style={{ width: '400px' }}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                          {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  label="New Password"
                  id="newpassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  helperText={newPasswordHelperText}
                  error={!!newPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  // style={{ width: '400px' }}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                          {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  label="Confirm Password"
                  id="cpassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  helperText={confirmPasswordHelperText}
                  error={!!confirmPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  // style={{ width: '400px' }}
                  fullWidth
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                          {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={handleSaveClick}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
