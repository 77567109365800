import React, { useState, useEffect } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
    Box
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';

import GetUsersDataAPI from './GetUsersDataAPI'
import GetLocationsDetailsApi from './GetLocationsDetailsApi';
import UpdateLocationDataApiCall from './UpdateLocationDataApiCall';

export default function AssignUserDialog(props) {
    const { openMappingDialog, setOpenMappingDialog, setUpdateAssignStatus, setShowAssignUser, selectedUser, setUpdateMessage } = props





    const handleOnClose = () => {
        if (changesMade) {
            setShowWarning(true);
        } else {
            setOpenMappingDialog(false);
        }
    }
    const handleConfirmClose = (confirmed) => {
        setShowWarning(false);
        if (confirmed) {
            setOpenMappingDialog(false);
        }
    };
    const firstNameRegex = /^[A-Za-z][A-Za-z-_\d ]*$/
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const usernameRegex = /^(?!.{51})(?![ -])[A-Za-z\d!@#$%&*_()+,\-.:;<=>?@]+[A-Za-z\d]$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()@#$%^:;&+=!])[A-Za-z\d@():;#$%^&+=!]{8,}$/;



    const { setSelectedUserIds, onSelectionChange, user } = props;
    const [Users, setUsers] = useState([]);
    const [UserNames, setUserNames] = useState([]);
    const [selectedUserNames, setSelectedUserNames] = useState([]);

    const [usersData, setUsersData] = useState([]);
    const [allUsersData, setAllUserData] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showUpdateWarning , setShowUpdateWarning] = useState(false);

    // ------------------
    const [left, setLeft] = useState([]);// available
    const [right, setRight] = useState([]);// allocated
    const [phrase, setphrase] = useState(1);// selecting
    const [userAllocatedData, setUserAllocatedData] = useState([]);
    const [changesMade, setChangesMade] = useState(false);

    const [matchingGroupsArray, setMatchingGroupsArray] = useState([]);


    const handleDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const sourceList = source.droppableId === 'Available' ? left : right;
        const destinationList = destination.droppableId === 'Allocated' ? right : left;

        const draggedItem = sourceList[source.index];

        sourceList.splice(source.index, 1);
        destinationList.splice(destination.index, 0, draggedItem);

        setLeft(left.slice());
        setRight(right.slice());

        setChangesMade(true);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changesMade) {
                const message = "Are you sure you want to leave without updating?";
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changesMade]);



    const handleSnackClose = (event, reason) => {
        
    };

    const handleGroupSelectionChange = (event, values) => {
        const UserIds = values.map((UserName) => {
            const UserN = Users.find((User) => User.user_name === UserName);
            return UserN ? UserN.user_id : null;
        });

        const filteredIds = UserIds.filter((id) => id !== null);

        setSelectedUserNames(values);

        onSelectionChange(filteredIds);
    };

    const _GetUserDetailsApiCall = async () => {
        await GetUsersDataAPI(setUsers, setUsersData, setAllUserData);

        setUserNames(Users.map(user => user.user_name));
    }

    useEffect(() => {
        _GetUserDetailsApiCall();
    }, []);

    useEffect(() => {
        if (Users.length > 0) {
            setUserNames(Users.map(user => user.user_name));
        }
    }, [Users])

    useEffect(() => {
        if (selectedUser) {
            setSelectedUserNames([selectedUser.user_name]);
        }
    }, [selectedUser]);

    const handleChange = (event) => {
        setphrase(event.target.value);
    }
    useEffect(() => {
        setLeft([])
        setRight([])
        GetLocationsDetailsApi(setLeft, setRight, setUserAllocatedData, phrase, user)
    }, [phrase])


    useEffect(() => {
        updateLocationDataApiCall(right, phrase);
    }, [right]);



    const updateLocationDataApiCall = (right, phrase) => {
        if (phrase === 1) {
            setUserAllocatedData(right);
        }
        if (phrase === 2) {
            setUserAllocatedData(right);
        }
        if (phrase === 3) {
            setUserAllocatedData(right);
        }
    };


    const _UpdateLocationDataApiCall = async () => {
        await UpdateLocationDataApiCall(userAllocatedData, setUpdateAssignStatus, user, setUpdateMessage,phrase);
    }

    const handleUpdateClick = async () => {
    
        if (changesMade) {
            _UpdateLocationDataApiCall(userAllocatedData, setUpdateAssignStatus, user, setUpdateMessage,phrase);
            setChangesMade(false);
        } else {
            setShowWarning(true);
        }
    }

    return (
        <>
            <Dialog open={openMappingDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Assign User
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Box sx={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%', marginBottom: '2%', marginLeft: '30%' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={phrase}
                                        label="Select"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>Zone</MenuItem>
                                        <MenuItem value={2}>Group</MenuItem>
                                        <MenuItem value={3}>Sites</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Grid container item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {phrase === 1 &&
                                    <div key={1} style={{ display: 'flex', justifyContent: 'center', Height: '400px', overflowY: 'auto' }}>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            {[left, right].map((zones, idx) => (
                                                <DragAndDropZone key={idx} zones={zones} isAvailable={idx === 0} />
                                            ))}
                                        </DragDropContext>
                                    </div>
                                }
                                {phrase === 2 &&
                                    <div key={2} style={{ display: 'flex', justifyContent: 'center', Height: '400px', overflowY: 'auto' }}>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            {[left, right].map((zones, idx) => (
                                                <DragAndDropGroup key={idx} zones={zones} isAvailable={idx === 0} />
                                            ))}
                                        </DragDropContext>
                                    </div>
                                }

                                {phrase === 3 && (
                                    <div key={3} style={{ display: 'flex', justifyContent: 'center', Height: '400px', overflowY: 'auto' }}>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            {[left, right].map((zones, idx) => (
                                                <DragAndDropLocation key={idx} zones={zones} isAvailable={idx === 0} />
                                            ))}
                                        </DragDropContext>
                                    </div>
                                )}

                            </Grid>
                            {/* Warning Dialog */}
                            <Dialog open={showWarning} onClose={() => handleConfirmClose(false)}
                            fullWidth
                            maxWidth="xs" // Set the maximum width
                            PaperProps={{ style: { minHeight: '200px', maxHeight: '400px' } }}
                            >
                                <DialogTitle>Warning!</DialogTitle>
                                <hr style={{ marginLeft: "10px",marginRight:"10px"}}/>
                                <DialogContent >
                                {changesMade ? "Are you sure you want to leave without updating?" : "No changes made to update."}
                                </DialogContent>
                                <DialogActions>
                                {changesMade && <Button onClick={() => handleConfirmClose(false)}>No</Button>}
                                {changesMade &&  <Button onClick={() => handleConfirmClose(true)}>Yes</Button>}
                                {!changesMade && <Button onClick={() => handleConfirmClose(false)}>OK</Button>}
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleUpdateClick}>Update</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const DragAndDropZone = ({ zones, isAvailable }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{isAvailable ? 'Available Zone' : 'Allocated Zone'}</h2>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={isAvailable ? 'Available' : 'Allocated'}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                padding: 10,
                                width: 350,
                                minHeight: 500,  // Set an upper limit for height
                                overflowY: 'auto', // Add this line for vertical scrolling
                                maxHeight: 500  // Set the maximum height
                            }}
                        >
                            {zones.map((item, index) => (
                                item.zone_id !== "null" && <Draggable key={item.zone_id} draggableId={item.zone_name} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                color: 'white',
                                                ...provided.draggableProps.style,

                                            }}
                                        >
                                            {item.zone_name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

const DragAndDropGroup = ({ zones, isAvailable }) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{isAvailable ? 'Available Group' : 'Allocated Group'}</h2>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={isAvailable ? 'Available' : 'Allocated'}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                padding: 10,
                                width: 350,
                                minHeight: 500,  // Set an upper limit for height
                                overflowY: 'auto', // Add this line for vertical scrolling
                                maxHeight: 500  // Set the maximum height
                            }}
                        >
                            {zones.map((item, index) => (
                                <Draggable key={item.group_id} draggableId={item.group_name} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                color: 'white',
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            {item.group_name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

const DragAndDropLocation = ({ zones, isAvailable }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{isAvailable ? 'Available Sites' : 'Allocated Sites'}</h2>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={isAvailable ? 'Available' : 'Allocated'}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                padding: 10,
                                width: 350,
                                minHeight: 500,  // Set an upper limit for height
                                overflowY: 'auto', // Add this line for vertical scrolling
                                maxHeight: 500  // Set the maximum height
                            }}
                        >
                            {zones.map((item, index) => (
                                <Draggable key={item.location_id} draggableId={item.site_name} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                color: 'white',
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            {item.site_name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );



};