// material
import {
  Grid,
} from '@mui/material';

// components
import MapComponents from './Components/MapComponents';

export default function ProbeLocationsMapComponent() {
  return (
    <Grid item container sx={{ paddingTop: "40px" }}>
      <Grid item lg={12} sm={12} xs={12} md={12} xl={12} >
        <MapComponents />
      </Grid>
    </Grid>
  );
}
