import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReactModal from 'react-modal';
import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
} from '@mui/material';



import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import PasswordChangeIcon from '@mui/icons-material/Lock';
import SiteAddIcon from '@mui/icons-material/LocationCity';
import LinkAddIcon from '@mui/icons-material/Link';
import EdgeDeviceIcon from '@mui/icons-material/DeviceHub';
import ProbeIcon from '@mui/icons-material/Computer';
import ReviewIcon from '@mui/icons-material/Summarize';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import CreateNewPassowrd from '../components/WizardComponents/CreateNewPassword';
import CreateSite from '../components/WizardComponents/CreateSite';
import CreateLink from '../components/WizardComponents/CreateLink';
import ConnectToProbe from '../components/WizardComponents/ConnectToProbe';
import CreateEdgeDevice from '../components/WizardComponents/CreateEdgeDevice';
import ReviewPage from '../components/WizardComponents/ReviewPage';


import ChangePasswordRestAPICall from './dashboard/ChangePasswordRestAPICall';
import AddSingleLocationRestApi from '../components/LocationComponents/SingleLocationComponent/AddSingleLocationRestApi';
import { PushSingleISPInfo } from '../pages/ISPAddingAPICall';
import AddEdgeDeviceApiCall from '../components/LocationComponents/ViewLocationComponents/AddEdgeDeviceApiCall';
import UpdateOnboardStatusAPICall from '../components/WizardComponents/UpdateOnboardStatusAPICall';

// //// START

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(255,215,0) 0%,rgb(255,215,0) 50%, rgb(163,138,0) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(255,215,0) 0%,rgb(255,215,0) 50%, rgb(163,138,0) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255,215,0) 0%, rgb(255,215,0) 50%, rgb(163,138,0) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255,215,0) 0%,rgb(255,215,0) 50%, rgb(163,138,0) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <PasswordChangeIcon />,
        2: <SiteAddIcon />,
        3: <LinkAddIcon />,
        4: <EdgeDeviceIcon />,
        5: <ProbeIcon />,
        6: <ReviewIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
// //// END


const steps = ["Change Password", "Add Site", "Add Link", "Add Edge Device", "Connect To Probe", "Review + Finish"]

export default function HorizontalLinearStepper() {
    const [page, setPage] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const [onboardStatus, setOnboradStatus] = useState(0);
    // all api status 
    const [passwordStatus, setPasswordStatus] = useState(0); // for create password
    const [siteStatus, setSiteStatus] = useState(0); // for create site
    const [linkStatus, setLinkStatus] = useState(0); // for create link
    const [deviceStatus, setDeviceStatus] = useState(0); // for device status
    const [severStatus, setSeverStatus] = useState(0);
    const [openAddEd, setOpenAddEd] = useState(0);

    // for CreateNewPassword form and API call

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // for CreateSite form and API call
    const [locationName, setLocationName] = useState("");
    const [businessHrStart, setBusinessHrStart] = useState(9);
    const [businessHrEnd, setBusinessHrEnd] = useState(18);
    const [remarks, setRemarks] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [managerEmail, setManagerEmail] = useState("");
    const [country, setCountry] = useState("");

    const [escMail, setEscMail] = useState("");
    const [managerId, setManagerId] = useState(-1);
    const [managerName, setManagerName] = useState("");
    const [managerContact, setManagerContact] = useState("");
    const [latency, setLatency] = useState(150);
    const [jitter, setJitter] = useState(150);
    const [packetLoss, setPacketLoss] = useState(0);


    // for CreateLink form and API call
    const [ispLocations, setIspLocations] = useState([{}]);
    const [value, setValue] = useState(null);
    const [circuitId, setCircuitId] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorEmail, setVendorEmail] = useState("");
    const [defaultGateway, setDefaulGateway] = useState("");
    const [publicIp, setPublicIp] = useState("");
    const [internalIp, setInternalIp] = useState("");
    const [promiseUptime, setPromisedUptime] = useState("0");
    const [resolutionTime, setResolutionTime] = useState("0");
    const [link, setLink] = useState("");
    const [price, setPrice] = useState("0");
    const [provisionedSpeed, setProvisionedSpeed] = useState("0");
    const [vendors, setVendors] = useState([]);
    const [selectedVendorId, setSelectedVendorId] = useState(-1);


    // for CreateEdgeDevice form and API call
    const [locationId, setLocationId] = useState("");
    const [deviceId, setDeviceId] = useState(-1);
    const [deviceIp, setDeviceIp] = useState("");
    const [snmpStr, setSnmpStr] = useState("");
    const [sshUserName, setSshUserName] = useState("");
    const [sshPassword, setSshPassword] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [sshPort, setSshPort] = useState("");
    const [apiPort, setApiPort] = useState("");
    const [deviceType, setDeviceType] = useState(0);
    const [deviceVendor, setDeviceVendor] = useState(0);

    const [oldPasswordHelperText, setOldPasswordHelperText] = useState('');
    const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
    const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');

    const [locationNameHelperText, setLocationNameHelperText] = useState("");
    const [cityHelperText, setCityHelperText] = useState("");
    const [stateHelperText, setStateHelperText] = useState("");
    const [countryHelperText, setCountryHelperText] = useState("");


    const [circuitIdHelperText, setCircuitIdHelperText] = useState("");
    const [publicIpHelerText, setPublicIpHelerText] = useState("");
    const [InternalIpHelerText, setInterIpHelerText] = useState("");
    const [vendorNameHelperText, setVendorNameHelperText] = useState("");
    const [vendorEmailHelperText, setVendorEmailHelperText] = useState("");
    const [provisionedSpeedHelperText, setProvisionedSpeedHelperText] = useState("");
    const [priceHelperText, setPriceHelperText] = useState("");

    const [deviceIpHelerText, setDeviceIpHelerText] = useState("");
    const [snmpStrHelerText, setSnmpStrHelerText] = useState("");
    const [apiKeyHelperText, setApiKeyHelperText] = useState("");
    const [sshUsernameHelperText, setSshUsernameHelperText] = useState("");
    const [sshPassHelperText, setSshPassHelperText] = useState("");
    const [sshPortHelperText, setSshPortHelperText] = useState("");
    const [apiPortHelperText, setApiPortHelperText] = useState("");

    



    const [showDialog, setShowDialog] = useState(false);

    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()@#$%^:;&+=!])[A-Za-z\d@():;#$%^&+=!]{8,}$/;
    const oldPassRegex = /^\S+$/;
    
    const locationRegex =/^[a-zA-Z][a-zA-Z0-9-_\s]*$/;
    const businessHrRegex = /^(?:[0-9]|1[0-9]|2[0-4])$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const latencyRegex = /^\d+(\.\d+)?$/;
    const packetLossRegex =  /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;

    const errorRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const provisionedSpeedRegex = /^\d+$/;
    const priceRegex = /^\d+$/;
    const circuitIdRegex = /^[^\s].*$/;

    const snmpStrRegex = /^[^\s].*$/;
    const portRegex = /^[0-9]+$/;
  

    // useEffect(() => {
    //     const linkFormString = localStorage.getItem('LinkFormData');
    //     if (linkFormString) {
    //         const linkForm = JSON.parse(linkFormString);

    //         for (let i = 0; i < linkForm.ispLocations.length; i += 1) {
    //             if (linkForm.ispLocations[i].location_id === locationName) {
    //                 setValue2(linkForm.ispLocations[i].location_id)
    //             }
    //         }
    //     }
    // }, []);

    const navigate = useNavigate();
  
    const handleGoToDashboard = () => {
      navigate("/home/dashboard");
    };

    const isStepOptional = (step) => {
        return step === 4;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };


    const handleDialogClose = () => {
        setShowDialog(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setPage((currPage) => currPage - 1);
        // console.log(page, activeStep, deviceStatus);
    };

    const handleDialogSubmit = async () => {
        try {
          await UpdateOnboardStatusAPICall(setOnboradStatus);
          await ChangePasswordRestAPICall(oldPassword, newPassword, confirmPassword, setPasswordStatus);
          await AddSingleLocationRestApi(locationName, businessHrStart, businessHrEnd, remarks, city, state, country, managerId, managerName, managerContact, managerEmail, escMail, latency, jitter, packetLoss, setSiteStatus);
          await PushSingleISPInfo(publicIp, internalIp, circuitId, locationName, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, price, city, setLinkStatus);
      
          const siteId = localStorage.getItem("siteId");
          await AddEdgeDeviceApiCall(siteId, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setDeviceStatus, setSeverStatus, setOpenAddEd);
      
          setShowDialog(false);
          setActiveStep((prevActiveStep) => prevActiveStep);
          setPage((currPage) => currPage);
        //   console.log(page, activeStep, deviceStatus);
        } catch (error) {
            console.log(error);
          // Handle errors here
        }
      };
      
      const handleSubmit = () => {
        handleDialogSubmit();
        handleGoToDashboard();
      }


    const handleNext = () => {
        let isValid = true;

        // Validate fields based on the current page
        switch (page) {
            case 0:
                if (!oldPassword.match(oldPassRegex)) {
                    isValid = false;
                    setOldPasswordHelperText("Password Should be Valid");
                    break;
                }
                if (!newPassword.match(passwordRegex)) {
                    isValid = false;
                    setNewPasswordHelperText("Password must contain at least 8 characters, one letter, one number and one special character");
                    break;
                }
                if (!confirmPassword.match(passwordRegex)) {
                    isValid = false;
                    setConfirmPasswordHelperText("Password must contain at least 8 characters, one letter, one number and one special character");
                    break;
                }
                break;
            case 1:
                // Validate CreateSite form fields
                if (!locationName.match(locationRegex)) {
                    isValid = false;
                    setLocationNameHelperText("Location Should be Valid");
                    break;
                }
                if (!city.match(cityRegex)) {
                    isValid = false;
                    setCityHelperText("Enter valid city name");
                    break;
                }
                if (!state.match(cityRegex)) {
                    isValid = false;
                    setStateHelperText("Enter valid state name");
                    break;
                }
                if (!country.match(country)) {
                    isValid = false;
                    setCountryHelperText("Enter valid country name");
                    break;
                }
                break;

            case 2:
                // Validate CreateLink form fields
                if (!circuitId.match(circuitIdRegex)) {
                    isValid = false;
                    setCircuitIdHelperText("Circuit ID Should be Valid");
                    break;
                }
                if (!vendorName.match(locationRegex)) {
                    isValid = false;
                    setVendorNameHelperText("Enter valid vendor name");
                    break;
                }
                if (!vendorEmail.match(emailRegex)) {
                    isValid = false;
                    setVendorEmailHelperText("Enter valid state name");
                    break;
                }
                if (!publicIp.match(errorRegex)) {
                    isValid = false;
                    setPublicIpHelerText("Enter valid IP Address");
                    break;
                }
                if (!internalIp.match(errorRegex)) {
                    isValid = false;
                    setInterIpHelerText("Enter valid IP Address");
                    break;
                }
                break;
        
            case 3:
                // Validate CreateEdgeDevice form fields
                if (!deviceIp.match(errorRegex)) {
                    isValid = false;
                    setDeviceIpHelerText("Device IP Should be Valid");
                    break;
                }
                if (!snmpStr.match(snmpStrRegex)) {
                    isValid = false;
                    setSnmpStrHelerText("Enter valid SNMP String");
                    break;
                }
                if (!apiKey.match(snmpStrRegex)) {
                    isValid = false;
                    setApiKeyHelperText("Enter valid API Key");
                    break;
                }
                if (!sshUserName.match(locationRegex)) {
                    isValid = false;
                    setSshUsernameHelperText("Enter valid SSH user name");
                    break;
                }
                if (!sshPassword.match(snmpStrRegex)) {
                    isValid = false;
                    setSshPassHelperText("Enter valid SSH password");
                    break;
                }
                if (!sshPort.match(portRegex)) {
                    isValid = false;
                    setSshPort("Enter valid SSH Port number");
                    break;
                }
                if (!apiPort.match(portRegex)) {
                    isValid = false;
                    setApiPortHelperText("Enter valid Port number");
                    break;
                }
                break;

            case 5:
                setShowDialog(true);
                break;
            
            default:
                break;
        }


        if (isValid) {
            // Proceed to the next step
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }


            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);

            // if (page === steps.length - 1) {
            //     alert('Form Submitted');
            // }
            setPage((currPage) => currPage + 1);
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setPage((currPage) => currPage - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
        if (page === steps.length - 1) {
            alert('Form Submitted');
        }
        setPage((currPage) => currPage + 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const formDisplay = () => {
        if (page === 0) {
            return <CreateNewPassowrd
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                passwordStatus={passwordStatus}
                setPasswordStatus={setPasswordStatus} />;
        }
        if (page === 1) {
            return <CreateSite
                locationName={locationName} setLocationName={setLocationName} businessHrStart={businessHrStart} setBusinessHrStart={setBusinessHrStart}
                businessHrEnd={businessHrEnd} setBusinessHrEnd={setBusinessHrEnd} remarks={remarks} setRemarks={setRemarks}
                city={city} setCity={setCity} state={state} setState={setState} country={country} setCountry={setCountry} siteStatus={siteStatus} setSiteStatus={setSiteStatus}
                escMail={escMail} setEscMail={setEscMail} managerId={managerId} setManagerId={setManagerId} managerName={managerName} setManagerName={setManagerName}
                managerContact={managerContact} setManagerContact={setManagerContact} managerEmail={managerEmail} setManagerEmail={setManagerEmail}
                latency={latency} setLatency={setLatency} jitter={jitter} setJitter={setJitter} packetLoss={packetLoss} setPacketLoss={setPacketLoss}
            />;
        }
        if (page === 2) {
            return <CreateLink
                ispLocations={ispLocations} setIspLocations={setIspLocations} value={value} setValue={setValue} circuitId={circuitId} setCircuitId={setCircuitId} vendorName={vendorName} setVendorName={setVendorName}
                defaultGateway={defaultGateway} setDefaulGateway={setDefaulGateway} publicIp={publicIp} setPublicIp={setPublicIp} internalIp={internalIp} setInternalIp={setInternalIp} vendorEmail={vendorEmail} setVendorEmail={setVendorEmail}
                promiseUptime={promiseUptime} setPromisedUptime={setPromisedUptime} resolutionTime={resolutionTime} setResolutionTime={setResolutionTime} linkStatus={linkStatus} setLinkStatus={setLinkStatus}
                link={link} setLink={setLink} price={price} setPrice={setPrice} provisionedSpeed={provisionedSpeed} setProvisionedSpeed={setProvisionedSpeed} city={city} setCity={setCity} vendors={vendors} setVendors={setVendors}
            />;
        }
        if (page === 3) {
            return <CreateEdgeDevice
                locationName={locationName} setLocationName={setLocationName} locationId={locationId} setLocationId={setLocationId} deviceId={deviceId} setDeviceId={setDeviceId}
                deviceIp={deviceIp} setDeviceIp={setDeviceIp} snmpStr={snmpStr} setSnmpStr={setSnmpStr} sshUserName={sshUserName} setSshUserName={setSshUserName}
                sshPassword={sshPassword} setSshPassword={setSshPassword} apiKey={apiKey} setApiKey={setApiKey} sshPort={sshPort} setSshPort={setSshPort} apiPort={apiPort} setApiPort={setApiPort}
                deviceType={deviceType} setDeviceType={setDeviceType} deviceVendor={deviceVendor} setDeviceVendor={setDeviceVendor} deviceStatus={deviceStatus} setDeviceStatus={setDeviceStatus}
            />;
        }
        if (page === 4) {
            return <ConnectToProbe />;
        }
        if (page === 5) {
            return <ReviewPage />
        }
        
    };

    return (
        <div>
            {/* <button onClick={() => setIsOpen(true)}>
                Open Pop-up
            </button> */}

            <ReactModal
                isOpen={isOpen}
                contentLabel="Example Modal"
            >
                <Box sx={{ width: '100%' }} >
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label}{...stepProps}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {formDisplay()}

                    {passwordStatus === 1 && siteStatus === 1 && linkStatus === 1 && deviceStatus === 1 && <Alert severity="success" onClose={() => { setPasswordStatus(0) }} >Information is added Successfully</Alert>}
                    {passwordStatus === -1 && <Alert severity="error" onClose={() => { setPasswordStatus(0) }} >Error in Changing Passowrd</Alert>}
                    {siteStatus === -1 && <Alert severity="error" onClose={() => { setSiteStatus(0) }} >Error in Creating Site</Alert>}
                    {linkStatus === -1 && <Alert severity="error" onClose={() => { setLinkStatus(0) }} >Error in Creating Link</Alert>}
                    {deviceStatus === -1 && <Alert severity="error" onClose={() => { setDeviceStatus(0) }} >Error in Adding Edge Device</Alert>}
                    
        
                    {activeStep === steps.length ? (
                        <fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                            </Box>
                        </fragment>
                    ) : (
                        <fragment>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    style={{ width: '200px', height: '50px', margin: "20px" }}
                                    variant="contained"
                                    sx={{ fontSize: '1rem' }}
                                    disabled={page === 0}
                                    onClick={handleBack}

                                >
                                    {'<<   Prev'}
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {isStepOptional(activeStep) && (
                                    <Button color="inherit" style={{ width: '200px', height: '50px', margin: '20px' }}
                                        variant="contained"
                                        sx={{ fontSize: '1rem' }} onClick={handleSkip} >
                                        Skip
                                    </Button>
                                )}

                                <Button onClick={handleNext} style={{ width: '200px', height: '50px', margin: "20px" }}
                                    variant="contained"
                                    sx={{ fontSize: '1rem' }}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next  >>'}
                                </Button>
                            </Box>
                        </fragment>
                    )}
                </Box>
                <Dialog open={showDialog} >
                    <DialogTitle>Submit Form</DialogTitle>
                    <DialogContent>
                        <p>Do you want to complete onboarding ?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </ReactModal>
        </div>
    );
}
