import axios from 'axios';

export const DeleteApplicationAPICall = async (appId, appType, appUrl, setDeleteStatus, setSeverStatus,setDeleteStatusMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "delete_app_data_msp.php" : "delete_app_data.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "app_id": appId,
        "app_type": appType, 
        "app_url": appUrl
    });

    const mspPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "msp_client_id": localStorage.getItem("cust_id"),
      "reg_client_id": localStorage.getItem("reg_cust_id"),
      "app_id": appId,
      "app_type": appType, 
      "app_url": appUrl
    });
  
  
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setDeleteStatus(1);
        setSeverStatus("success");
        setDeleteStatusMessage(response.data.message)
      }
      else {
        setDeleteStatus(-1);
        setSeverStatus("error");
        setDeleteStatusMessage(response.data.message)
      }
    })
  }
  