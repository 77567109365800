import axios from 'axios';

// API CALL CODE HERE

const LogOutRestAPICall = async (setLogoutStatus) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/logout.php`;

    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
    });
  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 1) {
        setLogoutStatus(true);
      }
    });
}

export default LogOutRestAPICall;

