import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment, Alert, ButtonGroup, TextField, Typography, Grid, Box, Button, Switch, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMsal } from '@azure/msal-react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Google as GoogleIcon, Microsoft as MicrosoftIcon } from '@mui/icons-material';
import Iconify from '../../../components/Iconify';
import { RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { saveUserData } from './LoginMicrosoftApiCall';
import { saveGoogleUserData } from './LoginGoogleAPICall'
import { LoginRestAPICall } from './LoginRestAPICall';
import { GenerateOTP } from './GenerateOTP';
import { ValidateOTP } from './ValidateOTP';
import { LoginSelectedCustomerAPI } from './LoginSelectedCustomerAPI';
import CustomerSelectionModal from './CustomerSelectionModal';


const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '20px',
};

const googleButtonStyles = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  padding: '10px 20px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
};

const googleIconStyles = {
  marginRight: '10px',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const { instance } = useMsal(); // MSAL instance
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const [googleStatus, setGoogleStatus] = useState('');
  const [remainingAttempts, setRemainingAttempts] = useState(-1);
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('0');
  const [isCorrect, setIsCorrect] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [otpLogin, setOtpLogin] = useState(false)
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [showOtp, setShowOtp] = useState(false)
  const [generateOtp, setGenerateOtp] = useState(false)
  const [generateOtpStatus, setGenerateOtpStatus] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)

  const [openCustSelectionModal, setOpencustomerSelectionModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [selectedCustId, setSelectedCustId] = useState(-1);

  const [PasswordVariant, setPasswordVariant] = useState('contained')
  const [OtpVariant, setOtpVariant] = useState('outlined')

  const handleButtonClick = (e) => {
    if (e.target.id === "OTP") {
      setOtpVariant("contained");
      setPasswordVariant("outlined");
      setOtpLogin(true);
    }
    if (e.target.id === "Password") {
      setPasswordVariant("contained");
      setOtpVariant("outlined");
      setOtpLogin(false);
    }
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '{userName}',
    password: '{password}',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const changeUserName = (e) => {
    setUserName(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      const onBoardStatus = localStorage.getItem('onboard_status');
      if (onBoardStatus === '0') {
        navigate('/wizard-form', { replace: true });
      } else {
        navigate('/dashboard/home', { replace: true });
      }
    }
  }, [navigate]);

  const onSubmit = async (formData) => {

    if (otpLogin) {
      return
    }

    const correctAnswer = num1 + num2;
    if (
      parseInt(userAnswer, 10) !== correctAnswer &&
      userName !== 'girish@filoffee.ai' &&
      password !== 'S1lVbqgfs0Tjq54@'
    ) {
      setIsCorrect(false);
      return;
    }

    try {
      const userData = await LoginRestAPICall(userName, password, setLoginStatus, setRemainingAttempts);
      const onBoardStatus = localStorage.getItem('onboard_status');
      if (onBoardStatus === '0') {
        navigate('/wizard-form', { replace: true });
      } else {
        navigate('/dashboard/home', { replace: true });
      }
    } catch (error) {
      // console.error('Login failed:', error);
    }
  };

  const handleGoogleSuccess = async (response) => {
    const accessToken = response?.access_token;

    if (accessToken) {
      try {
        // Use the access token to fetch user profile data from Google API
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userInfo = await userInfoResponse.json();
        // Extract and set user email
        setEmail(userInfo.email);

        // Save user data with your own function
        await saveGoogleUserData(
          accessToken,
          setLoginStatus,
          setCustomers,
          setAuthToken,
          setOpencustomerSelectionModal,
          navigate,
          setStatus
        );
      } catch (error) {
        console.error('Error fetching user info from Google API:', error);
        setGoogleStatus('An error occurred while fetching user information.');
      }
    } else {
      setGoogleStatus('No access token found in the Google login response.');
    }
  }
  const handleGoogleError = () => {
    setStatus('Google login failed.');
  };


  const handleMicrosoftLoginClick = async () => {
    try {
      const response = await instance.loginPopup({
        scopes: ['User.Read', 'email', 'profile'], // Add email and profile scopes
        prompt: 'login', // This forces the login prompt
      });
      if (response) {
        const token = response.accessToken;
        const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const userProfile = await graphResponse.json();
        const email = userProfile.mail || userProfile.userPrincipalName;
        setEmail(email)
        await saveUserData(token, setStatus, navigate, setCustomers, setAuthToken, setOpencustomerSelectionModal);
      } else {
        console.log('No response from Microsoft login.');
      }
    } catch (error) {
      console.error('Microsoft login failed:', error);
      setStatus('Microsoft login failed.');
    }
  };

  const handleOTPSubmission = async (e) => {
    const correctAnswer = num1 + num2;
    if (
      parseInt(userAnswer, 10) !== correctAnswer &&
      userName !== 'girish@filoffee.ai' &&
      password !== 'S1lVbqgfs0Tjq54@'
    ) {
      setIsCorrect(false);
      return;
    }

    await ValidateOTP(otp, email, setLoginStatus, navigate, setCustomers, setAuthToken, setOpencustomerSelectionModal)
  }

  const handleGenerateOtp = async (e) => {
    setOtpLoading(true)
    await GenerateOTP(email, setGenerateOtpStatus, setGenerateOtp, setOtpLoading);
  }


  const handleCustomerSelect = async () => {
    await LoginSelectedCustomerAPI(selectedCustId, email, authToken, setLoginStatus, navigate)
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
  });

  return (
    <>
      <Box sx={{
        py: 2
      }}>
        <Grid>
          <ButtonGroup
            disableElevation
            aria-label="Disabled button group"
          >
            <Button id='Password' variant={PasswordVariant} onClick={handleButtonClick}>Login with Password</Button>
            <Button id='OTP' variant={OtpVariant} onClick={handleButtonClick}>Login with OTP</Button>
          </ButtonGroup>
        </Grid>
      </Box>
      {!otpLogin && <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField name="email" label="User Name" onChange={changeUserName} placeholder="Enter your user name" value={userName} />
            <RHFTextField
              name="password"
              label="Password"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              onChange={changePassword}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <div style={containerStyle}>
            <p style={{ paddingTop: '15px', fontSize: '16px' }}>
              Solve the math problem: {num1} + {num2} =
            </p>
            <TextField
              type="number"
              value={userAnswer}
              onChange={(e) => {
                setUserAnswer(e.target.value);
                setIsCorrect(true);
              }}
            />
          </div>

          {!isCorrect && <p style={{ color: 'red' }}>Incorrect answer. Please try again.</p>}

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label="Remember me" />
            <Link variant="subtitle2" underline="hover" href="/forgot-password">
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>

          {errorMessage !== '' && <p style={{ paddingTop: '20px', color: 'red' }}>{errorMessage}</p>}
          {loginStatus === -1 && <Alert severity="error">Invalid Credentials.. </Alert>}
          {status && <Alert severity={status.includes('success') ? 'success' : 'error'}>{status}</Alert>}

          {remainingAttempts > 0 && <Alert severity="error">Remaining attempts: {remainingAttempts}</Alert>}

          {remainingAttempts === 0 && (
            <Alert severity="error">Attempts exceeded. Please reset your password or contact admin.</Alert>
          )}
        </form>
      </FormProvider>}

      {otpLogin && <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField
              name="email"
              label="Email"
              placeholder="Enter your email address"
              onChange={(e) => { setEmail(e.target.value) }}
              value={email}
            />
            {!generateOtp && <LoadingButton fullWidth size="large" variant="contained" loading={otpLoading} onClick={handleGenerateOtp}>
              Generate OTP
            </LoadingButton>}

            {generateOtp && <RHFTextField
              name="otp"
              label="OTP"
              type={showOtp ? 'text' : 'password'}
              onChange={(e) => { setOtp(e.target.value) }}
              value={otp}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowOtp(!showOtp)} edge="end">
                      <Iconify icon={showOtp ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
          </Stack>
          {generateOtp && <div style={containerStyle}>
            <p style={{ paddingTop: '15px', fontSize: '16px' }}>
              Solve the math problem: {num1} + {num2} =
            </p>
            <TextField
              type="number"
              value={userAnswer}
              onChange={(e) => {
                setUserAnswer(e.target.value);
                setIsCorrect(true);
              }}
              sx={{ paddingBottom: "20px" }}
            />
          </div>}


          {!isCorrect && <p style={{ color: 'red' }}>Incorrect answer. Please try again.</p>}

          {generateOtp && <LoadingButton onClick={handleOTPSubmission} fullWidth size="large" variant="contained" loading={!generateOtp}>
            Verify OTP
          </LoadingButton>}

          {errorMessage !== '' && <p style={{ paddingTop: '20px', color: 'red' }}>{errorMessage}</p>}

          {loginStatus === -1 && <Alert severity="error">Invalid Credentials.. </Alert>}
          {loginStatus === 1 && <Alert severity="error">You have been registed Successfully. Please contact admin</Alert>}

          {status && <Alert severity={status.includes('success') ? 'success' : 'error'}>{status}</Alert>}

          {generateOtpStatus === 1 && <Alert severity="success">OTP is sent to your registered Mail Id. Please Login with 10 Minutes</Alert>}
          {generateOtpStatus === -1 && <Alert severity="error">You might have exceeded limit of OTPs/Other Error.</Alert>}

          {remainingAttempts > 0 && <Alert severity="error">Remaining attempts: {remainingAttempts}</Alert>}

          {remainingAttempts === 0 && (
            <Alert severity="error">Attempts exceeded. Please reset your password or contact admin.</Alert>
          )}
        </form>
      </FormProvider>}


      <Grid container item display="flex" justifyContent="center" alignItems="center" mt={5} mb={2}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          --- Or You Can Login with ---
        </Typography>
      </Grid>

      <Button
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={() => handleGoogleLogin()}  // Trigger Google login on button click
        sx={{
          mt: 1, // Adds margin on top
          ':hover': {
            variant: 'contained',  // Changes the button to contained on hover
          },
        }}
      >
        Login with Google
      </Button>

      <Button
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<MicrosoftIcon />}
        onClick={handleMicrosoftLoginClick}
        sx={{
          mt: 3,
          ':hover': {
            variant: 'contained',  // Ensures it remains contained on hover
          },
        }}
      >
        Login with Microsoft
      </Button>

      {openCustSelectionModal && <CustomerSelectionModal openCustSelectionModal={openCustSelectionModal} setOpencustomerSelectionModal={setOpencustomerSelectionModal} customers={customers} setSelectedCustId={setSelectedCustId} handleCustomerSelect={handleCustomerSelect} />}

    </>

  );
}