import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    Typography,
    Divider,
    InputAdornment,
    FormHelperText
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';



// Api Import
import AddNewVendorApiCall from './AddNewVendorApiCall';
import { GetVendorCustomTagDetailApiCall } from './GetVendorCustomTagDetailApiCall';



export default function AddNewVendorDialog(props) {
    const { openAddVendorDialog, setOpenAddVendorDialog, setAddVendorStatus, setAddVendorMessage } = props;

    const [vendorName, setVendorName] = useState("");
    const [vendorAddress, setVendorAddress] = useState("");
    const [vendorContact, setVendorContact] = useState("");
    const [helpDeskEmail, setHelpDeskEmail] = useState("");
    const [escalationLevel1Mail, setEscalationLevel1Mail] = useState("");
    const [escalationLevel2Mail, setEscalationLevel2Mail] = useState("");
    const [vendorCity, setVendorCity] = useState("");
    const [promisedUptime, setPromisedUptime] = useState("98");
    const [resolutionTime, setResolutionTime] = useState("4");

    const [vendorNameHelperText, setVendorNameHelperText] = useState("");
    const [vendorAddressHelperText, setVendorAddressHelperText] = useState("");
    const [vendorContactHelperText, setVendorContactHelperText] = useState("");
    const [helpDeskEmailHelper, setHelpDeskEmailHelper] = useState("");
    const [escalationLevel1MailHelper, setEscalationLevel1MailHelper] = useState("");
    const [escalationLevel2MailHelper, setEscalationLevel2MailHelper] = useState("");
    const [vendorCityHelper, setVendorCityHelper] = useState("");
    const [promisedUptimeHelper, setPromisedUptimeHelper] = useState("");
    const [resolutionTimeHelper, setResolutionTimeHelper] = useState("");

    const [customtagsData, setCustomTagsData] = useState([]);
    const [dynamicFieldErrors, setDynamicFieldErrors] = useState({});
    const [Vendor, setVendor] = useState("vendor")
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);


    const vendorNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const vendorAddressRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*[A-Za-z\d]$/;
    const vendorContactRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*$/;
    const uptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
    const resolutionTimeRegex = /^(?:\d{1,2}|100)$/;
    // console.log("customtagsdata;", customtagsData);

    const handleOnClose = () => {
        setOpenAddVendorDialog(false);
    }

    const _GetVendorCustomTagDetailApiCall = async () => {
        await GetVendorCustomTagDetailApiCall(setCustomTagsData, Vendor)
        // console.log("Custom tags data fetched:", customtagsData);
    }
    useEffect(() => {
        _GetVendorCustomTagDetailApiCall();

    }, [])

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "vendor_name": if (!e.target.value.match(vendorNameRegex)) {
                setVendorNameHelperText("Vendor Name Should be Valid");
            }
            else {
                setVendorNameHelperText("");

            }
                setVendorName(e.target.value);
                break;

            case "vendor_address": if (!e.target.value.match(vendorAddressRegex)) {
                setVendorAddressHelperText("Vendor Address Should be Valid");
            }
            else {
                setVendorAddressHelperText("");

            }
                setVendorAddress(e.target.value);
                break;

            case "vendor_contact": if (!e.target.value.match(vendorContactRegex)) {
                setVendorContactHelperText("Phone Number should be Valid");
            }
            else {
                setVendorContactHelperText("");

            }
                setVendorContact(e.target.value);
                break;

            case "help_desk_email": if (!e.target.value.match(emailRegex)) {
                setHelpDeskEmailHelper("Enter Valid Email ID");
            }
            else {
                setHelpDeskEmailHelper("");

            }
                setHelpDeskEmail(e.target.value);
                break;

            case "escalation_level1_mail": if (!e.target.value.match(emailRegex)) {
                setEscalationLevel1MailHelper("Enter Valid Email ID");
            }
            else {
                setEscalationLevel1MailHelper("");

            }
                setEscalationLevel1Mail(e.target.value);
                break;


            case "escalation_level2_mail": if (!e.target.value.match(emailRegex)) {
                setEscalationLevel2MailHelper("Enter Valid Email ID");
            }
            else {
                setEscalationLevel2MailHelper("");

            }
                setEscalationLevel2Mail(e.target.value);
                break;

            case "vendor_city": if (!e.target.value.match(cityRegex)) {
                setVendorCityHelper("Enter Valid City Name");
            }
            else {
                setVendorCityHelper("");

            }
                setVendorCity(e.target.value);
                break;

            case "promised_uptime": if (!e.target.value.match(uptimeRegex)) {
                setPromisedUptimeHelper("Enter Valid Promised Uptime");
            }
            else {
                setPromisedUptimeHelper("");

            }
                setPromisedUptime(e.target.value);
                break;

            case "resolution_time": if (!e.target.value.match(resolutionTimeRegex)) {
                setResolutionTimeHelper("Enter Valid Resolution Time");
            }
            else {
                setResolutionTimeHelper("");

            }
                setResolutionTime(e.target.value);
                break;

            default: setPromisedUptime(e.target.value);
                break;

        }
    };
    const validateDynamicFields = () => {
        let valid = true;
        const errors = {};

        customtagsData.forEach(field => {
            const { tags, defaultValue, dataType, isMandatory } = field;

            // Check for mandatory fields only when isMandatory is 1
            if (isMandatory === 1 && (!defaultValue || defaultValue.trim() === "")) {
                errors[tags] = `${tags} is required`;
                valid = false;
            }

            // Data type validation if the field is not empty
            if (isMandatory === 1 && defaultValue && dataType) {
                switch (dataType.toUpperCase()) {
                    case 'Whole Number': {
                        if (Number.isNaN(Number(defaultValue)) || !Number.isInteger(Number(defaultValue))) {
                            errors[tags] = `Please enter valid ${dataType} only`;
                            valid = false;
                        }
                        break;
                    }
                    case 'Decimal': {
                        if (Number.isNaN(Number(defaultValue)) || !parseFloat(defaultValue)) {
                            errors[tags] = `Please enter valid ${dataType} only`;
                            valid = false;
                        }
                        break;
                    }

                    case 'STRING': {
                        if (defaultValue.trim() === "") {
                            errors[tags] = "";
                        } else if (!/^[A-Za-z0-9\s]+$/.test(defaultValue)) {
                            errors[tags] = `Please enter valid ${dataType} (letters, numbers, and spaces only)`;
                            valid = false;
                        }
                        break;
                    }
                    case 'SELECT': {
                        if (isMandatory === 1 && (!defaultValue || defaultValue.trim() === "")) {
                            errors[tags] = `${tags} is required. Please select an option.`;
                            valid = false;
                        }
                        break;
                    }
                    case 'Date': {
                        if (!defaultValue || defaultValue.trim() === "") {
                            errors[tags] = `${tags} is required`; // Error if field is empty
                            valid = false;
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
        });

        setDynamicFieldErrors(errors);
        return valid;
    };

    const handleDynamicFieldChange = (event, index) => {
        const newValue = event.target.value;

        setCustomTagsData(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                defaultValue: newValue
            };

            const field = updatedData[index];
            const { tags, dataType, isMandatory } = field;
            const errors = { ...dynamicFieldErrors };

            // Check for mandatory fields
            if (isMandatory && (!newValue || newValue.trim() === "")) {
                errors[tags] = `${tags} is required`;
            } else {
                delete errors[tags];
            }

            // Data type validation
            if (dataType) {
                switch (dataType.toUpperCase()) {
                    case 'WHOLE NUMBER': {
                        if (Number.isNaN(Number(newValue)) || !Number.isInteger(Number(newValue))) {
                            errors[tags] = `Please enter valid ${dataType} only`;
                        } else {
                            delete errors[tags];
                        }
                        break;
                    }

                    case 'DECIMAL': {
                        if (newValue && (Number.isNaN(Number(newValue)) || Number.isNaN(parseFloat(newValue)))) {
                            errors[tags] = `Please enter valid ${dataType} only`;
                        } else {
                            delete errors[tags];
                        }
                        break;
                    }

                    case 'STRING': {
                        // Clear the error if the field is empty
                        if (newValue.trim() === "") {
                            errors[tags] = "";
                        } else if (/^[A-Za-z0-9\s]+$/.test(newValue)) {
                            delete errors[tags]; // Valid string
                        } else {
                            errors[tags] = "Please enter a valid String (letters, numbers, and spaces only).";
                        }
                        break;
                    }

                    case 'DATE': {
                        // Validate that date is not empty
                        if (!newValue) {
                            errors[tags] = `${tags} is required`;
                        } else {
                            delete errors[tags];
                        }
                        break;
                    }
                    case 'SELECT': {
                        if (isMandatory === 1 && (!newValue || newValue.trim() === "")) {
                            errors[tags] = `${tags} is required. Please select an option.`;
                        } else {
                            delete errors[tags];
                        }
                        break;
                    }

                    default:
                        break;
                }
            }

            setDynamicFieldErrors(errors);
            return updatedData;
        });
    };



    const handleOnSave = (e) => {

        if (!vendorName.match(vendorNameRegex)) {
            setVendorNameHelperText("Vendor Name Should be Valid");
            return;
        }


        if (!vendorAddress.match(vendorAddressRegex)) {
            setVendorAddressHelperText("Vendor Address Should be Valid");
            return;
        }


        // if (!vendorContact.match(vendorContactRegex)) {
        //     setVendorContactHelperText("Phone Number should be Valid");
        //     return;
        // }


        if (!helpDeskEmail.match(emailRegex)) {
            setHelpDeskEmailHelper("Invalid email address");
            return;
        }


        if (!vendorCity.match(cityRegex)) {
            setVendorCityHelper("Enter Correct City Name");
            return;
        }


        if (promisedUptime.trim() === "") {
            setPromisedUptimeHelper("Enter Valid Promised Uptime");
            return;
        }


        if (!resolutionTime.match(resolutionTimeRegex)) {

            setResolutionTimeHelper("Enter Valid Resolution Time");
            return;
        }
        if (!validateDynamicFields()) {
            // console.log('Validation failed, not saving.'); 
            return;
        }

        const vendorData = {
            vendor_name: vendorName,
            vendor_address: vendorAddress,
            vendor_contact: vendorContact,
            helpdesk_email: helpDeskEmail,
            escalation_level1_mail: escalationLevel1Mail,
            escalation_level2_mail: escalationLevel2Mail,
            vendor_city: vendorCity,
            promised_uptime: promisedUptime,
            resolution_time: resolutionTime,
        };

        const customFields = {};
        if (Array.isArray(customtagsData)) {
            customtagsData.forEach(field => {
                customFields[field.tags] = field.defaultValue;
            });
        }


        // Call the API with the params
        // AddNewVendorApiCall( customtagsData, vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime ,setAddVendorStatus, setAddVendorMessage);
        AddNewVendorApiCall(vendorData, customFields, setAddVendorStatus, setAddVendorMessage);

        handleOnClose();

    }
    return (
        <>
            <Dialog open={openAddVendorDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add New Vendor
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Vendor Name" id="vendor_name" value={vendorName} onChange={handleOnChange} helperText={vendorNameHelperText} error={!!vendorNameHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Address" id="vendor_address" value={vendorAddress} onChange={handleOnChange} helperText={vendorAddressHelperText} error={!!vendorAddressHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Contact Number" id="vendor_contact" value={vendorContact} onChange={handleOnChange} helperText={vendorContactHelperText} error={!!vendorContactHelperText} variant="outlined" style={{ width: "400px" }}  />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Help Desk Email" id="help_desk_email" value={helpDeskEmail} onChange={handleOnChange} helperText={helpDeskEmailHelper} error={!!helpDeskEmailHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-1 Email" id="escalation_level1_mail" value={escalationLevel1Mail} onChange={handleOnChange} helperText={escalationLevel1MailHelper} error={!!escalationLevel1MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-2 Email" id="escalation_level2_mail" value={escalationLevel2Mail} onChange={handleOnChange} helperText={escalationLevel2MailHelper} error={!!escalationLevel2MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="City" id="vendor_city" value={vendorCity} onChange={handleOnChange} helperText={vendorCityHelper} error={!!vendorCityHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Promised Uptime" id="promised_uptime" value={promisedUptime} onChange={handleOnChange} helperText={promisedUptimeHelper} error={!!promisedUptimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Resolution Time" id="resolution_time" value={resolutionTime} onChange={handleOnChange} helperText={resolutionTimeHelper} error={!!resolutionTimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>

                        {customtagsData.length > 0 && (<Divider sx={{ paddingTop: "20px" }} />)}
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {customtagsData.length > 0 && (
                                    <Typography variant='h5'>
                                        Tags
                                    </Typography>
                                )}
                            </Grid>
                            {/* {
                            customtagsData.map((field, index) => {

                                const dateValue = field.defaultValue ? new Date(field.defaultValue) : null;
                                // console.log(`Field: ${field.tags}, isMandatory: ${field.isMandatory}, dateValue:`, dateValue);
                                const defaultDateValue = field.defaultValue ? dayjs(field.defaultValue) : null;


                                return (
                                    <Grid item xs={6} key={field.tags}>
                                        {field.dataType === 'DATE' ? (

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label={field.tags}
                                                    value={defaultDateValue && defaultDateValue.isValid() ? defaultDateValue : null}
                                                    style={{ width: '400px' }}
                                                    onChange={(newValue) => {
                                                        if (newValue && newValue.isValid()) {
                                                            const formattedDateTime = newValue.format('YYYY-MM-DD HH:mm:ss');
                                                            handleDynamicFieldChange({ target: { value: formattedDateTime } }, index);
                                                        } else {
                                                            handleDynamicFieldChange({ target: { value: '' } }, index);
                                                        }
                                                    }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    sx={{ width: '400px' }}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            required: field.isMandatory === 1,
                                                            error: !!dynamicFieldErrors[field.tags],
                                                            helperText: dynamicFieldErrors[field.tags],
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )  : field.dataType === 'Select' ? (
                                            <FormControl fullWidth style={{ width: '400px' }}>
                                                <InputLabel>{field.tags}</InputLabel>
                                                <Select
                                                    value={selectOptions.includes(field.defaultValue) ? field.defaultValue : ""}
                                                    onChange={(e) => handleDynamicFieldChange(e, index)}
                                                    required={field.isMandatory === 1}
                                                    error={!!dynamicFieldErrors[field.tags]}
                                                >
                                                    {selectOptions.map((option, optIndex) => (
                                                        <MenuItem key={optIndex} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {dynamicFieldErrors[field.tags] && (
                                                    <FormHelperText error>{dynamicFieldErrors[field.tags]}</FormHelperText>
                                                )}
                                            </FormControl>
                                         
                                            ) : (
                                            <TextField
                                                label={field.tags}
                                                value={field.defaultValue}
                                                onChange={(e) => handleDynamicFieldChange(e, index)}
                                                fullWidth
                                                required={field.isMandatory === 1}
                                                error={!!dynamicFieldErrors[field.tags]}
                                                helperText={dynamicFieldErrors[field.tags]}
                                                style={{ width: '400px' }}
                                            />
                                        )}
                                    </Grid>
                                );
                            })
                         } */}
                            {
                                customtagsData.map((field, index) => {
                                    const dateValue = field.defaultValue ? new Date(field.defaultValue) : null;
                                    const defaultDateValue = field.defaultValue ? dayjs(field.defaultValue) : null;

                                    // Define select options only when dataType is 'Select'
                                    const selectOptions = (field.dataType === 'Select' && field.values && typeof field.values === 'string')
                                        ? field.values.split(',').map(option => option.trim())
                                        : [];
                                    

                                    // Define the component to render based on data type to avoid nested ternaries
                                    let fieldComponent;

                                    if (field.dataType === 'DATE') {
                                        fieldComponent = (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label={field.tags}
                                                    value={defaultDateValue && defaultDateValue.isValid() ? defaultDateValue : null}
                                                    style={{ width: '400px' }}
                                                    onChange={(newValue) => {
                                                        if (newValue && newValue.isValid()) {
                                                            const formattedDateTime = newValue.format('YYYY-MM-DD HH:mm:ss');
                                                            handleDynamicFieldChange({ target: { value: formattedDateTime } }, index);
                                                        } else {
                                                            handleDynamicFieldChange({ target: { value: '' } }, index);
                                                        }
                                                    }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    sx={{ width: '400px' }}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            required: field.isMandatory === 1,
                                                            error: !!dynamicFieldErrors[field.tags],
                                                            helperText: dynamicFieldErrors[field.tags],
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        );
                                    } else if (field.dataType === 'Select') {
                                        fieldComponent = (
                                            <FormControl fullWidth style={{ width: '400px' }}>
                                                <InputLabel id={`select-label-${index}`}>{field.tags}{field.isMandatory === 1 && <span style={{ color: 'Black' }}> *</span>}</InputLabel>
                                                <Select
                                                    labelId={`select-label-${index}`}
                                                    value={selectOptions.includes(field.defaultValue) ? field.defaultValue : ""}
                                                    onChange={(e) => handleDynamicFieldChange(e, index)}
                                                    required={field.isMandatory === 1}
                                                    error={!!dynamicFieldErrors[field.tags]}
                                                    label={field.tags}  // Add this line to set the label in the Select component
                                                >
                                                    {selectOptions.map((option, optIndex) => (
                                                        <MenuItem key={optIndex} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {dynamicFieldErrors[field.tags] && (
                                                    <FormHelperText error>{dynamicFieldErrors[field.tags]}</FormHelperText>
                                                )}
                                            </FormControl>
                                        );
                                    } else {
                                        fieldComponent = (
                                            <TextField
                                                label={field.tags}
                                                value={field.defaultValue}
                                                onChange={(e) => handleDynamicFieldChange(e, index)}
                                                fullWidth
                                                required={field.isMandatory === 1}
                                                error={!!dynamicFieldErrors[field.tags]}
                                                helperText={dynamicFieldErrors[field.tags]}
                                                style={{ width: '400px' }}
                                            />
                                        );
                                    }

                                    return (
                                        <Grid item xs={6} key={field.tags}>
                                            {fieldComponent}
                                        </Grid>
                                    );
                                })
                            }

                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
