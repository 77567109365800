import React, { useState } from 'react';
import { Dialog,Grid, DialogTitle, DialogContent, Tabs, Tab, Box } from '@mui/material';

import ManageLinksTableDialog from './ManageLinksTableDialog'; 
import ViewLinksTableDialog from './ViewLinksTableDialog'; 

// const useStyles = makeStyles({
//     container: {
//         maxHeight: 'calc(100vh - 100px)',
//         overflowY: 'auto',
//         '&::-webkit-scrollbar': {
//             width: '6px', // Adjust the width as desired
//         },
//         '&::-webkit-scrollbar-thumb': {
//             backgroundColor: '#888',
//         },
//     },
// });

export default function LinkTableSettings() {
  const [selectedTab, setSelectedTab] = useState(0); // State to control tab selection
//   const classes = useStyles();
  // Handle tab change
  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  return (
    <div>
      {/* Dialog with tabs */}
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> 
      
        
        <DialogTitle>Links Management</DialogTitle>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="links dialog tabs">
          <Tab label="Manage Links" />
          <Tab label="View Links" />
        </Tabs>

        <DialogContent >
          <TabPanel value={selectedTab} index={0}>
            <ManageLinksTableDialog />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <ViewLinksTableDialog />
          </TabPanel>
        </DialogContent>
      
      </Grid>
    </div>
  );
}

// TabPanel helper component to render tab content conditionally
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};
