import axios from 'axios';

// API CALL CODE HERE
const AddNewMspUserApiCall = async (firstName, lastName, phoneNo, emailId, userName, password, role, setAddStatus, setAddOpen, setSeverStatus, setAddMSPUserMessage ) => {

  const path = "create_new_user.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "password": password,
      "user_role": role
    }
  });

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAddStatus(1);
      setSeverStatus("success");
      setAddMSPUserMessage(response.data.message)
    }
    else {
      setAddStatus(-1);
      setSeverStatus("error");
      setAddMSPUserMessage(response.data.message)
    }
    setAddOpen(true)
  }).catch(() => {
    setAddStatus(-1);
    setSeverStatus("error");
    setAddOpen(true)
  })
}

export default AddNewMspUserApiCall;
