import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import Iconify from '../../Iconify';


import GetProbesDetailsApiCall from './GetProbesDetailsApiCall';
import EnableDisableDialog from './EnableDisableDialog';

import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'cust_name',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'ProbeID',
        numeric: false,
        disablePadding: true,
        label: 'Probe ID',
    },
    {
        id: 'Probe Name',
        numeric: false,
        disablePadding: false,
        label: 'Probe Name',
    },
    {
        id: 'NoOfLocationsMapped',
        numeric: false,
        disablePadding: false,
        label: 'No Of Locations',
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Created On',
        numeric: false,
        disablePadding: false,
        label: 'Created On',
    },
    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    }
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead({ showCustomer }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (headCell.id !== 'cust_name' || showCustomer) &&
                    <TableCell

                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    showCustomer: PropTypes.bool.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, setOpenAddDialog, handleAddClick }) {
    return (
        <RootStyle>
            <Typography variant='h4'>
                Internal Probes
            </Typography>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ProbesTable() {
    const { globalState, setGlobalState } = useGlobalState();
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspClientId = localStorage.getItem("cust_id");
    const regClientId = localStorage.getItem("reg_cust_id");
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const isEngineer = (localStorage.getItem("role") === "2")

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [value, setValue] = useState({});

    const [openEnableDisableDialog, setOpenEnableDisableDailog] = useState(false);
    const [enableDisableMessage, setEnableDisbaleMessage] = useState("");
    const [enableDisableStatus, setEnableDisableStatus] = useState(0);
    const [openWanDialog, setOpenWarnDialog] = useState(false);
    const [snackOpenStatus, setSnackOpenStatus] = useState(false);

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));

    const handleStartStopClieck = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenEnableDisableDailog(true);
    }

    // const handleDelete = (e, row) => {
    //     resetAll();
    //     if (globalState === custId && mspStatus === 1) {
    //         setOpenWarnDialog(true);
    //         return
    //     }
    //     setValue(row);
    //     setOpenDeleteDialog(true);
    // }

    const handleFilterByName = (event, searchedVal) => {
        setFilterName(event.target.value)
        const ftrRows = rows.filter((row) => {
            return (
                row.server_name.toLowerCase().includes(searchedVal.toLowerCase())||
                row.server_unique_id.toLowerCase().includes(searchedVal.toLowerCase()) 
            );
        });
        setFilteredRows(ftrRows);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const   _GetProbeDetails = async () => {
        await GetProbesDetailsApiCall(setRows, setFilteredRows);
    }

    useEffect(() => {
        _GetProbeDetails();
    }, [globalState, enableDisableMessage, enableDisableStatus])


    const resetAll = () => {
        setOpenEnableDisableDailog(false);
        setEnableDisbaleMessage("");
        setEnableDisableStatus(0);
        setSnackOpenStatus(false)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
    };

    // const handleAddClick = () => {
    //     resetAll();
    //     if (globalState === custId && mspStatus === 1) {
    //         setOpenWarnDialog(true);
    //         return
    //     }
    //     setOpenAddDialog(true);
    // }
    // const handleUploadClick = () => {
    //     resetAll();
    //     setValue(rows);
    // }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e, filterName) }}
                    // setOpenAddDialog={setOpenAddDialog}
                    // handleAddClick={handleAddClick}
                    numSelected={selected.length}

                />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            showCustomer={mspClientId === regClientId && mspStatus === 1}
                            numSelected={selected.length}
                            rowCount={filteredRows.length}
                        />

                        <TableBody>
                            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.zone_id}
                                        >
                                            <TableCell align="center">{index + 1}</TableCell>
                                            {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                                            <TableCell align="left">{row.server_unique_id}</TableCell>
                                            <TableCell align="left">{row.server_name}</TableCell>
                                            <TableCell align="left">{row.locations_assigned}</TableCell>
                                            {row.status === "1" && <TableCell align="left">ACTIVE</TableCell>}
                                            {row.status !== "1" && <TableCell align="left">IN ACTIVE</TableCell>}
                                            <TableCell align="left">{row.ent}</TableCell>
                                            <TableCell align="center">
                                                <Grid direction="row">
                                                    {row.status === "1" && <IconButton variant="outlined" color="primary" onClick={(e) => { handleStartStopClieck(e, row) }}><StopCircleIcon /></IconButton>}
                                                    {row.status !== "1" && <IconButton variant="outlined" color="primary" onClick={(e) => { handleStartStopClieck(e, row) }}><PlayCircleOutlineIcon /></IconButton>}
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {openEnableDisableDialog && <EnableDisableDialog value={value} openEnableDisableDialog={openEnableDisableDialog} setOpenEnableDisableDailog={setOpenEnableDisableDailog} setEnableDisableStatus={setEnableDisableStatus} setEnableDisbaleMessage={setEnableDisbaleMessage} setSnackOpenStatus={setSnackOpenStatus}/>}
            </Paper >

            {enableDisableStatus === 1 && <Snackbar open={snackOpenStatus} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    <strong>Status changed Successfully</strong>
                </Alert>
            </Snackbar>}

            {enableDisableStatus === -1 && <Snackbar open={snackOpenStatus} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    <strong>Status changed Successfully</strong>
                </Alert>
            </Snackbar>}
        </Box >
    );
}