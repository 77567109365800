import axios from 'axios';

const EditTemplateApiCall = async (reportData, setAddReportStatus, setServerStatus) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/edit_report_template.php`;

    const payload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("cust_id"),
      "report_data": reportData
 
    });
  
    return axios.post(baseUrl, payload).then((response) => {
      if (response.data.code === 1) {
        setAddReportStatus(1);
        setServerStatus("success");
      }
      else {
        setAddReportStatus(-1);
        setServerStatus("error");
      }
    })
  }
  
  export default EditTemplateApiCall;




  

