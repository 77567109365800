import axios from 'axios';
import { useState } from 'react';


const GetLocationsDetailsApi = async (setLeft, setRight, setUserAllocatedData, phrase, usersData) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_user_mapping_details_for_msp.php" : "get_user_mapping_details.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`; 

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "user_id": usersData.user_id,
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "user_id": usersData.user_id,
  });


  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  try {
    const response = await axios.post(createBaseURL, payload);


    if (response.data.code === 1 && phrase === 1) {
      const allocatedResponse = response.data.user_map_data.zones.allotted;
      const availableResponse = response.data.user_map_data.zones.availabled;
      if (availableResponse && allocatedResponse && availableResponse.length > 0 && allocatedResponse.length > 0) {
        setRight(allocatedResponse);
        setLeft(availableResponse);

      }

      else {
        setRight(allocatedResponse || []);
        setLeft(availableResponse || []);
      }
      

    }

    else if (response.data.code === 1 && phrase === 2) {
      const allocatedResponse = response.data.user_map_data.site_groups.allotted;
      const availableResponse = response.data.user_map_data.site_groups.availabled
      if (availableResponse.length > 0 && allocatedResponse.length > 0) {
        setRight(allocatedResponse);
        setLeft(availableResponse);
      }
      else {
        setRight(allocatedResponse || []);
        setLeft(availableResponse || []);
      }
      
    }

    else if (response.data.code === 1 && phrase === 3) {
      const allocatedResponse = response.data.user_map_data.sites.allotted;
      const availableResponse = response.data.user_map_data.sites.availabled
      if (availableResponse.length > 0 && allocatedResponse.length > 0) {
        setRight(allocatedResponse);
        setLeft(availableResponse);
      }
      else {
        setRight(allocatedResponse || []);
        setLeft(availableResponse || []);
      }
    }
    else {
      setLeft([]);
      setRight([]);
    }
  } catch (error) {
    setLeft([]);
    setRight([]);
  }
};

export default GetLocationsDetailsApi;





