import React from 'react';
// material
import {
  Link,
  Breadcrumbs,
  Container,
  Grid
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import Page from '../components/Page';
import CustomersComponents from '../components/CustomersComponents/CustomersComponents';


export default function Customers() {
  return (
    <Page title="Customers">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/customers">
              Customers
            </Link>
          </Breadcrumbs>
        </div>

        <Grid container>
          <CustomersComponents />
        </Grid>

      </Container>
    </Page>
  );
}
