import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Breadcrumbs, Container, Link, Divider, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import AppMonitorDashboard from '../components/SAMonitorComponents/AppMonitorDashboard';

export default function AppMonitor() {

    return (
        <Page title="AppMonitor">
            <Container maxWidth={false}>
                <Grid>
                    <Grid item container>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <AppMonitorDashboard />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
