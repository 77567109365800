import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useGlobalState } from '../../../globalHooks/GlobalState';
import GetSiteGroupDetailsApiCall from '../../SiteGroupComponents/Components/GetSiteGroupDetailsApiCall';
import GetSiteGroupNamesApiCall from '../ApiCalls/GetSiteGroupNamesApiCall';


const DropDownGroupComponent = React.forwardRef((props) => {
  const { setSelectedGroupIds, onSelectionChange, forceGroupDetailsRefresh, fullScreenChecking, selectedGroupNames, setSelectedGroupNames, isFullScreen } = props;
  const [groups, setGroups] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const prevGlobalStateRef = useRef(globalState);
  const buGlobalStateRef = useRef(buState);
  // const [selectedGroupNames, setSelectedGroupNames] = useState([]);

  const handleGroupSelectionChange = (event, values) => {
    // Map selected group names to group IDs and update state
    const selectedIds = values.map((groupName) => {
      const selectedGroup = groups.find((group) => group.group_name === groupName);
      return selectedGroup ? selectedGroup.group_id : null;
    });

    // Remove null values (in case a group name doesn't have a matching ID)
    const filteredIds = selectedIds.filter((id) => id !== null);

    setSelectedGroupIds(filteredIds);
    setSelectedGroupNames(values); // Update selectedGroupNames with the new values
    onSelectionChange(filteredIds);

  };
  const _GetSiteGroupNamesApiCall = async () => {
    await GetSiteGroupNamesApiCall(setGroups, buState);

    setGroupNames(groups.map(group => group.group_name));
  };

  useEffect(() => {
    _GetSiteGroupNamesApiCall();
  }, [globalState, forceGroupDetailsRefresh, buState]);

  useEffect(() => {
    if (groups.length > 0) {
      setGroupNames(groups.map(group => group.group_name));
    }
  }, [groups, globalState, buState]);

  useEffect(() => {
    if (!isFullScreen && fullScreenChecking && selectedGroupNames.length > 0) {
      setSelectedGroupNames([]);
    }
  }, [forceGroupDetailsRefresh, globalState, buState]);

  useEffect(() => {
    if (prevGlobalStateRef.current !== globalState) {
      setSelectedGroupNames([]);
    }
    prevGlobalStateRef.current = globalState;
  }, [globalState]);

  useEffect(() => {
    if (buGlobalStateRef.current !== buState) {
      setSelectedGroupNames([]);
    }
    buGlobalStateRef.current = buState;
  }, [buState]);

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          options={groupNames}
          disableCloseOnSelect
          value={selectedGroupNames}
          onChange={handleGroupSelectionChange}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                key={option}
                value={option}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Site Group"
            />
          )}
        />
      </FormControl>
    </Grid>
  );
});

export default DropDownGroupComponent;
