import axios from 'axios';

// API CALL CODE HERE

const CheckRechabilityOverInternetApiCall = async (ispWanId, setInternetRechStatus, setGotInternetRechStatus, setInternetRechStatusMessage, setContinueDgLanStatus, setDetailInternetRechStatusMessage, setRcaInternetRechStatusMessage,setGreenInternet,setRedFailInternet,setRedFailsInternet,setCheckStatus ,linkType, setCheckStates,setInternetReachableMeraki) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_internet_reachability_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1 && response.data.continue_status === 1) {
      setInternetRechStatus(true)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(true)
      setInternetRechStatusMessage(response.data.message)
      setGreenInternet(true)
    
     
      if(linkType === "ILL" || linkType === "Broadband" || linkType === 'MPLS' || linkType === 'P2P'){
        setCheckStates(false);
      }
      else{
        setCheckStates(true)
      }
    }
    else if (response.data.code === -1 && response.data.continue_status === 1) {
      setInternetRechStatus(true)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(true)
      setInternetRechStatusMessage(response.data.message)
      setRedFailInternet(true)
      if(linkType === "ILL" || linkType === "Broadband" || linkType === 'MPLS' || linkType === 'P2P'){
      setCheckStatus(true)
      }
      else{
        setCheckStates(true)
        setCheckStatus(true)
      }
      

    }
    else if (response.data.code === -1 && response.data.continue_status === -1) {
      setInternetRechStatus(false)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(false)
      setInternetRechStatusMessage(response.data.message)
      setRedFailsInternet(true)
     
    }
    else {
      setInternetRechStatus(false)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(false)

      setInternetRechStatusMessage(response.data.message)
    }
    setDetailInternetRechStatusMessage(response.data.detailed_message)
    setRcaInternetRechStatusMessage(response.data.rca_message)
    setInternetReachableMeraki(true);

  })
}

export default CheckRechabilityOverInternetApiCall;
