import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
    Typography
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';


// Api Import
import AddNewSiteGroupApiCall from './AddNewSiteGroupApiCall';
import GetSiteGroupDetailsApiCall from './GetSiteGroupDetailsApiCall';


export default function AddNewSiteGroupDialog(props) {
    const { openAddSiteGroupDialog, setOpenAddSiteGroupDialog, setAddSiteGroupStatus, setAddSiteMessage } = props;

    const [groupName, setGroupName] = useState("");
    const [groupType, setGroupType] = useState("");
    const [criticality, setCriticality] = useState("");

    const [latency, setLatency] = useState("150");
    const [jitter, setJitter] = useState("30");
    const [packetLoss, setPacketLoss] = useState("0");

    const [groupNameHelperText, setGroupNameHelperText] = useState("");
    const [groupTypeHelperText, setGroupTypeHelperText] = useState("");
    const [latencyHelperText, setLatencyHelperText] = useState("");
    const [jitterHelperText, setJitterHelperText] = useState("");
    const [packetLossHelperText, setPacketLossHelperText] = useState("");
    const [criticalityHelperText, setcriticalityHelperText] = useState("");
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState("");
    const [warningHelperText, setWarningHelperText] = useState("")
    const [critical, setCritical] = useState("");
    const [criticalHelperText, setCriticalHelperText] = useState("")
    const [thresholds, setThreasholds] = useState([])
    const [groups, setGroups] = useState([]);

    const handleOnClose = () => {
        setOpenAddSiteGroupDialog(false);
    }

    const groupNameRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
    const groupTypeRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
    const latencyRegex = /^\d+(\.\d+)?$/;
    const packetLossRegex = /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
    const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;

    const validateNumber = (value) => numericPattern.test(value);

    const handleOnSave = async () => {


        if (!groupName.match(groupNameRegex)) {
            setGroupNameHelperText("Enter valid Group Name");
            return;
        }


        if (!groupType.match(groupTypeRegex)) {
            setGroupTypeHelperText("Group Type Should be Valid");
            return;
        }

        if (!latency.match(latencyRegex)) {
            setLatencyHelperText("Enter Latency Correctly");
            return;
        }

        if (!jitter.match(latencyRegex)) {
            setJitterHelperText("Enter Jitter Correctly");
            return;
        }

        if (!packetLoss.match(packetLossRegex)) {
            setPacketLossHelperText("Enter Packet Loss Correctly");
            return;
        }
        if (criticality === "") {
            setError(true);
            setcriticalityHelperText("Select the Criticality");
            return;

        }

        if (!validateNumber(warning)) {
            setWarningHelperText("Warning Threshold must be a valid number");
            return;
        }

        if (!validateNumber(critical)) {
            setCriticalHelperText("Critical Threshold must be a valid number");
            return;
        }
        await AddNewSiteGroupApiCall(groupName, groupType, criticality, latency, jitter, packetLoss, warning, critical, setAddSiteGroupStatus, setAddSiteMessage);
        setError(false);
        setcriticalityHelperText("");
        handleOnClose();

    }

    const handleCriticalChange = (e) => {
        switch (e.target.id) {
            case "group_name": if (!e.target.value.match(groupNameRegex)) {
                setGroupNameHelperText("Group Name Should be Valid");
            }
            else {
                setGroupNameHelperText("");

            }
                setGroupName(e.target.value);
                break;

            case "group_type": if (!e.target.value.match(groupTypeRegex)) {
                setGroupTypeHelperText("Group Type Should be Valid");
            }
            else {
                setGroupTypeHelperText("");

            }
                setGroupType(e.target.value);
                break;



            case "latency": if (!e.target.value.match(latencyRegex)) {
                setLatencyHelperText("Enter Latency Correctly");
            }
            else {
                setLatencyHelperText("");

            }
                setLatency(e.target.value);
                break;

            case "jitter": if (!e.target.value.match(latencyRegex)) {
                setJitterHelperText("Enter Jitter Correctly");
            }
            else {
                setJitterHelperText("");

            }
                setJitter(e.target.value);
                break;

            case "packet_loss": if (!e.target.value.match(packetLossRegex)) {
                setPacketLossHelperText("Enter Packet Loss Correctly");
            }
            else {
                setPacketLossHelperText("");

            }
                setPacketLoss(e.target.value);
                break;
            default: setCriticality(e.target.value);
                setcriticalityHelperText("");
                setError(false);
                break;
        }
    }



    const _GetSiteGroupDetailsApiCall = async () => {
        await GetSiteGroupDetailsApiCall(setGroups, setWarning, setCritical);
        // setWarning(thresholds[0]);
        // setCritical(thresholds[1]);
    };

    useEffect(() => {
        _GetSiteGroupDetailsApiCall();
    }, []);


    return (
        <>
            <Dialog open={openAddSiteGroupDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add Site Group
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>

                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Group Name" id="group_name" value={groupName} onChange={handleCriticalChange} helperText={groupNameHelperText} error={!!groupNameHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Group Type" id="group_type" value={groupType} onChange={handleCriticalChange} helperText={groupTypeHelperText} error={!!groupTypeHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth required error={error} >
                                    <InputLabel id="critical-label">Criticality</InputLabel>
                                    <Select
                                        labelId="critical-label"
                                        id="criticality"
                                        label="Criticality"
                                        value={criticality}
                                        onChange={handleCriticalChange}

                                    >
                                        <MenuItem value={'0'}>Low</MenuItem>
                                        <MenuItem value={'1'}>Medium</MenuItem>
                                        <MenuItem value={'2'}>High</MenuItem>
                                    </Select>
                                    {error && <Typography variant="caption" color="error">{criticalityHelperText}</Typography>}

                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                    {/* <Divider/> */}
                    <Typography variant='h6' sx={{ padding: "20px" }}>
                        Group Parameters
                    </Typography>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Latency(in ms)" id="latency" value={latency} onChange={handleCriticalChange} helperText={latencyHelperText} error={!!latencyHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Jitter(in ms)" id="jitter" value={jitter} onChange={handleCriticalChange} helperText={jitterHelperText} error={!!jitterHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Packet Loss(in %)" id="packet_loss" value={packetLoss} onChange={handleCriticalChange} helperText={packetLossHelperText} error={!!packetLossHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>
                    </form>
                    <Typography variant='h6' sx={{ padding: "20px" }}>
                        Utilization Threshold
                    </Typography>
                    <form>

                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField
                                    id="Waring-Thresh"
                                    label="Warning Threshold"
                                    variant="outlined"
                                    value={warning}
                                    placeholder="Enter Warning Threshold(in %)"
                                    onChange={(e) => {
                                        // setWarningHelperText("");
                                        // setWarning(e.target.value)
                                        const value = e.target.value;
                                        setWarning(value);
                                        if (!validateNumber(value)) {
                                            setWarningHelperText("Warning Threshold must be a valid number");
                                        } else {
                                            setWarningHelperText("");
                                        }
                                    }
                                    }
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={warningHelperText}
                                    error={!!warningHelperText}
                                    required
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="Critical-Thresh"
                                    label="Critical Threshold"
                                    variant="outlined"
                                    value={critical}
                                    placeholder="Enter Critical Threshold(in %)"
                                    onChange={(e) => {
                                        // setCriticalHelperText("");
                                        // setCritical(e.target.value)
                                        const value = e.target.value;
                                        setCritical(value);
                                        if (!validateNumber(value)) {
                                            setCriticalHelperText("Critical Threshold must be a valid number");
                                        } else {
                                            setCriticalHelperText("");
                                        }
                                    }
                                    }
                                    fullWidth
                                    style={{ width: "400px" }}
                                    helperText={criticalHelperText}
                                    error={!!criticalHelperText}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
