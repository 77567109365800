import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
} from '@mui/material';
import ViewLinksInventoryTable from './ViewLinksInventoryTable'
import ViewEdgeDevicesInventoryTable from './ViewEdgeDevicesInventoryTable';

export default function ViewInventoryComponents() {

  const [linkVariant, setLinkVariant] = useState('contained');
  const [edgeDeviceVariant, setEdgeDeviceVariant] = useState('outlined');
  const [holisticValue, setHolisticValue] = useState('0');

  const handleButtonChange = (e) => {
    setHolisticValue(e.target.value);

    if (e.target.value === "1") {
      setLinkVariant('outlined');
      setEdgeDeviceVariant('contained');
    }

    if (e.target.value === "0") {
      setLinkVariant('contained');
      setEdgeDeviceVariant('outlined');
    }

  }

  return (
    <>
      <Grid container sx={{ paddingTop: '30px'}}>
        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ paddingBottom: '30px' }}>
          <ButtonGroup size="large" aria-label="outlined primary button group">
            <Button value="0" variant={linkVariant} onClick={handleButtonChange}>Links</Button>
            <Button value="1" variant={edgeDeviceVariant} onClick={handleButtonChange}>Edge Devices</Button>
          </ButtonGroup>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          {holisticValue === "0" && <ViewLinksInventoryTable />}
          {holisticValue === "1" && <ViewEdgeDevicesInventoryTable />}
        </Grid>
      </Grid>
    </>
  );
}
