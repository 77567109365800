import axios from 'axios';

// API CALL CODE HERE
export const EdLogsAPICall = async (setEdgeAlerts,setAllEdgeAlerts, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_logs_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id" :buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setEdgeAlerts(response.data.logs_data);
        setAllEdgeAlerts(response.data.logs_data)

      }
    })
  }
  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
      const logsBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_logs_for_bu.php`;
      const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "bu_id": buState
      });

      return axios.post(logsBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setEdgeAlerts(response.data.logs_data);
          setAllEdgeAlerts(response.data.logs_data)
        }
        else {
          setEdgeAlerts([]);
          setAllEdgeAlerts([]);
        }
      })
    }

    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_edge_device_logs_for_msp.php" : "get_edge_device_logs.php";
      const logsBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

      return axios.post(logsBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setEdgeAlerts(response.data.logs_data);
          setAllEdgeAlerts(response.data.logs_data)
        }
        else {
          setEdgeAlerts([]);
          setAllEdgeAlerts([]);
        }
      })
    }
  }
}
