import React, { useState, createRef, useEffect } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { CSVLink } from 'react-csv';
import {
    Breadcrumbs, Container, Link, Grid, TextField, Button, Alert, AlertTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Modal, Box, Fade, Typography,
    Paper, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import FileUpload from "react-material-file-upload";
import Papa from "papaparse";
import DownloadIcon from '@mui/icons-material/Download';

import UploadEdgeDeviceApiCall from './UploadEdgeDeviceListApiCall';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};
const theme = createTheme({
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#D0D0D0',
                    },
                    '&.MuiTableRow-head': {
                        backgroundColor: '#D0D0D0',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: 'Black',
                    backgroundColor: '#D0D0D0',
                    fontSize: '15px'
                },
            },
        },
    },
});
const useStyles = makeStyles((theme) => ({
    // redBorderCell: {
    //   border: '1px solid red',
    // },
    redText: {
        color: 'red',
        fontWeight: 'bold'
    }
}));
export default function EdgeDeviceUploadDialog(props) {
    const classes = useStyles();

    const { value, openUploadDialog, setOpenUploadDialog, setUploadEds } = props;

    const [files, setFiles] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [data, setData] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [formatError, setFormatError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFiles([]);
        setCsvData([]);
        setDeviceData([]);
        setData([]);
        setValidationError(false);
        setOpen(false);
        setOpenUploadDialog(false);
    };

    const edLink = createRef();
    const deviceLink = createRef();

    // regex 
    const locationIdRegex = /^-?\d+$/;
    const locationRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _(),-]*(?<![-_( ])$/;
    const ipRegEx = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const sshUserNameRegEx = /^(?!.{51})(?![ -])[A-Za-z\d!@#$%&*_()+,\-.:;<=>?@]+[A-Za-z\d]$/;
    const nameRegex = /^[a-zA-Z0-9(][a-zA-Z0-9 _(),-]*(?<![-_( ])$/;
    const portRegex = /^\d+$/;

    const expectedHeaders = [
        "location_id", "location", "device_ip", "snmp_str", "ssh_username","ssh_password","api_key","ssh_port","api_port", "device_type", "vendor_name"]

    const handleSave = () => {
        const file = files[0];
        if (!file) return "";
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true, });
            const parsedData = csv?.data;
            setValidationError(false);
            setFormatError(false); 
            const headers = csv?.meta?.fields; 
            const formatValid = checkFormat(headers); 
            setFormatError(!formatValid);

            if (!formatValid) {
                setOpen(true); 
                setValidationMessage("CSV format is incorrect. Please upload the file with the correct headers."); 
                return; 
            }

            if (parsedData.length === 0) {
                setOpen(true);
                setValidationMessage("No data available in the uploaded file."); 
                return; 
            }
            parsedData.forEach(row => {
                Object.entries(row).forEach(([key, value]) => {
                    if (
                        (key === 'location_id' && !locationIdRegex.test(value.trim())) ||
                        (key === 'location' && !locationRegex.test(value.trim())) ||
                        (key === 'device_ip' && !ipRegEx.test(value.trim())) ||
                        (key === 'snmp_str' && row.snmp_str.trim() === "") ||
                        (key === 'ssh_password' && row.ssh_password.trim() === "") ||
                        (key === 'api_key' && row.api_key.trim() === "") ||
                        (key === 'ssh_port' && !portRegex.test(value.trim())) ||
                        (key === 'api_port' && !portRegex.test(value.trim())) ||
                        (key === 'device_type' && !nameRegex.test(value.trim())) ||
                        (key === 'vendor_name' && !nameRegex.test(value.trim()))
                    ) {

                        setValidationError(true);
                    }
                });
            });
            if (validationError) return;
            setData(parsedData);
            setOpen(true);

        };
        reader.readAsText(file);
    }
    const checkFormat = (headers) => {
        if (headers.length !== expectedHeaders.length) return false;

        for (let i = 0; i < headers.length; i += 1) {
            if (headers[i].trim() !== expectedHeaders[i].trim()) {
                return false;
            }
        }
        return true;
    };
    const handleOk = () => {
        UploadEdgeDeviceApiCall(data, setOpenUploadDialog, setUploadEds);
        setOpen(false)
    }
    useEffect(() => {
        createDownLoadData();
        createSupportedDevicesCSV();
    }, [])

    const createSupportedDevicesCSV = () => {
        const data = [
            { "Vendor": "Fortinet", "Type": "Firewall" },
            { "Vendor": "SOPHOS", "Type": "Firewall" },
            { "Vendor": "Cisco", "Type": "Router" },
            { "Vendor": "Aruba", "Type": "SD WAN Box" },
            { "Vendor": "Versa", "Type": "SD WAN Box" },
            { "Vendor": "Meraki", "Type": "SD WAN Box" },
            { "Vendor": "Mist", "Type": "SD WAN Box" },
            { "Vendor": "Silverpeak", "Type": "SD WAN Box" },
            { "Vendor": "Viptella", "Type": "SD WAN Box" }
        ]
        setDeviceData(data);
    }
    const createDownLoadData = () => {
        const dataArr = [];
        for (let index = 0; index < value.length; index += 1) {
            const item = {};
            item.location_id = value[index].location_id;
            item.location = value[index].location_name;
            item.device_ip = value[index].device_ip;
            item.snmp_str = value[index].snmp_str;
            item.ssh_username = value[index].ssh_username;
            item.ssh_password = value[index].ssh_password;
            item.api_key = value[index].api_key;
            item.ssh_port = value[index].ssh_port;
            item.api_port = value[index].api_port;
            item.device_type = value[index].device_type;
            item.vendor_name = value[index].vendor_name;
            dataArr.push(item);
        }
        setCsvData(dataArr);
    }

    const handleDownload = () => {
        edLink.current.link.click();
    }

    const handleDeviceClick = () => {
        deviceLink.current.link.click();
    }
    return (
        <>
            <Dialog open={openUploadDialog} onClose={() => { setOpenUploadDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Upload Edge Device
                    <IconButton onClick={() => { setOpenUploadDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 6, px: 3 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '25vh' }}
                    >

                        <FileUpload sx={{ width: "100%", height: "100%" }} alignItems="center" value={files} onChange={setFiles} accept={".csv"} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CSVLink data={csvData} filename={`ed_format.csv`} ref={edLink}>{ }</CSVLink>
                    <CSVLink data={deviceData} filename={`supported_devices.csv`} ref={deviceLink}>{ }</CSVLink>
                    <Button variant="contained" onClick={handleDeviceClick} startIcon={<DownloadIcon />} Upload>Supported Devices</Button>
                    <Button variant="contained" onClick={handleDownload} startIcon={<DownloadIcon />} Upload>Format</Button>
                    <Button variant="contained" onClick={handleSave} >Save</Button>
                    <Button variant="contained" onClick={() => { setOpenUploadDialog(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                sx={{ overflowX: 'auto' }}
            >
                <Fade in={open}>
                    <Box sx={{ ...style, maxHeight: 800 }}>
                        <Typography id="transition-modal-title" variant="h6" component="h2" >
                            Edge Device Preview
                        </Typography>
                        {data.length > 0 ? (
                            <TableContainer component={Paper} sx={{ marginTop: '5%', maxHeight: 400, overflow: 'auto', scrollbarWidth: 'thin' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    {data && data.length > 0 && (
                                        <TableHead>
                                            <ThemeProvider theme={theme}>
                                                <TableRow sx={{ bgcolor: '#D3D3D3' }}>
                                                    {Object.keys(data[0]).map((key, index) => (
                                                        <TableCell key={index}>{key}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </ThemeProvider>
                                        </TableHead>
                                    )}
                                    <TableBody>
                                        {data && data.length > 0 && data.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {Object.entries(row).map(([key, cell], cellIndex) => {
                                                    const isEmptyOrInvalid = !cell || (
                                                        (key === 'location_id' && !locationIdRegex.test(cell.trim())) ||
                                                        (key === 'location' && !locationRegex.test(cell.trim())) ||
                                                        (key === 'device_ip' && !ipRegEx.test(cell.trim())) ||
                                                        (key === 'snmp_str' && cell.trim() === "") ||
                                                        (key === 'ssh_password' && cell.trim() === "") ||
                                                        (key === 'api_key' && cell.trim() === "") ||
                                                        (key === 'ssh_port' && !portRegex.test(cell.trim())) ||
                                                        (key === 'api_port' && !portRegex.test(cell.trim())) ||
                                                        (key === 'device_type' && !nameRegex.test(cell.trim())) ||
                                                        (key === 'vendor_name' && !nameRegex.test(cell.trim()))
                                                    );
                                                    return (
                                                        <TableCell key={cellIndex} style={{ color: isEmptyOrInvalid ? 'red' : 'inherit' }}>
                                                            {isEmptyOrInvalid ? 'Details Missing!' : cell}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography sx={{ mt: 20, color: 'red', fontSize: '20px', textAlign: 'center' }}>
                                {/* No data available in the uploaded file. */}
                                {validationMessage}
                            </Typography>
                        )}
                        <Box sx={{ position: 'absolute', bottom: 16, right: 16, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button onClick={handleClose} sx={{ marginRight: 2 }}>Cancel</Button>
                            <Button onClick={handleOk} variant="contained" color="primary" disabled={validationError || data.length === 0}>Ok</Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
