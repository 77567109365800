import React from 'react';
// material
import {
  Link,
  Breadcrumbs,
  Grid,
  Paper
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import AddSingleLocationComponent from '../components/LocationComponents/SingleLocationComponent/AddSingleLocationComponent';
import Page from '../components/Page';


export default function AddSingleLocation() {

  return (
    <Page title="Add Single Location">
      <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <BuildIcon />
          </Link>
          <Link underline="hover" color="inherit">
            Site Management
          </Link>
          <Link underline="hover" color="inherit" href="/dashboard/add-single-location">
            Add Site
          </Link>
        </Breadcrumbs>
      </div>
      <Grid>
        <Grid item lg={12} md={12} sm={12}>
          <Paper elevation={15}>
            <AddSingleLocationComponent />
          </Paper>
        </Grid>
      </Grid>

    </Page>
  );

}
