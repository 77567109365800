import axios from 'axios';


export const GetSiteDataApiCall = async (setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState) => {
  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_location_for_agent_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id" : buState
    });
    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        if (response.data.locations.length > 0) {
          const firstLocation = response.data.locations[0];
          setIspLocations(response.data.locations);
          setLocationdata(firstLocation);
          setLocationId(firstLocation.location_id);
          setFirstLocationName(firstLocation.location);
        } else {
          setIspLocations([{}]);
          setLocationdata(null);
          setLocationId(null);
          setFirstLocationName('No location assigned');
        }
      }
    });
  }
  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const url = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_location_for_agent_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState
      });

      return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
          if (response.data.locations.length > 0) {
            const firstLocation = response.data.locations[0];
            setIspLocations(response.data.locations); 
            setLocationdata(firstLocation);
            setLocationId(firstLocation.location_id || []);
            setFirstLocationName(firstLocation.location);
          } else {
            setIspLocations([{}]);
            setLocationdata(null);
            setLocationId(null);
            setFirstLocationName('No location assigned');
          }
        }
      });
    }

    if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_isp_location_for_agent_msp.php" : "get_isp_location_for_agent.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          if (response.data.locations.length > 0) {
            const firstLocation = response.data.locations[0];
            setIspLocations(response.data.locations);
            setLocationdata(firstLocation);
            setLocationId(firstLocation.location_id);
            setFirstLocationName(firstLocation.location);
          } else {
            setIspLocations([{}]);
            setLocationdata(null);
            setLocationId(null);
            setFirstLocationName('No location assigned');
          }
        }
      });
    }
  }
}