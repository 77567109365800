import axios from 'axios';

// API CALL CODE HERE

const CheckRechabilityOverInternetApiCall = async (ispWanId, setInternetRechStatus, setGotInternetRechStatus, setInternetRechStatusMessage, setContinueDgLanStatus, setDetailInternetRechStatusMessage, setRcaInternetRechStatusMessage) => {
  
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_reachability_over_internet.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response) => {
    // console.log(response.data);
    if (response.data.code === 1) {
      setInternetRechStatus(true)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(true)
      setInternetRechStatusMessage(response.data.message)
    }
    else {
      setInternetRechStatus(false)
      setGotInternetRechStatus(true)
      setContinueDgLanStatus(false)
      setInternetRechStatusMessage(response.data.message)
    }
    setDetailInternetRechStatusMessage(response.data.detailed_message)
    setRcaInternetRechStatusMessage(response.data.rca_message)
  })
}

export default CheckRechabilityOverInternetApiCall;
