import axios from 'axios';

// API CALL CODE HERE

const CheckEdLanStatusApiCall = async (ispWanId, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_edge_device_reachability.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 2 || response.data.code === 3) {
      setEdAddedStatus(false)
      setGotEdLanStatus(true)
      setGotEdPortStatus(true)
      setEdLanStatusMessage(response.data.message)
      setDetailEdLanStatusMessage(response.data.detailed_message)
      setRcaEdLanStatusMessage(response.data.rca_message)
      setCode(response.data.code)

      setContinueEdPortStatus(false);
      return
    }
    
    if (response.data.fw_status === 1 && response.data.port_status === 1) {
      setEdLanStatus(true)
      setEdPortStatus(true)
      setContinueEdHealthStatus(true)
      setContinueEdPortStatus(true);
    }
    else {
      setEdLanStatus(false)
      setEdPortStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
    }

    

    setEdAddedStatus(true)
    setGotEdLanStatus(true)
    setGotEdPortStatus(true);
    setEdLanStatusMessage(response.data.message)
    setDetailEdLanStatusMessage(response.data.message);
    setRcaEdLanStatusMessage(response.data.message)
  })
}

export default CheckEdLanStatusApiCall;
