
import axios from 'axios';

export const AddUtilizationApiCall = async (data, setAddUtilizationStatus, setAddUtilizationMessage) => {

    const path = localStorage.getItem("msp_status") === "1" ? "utilization_config_for_msp.php" : "utilization_config.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("reg_cust_id"),
        "data": {
            locationId: data.locationId,
            warningThresholdValue: data.warningThreshold,
            criticalThresholdValue: data.criticalThreshold
        }

    });
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_cust_id": localStorage.getItem("cust_id"),
        "reg_cust_id": localStorage.getItem("reg_cust_id"),
        "data": {
            locationId: data.locationId,
            warningThresholdValue: data.warningThreshold,
            criticalThresholdValue: data.criticalThreshold
        }

    });
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrl, payload).then((response) =>{
        if (response.data.code === 1) {
            setAddUtilizationStatus(response.data.code);
            setAddUtilizationMessage(response.data.message);
        } else {
            setAddUtilizationStatus(response.data.code);
            setAddUtilizationMessage(response.data.message);
        }
    })
}
