import axios from 'axios';

// API CALL CODE HERE

const GetZoneLocationsDataApiCall = async (zoneId, setPresentLocations, setAvailableLocations, buState) => {


  const path = localStorage.getItem("msp_status") === "1" ? "get_locations_by_zone_for_msp.php" : "get_locations_by_zone.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "zone_id": zoneId,
    "bu_id": buState
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "zone_id": zoneId
  })
 
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAvailableLocations(response.data.present_locations);
      setPresentLocations(response.data.spare_locations);
    }
    else {
      setAvailableLocations([]);
      setPresentLocations([]);
    }
  }).catch(()=>{
      setAvailableLocations([]);
      setPresentLocations([]);
  })
}

export default GetZoneLocationsDataApiCall;