import axios from 'axios';

const EditRaspiAgentAPICall = async (prevLocationId, locationId, serialNumber, macAddress, setAddAgentStatus, setSeverStatus, setAddRaspiMessage) => {
  const path =   localStorage.getItem("msp_status") === "1" ? "edit_raspi_agent_for_location_msp.php" :"edit_raspi_agent_for_location.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "prev_location_id" :prevLocationId,
    "present_location_id": locationId,
    "serial_number": serialNumber,
    "mac_address": macAddress
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
    "prev_location_id" :prevLocationId,
    "present_location_id": locationId,
    "serial_number": serialNumber,
    "mac_address": macAddress
  });
  const payload = localStorage.getItem("msp_status") === "1"? mspPayload : regPayload;

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setAddAgentStatus(1);
      setSeverStatus("success");
      setAddRaspiMessage(response.data.message)
    }
    else {
      setAddAgentStatus(-1);
      setSeverStatus("error");
      setAddRaspiMessage(response.data.message)
    }
  });
}

export default EditRaspiAgentAPICall