// @mui
import {
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link,
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Outlet } from 'react-router-dom';
import EdgeDeviceDashBaordCards from '../components/EdgeDevicesManagementTable/EdgeDevicesManagementDashboard/EdgeDeviceDashBaordCards';
import EdgeDeviceInfo from '../components/EdgeDeviceInfoComponents/EdgeDeviceInfoDashboard/EdgeDeviceInfo';

// components
import Page from '../components/Page';  
// sections


// ----------------------------------------------------------------------

export default function EdgeDevicesData(props) {
  return (
    <Page title="EdgeDevicesOverview">
      <Container maxWidth={false}>
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/edge-device-data">
              Edge Devices
            </Link>
          </Breadcrumbs>
        </div>
        <Divider/>
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }} data-tut="linkeyetour_home_totalgoodbaddownlinks">
          <EdgeDeviceDashBaordCards />
        </Grid>

        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }} data-tut="linkeyetour_home_totalgoodbaddownlinks">
          <EdgeDeviceInfo />
        </Grid>
      </Container>
    </Page>
  );
}
