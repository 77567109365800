import React from "react";

function LargeLine(props) {
  const line = props.line;
  const [firstPart, secondPart] = line.split("/");

  return (
    <div>
      <p>{firstPart}</p>
      <p>{secondPart}</p>
    </div>
  );
}

export default LargeLine;