import axios from 'axios';

// API CALL CODE HERE
export const SiteAlertsApiCall = async (setAlerts, setAllAlerts, buState) => {

      const path = localStorage.getItem("msp_status") === "1" ? "get_decommissioned_sites_for_msp.php" : "get_decommissioned_sites.php";
      const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(alertBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setAlerts(response.data.site_data);
          setAllAlerts(response.data.site_data);
        }
        else {
          setAlerts([]);
        }
      })
  }

