import axios from "axios"

const DeleteLocationApiCall = async(locationId, locationName, setDeleteStatus, setSeverStatus) => {
    const path = localStorage.getItem("msp_status") === "1" ? "delete_location_for_msp.php" : "delete_location.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "location_data":{
            "location_id": locationId,
            "location_name": locationName,
        }
      });
    
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "location_data":{
            "location_id": locationId,
            "location_name": locationName,
        }
      });
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

   
    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setDeleteStatus(1);
            setSeverStatus("success");
        }
        else
        {
            setDeleteStatus(-1);
            setSeverStatus("error");
        }
    })
  
}

export default DeleteLocationApiCall