import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

import UploadLogoAPICall from './UploadLogoAPICall';

import MenuPopover from '../../components/MenuPopover';
// import { uploadLogo } from './api'; // import your API function here

export default function ChangeLogoPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [custId, setCustId] = useState(localStorage.getItem('cust_id'));

  const imagePath = `${process.env.REACT_APP_ASSETS_SERVER_URL}/cust_logo${custId}.png?${new Date().getTime()}`;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  
  const handleClose = () => {
    setOpen(null);
  };


  const _UploadLogoAPICall = async () => {
    await UploadLogoAPICall(selectedLogo);
  };

  const handleFileChange = (event) => {
    setSelectedLogo(event.target.files[0]);
  };

  const handleLogoUpload = async () => {
    await _UploadLogoAPICall();
    window.location.href = '/dashboard/home';
    // console.log(selectedLogo.type);
  };

  return (
    <>
      <IconButton ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'relative',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              boxShadow: '10px 2px 5px rgba(0, 0, 0, 0.2)',
            },
          }),
        }}>

        <img src={imagePath} alt="logo" style={{ width: '150px', marginRight: '10px', height: "110px", padding: "5px" }} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ m: 1, p: 1, minWidth: 200 }}>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Change Logo
          </Typography>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
          />
          <button type="file"
            ref={fileInputRef}
            onClick={handleLogoUpload}
            style={{
              backgroundColor: '#0E86D4',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 16px',
              border: 'none',
              cursor: 'pointer',
              margin: "10px",
            }}
          >Upload</button>
        </Box>
      </MenuPopover>
    </>
  );
}