import { useEffect, useState } from 'react';

// @mui
import { Box, Grid, Paper, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import useWindowSize from './useWindowSize';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import { GetEdgeDeviceSSHSNMPDataApiCall } from './GetEdgeDeviceSSHSNMPDataApiCall';


// ---------------------------------------------------
const useStyles = makeStyles({
    boxPadding: {
        textDecoration: 'none',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

export default function SitewiseEdgeDeviceData(props) {
    const { locationId } = props;
    const { globalState, setGlobalState } = useGlobalState();

    const [deviceIp, setDeviceIp] = useState("");
    const [snmpPortStatus, setSnmpPortStatus] = useState(-1);
    const [snmpAuthStatus, setSnmpAuthStatus] = useState(-1);
    const [sshPortStatus, setSshPortStatus] = useState(-1);
    const [sshAuthStatus, setSshAuthStatus] = useState(-1);
    const [timeStamp, setTimeStamp] = useState("");

    const classes = useStyles();

    const _GetEdgeDeviceData = () => {
        GetEdgeDeviceSSHSNMPDataApiCall(locationId, setDeviceIp, setSnmpPortStatus, setSnmpAuthStatus, setSshPortStatus, setSshAuthStatus, setTimeStamp);
    }

    useEffect(() => {
        _GetEdgeDeviceData();
    }, [locationId, globalState, deviceIp]);


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', marginBottom: '-10px' }}>
            <Paper sx={{ minWidth: '100%', px: 2, py: 2 }} elevation={15}>
                {deviceIp !== "" && <div>
                    <Typography variant="subtitle1" style={{ paddingBottom: "20px" }} component="div">
                        Site Edge Device SNMP/SSH Status [IP: {deviceIp}]
                    </Typography>
                    <div style={{ display: 'flex', gap: '50px'}}>
                        <Paper sx={{ maxWidth: '30%', px: 2, py: 2, marginBottom: "20px" }} elevation={15}>
                            <Typography variant="subtitle1" style={{ paddingBottom: "10px" }} component="div">
                                SNMP Status
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'normal', paddingBottom: "10px", marginRight: "100px", color: snmpPortStatus === 1 ? "green" : "red" }} component="div">
                                    Port Status : {snmpPortStatus === 1 ? "Open" : "Closed"}
                                </Typography>
                                <Typography variant="subtitle1" style={{ fontWeight: 'normal', paddingBottom: "10px", color: snmpAuthStatus === 1 ? "green" : "red" }} component="div">
                                    Auth Status : {snmpAuthStatus === 1 ? "Success" : "Failed"}
                                </Typography>
                            </div>
                        </Paper>
                        <Paper sx={{ maxWidth: '30%', px: 2, py: 2, marginBottom: "20px" }} elevation={15}>
                            <Typography variant="subtitle1" style={{ paddingBottom: "10px" }} component="div">
                                SSH Status
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography variant="subtitle1" style={{ fontWeight: 'normal', paddingBottom: "10px", marginRight: "100px", color: sshPortStatus === 1 ? "green" : "red" }} component="div">
                                    Port Status : {sshPortStatus === 1 ? "Open" : "Closed"}
                                </Typography>
                                <Typography variant="subtitle1" style={{ fontWeight: 'normal',  color: sshAuthStatus === 1 ? "green" : "red" }} component="div">
                                    Auth Status : {sshAuthStatus === 1 ? "Success" : "Failed"}
                                </Typography>
                            </div>
                        </Paper>
                    </div>

                    <Typography variant="subtitle1" style={{ fontWeight: 'normal', paddingBottom: "10px", textAlign: "right", fontStyle: 'italic' }} component="div">
                        Updated at : {timeStamp}
                    </Typography>
                </div>}
                {deviceIp === "" && <div>
                    <Typography variant="h6" style={{ fontWeight: 'normal', paddingBottom: "10px" }} component="div">
                        Edge Device SNMP/SSH Port/Auth Info Not Available.
                    </Typography>
                </div>}
            </Paper>
        </div>
    );
}
