import axios from 'axios';

export const GetImConfig = async (setWaConfigId, setTeamsConfigId, setWaNumbers, setWaInfoAlertChecked, setWaWarnAlertChecked, setWaCrticalAlertChecked, 
                                  setWaSystemAlertChecked, setTeamsWebHook, setTeamsInfoAlertChecked, setTeamsWarnAlertChecked, 
                                  setTeamsCrticalAlertChecked, setTeamsSystemAlertChecked, setOpenBackdrop) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_im_config_settings_for_msp.php" : "get_im_config_settings.php";
  const alerMailtBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(alerMailtBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      response.data.im_config.forEach(item=>{

        if(item.im_name === "WhatsApp"){
          setWaConfigId(item.config_id)
          setWaNumbers(item.ph_no_or_hook)
          setWaInfoAlertChecked(item.info_alerts === 1)
          setWaWarnAlertChecked(item.warn_alerts === 1)
          setWaCrticalAlertChecked(item.critical_alerts === 1)
          setWaSystemAlertChecked(item.system_alerts === 1)
        }

        if(item.im_name === "Teams"){
          // console.log(item)
          setTeamsConfigId(item.config_id);
          setTeamsWebHook(item.ph_no_or_hook)
          setTeamsInfoAlertChecked(item.info_alerts === 1)
          setTeamsWarnAlertChecked(item.warn_alerts === 1)
          setTeamsCrticalAlertChecked(item.critical_alerts === 1)
          setTeamsSystemAlertChecked(item.system_alerts === 1)
        }
      })
      setOpenBackdrop(false);
    }
    else {
      setOpenBackdrop(false);
    }
  })

}



