import { useState, useEffect, forwardRef } from 'react';
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Toolbar,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,

} from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';


// components
import Iconify from '../Iconify';
import WifiTroubleshootInsightsDialog from './WifiTroubleshootInsightsDialog';

import { useGlobalState } from '../../globalHooks/GlobalState';

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});

const headCells = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'user_name', label: 'User Name', alignRight: false },
  { id: 'mac_address', label: 'MAC Address', alignRight: false },
  { id: 'ap_name', label: 'Connected AP', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'ssid', label: 'SSID', alignRight: false },
  { id: 'signal_strength', label: 'Signal Strength', alignRight: false },
  { id: 'ip_addr', label: 'IP Address', alignRight: false },
  { id: 'co_pilot_summary', label: 'Co-Pilot Summary', alignRight: false },
  { id: 'insights', label: 'Insights', alignRight: false },
];

const wifis = [
  {
    'user_name': 'Sahana M',
    'mac_addr': 'a0:29:42:22:fb:32',
    'ap_name': 'Open-Area-2F',
    'location': 'Bangalore HQ',
    'ssid': 'Secure',
    'signal_strength': '56',
    'ip_addr': '0.0.0.0',
    'co_pilot_summary': 'Client Authentication is Successful,But Client is unable to Get an IP Address. The Role configuration in the AccessPoint has DHCP allowed . Check VLAN Configuration on the Transient Switches or Check the DHCP server Lease',
    'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status.Checking Client SNR. Checking User Firewall/Role  Policies. Checking Client VLAN Configuration on SSID.Checking IP Address Assignment',
    'rca_data': ''
  },
  {
    'user_name': 'Sachin Gupta',
    'mac_addr': '1e:a6:22:72:be:c7',
    'ap_name': '4F-3B-AP',
    'location': 'Delhi', 'ssid':
      'Secure', 'signal_strength': '72',
    'ip_addr': '0.0.0.0',
    'co_pilot_summary': 'Client Association was successful But Dot1x Authentication failed . External Radius Server is configured . Please check the Logs in the External Radius Server.',
    'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status',
    'rca_data': ''
  },
  {
    'user_name': 'Charles',
    'mac_addr': '28:de:65:b1:a3:25',
    'ap_name': 'FF-25-AP',
    'location': 'Chennai',
    'ssid': 'Secure',
    'signal_strength': '65',
    'ip_addr': '0.0.0.0',
    'co_pilot_summary': 'Client Association failed due to Maximum client Thresold reached on the AccessPoint .Check the Status of Other AccessPoints in vicinity or increase the AP count.',
    'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model',
    'rca_data': ''
  }
]
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function EnhancedTableHead({ showCustomer, tagDataKeys }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.slice(0, 14).map((headCell) => (
          (headCell.id !== 'cust_name' || showCustomer) &&
          <TableCell
            sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  showCustomer: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  tagDataKeys: PropTypes.array.isRequired,
};

function EnhancedTableToolbar({ numSelected, filterName, onFilterName, handleAddClick }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Typography variant='h4'>
        Wifi Events
      </Typography>

      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        onKeyDown={onFilterName}
        onKeyUp={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function ViewWifiTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();
  const [value, setValue] = useState(null);
  const [openInsightDialog, setOpenInsightDialog] = useState(false);

  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);


  const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })

  const onInsightClick = (e, val) => {
    setValue(val);
    setOpenInsightDialog(true);
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <EnhancedTableToolbar
            tableHeading={'View'}
          />
          <TableContainer>
            <Table stickyHeader>
              <EnhancedTableHead
              />
              <TableBody>
                {wifis.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                    >
                      <TableCell align="left" >{index + 1}</TableCell>
                      <TableCell>{row.user_name}</TableCell>
                      <TableCell>{row.mac_addr}</TableCell>
                      <TableCell>{row.ap_name}</TableCell>
                      <TableCell>{row.location}</TableCell>
                      <TableCell>{row.ssid}</TableCell>
                      <TableCell>{row.signal_strength}</TableCell>
                      <TableCell>{row.ip_addr}</TableCell>
                      <TableCell style={{ width: '600px' }}>{row.co_pilot_summary}</TableCell>
                      <TableCell>
                        <IconButton variant="outlined" color="primary" onClick={(e) => { onInsightClick(e, row) }}>
                          <TipsAndUpdatesIcon style={{ color: '#FFD400' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {openInsightDialog && <WifiTroubleshootInsightsDialog value={value} openInsightDialog={openInsightDialog} setOpenInsightDialog={setOpenInsightDialog} />}
      </Grid>
    </>
  );
}
