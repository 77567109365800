import axios from 'axios';

// API CALL CODE HERE

const CheckEdLanStatusApiCall = async (ispWanId, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage, fwsetEdLanStatusMessage, fwsetDetailEdLanStatusMessage, fwsetRcaEdLanStatusMessage, setGreenPass, setRedFail, setRedFails) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_edge_device_reachability_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });
  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 2 || response.data.code === 3 || response.data.code === 4 || response.data.code === 5) {
      setEdAddedStatus(false)
      setGotEdLanStatus(true)
      setGotEdPortStatus(false);
      fwsetEdLanStatusMessage(response.data.message)
      fwsetDetailEdLanStatusMessage(response.data.detailed_message)
      fwsetRcaEdLanStatusMessage(response.data.rca_message)
      setCode(response.data.code)
      setRedFails(true)
      setEdLanStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
      return
    }
    if (response.data.reachability_status === 1 && response.data.code === 1) {
      setEdLanStatus(true)
      setContinueEdHealthStatus(true)
      setContinueEdPortStatus(true);
      fwsetEdLanStatusMessage(response.data.reachability_message)
      fwsetDetailEdLanStatusMessage(response.data.reachability_detailed_message)
      fwsetRcaEdLanStatusMessage(response.data.reachability_rca_message)
      setGreenPass(true)

    } else if (response.data.reachability_status === -1 && response.data.code === 1) {
      setEdLanStatus(true)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(true);
      fwsetEdLanStatusMessage(response.data.reachability_message)
      fwsetDetailEdLanStatusMessage(response.data.reachability_detailed_message)
      fwsetRcaEdLanStatusMessage(response.data.reachability_rca_message)
      setRedFail(true)
    }
    else if (response.data.reachability_status === -1 && response.data.code === -1) {
      setEdLanStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
      fwsetEdLanStatusMessage(response.data.reachability_message)
      fwsetDetailEdLanStatusMessage(response.data.reachability_detailed_message)
      fwsetRcaEdLanStatusMessage(response.data.reachability_rca_message)
      setRedFails(true)

    }
    else {
      setEdLanStatus(false)
      setContinueEdHealthStatus(false)
      setContinueEdPortStatus(false);
      fwsetEdLanStatusMessage(response.data.message)
      fwsetDetailEdLanStatusMessage(response.data.detailed_message)
      fwsetRcaEdLanStatusMessage(response.data.rca_message)
    }

    setEdAddedStatus(false)
    setGotEdLanStatus(true)

  })
}

export default CheckEdLanStatusApiCall;