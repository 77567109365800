import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Grid,
  Box,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton
} from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Iconify from '../../Iconify';

// API Call Imports
import UpgradeLicenseComponent from './UpgradeLicenseComponent';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import GetLicenseApiCall from './GetLicenseApiCall';

const headCells = [
  { id: 'slNo', label: 'Sl No', numeric: true, disablePadding: false },
  { id: 'License Type', label: 'License Type', numeric: false, disablePadding: false },
  { id: 'License Key', label: 'License Key', numeric: false, disablePadding: false },
  { id: 'License Validity', label: 'License Validity', numeric: false, disablePadding: false }


];

function EnhancedTableHead(props) {
  const { order, orderBy } =
    props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ background: '#f0f0f0', padding: '10px' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function EnhancedTableToolbar(props) {
  const { handleAddClick } = props;

  return (
    <RootStyle>
      <Typography variant='h4'>
        License
      </Typography>
      <Box
        width="75%"
        display="flex"
        justifyContent="flex-end"
        paddingRight="10px"
      >
        <Box
          width="10px"
        />
        <Button
          variant="contained"
          endIcon={<UpgradeIcon />}
          sx={{ width: "250px", height: "40px", paddingLeft: "10px" }}
          onClick={handleAddClick}
        >
          Extend License
        </Button>
      </Box>

      <SearchStyle
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ViewLicenseComponent() {
  const [groups, setGroups] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [openAddSiteGroupDialog, setOpenAddSiteGroupDialog] = useState(false);
  const [addLicenseStatus, setAddLicenseStatus] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const { globalState, setGlobalState } = useGlobalState();

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const handleAddClick = () => {
    if (localStorage.getItem("msp_status") === "1" && localStorage.getItem("cust_id") === localStorage.getItem("reg_cust_id")) {
      setOpenSnackBar(true);
      return;
    }
    setOpenAddSiteGroupDialog(true);
  }
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const _GetLicenseDetailsApiCall = async () => {
    await GetLicenseApiCall(setGroups);
  }

  useEffect(() => {
    _GetLicenseDetailsApiCall();

  }, [globalState, addLicenseStatus, editStatus, deleteStatus]);

  const resetAll = () => {
    setOpenAddSiteGroupDialog(false);
    setAddLicenseStatus(false);
    setOpenEditDialog(false);
    setEditStatus(false);
    setOpenDeleteDialog(false);
    setDeleteStatus(false);
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetAll();
  };

  const handleDataReLoad = () => {
    _GetLicenseDetailsApiCall();
  };

  const styles = {
    Background: {
      backgroundColor: '#6495ED',
      color: '#002244',
      // border: '1px solid #0E3386', 
      borderRadius: '8px',
      padding: '20px',
      margin: '20px auto',
      textAlign: 'center',
      maxWidth: '600px',
      boxShadow: '0px 10px 20px #72A0C1 '

    },
    title: {
      margin: '0',
      paddingBottom: '10px',
      fontSize: '1.5em'
    },
    bodyText: {
      margin: '0',
      fontSize: '1.1em'
    }
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
            {/* Please select a customer. */}
          </Alert>
        </Snackbar>
        <EnhancedTableToolbar handleAddClick={handleAddClick} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750, maxHeight: 500 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
            />
            <TableBody>
              {groups.map((row, index) =>
                <TableRow
                  hover
                  key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">{row.license_type}</TableCell>
                  <TableCell align="left">{row.license_key}</TableCell>
                  <TableCell align="left">{row.license_end_date}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {openAddSiteGroupDialog &&
        <UpgradeLicenseComponent
          openAddSiteGroupDialog={openAddSiteGroupDialog}
          setOpenAddSiteGroupDialog={setOpenAddSiteGroupDialog}
          setAddLicenseStatus={setAddLicenseStatus} handleDataReLoad={handleDataReLoad}
          defaultLicenseType={groups.length > 0 ? groups[0].license_type : ''} // Pass the default license type
        />}



      {addLicenseStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          License Request for Upgradation sent Successfully.
        </Alert>
      </Snackbar>}
      {addLicenseStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          Error in sending License Request for Upgradation.
        </Alert>
      </Snackbar>}

      <Paper style={styles.Background} elevation={15}>
        <h3 style={styles.title}>Processing Your License Extending Request.</h3>
        <p style={styles.bodyText}>Please wait</p>
      </Paper>
    </Box>
  );
}