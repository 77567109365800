import axios from 'axios';

export const UpdateSystemAlertMailSettingsAPICall = async(toMails, setUpdateSuccessStatus) =>{
  
  const path = localStorage.getItem("msp_status") === "1" ? "push_system_alert_mail_settings_for_msp.php" : "push_system_alert_mail_settings.php";
  const alerMailtBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "to_mails": toMails
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "to_mails": toMails,
    "msp_status": 1,
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(alerMailtBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateSuccessStatus(true)
    }
    else{
      setUpdateSuccessStatus(false)
    }
  })

}


