import axios from 'axios';

// API CALL CODE HERE

const CheckWanInterfaceStatusApiCall = async(ispWanId, setEdInterfaceStatus, setGotEdInterfaceStatus, setEdInterfaceMessage, setContinueWanIpLanStatus, setDetailEdInterfaceMessage, setRcaEdInterfaceMessage, edVendorId,setContinueInternetRechStatus,setCheckWanStatus) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_interface_status_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response)=>{
    if(response.data.code === 1){
        setEdInterfaceStatus(true)
        setContinueWanIpLanStatus(true)
        setCheckWanStatus(true);
    }
    else
    {
        setEdInterfaceStatus(false)
        setContinueWanIpLanStatus(false)
        setCheckWanStatus(false);
    }

    setGotEdInterfaceStatus(true)
    setEdInterfaceMessage(response.data.message)
    setDetailEdInterfaceMessage(response.data.detailed_message) 
    setRcaEdInterfaceMessage(response.data.rca_message)
    if(edVendorId === 10){
      setContinueInternetRechStatus(true)
    }
  })
}

export default CheckWanInterfaceStatusApiCall;
