import {
    Breadcrumbs, Container, Link,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import ViewWifiComponents from '../components/WifiComponents/ViewWifiComponents';

import Page from '../components/Page';
// ----------------------------------------------------------------------

export default function ViewWifi() {

    return (
        <Page title="View Wifi">
            <Container maxWidth={false}>
                <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit">
                            <BuildIcon />
                        </Link>
                        
                        <Link
                            underline="hover"
                            color="inherit"
                        >
                            Wifi Management
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/dashboard/view-wifi"
                        >
                            Wifi Events
                        </Link>
                    </Breadcrumbs>
                </div>

                <ViewWifiComponents />

            </Container>
        </Page>
    );
}
