import axios from 'axios';

export const GetEmailConfig = async (setPresentSettings, setOpenBackdrop) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_email_settings_for_msp.php" : "get_email_settings.php";
  const alerMailtBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(alerMailtBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setPresentSettings(response.data.settings);
      setOpenBackdrop(false);
    }
    else {
      setPresentSettings(response.data.settings);
      setOpenBackdrop(false);
    }
  })

}



