import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Paper, Typography, Box, Divider, useMediaQuery, useTheme, Modal, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { pdf, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import GetSLABreachesCountsApiCall from '../ApiCalls/GetSLABreachesCountsApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    boxPadding: {
        padding: '25px',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 20,
    },
    header: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        textAlign: 'right',
        fontSize: 12,
        marginBottom: 10,
    },
    tableTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: 5,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColHeader: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: '#f0f0f0',
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCellHeader: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: 5,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
        textAlign: 'left',
        paddingLeft: 5,
        // fontWeight: 'bold'
    },
});

const MyDocument = ({ slaData, currentDate }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <Text style={styles.header}>SLA Breaches Report</Text>
                <Text style={styles.date}>Date: {currentDate}</Text>
            </View>
            <View>
                <Text style={styles.tableTitle}>TTR Breaches</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Location</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Breaches</Text>
                        </View>
                    </View>
                    {slaData && slaData.ttr_breaches && Object.entries(slaData.ttr_breaches).map(([location, breaches]) => (
                        <View style={styles.tableRow} key={location}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{location}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{breaches}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <Text style={styles.tableTitle}>Uptime Breaches</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Location</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Breaches</Text>
                        </View>
                    </View>
                    {slaData && slaData.uptime_breaches &&  Object.entries(slaData.uptime_breaches).map(([location, breaches]) => (
                        <View style={styles.tableRow} key={location}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{location}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{breaches}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);

export default function SLABreachesComponent(props) {
    const { locationId, selectedGroupIds, currentDate } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        p: 4,
    };

    const classes = useStyles();
    const [isCounting, setIsCounting] = useState(false);

    const [ttrBreachesCnt, setTtrBreachesCnt] = useState(0);
    const [uptimeBreachesCnt, setUptimeBreachesCnt] = useState(0);
    const [slaData, setSlaData] = useState([])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    // const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    // const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    // const isMd = useMediaQuery(theme.breakpoints.only('md'));
    // const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    // const isXl = useMediaQuery(theme.breakpoints.only('xl'));
    // let fontSize = '20px';
    // let chartHeight = 200;
    // let margintop = 1;
    // let dateFontWeight = '10px';
    // let paddingLeft = '1px';
    // if (isXs) {
    //     fontSize = '20px';
    //     chartHeight = 150;
    // } else if (isSm) {
    //     fontSize = '20px';
    //     chartHeight = 150;
    // } else if (isMd) {
    //     fontSize = '20px';
    //     chartHeight = 150;
    // } else if (isLg) {
    //     fontSize = '18px';
    //     chartHeight = 210;
    //     margintop = -2;
    //     dateFontWeight = '13px';
    //     paddingLeft = '80px';
    // } else if (isXl) {
    //     fontSize = '18px';
    //     chartHeight = 250;
    //     paddingLeft = '45px';
    // }

    useEffect(() => {
        setTimeout(() => {
            setIsCounting(false);
        }, 1000);
        GetSLABreachesCountsApiCall(locationId, selectedGroupIds, setTtrBreachesCnt, setUptimeBreachesCnt, buState, setSlaData);
    }, [isCounting, locationId, selectedGroupIds, globalState, buState]);

    // Define the animation properties
    const animationTtr = useSpring({
        from: { number: 0 },
        to: { number: ttrBreachesCnt },
        config: { duration: 1000 },
        onRest: () => setIsCounting(false),
    });

    const animationUptime = useSpring({
        from: { number: 0 },
        to: { number: uptimeBreachesCnt },
        config: { duration: 1000 },
        onRest: () => setIsCounting(false),
    });
    
    const handleDownloadClick = async () => {
        const doc = <MyDocument slaData={slaData} currentDate={currentDate} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SLA_Breaches_Report.pdf';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Paper elevation={8}>
            <div className={classes.titleStyle}>
                <Grid container spacing={2}>
                    <Grid item>
                    <Typography
                    variant='h6'
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    // sx={{ color: '#003366', fontSize, fontWeight: 'bold', cursor: 'pointer' ,textDecoration: 'underline'}}
                    component={'span'}
                    onClick={handleOpen}
                >
                    SLA Breaches ( In Nos )
                    
                </Typography>
                    </Grid>
               <Grid item sx={{cursor:'pointer'}} onClick={handleDownloadClick}><DownloadIcon /></Grid>
                </Grid>
            </div>
            <Divider />
            <Box display="flex" justifyContent="center">
                <Box justifyContent="center" alignItems="center" height={"180px"} width={"50%"}>
                    
                    <Typography variant='h3' sx={{ color: 'red', 
                        paddingLeft:"40%", paddingTop:"30px" }} >
                        <animated.span>
                            {isCounting ? animationTtr.number.to((value) => value.toFixed(0)) : ttrBreachesCnt}
                        </animated.span>
                    </Typography>
                    
                    <Typography variant='h6' sx={{ color: 'red', paddingLeft:"30%", paddingTop:"10px"}} >  
                        TTR Breaches
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="right" sx={{ paddingRight: "20px", paddingTop: "30px" }}>
                       
                        <Typography variant='h12' sx={{ color: '#003366' }} component={'span'}>
                            On {currentDate}
                        </Typography>
                    </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box justifyContent="center" alignItems="center" height={"180px"} width={"50%"}>
                <Typography variant='h3' sx={{ color: 'red', paddingLeft:"40%", paddingTop:"30px" }} >
                    
                        <animated.span>
                            {isCounting ? animationUptime.number.to((value) => value.toFixed(0)) : uptimeBreachesCnt}
                        </animated.span>
                    </Typography>
                    
                    <Typography variant='h6' sx={{ color: 'red', paddingLeft:"30%", paddingTop:"10px"}} >
                        Uptime Breaches
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="right" sx={{ paddingRight: "20px", paddingTop: "30px" }}>
                        {/* <Typography variant='h12' sx={{ color: '#003366', fontSize: dateFontWeight }} component={'span'}> */}
                        <Typography variant='h12' sx={{ color: '#003366' }} component={'span'}>
                            On {currentDate}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={ {...style,
                     position: 'relative', 
                     flexDirection: 'column',
                     cursor:'pointer',
                     maxHeight: '80vh',
                     overflowY: 'auto',
                      '::-webkit-scrollbar': {
                         width: '6px',  
                       },
                      '::-webkit-scrollbar-thumb': {
                         backgroundColor: '#888', 
                         borderRadius: '2px', 
                        },
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        TTR Breaches
                    </Typography>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ul>
                            {slaData && slaData.ttr_breaches && Object.entries(slaData.ttr_breaches).map(([location, breaches]) => (
                                <li key={location}>
                                   <strong>{location}</strong>: {breaches}
                                </li>
                            ))}
                        </ul>
                    </Typography>
                    <br />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Uptime Breaches
                    </Typography>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ul>
                            {slaData && slaData.uptime_breaches && Object.entries(slaData.uptime_breaches).map(([location, breaches]) => (
                                <li key={location}>
                                    <strong>{location}</strong>: {breaches}
                                </li>
                            ))}
                        </ul>
                    </Typography>
                    <br />
                    <Box
                         sx={{
                            position: 'sticky',  
                            bottom: 0,           
                            width: '100%',       
                            display: 'flex',     
                            justifyContent: 'flex-end', 
                            // padding: '16px',     
                            zIndex: 1,           
                           }}
            
        >
                    {/* <Grid container item display='flex' justifyContent='flex-end'> */}
                        <Button variant="contained" onClick={handleDownloadClick}>
                            Download
                        </Button>
                        <Button variant="text" onClick={handleClose}>Close</Button>
                    {/* </Grid> */}
                    </Box>
                </Box>
            </Modal>
        </Paper>
    );
}
