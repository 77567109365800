import axios from 'axios';

// API CALL CODE HERE
const DeleteSiteGroupApiCall = async (groupId, groupName, setDeleteStatus) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/delete_site_group.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "group_id": groupId,
    "group_name": groupName
  });

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setDeleteStatus(1);
    }
    else {
      setDeleteStatus(-1);
    }
  }).catch(()=>{
    setDeleteStatus(-1);
  })
}

export default DeleteSiteGroupApiCall;