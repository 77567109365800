import axios from 'axios';

// API CALL CODE HERE

const AddBusinessUnitApiCall = async (businessUnitName,businessUnitHeadOffice, setBusinessUnitNameStatus, setBusinessUnitNameMessage) => {

const path = localStorage.getItem("msp_status") === "1" ? "create_new_business_unit_for_msp.php" : "create_new_business_unit.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "business_unit_data": {
		"bu_name": businessUnitName,
    "bu_head_office" : businessUnitHeadOffice
	}
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "business_unit_data": {
      "bu_name": businessUnitName,
      "bu_head_office" : businessUnitHeadOffice
    }
  })
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setBusinessUnitNameStatus(1);
      setBusinessUnitNameMessage(response.data.message)
    }
    else {
      setBusinessUnitNameStatus(-1);
      setBusinessUnitNameMessage(response.data.message)
    }
  }).catch((error) => {
    setBusinessUnitNameStatus(-1);
    setBusinessUnitNameMessage(error.response.data.message)

  })
}

export default AddBusinessUnitApiCall;