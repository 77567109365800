import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import { ISPLocationsDataAPICall } from "../../../pages/SitewiseRestAPICalls";
import AddNewRaspiAgentAPICall from './AddNewRaspiAgentAPICall';

export default function AddNewRaspiAgentsDialog(props) {
    const { openAddRaspiDialog, setOpenAddRaspiDialog, setAddAgentStatus, setAddAgentOpen, setSeverStatus, setAddRaspiMessage } = props;

    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const [locationData, setLocationdata] = useState(null);
    const [ispLocations, setIspLocations] = useState([]);
    const [firstLocationName, setFirstLocationName] = useState("");
    const [locationId, setLocationId] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const [macAddress, setMacAddress] = useState("");
    const [serialNumberHelperText, setSerialNumberHelperText] = useState("");
    const [macAddressHelperText, setMacAddressHelperText] = useState("");
    const [siteHelperText, setSiteHelperText] = useState("");

    const [isSerialDisabled, setIsSerialDisabled] = useState(false);
    const [isMacDisabled, setIsMacDisabled] = useState(false);
    const [warning, setWarning] = useState("")
const [critical, setCritical]= useState("")

    const serialRegex = /^RSPI[A-Za-z0-9]+$/;
    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "serial_number":
                if (!e.target.value.match(serialRegex)) {
                    setSerialNumberHelperText("Serial Number should be valid");
                } else {
                    setSerialNumberHelperText("");
                }
                setSerialNumber(e.target.value);
                setIsMacDisabled(!!e.target.value);
                break;

            case "mac_address":
                if (!e.target.value.match(macRegex)) {
                    setMacAddressHelperText("Mac Address Should be Valid");
                } else {
                    setMacAddressHelperText("");
                }
                setMacAddress(e.target.value);
                setIsSerialDisabled(!!e.target.value);
                break;

            default:
                break;
        }
    };

    const handleOnSubmit = (e) => {
        if (!isSerialDisabled) {
            if (!serialNumber.match(serialRegex)) {
                setSerialNumberHelperText("Serial Number should be valid");
                return;
            }
        }
        if (!isMacDisabled) {
            if (!macAddress.match(macRegex)) {
                setMacAddressHelperText("Mac Address Should be Valid");
                return;
            }
        }

        if (selectedLocation === null) {
            setSiteHelperText("Please Select Site");
            return;
        }

        AddNewRaspiAgentAPICall(locationId, serialNumber, macAddress, setAddAgentStatus, setSeverStatus, setAddRaspiMessage);
        setAddAgentOpen(true);
        setOpenAddRaspiDialog(false);
    };

    const handleLocationChange = (event, newValue) => {
        setSelectedLocation(newValue);
        setLocationId(newValue ? newValue.location_id : null);
        setSiteHelperText("");
    };

    const _ISPLocationsDataAPICall = async () => {
        await ISPLocationsDataAPICall(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState, setWarning, setCritical);
    }

    useEffect(() => {
        _ISPLocationsDataAPICall();
    }, [globalState, buState]);

    const handleOnCancel = () => {
        setOpenAddRaspiDialog(false);
    };

    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
        }
    }, [globalState]);

    return (
        <>
            <Dialog open={openAddRaspiDialog} onClose={handleOnCancel} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title">
                <DialogTitle sx={{ py: 2 }}>
                    Add Agent
                    <IconButton sx={{ float: 'right' }} onClick={handleOnCancel}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form onSubmit={handleOnSubmit}>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Serial Number"
                                    id="serial_number"
                                    helperText={serialNumberHelperText}
                                    error={!!serialNumberHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange}
                                    required
                                    disabled={isSerialDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="MAC Address(optional)"
                                    id="mac_address"
                                    helperText={macAddressHelperText}
                                    error={!!macAddressHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange}
                                    // required
                                    disabled={isMacDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth error={Boolean(siteHelperText)}>
                                    <Autocomplete
                                        id="location-select"
                                        options={ispLocations}
                                        getOptionLabel={(option) => option.location ? option.location : "No location assigned"}
                                        value={selectedLocation}
                                        onChange={handleLocationChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Site"
                                                required
                                                error={Boolean(siteHelperText)}
                                            />
                                        )}
                                        style={{ width: '100%' }}
                                    />
                                    {siteHelperText && (
                                        <FormHelperText>{siteHelperText}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleOnCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleOnSubmit} disabled={!selectedLocation}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

