// @mui
import {
    Breadcrumbs,
    Container,
    Divider,
    Link,
  } from '@mui/material';
  import BuildIcon from '@mui/icons-material/Build';
  
  // components
  import Page from '../components/Page';  
  import SiteGroupComponents from '../components/SiteGroupComponents/SiteGroupComponents';
  
  export default function SiteGroups(props) {
    return (
      <Page title="Overview">
        <Container maxWidth={false}>
          <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit">
                <BuildIcon />
              </Link>
              <Link underline="hover" color="inherit">
                Site Management
              </Link>
              <Link underline="hover" color="inherit" href="/dashboard/site-groups">
                Site Groups
              </Link>
            </Breadcrumbs>
          </div>
          <Divider/>
            <SiteGroupComponents />
        </Container>
      </Page>
    );
  }
  