import axios from 'axios';

// API CALL CODE HERE
const GetTemplatesApiCall = async (setRows) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_templates_details.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });
  // console.log(payload);
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setRows(response.data.templates);
    }
    else {
        setRows([]);
    }
  })
}

export default GetTemplatesApiCall;