import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
    Typography, Divider,  FormHelperText
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


// Api Import
import EditVendorApiCall from './EditVendorApiCall';


export default function EditVendorDialog(props) {
    const { value, openEditVendorDialog, setOpenEditVendorDialog, setEditVendorStatus, setEditVendorMessage } = props;

    const [vendorId, setVendorId] = useState(value.vendor_id);
    const [vendorName, setVendorName] = useState(value.vendor_name);
    const [vendorAddress, setVendorAddress] = useState(value.vendor_address);
    const [vendorContact, setVendorContact] = useState(value.vendor_phone_no);
    const [helpDeskEmail, setHelpDeskEmail] = useState(value.helpdesk_email);
    const [escalationLevel1Mail, setEscalationLevel1Mail] = useState(value.escalation_email_1);
    const [escalationLevel2Mail, setEscalationLevel2Mail] = useState(value.escalation_email_2);
    const [vendorCity, setVendorCity] = useState(value.city);
    const [promisedUptime, setPromisedUptime] = useState(value.promised_uptime);
    const [resolutionTime, setResolutionTime] = useState(value.resolution_time);
    const [dynamicFieldErrors, setDynamicFieldErrors] = useState({});

    const [vendorNameHelperText, setVendorNameHelperText] = useState("");
    const [vendorAddressHelperText, setVendorAddressHelperText] = useState("");
    const [vendorContactHelperText, setVendorContactHelperText] = useState("");
    const [helpDeskEmailHelper, setHelpDeskEmailHelper] = useState("");
    const [escalationLevel1MailHelper, setEscalationLevel1MailHelper] = useState("");
    const [escalationLevel2MailHelper, setEscalationLevel2MailHelper] = useState("");
    const [vendorCityHelper, setVendorCityHelper] = useState("");
    const [promisedUptimeHelper, setPromisedUptimeHelper] = useState("");
    const [resolutionTimeHelper, setResolutionTimeHelper] = useState("");
    const [customTagsData, setCustomTagsData] = useState(value.tag_data)
    const vendorNameRegex = /^[a-zA-Z][a-zA-Z0-9(),-_\s]*[A-Za-z\d]$/;
    const vendorAddressRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*[A-Za-z\d]$/;
    const vendorContactRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9,()-_\s]*$/;
    const uptimeRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/;
    const resolutionTimeRegex = /^(?:\d{1,2}|100)$/;

    const handleOnClose = () => {
        setOpenEditVendorDialog(false);
    }
    

    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "vendor_name":
                if (!e.target.value.match(vendorNameRegex)) {
                    setVendorNameHelperText("Vendor Name Should be Valid");
                } else {
                    setVendorNameHelperText("");
                }
                setVendorName(e.target.value);
                break;
            case "vendor_address":
                if (!e.target.value.match(vendorAddressRegex)) {
                    setVendorAddressHelperText("Vendor Address Should be Valid");
                } else {
                    setVendorAddressHelperText("");
                }
                setVendorAddress(e.target.value);
                break;
            case "vendor_contact":
                if (!e.target.value.match(vendorContactRegex)) {
                    setVendorContactHelperText("Vendor Contact Should be Valid");
                } else {
                    setVendorContactHelperText("");
                }
                setVendorContact(e.target.value);
                break;
            case "help_desk_email":
                if (!e.target.value.match(emailRegex)) {
                    setHelpDeskEmailHelper("Enter Valid Email ID");
                } else {
                    setHelpDeskEmailHelper("");
                }
                setHelpDeskEmail(e.target.value);
                break;
            case "escalation_level1_mail":
                if (!e.target.value.match(emailRegex)) {
                    setEscalationLevel1MailHelper("Enter Valid Email ID");
                } else {
                    setEscalationLevel1MailHelper("");
                }
                setEscalationLevel1Mail(e.target.value);
                break;
            case "escalation_level2_mail":
                if (!e.target.value.match(emailRegex)) {
                    setEscalationLevel2MailHelper("Enter Valid Email ID");
                } else {
                    setEscalationLevel2MailHelper("");
                }
                setEscalationLevel2Mail(e.target.value);
                break;
            case "vendor_city":
                if (!e.target.value.match(cityRegex)) {
                    setVendorCityHelper("Enter Valid City Name");
                } else {
                    setVendorCityHelper("");
                }
                setVendorCity(e.target.value);
                break;
            case "promised_uptime":
                if (e.target.value === "" || !e.target.value.match(uptimeRegex)) {
                    setPromisedUptimeHelper("Enter Valid Promised Uptime");
                } else {
                    setPromisedUptimeHelper("");
                }
                setPromisedUptime(e.target.value);
                break;
            case "resolution_time":
                if (!e.target.value.match(resolutionTimeRegex)) {
                    setResolutionTimeHelper("Enter Valid Resolution Time");
                } else {
                    setResolutionTimeHelper("");
                }
                setResolutionTime(e.target.value);
                break;
            default:
                setResolutionTime(e.target.value);
                break;
        }
    };

    const handleDynamicFieldChange = (event, index) => {
        const newValue = event.target.value;
    
        setCustomTagsData(prevData => {
            const updatedData = [...prevData];
            const field = updatedData[index];
    
            updatedData[index] = {
                ...field,
                value: newValue 
            };
    
            const { label,value, data_type: dataType, is_mandatory: isMandatory } = field; 
            const errors = { ...dynamicFieldErrors }; 
    
           
            if (isMandatory === 1 && (!newValue || newValue.trim() === "")) {
                errors[label] = `${field.label || label} is required`;
            } else {
                delete errors[label]; 
            }
    
          
            if (dataType) {
                switch (dataType.toUpperCase()) {
                    case 'WHOLE NUMBER': {
                        if (Number.isNaN(Number(newValue)) || !Number.isInteger(Number(newValue))) {
                            errors[label] = `Please enter a valid ${dataType}`;
                        } else {
                            delete errors[label];
                        }
                        break;
                    }
    
                    case 'DECIMAL': {
                        if (newValue && (Number.isNaN(Number(newValue)) || !parseFloat(newValue))) {
                            errors[label] = `Please enter a valid ${dataType}`;
                        } else {
                            delete errors[label];
                        }
                        break;
                    }
    
                    case 'STRING': {
                        if (newValue.trim() === "") {
                            errors[label] = ""; 
                        } else if (/^[A-Za-z0-9\s]+$/.test(newValue)) {
                            delete errors[label]; 
                        } else {
                            errors[label] = "Please enter a valid String (letters, numbers, and spaces only).";
                        }
                        break;
                    }
    
                    case 'DATE': {
                        if (!newValue) {
                            errors[label] = `${field.label || label} is required`;
                        } else {
                            delete errors[label];
                        }
                        break;
                    }
    
                    case 'SELECT': {
                        if (isMandatory === 1 && (!newValue || newValue.trim() === "")) {
                            errors[label] = `${label} is required. Please select an option.`;
                        } else {
                            delete errors[label];
                        }
                        break;
                    }
    
                    default:
                        break;
                }
            }
    
            setDynamicFieldErrors(errors); 
            return updatedData;
        });
    };
    

    const validateDynamicFields = () => {
        let valid = true;
        const errors = {};

        customTagsData.forEach(field => {
            const { label, value, data_type: dataType, is_mandatory: isMandatory } = field; 

           
            if (isMandatory === 1 && (!value || value.trim() === "")) {
                errors[label] = `${label} is required`; 
                valid = false;
            }

           if (isMandatory === 1 && value && dataType) {
                switch (dataType.toUpperCase()) {
                    case 'WHOLE NUMBER': {
                        if (Number.isNaN(Number(value)) || !Number.isInteger(Number(value))) {
                            errors[label] = `Please enter valid ${dataType} only`;
                            valid = false;
                        }
                        break;
                    }
                    case 'DECIMAL': {
                        if (Number.isNaN(Number(value)) || !parseFloat(value)) {
                            errors[label] = `Please enter valid ${dataType} only`;
                            valid = false;
                        }
                        break;
                    }
                    case 'STRING': {
                        if (value.trim() === "") {
                            errors[label] = ""; 
                        } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                            errors[label] = `Please enter valid ${dataType} (letters, numbers, and spaces only)`; 
                            valid = false;
                        }
                        break;
                    }
                    case 'SELECT': {
                        if (isMandatory === 1 && (!value || value.trim() === "")) {
                            errors[label] = `${label} is required. Please select an option.`;
                            valid = false;
                        }
                        break;
                    }
                    case 'DATE': {
                        if (!value || value.trim() === "") {
                            errors[label] = `${label} is required`; 
                            valid = false;
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
        });

        setDynamicFieldErrors(errors);
        return valid;
    };



    const handleOnSave = (e) => {
        e.preventDefault();
        if (!vendorName.match(vendorNameRegex)) {
            setVendorNameHelperText("Vendor Name Should be Valid");
            return;
        }
        if (!String(vendorAddress).match(vendorAddressRegex)) {
            setVendorAddressHelperText("Vendor Address Should be Valid");
            return;
        }
        // if (!String(vendorContact).match(vendorContactRegex)) {
        //     setVendorContactHelperText("Phone Number should be Valid");
        //     return;
        // }
        if (!helpDeskEmail.match(emailRegex)) {
            setHelpDeskEmailHelper("Invalid email address");
            return;
        }
        if (!String(vendorCity).match(cityRegex)) {
            setVendorCityHelper("Enter Correct City Name");
            return;
        }
        if (promisedUptime.length === 0 || !promisedUptime.toString().match(uptimeRegex)) {
            setPromisedUptimeHelper("Enter Valid Promised Uptime");
            return;
        }
        if (resolutionTime.length === 0 || !resolutionTime.toString().match(resolutionTimeRegex)) {
            setResolutionTimeHelper("Enter Valid Resolution Time");
            return;
        }
        if (!validateDynamicFields()) {
            // console.log('Validation failed, not saving.'); 
            return;
        }
        const vendorData = {
            vendor_id: vendorId,
            vendor_name: vendorName,
            vendor_address: vendorAddress,
            vendor_contact: vendorContact,
            helpdesk_email: helpDeskEmail,
            escalation_level1_mail: escalationLevel1Mail,
            escalation_level2_mail: escalationLevel2Mail,
            vendor_city: vendorCity,
            promised_uptime: promisedUptime,
            resolution_time: resolutionTime,
        };
        const customFields = {};
        if (Array.isArray(customTagsData)) {
            customTagsData.forEach(field => {
                const { label, value } = field;
                customFields[label] = value;
            });
        }
        // EditVendorApiCall(vendorId, vendorName, vendorAddress, vendorContact, helpDeskEmail, escalationLevel1Mail, escalationLevel2Mail, vendorCity, promisedUptime, resolutionTime, setEditVendorStatus, setEditVendorMessage);
        EditVendorApiCall(vendorData, customFields,setEditVendorStatus, setEditVendorMessage)
        handleOnClose();
    }
    
    
    return (
        <>
            <Dialog open={openEditVendorDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Update Vendor
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField label="Vendor Name" id="vendor_name" value={vendorName} onChange={handleOnChange} helperText={vendorNameHelperText} error={!!vendorNameHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Address" id="vendor_address" value={vendorAddress} onChange={handleOnChange} helperText={vendorAddressHelperText} error={!!vendorAddressHelperText} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Vendor Contact Number" id="vendor_contact" value={vendorContact} onChange={handleOnChange} helperText={vendorContactHelperText} error={!!vendorContactHelperText} variant="outlined" style={{ width: "400px" }}  />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Help Desk Email" id="help_desk_email" value={helpDeskEmail} onChange={handleOnChange} helperText={helpDeskEmailHelper} error={!!helpDeskEmailHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-1 Email" id="escalation_level1_mail" value={escalationLevel1Mail} onChange={handleOnChange} helperText={escalationLevel1MailHelper} error={!!escalationLevel1MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Escalation Level-2 Email" id="escalation_level2_mail" value={escalationLevel2Mail} onChange={handleOnChange} helperText={escalationLevel2MailHelper} error={!!escalationLevel2MailHelper} variant="outlined" style={{ width: "400px" }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="City" id="vendor_city" value={vendorCity} onChange={handleOnChange} helperText={vendorCityHelper} error={!!vendorCityHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Promised Uptime" id="promised_uptime" value={promisedUptime} onChange={handleOnChange} helperText={promisedUptimeHelper} error={!!promisedUptimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Resolution Time" id="resolution_time" value={resolutionTime} onChange={handleOnChange} helperText={resolutionTimeHelper} error={!!resolutionTimeHelper} variant="outlined" style={{ width: "400px" }} required />
                            </Grid>
                        </Grid>

                        {customTagsData.length > 0 && ( <Divider sx={{ paddingTop: "20px" }} /> )}
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {customTagsData.length > 0 && (
                                    <Typography variant='h5'>
                                        Tags
                                    </Typography>
                                )}
                            </Grid>
                             {/* {
                                customTagsData.map((field, index) => {
                                    // Initialize dateValue if data type is Date
                                    const dateValue = field.value ? dayjs(field.value) : null;

                                    return (
                                        <Grid item xs={6} label={field.label}>
                                            {field.data_type === 'DATE' ? (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        label={field.label}
                                                        value={dateValue && dateValue.isValid() ? dateValue : null}
                                                        style={{ width: '400px' }}
                                                        onChange={(newValue) => {
                                                            if (newValue && newValue.isValid()) {
                                                                const formattedDateTime = newValue.format('YYYY-MM-DD HH:mm:ss');
                                                                handleDynamicFieldChange({ target: { value: formattedDateTime } }, index);
                                                            } else {
                                                                handleDynamicFieldChange({ target: { value: '' } }, index);
                                                            }
                                                        }}
                                                        format="YYYY-MM-DD HH:mm:ss"
                                                        sx={{ width: '400px' }}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                required: field.is_mandatory === 1,
                                                                error: !!dynamicFieldErrors[field.label],
                                                                helperText: dynamicFieldErrors[field.label],
                                                            }
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            ) : (
                                                <TextField
                                                    label={field.label}
                                                    value={field.value || ""}
                                                    onChange={(e) => handleDynamicFieldChange(e, index)}
                                                    fullWidth
                                                    required={field.is_mandatory === 1}
                                                    error={!!dynamicFieldErrors[field.label]}
                                                    helperText={dynamicFieldErrors[field.label]}
                                                    style={{ width: '400px' }}
                                                />
                                            )}
                                        </Grid>
                                    );
                                })
                            }
                          
  */}

{
    customTagsData.map((field, index) => {
        // Initialize dateValue for date fields
        const dateValue = field.value ? dayjs(field.value) : null;

        // Define select options for 'Select' data type
        const selectOptions = (field.data_type === 'Select' && field.values && typeof field.values === 'string')
            ? field.values.split(',').map(option => option.trim())
            : [];

        // Define the component to render based on the data type
        let fieldComponent;

        if (field.data_type === 'DATE') {
            fieldComponent = (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={field.label}
                        value={dateValue && dateValue.isValid() ? dateValue : null}
                        style={{ width: '400px' }}
                        onChange={(newValue) => {
                            if (newValue && newValue.isValid()) {
                                const formattedDateTime = newValue.format('YYYY-MM-DD HH:mm:ss');
                                handleDynamicFieldChange({ target: { value: formattedDateTime } }, index);
                            } else {
                                handleDynamicFieldChange({ target: { value: '' } }, index);
                            }
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        sx={{ width: '400px' }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                required: field.is_mandatory === 1,
                                error: !!dynamicFieldErrors[field.label],
                                helperText: dynamicFieldErrors[field.label],
                            }
                        }}
                    />
                </LocalizationProvider>
            );
        } else if (field.data_type === 'Select') {
            fieldComponent = (
                <FormControl fullWidth style={{ width: '400px' }}>
                    <InputLabel id={`select-label-${index}`}>
                        {field.label}{field.is_mandatory === 1 && <span style={{ color: 'black' }}> *</span>}
                    </InputLabel>
                    <Select
                        labelId={`select-label-${index}`}
                        value={selectOptions.includes(field.value) ? field.value : ""}
                        onChange={(e) => handleDynamicFieldChange(e, index)}
                        required={field.is_mandatory === 1}
                        error={!!dynamicFieldErrors[field.label]}
                        label={field.label}
                    >
                        {selectOptions.map((option, optIndex) => (
                            <MenuItem key={optIndex} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    {dynamicFieldErrors[field.label] && (
                        <FormHelperText error>{dynamicFieldErrors[field.label]}</FormHelperText>
                    )}
                </FormControl>
            );
        } else {
            fieldComponent = (
                <TextField
                    label={field.label}
                    value={field.value || ""}
                    onChange={(e) => handleDynamicFieldChange(e, index)}
                    fullWidth
                    required={field.is_mandatory === 1}
                    error={!!dynamicFieldErrors[field.label]}
                    helperText={dynamicFieldErrors[field.label]}
                    style={{ width: '400px' }}
                />
            );
        }

        return (
            <Grid item xs={6} key={field.label}>
                {fieldComponent}
            </Grid>
        );
    })
}

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
