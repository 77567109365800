import React from 'react'
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

import Page from '../components/Page';
import VendorComponents from '../components/VendorComponents/VendorComponents'

export default function Vendors() {
    return (
        <Page title="Vendors">
            <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                        Vendor Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/vendors">
                        Vendors
                    </Link>
                </Breadcrumbs>
            </div>
            <Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper elevation={15}>
                        <VendorComponents />
                    </Paper>
                </Grid>
            </Grid>

        </Page>
    )
}
