import axios from "axios"

const AddEdgeDeviceApiCall = async(locationId, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setAddEdStatus, setSeverStatus, setOpenAddEd) => {
    const path = localStorage.getItem("msp_status") === "1" ? "push_edge_device_data_for_msp.php" : "push_edge_device_data.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("reg_cust_id"),
        "device_data":{
            "location_id": locationId,
            "device_ip": deviceIp,
            "snmp_str": snmpStr,
            "ssh_username": sshUserName,
            "ssh_password": sshPassword,
            "api_key": apiKey,
            "ssh_port": sshPort,
            "api_port": apiPort,
            "device_vendor": deviceVendor,
            "device_type": deviceType
        }
      });
    
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_data":{
            "location_id": locationId,
            "device_ip": deviceIp,
            "snmp_str": snmpStr,
            "ssh_username": sshUserName,
            "ssh_password": sshPassword,
            "api_key": apiKey,
            "ssh_port": sshPort,
            "api_port": apiPort,
            "device_vendor": deviceVendor,
            "device_type": deviceType
        }
      });
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setAddEdStatus(1);
            setSeverStatus("success");
            
        }
        else if(response.data.code === -1)
        {
            setAddEdStatus(-1);
            setSeverStatus("error");
        }
        else
        {
            setAddEdStatus(2);
            setSeverStatus("error");
        }
    })
  
}

export default AddEdgeDeviceApiCall