// @mui
import { Breadcrumbs, Container, Link, Grid } from '@mui/material';
// components
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Page from '../components/Page';
import LocationSpecificData from '../components/SidewiseComponents/Components/LocationSpecificData';
import { ISPLocationsDataAPICall } from "./SitewiseRestAPICalls"
import SidewiseParamsCards from '../components/SidewiseComponents/Components/SidewiseParamsCards';
import SitewiseEdgeDeviceData from '../components/SidewiseComponents/Components/SitewiseEdgeDeviceData';
import { useGlobalState } from '../globalHooks/GlobalState';

export default function Sidewise(props) {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const [locationData, setLocationdata] = useState(null);
  const [ispLocations, setIspLocations] = useState([{}])
  const [firstLocationName, setFirstLocationName] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [warning, setWarning] = useState("")
const [critical, setCritical]= useState("")

  
  
  const handelChange = (e, value) => {
    setLocationdata(value); 
    setLocationId(value ? value.location_id : null);
    setFirstLocationName(value ? value.location : "");
    setSelectedLocation(value);
  };

  const _ISPLocationsDataAPICall = async() =>{
    ISPLocationsDataAPICall(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState, setWarning, setCritical);
  }

  useEffect(() => {
    _ISPLocationsDataAPICall();
    setFirstLocationName("");
    setSelectedLocation(null); // Reset selected value in Autocomplete
  }, [globalState, buState]);

  
  return (
    <Page title="Sitewise">
      <Container maxWidth={false}>
        <div role="presentation" style={{paddingBottom: '20px'}}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/isp-sitewise">
              Sitewise
            </Link>
          </Breadcrumbs>
        </div>
      
        
        <Autocomplete
          id="location-select-demo"
          sx={{ width: '70%', margin: '0 auto', mt: 2 }}
          options={ispLocations}
          autoHighlight
          onChange={handelChange}
          value={selectedLocation}
          getOptionLabel={(option) => option.location}
          renderOption={(props, option, { selected }) => <li {...props}>{option.location}</li>}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose  Site"
              placeholder={firstLocationName}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }}>
          {locationId && (
              <SidewiseParamsCards locationId={locationId}/>
          )}
        </Grid>
        {/* <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{py:1}} style={{ paddingBottom: '30px' }}>
          {locationId && (
              <SitewiseEdgeDeviceData locationId={locationId}/>
          )}
        </Grid> */}
        {locationData && (
          <>
            <LocationSpecificData locationData={locationData} />
          </>
        )}
      </Container>
    </Page>
  );
}
