import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Box, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ViewEdgeDevicesInventoryTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ViewEdgeDevicesInventoryTableToolbar({ numSelected, filterName, onFilterName, handleDownloadClick }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Typography variant='h4'>
        Edge Devices
      </Typography>
      <Box
        width="75%"
        display="flex"
        justifyContent="flex-end"
        paddingRight="10px"
      >
        <Box
          width="10px"
        />
        <Button
          variant="contained"
          endIcon={<DownloadIcon />}
          sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
          onClick={handleDownloadClick}
          >
          Download
        </Button>
      </Box>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        onKeyDown={onFilterName}
        onKeyUp={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
