import axios from 'axios';

const CheckAgentAssignedForLocationAPICall = async(locationId, setAgentStatus) => {

    const path = localStorage.getItem("msp_status") === "1" ? "check_agent_assigned_for_location_for_msp.php" : "check_agent_assigned_for_location.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId
    });
    
    // console.log(payload);
    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "location_id": locationId
      });

    const payload = localStorage.getItem("msp_status") === "1"? mspPayload : regPayload;

    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setAgentStatus(1);
        }
        else {
            setAgentStatus(0);
            
        }
    });
}

export default CheckAgentAssignedForLocationAPICall