import React, { useState, useEffect } from 'react';
import { Pagination, Box } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import GetPowerBiToken from './GetPowerBiToken';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';

export default function Report(props) {
  const [token, setToken] = useState("")
  const { globalState, setGlobalState } = useGlobalState();
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [custId, setCustId] = useState(parseInt(localStorage.getItem("cust_id"), 10));
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const customFilters = [{
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "fn_customers",
      column: "cust_id"
    },
    operator: "Eq",
    values: [parseInt(custId, 10)]
  }];

  useEffect(() => {
    if (localStorage.getItem("msp_status") === "1") {
      if (parseInt(localStorage.getItem("cust_id"), 10) === globalState) {
        setOpenWarnDialog(true);
        setShowReport(false)
      }
      else {
        setCustId(globalState);
        setShowReport(true);
      }
    }
    else {
      setCustId(parseInt(localStorage.getItem("cust_id"), 10));
      setShowReport(true)
    }

    GetPowerBiToken(setToken);
  }, [token, globalState])


  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{paddingY: 2}}
      >
        <Pagination
          count={2}       // Total number of pages
          page={page}     // Controlled value for the current page
          onChange={handleChange}  // Event handler for page change
          color="primary" // Optional: Changes the color of the pagination (primary, secondary, etc.)
        />
      </Box>

      {showReport && page === 1 &&
        <PowerBIEmbed
          key={page}
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: process.env.REACT_APP_POWER_BI_REPORT_ID, // Power BI Report for Azure Production
            embedUrl: process.env.REACT_APP_POWER_BI_EMBED_URL,
            accessToken: token,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: true,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            },
            filters: customFilters
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-Container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />}

      {showReport && page === 2 &&
        <PowerBIEmbed
          key={page}
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: process.env.REACT_APP_POWER_BI_REPORT_ID_DEICE_APP, // Power BI Report for Azure Production
            embedUrl: process.env.REACT_APP_POWER_BI_EMBED_DEVICE_APP_URL,
            accessToken: token,
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: true,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            },
            filters: customFilters
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"Embed-Container"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />}

      {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
    </div>
  );
};

