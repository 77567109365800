import axios from 'axios';

// API CALL CODE HERE

const DeleteZoneApiCall = async (zoneId, zoneName, setDeleteStatus,setDeleteZoneMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "delete_zone_for_msp.php" : "delete_zone.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "zone_data": {
      "zone_id": zoneId,
		  "zone_name": zoneName,
	}
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "zone_data": {
      "zone_id": zoneId,
		  "zone_name": zoneName,
	}
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setDeleteStatus(1);
        setDeleteZoneMessage(response.data.message)
    }
    else if(response.data.code === -1) {
        setDeleteStatus(-1);
        setDeleteZoneMessage(response.data.message)

    }
    else if(response.data.code === -2) {
      setDeleteStatus(-2);
      setDeleteZoneMessage(response.data.message)

    }
    
  }).catch((error)=>{
    setDeleteStatus(-1);
    setDeleteZoneMessage(error.response.data.message)

  })
}

export default DeleteZoneApiCall;