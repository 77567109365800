import axios from 'axios';

export const UpdateAlertMailSettingsAPICall = async(settingData, setUpdateSuccessStatus) =>{
  const path = localStorage.getItem("msp_status") === "1" ? "push_alert_mail_settings_for_msp.php" : "push_alert_mail_settings.php";
  const alerMailtBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "settings_data": settingData
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "settings_data": settingData,
    "msp_status": 1,
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(alerMailtBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateSuccessStatus(true)
    }
    else{
      setUpdateSuccessStatus(false)
    }
  })

}


