import { filter } from 'lodash';
import { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';

// components
import ViewEdgeDevicesInventoryTableHead from './ViewEdgeDevicesInventoryTableHead';
import ViewEdgeDevicesInventoryTableToolbar from './ViewEdgeDevicesInventoryTableToolbar';
// mock
import USERLIST from '../../_mock/user';
import GetLocationsByClientApi from './GetLocationsByClientApi';
import { useGlobalState } from '../../globalHooks/GlobalState';


// ----------------------------------------------------------------------

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'Location', label: 'Location', alignRight: false },
  { id: 'Device IP', label: 'Device IP', alignRight: false },
  { id: 'Device Type', label: 'Device Type', alignRight: false },
  { id: 'Vendor Name', label: 'Vendor Name', alignRight: false },
  { id: 'Connected Links', label: 'Connected Links', alignRight: false },
];

export default function ViewEdgeDevicesInventoryTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();


  const downloadLink = createRef();

  const classes = useStyles();

  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deviceData, setDeviceData] = useState([]);
  const [allDeviceData, setAllDeviceData] = useState([]);
  
  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = async (event, searchedVal) => {
    setFilterName(event.target.value);
    setDeviceData(allDeviceData);

    const filteredRows = allDeviceData.filter((row) => {
      return row.device_ip && row.device_type && row.vendor_name &&
       (row.location_name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
       (row.device_ip?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
       (row.device_type?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false) ||
       (row.vendor_name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ?? false);
    });
    
    const deviceData = searchedVal !== "" || searchedVal === null ? filteredRows:allDeviceData;
    setDeviceData(deviceData);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const _DeviceDataAPI = async () => {
    await GetLocationsByClientApi(setDeviceData, setAllDeviceData,buState);
  }
  useEffect(() => {
    _DeviceDataAPI();
  }, [globalState,buState]);
  useEffect(()=>{
    _DeviceDataAPI();
  },[])

  useEffect(()=>{
    createDownLoadData();
  }, [allDeviceData.length])

  const createDownLoadData = () =>{
    const dataArr = [];

    for(let index=0; index<allDeviceData.length; index+=1)
    {
        const item = {};

        if(allDeviceData[index].device_ip !== null)
        {
          item.location = allDeviceData[index].location_name;
          item.device_ip = allDeviceData[index].device_ip;
          item.device_type = allDeviceData[index].device_type;
          item.vendor_name = allDeviceData[index].vendor_name;
          item.connected_links = allDeviceData[index].isp_cnt;
          dataArr.push(item);
        }
    }
    setCsvData(dataArr);
}

  const handleDownloadClick = () => {
    downloadLink.current.link.click();
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <ViewEdgeDevicesInventoryTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleDownloadClick={handleDownloadClick}
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <ViewEdgeDevicesInventoryTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {deviceData.filter(row => row.device_ip !== null).map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                    >
                      <TableCell align="left" >{index + 1}</TableCell>
                      {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{row.cust_name}</TableCell> : null }
                      <TableCell align="left">{row.location_name}</TableCell>
                      <TableCell align="left">{row.device_ip}</TableCell>
                      <TableCell align="left">{row.device_type}</TableCell>
                      <TableCell align="left">{row.vendor_name}</TableCell>
                      <TableCell align="left">{row.isp_cnt}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <CSVLink data={csvData} filename={`Edge_Device_Details.csv`} ref={downloadLink}>{}</CSVLink>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
