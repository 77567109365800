import { filter } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
// material
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  TextField,
  Button,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// components
import Scrollbar from '../Scrollbar';

import USERLIST from '../../_mock/user';
import ViewUsersTableToolbar from './ViewUsersTableToolbar';
import ViewUsersTableHead from './ViewUsersTableHead';
import GetUsersDataAPI from './GetUsersDataAPI'
import EditUserDialog from './EditUserDialog';
import DeleteUserDialog from './DeleteUserDialog';
import AssignUserDialog from './AssignUserDialog';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../globalHooks/GlobalState';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'cust_name', label: 'Customer', alignRight: false },
  { id: 'FirstName', label: 'First Name', alignRight: false },
  { id: 'LastName', label: 'Last Name', alignRight: false },
  { id: 'PhoneNo', label: 'Phone No', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'UserName', label: 'User Name', alignRight: false },
  { id: 'Role', label: 'Role', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none"
  },
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
}));

export default function ViewUsersTable() {
  const { globalState, setGlobalState } = useGlobalState();
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspClientId = localStorage.getItem("cust_id");
  const regClientId = localStorage.getItem("reg_cust_id");
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const isEngineer = (localStorage.getItem("role") === "2")
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [allUsersData, setAllUserData] = useState([]);

  const [updateStatus, setUpdateStatus] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [updateAssignStatus, setUpdateAssignStatus] = useState(0);
  const [severStatus, setSeverStatus] = useState("");
  const [updateUserMessage, setUpdateUserMessage] = useState("");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeletDialog] = useState(false);
  const [openMappingDialog, setOpenMappingDialog] = useState(false);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [updateMessage, setUpdateMessage] = useState("");
  const classes = useStyles();
  const [openWarnDialog, setOpenWarnDialog] = useState(false);


  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateStatus(0);
    setDeleteStatus(0);
    setUpdateAssignStatus(0);
    setOpen(false);
    setDeleteOpen(false);
    setSeverStatus("")
  };

  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value)
    const filteredRows = allUsersData.filter((row) => {
      return row.cust_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.first_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.last_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.last_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.user_name.toLowerCase().includes(searchedVal.toLowerCase())
        || row.email.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUsersData(filteredRows);
  };

  const _GetUsersData = async () => {
    await GetUsersDataAPI(setUsersData, setAllUserData);
  }

  useEffect(() => {
    _GetUsersData();
  }, [updateStatus, deleteStatus, globalState]);

  const handleEditClick = (e, user) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenEditDialog(true);
    setEditUser(user);
  }

  const handleDeleteClick = (e, user) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setOpenDeletDialog(true);
    setEditUser(user);
  }

  const handleAddClick = (e, user) => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      return
    }
    setEditUser(user);
    setOpenMappingDialog(true);
  }

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <ViewUsersTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            handleAddClick={handleAddClick}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table stickyHeader>
                <ViewUsersTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD.filter(tableCell => {
                    return (
                      (mspClientId === regClientId || tableCell.id !== 'cust_name') &&
                      (mspStatus !== 0 || tableCell.id !== 'cust_name')
                    );
                  })}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {usersData.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        {mspClientId === regClientId && mspStatus === 1 ? <TableCell>{row.cust_name}</TableCell> : null}
                        <TableCell align="left">{row.first_name}</TableCell>
                        <TableCell align="left">{row.last_name}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.user_name}</TableCell>
                        {row.role === 0 && <TableCell align="left">Super Admin</TableCell>}
                        {row.role === 1 && <TableCell align="left">Read-Only Admin</TableCell>}
                        {row.role === 2 && <TableCell align="left">Engineer</TableCell>}
                        <TableCell align="left">
                          <Grid direction="row">
                            {isEngineer && "-"}
                            {!isEngineer && <IconButton variant="outlined" color="primary" onClick={(e) => { handleEditClick(e, row) }}><EditIcon /></IconButton>}
                            {!isEngineer && <IconButton variant="outlined" onClick={(e) => { handleDeleteClick(e, row) }} color="error" ><DeleteIcon /></IconButton>}
                            {!isEngineer && <IconButton
                              variant="outlined"
                              onClick={(e) => { handleAddClick(e, row) }}
                            >
                              <AssignmentIndIcon />
                            </IconButton>
                            }
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {openEditDialog && <EditUserDialog user={editUser} openEditDialog={openEditDialog} setOpenEditDialog={setOpenEditDialog} setUpdateStatus={setUpdateStatus} setOpen={setOpen} setSeverStatus={setSeverStatus} setUpdateUserMessage={setUpdateUserMessage} />}
          {openDeleteDialog && <DeleteUserDialog user={editUser} openDeleteDialog={openDeleteDialog} setOpenDeletDialog={setOpenDeletDialog} setDeleteStatus={setDeleteStatus} setDeleteOpen={setDeleteOpen} setSeverStatus={setSeverStatus} />}
          {openMappingDialog && <AssignUserDialog user={editUser} openMappingDialog={openMappingDialog} setOpenMappingDialog={setOpenMappingDialog} setUpdateAssignStatus={setUpdateAssignStatus} setOpenAssignUser={setShowAssignUser} selectedUser={selectedUser} setUpdateMessage={setUpdateMessage} />}
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

          <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {updateStatus === 1 && <strong>{updateUserMessage}</strong>}
              {updateStatus === -1 && <strong>Error: {updateUserMessage}</strong>}
            </Alert>
          </Snackbar>

          <Snackbar open={deleteOpen} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={severStatus} sx={{ width: '100%' }}>
              {deleteStatus === 1 && <strong>User Deleted Successfully</strong>}
              {deleteStatus === -1 && <strong>Error in Deleting User</strong>}
            </Alert>
          </Snackbar>
          {updateAssignStatus === 1 && (
            <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%', backgroundColor: '#006400', color: 'white' }}>
                {updateMessage}
              </Alert>
            </Snackbar>
          )}
          {updateAssignStatus === -1 && (
            <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
              <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                {updateMessage}
              </Alert>
            </Snackbar>
          )}
        </Paper>
      </Grid>
    </>
  );
}
