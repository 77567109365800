import React, { useEffect, useState, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Iconify from '../Iconify';

// Calling templates
import CreateTemplateDialog from './Templates/CreateTemplateDialog';
import GeneratedReports from './Templates/GeneratedReports';
import EditTemplateDialog from './Templates/EditTemplateDialog';
import DeleteTemplateDialog from './Templates/DeleteTemplateDialog';
import GetTemplateApiCall from './ReportApiCall/GetTemplateApiCall';

import { useGlobalState } from '../../globalHooks/GlobalState';

const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px', // Adjust the width as desired
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
});

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'reportName',
        numeric: false,
        disablePadding: true,
        label: 'Report Name',
    },
    {
        id: 'reportType',
        numeric: false,
        disablePadding: false,
        label: 'Report Type',
    },
    {
        id: 'reportCategory',
        numeric: false,
        disablePadding: false,
        label: 'Report Category',
    },
    {
        id: 'dateModified',
        numeric: false,
        disablePadding: false,
        label: 'Date Modified',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    },

];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, handleUploadClick, handleAddClick, handleReportClick }) {
    return (
        <RootStyle>
            <Typography variant='h4'>
                Templates

            </Typography>
            <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
            >

                <Box
                    width="10px"
                />
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
                    onClick={handleAddClick}>
                    New
                </Button>
                <Box
                    width="10px"
                />
                <Button
                    variant="contained"
                    sx={{ width: "160px", height: "40px", paddingLeft: "10px" }}
                    onClick={handleReportClick}>
                    Generated Reports
                </Button>
            </Box>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


export default function MainScreenComponent() {


    const { globalState, setGlobalState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const isEngineer = (localStorage.getItem("role") === "2")
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [value, setValue] = useState({});


    const [templates, setTemplates] = React.useState([]);

    const [openAddTemplateDialog, setOpenAddTemplateDialog] = useState(false);
    const [addTemplateStatus, setAddTemplateStatus] = useState(0);

    const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = useState(false);
    const [deleteTemplateStatus, setDeleteTemplateStatus] = useState(0);

    const [openEditTemplateDialog, setOpenEditTemplateDialog] = useState(false);
    const [openReportDialog, setOpenReportDialog] = useState(false);
    const [editTemplateStatus, setEditTemplateStatus] = useState(0);

    const [addTemplateMessage, setAddTemplateMessage] = useState("");

    const [editTemplateMessage, setEditTemplateMessage] = useState("");

    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [listStatus, setListStatus] = useState(0);

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));

    const handleOnEdit = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenEditTemplateDialog(true);
    }

    const handleDelete = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setValue(row);
        setOpenDeleteTemplateDialog(true);
    }

    useEffect(() => {
        GetTemplateApiCall(setListStatus, setReportList);
    }, [globalState, addTemplateStatus]);



    const handleFilterByName = (event, searchedVal) => {
        setFilterName(event.target.value)
        setReportList(reportList);

        if (searchedVal === "") {
            GetTemplateApiCall(setListStatus, setReportList);
        } else {
            const filteredRows = reportList.filter((row) => {
                return row.report_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.report_category.toLowerCase().includes(searchedVal.toLowerCase()) || row.report_type.toLowerCase().includes(searchedVal.toLowerCase());
            });
            setReportList(filteredRows);
        }

    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const resetAll = () => {
        setAddTemplateStatus(0);
        setOpenAddTemplateDialog(false);

        setDeleteTemplateStatus(0);
        setOpenDeleteTemplateDialog(false);

        setEditTemplateStatus(0);
        setOpenEditTemplateDialog(false);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
    };


    const handleUploadClick = () => {
        resetAll();
        setValue(rows);
    }

    const handleAddClick = () => {
        resetAll();

        setOpenAddTemplateDialog(true);
    }

    const handleReportClick = () => {
        setOpenReportDialog(true);
    }


    return (

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e, filterName) }}
                    handleUploadClick={handleUploadClick}
                    handleAddClick={handleAddClick}
                    handleReportClick={handleReportClick}
                />
                <TableContainer className={classes.container}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            rowCount={reportList.length}
                        />
                        <TableBody>
                            {reportList.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.zone_id}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="left">{row.report_name}</TableCell>
                                        <TableCell align="left">{row.report_type}</TableCell>
                                        <TableCell align="left">{row.report_category}</TableCell>
                                        <TableCell align="left">{row.updated_at ? new Date(row.updated_at).toDateString() : new Date(row.created_at).toDateString()}</TableCell>

                                        <TableCell align="center">
                                            <Grid direction="row">
                                                {/* <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton> */}
                                                {!isEngineer && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {openAddTemplateDialog && <CreateTemplateDialog openAddTemplateDialog={openAddTemplateDialog} setOpenAddTemplateDialog={setOpenAddTemplateDialog} setAddTemplateStatus={setAddTemplateStatus} setAddTemplateMessage={setAddTemplateMessage} />}
                {openDeleteTemplateDialog && <DeleteTemplateDialog value={value} openDeleteTemplateDialog={openDeleteTemplateDialog} setOpenDeleteTemplateDialog={setOpenDeleteTemplateDialog} setDeleteTemplateStatus={setDeleteTemplateStatus} />}
                {openEditTemplateDialog && <EditTemplateDialog value={value} openEditTemplateDialog={openEditTemplateDialog} setOpenEditTemplateDialog={setOpenEditTemplateDialog} setEditTemplateStatus={setEditTemplateStatus} setEditTemplateMessage={setEditTemplateMessage} />}
                {openReportDialog && <GeneratedReports openReportDialog={openReportDialog} setOpenReportDialog={setOpenReportDialog} />}


            </Paper>

            {addTemplateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {addTemplateMessage}
                </Alert>
            </Snackbar>}
            {addTemplateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {addTemplateMessage}
                </Alert>
            </Snackbar>}

            {deleteTemplateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    Vendor Deleted Successfully
                </Alert>
            </Snackbar>}
            {deleteTemplateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error in deleting Vendor
                </Alert>
            </Snackbar>}

            {editTemplateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {editTemplateMessage}
                </Alert>
            </Snackbar>}
            {editTemplateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {editTemplateMessage}
                </Alert>
            </Snackbar>}
        </Box>
    );
}