// @mui
import {
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EdDown1Card1 from './EdDown1Card1';
import timeArray from '../TimeData';
// components
// ----------------------------------------------------------------------

export default function EdDown1MainPage() {
  return (
    <>
      <Grid container spacing={1} sx={{ py: 1 }}>
        <EdDown1Card1 />
      </Grid>
    </>
  );
}
