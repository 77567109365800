import React, { useState, useEffect, forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Box,
  Checkbox,
  Autocomplete,
  FormControlLabel,
  Typography

} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CustomDateRangePicker from '../CustomDateRangePicker';
import EditTemplateApiCall from '../ReportApiCall/EditTemplateApiCall';

const EditTemplateDialog = (props) => {
  const { value, openEditTemplateDialog, setOpenEditTemplateDialog, setEditTemplateStatus, setEditTemplateMessage } = props;

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    recipient: '',
    columnsField: []
  });
  const [alignment, setAlignment] = useState('');
  const [category, setCategory] = useState('');
  const [dayCategory, setDayCategory] = useState('');
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [selectedAmPm, setSelectedAmPm] = useState('AM');
  const [generateNow, setGenerateNow] = useState(true);
  const [freqCategory, setFreqCategory] = useState('');
  const [pdfChecked, setPdfChecked] = useState(false);
  const [csvChecked, setCsvChecked] = useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [selectedAutocompleteValues, setSelectedAutocompleteValues] = useState([]);


  const [addReportstatus, setAddReportStatus] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [serverStatus, setServerStatus] = useState('');
  const [options, setOptions] = useState([
    'Location',
    'Down Time',
    'Average Uptime',
    'Business Impact',
    'Sla Breaches',
    'Circuit Id',
    'Vendor',
  ]);


  const resetAll = () => {
    setAddReportStatus(0);
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetAll();
    setOpenSnackBar(false);
  };

  const categoryNames = {
    "1": "Availability",
    "2": "Link Quality",
    "3": "Wan Economics",
    "4": "Bandwidth"
  };



  useEffect(() => {
    if (value && value.column_list && Array.isArray(value.column_list)) {
      const columnsField = value.column_list.map(option => ({ value: option }));
      setFormData(prevFormData => ({
        ...prevFormData,
        columnsField
      }));
      setSelectedColumns(columnsField);
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        columnsField: []
      }));
      setSelectedColumns([]);
    }
  }, [value]);



  // hooks use effect
  useEffect(() => {
    if (value && value.report_category !== undefined) {
      const reportCategory = value.report_category;
      let categoryId = '';
      switch (reportCategory.toLowerCase()) {
        case 'availability':
          categoryId = '1';
          break;
        case 'link quality':
          categoryId = '2';
          break;
        case 'wan economics':
          categoryId = '3';
          break;
        case 'bandwidth':
          categoryId = '4';
          break;
        default:
          categoryId = '';
      }
      setCategory(categoryId);
    }
  }, [value]);

  const handleOnClose = () => {
    setOpenEditTemplateDialog(false);
  }


  useEffect(() => {
    if (value) {
      setPdfChecked(!!value.is_pdf);
      setCsvChecked(!!value.is_csv);

      setFormData({
        name: value.report_name || '',
        recipient: value.recipient || '',
        columnsField: value.columnsField || []
      });

      setAlignment(value.report_type || '');
      setDayCategory(value.daily_frequency || '');
      const [time, amPm] = value.time_frequency.split(' ');
      const [hourString, minuteString] = time.split(':');

      const hour = hourString < 10 ? hourString : parseInt(hourString, 10);
      const minute = minuteString < 10 ? minuteString : parseInt(minuteString, 10);

      setSelectedHour(hour || 0);
      setSelectedMinute(minute || 0);

      setSelectedHour(hour || 0);
      setSelectedMinute(minute || 0);
      setSelectedAmPm(value.time_frequency.split(' ')[1] || 'AM');
      setGenerateNow(value.template_type === 'generate_now');
      setFreqCategory(value.schedule_frequency || '');
      setStartDate(new Date(value.start_date || null));
      setEndDate(new Date(value.end_date || null));
    }

    setSelectedColumns(value.columnsField || []);
  }, [value]);



  const handlePdfCheckboxChange = (event) => {
    setPdfChecked(event.target.checked);
  };

  const handleCsvCheckboxChange = (event) => {
    setCsvChecked(event.target.checked);
  };

  const handleGenerateOptionChange = () => {
    setGenerateNow((prev) => !prev);
  };

  const handleScheduledTimeChange = (type, value) => {
    switch (type) {
      case 'hour':
        setSelectedHour(value);
        break;
      case 'minute':
        setSelectedMinute(value);
        break;
      case 'ampm':
        setSelectedAmPm(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    try {
      const currentDate = new Date();
      const formattedCurrentDate = currentDate.toISOString().slice(0, 10);

      const generateType = generateNow ? 'generate_now' : 'schedule';
      const scheduledTime = `${selectedHour}:${selectedMinute} ${selectedAmPm}`;
      const reportData = {
        "id": value.id,
        "report_name": formData.name,
        "report_type": alignment,
        "column_list": formData.columnsField,
        "report_category": category,
        "start_date": startDate ? startDate.toISOString().slice(0, 10) : formattedCurrentDate,
        "end_date": endDate ? endDate.toISOString().slice(0, 10) : formattedCurrentDate,
        "generate_type": generateType,
        "recipient": formData.recipient,
        "schedule_frequency": freqCategory,
        "daily_frequency": dayCategory,
        "time_frequency": scheduledTime,
        "is_pdf": pdfChecked ? 1 : 0,
        "is_csv": csvChecked ? 1 : 0
      };


      await EditTemplateApiCall(reportData, setAddReportStatus, setServerStatus);

      setAddReportStatus(1);
      setOpenSnackBar(true);
      setOpenEditTemplateDialog(false);

      window.location.reload();
    } catch (error) {

      console.error("Error occurred:", error);
      setAddReportStatus(-1);
      setOpenSnackBar(true);
    }
  };

  const handleDateChanges = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCategoryChangeDays = (event) => {
    setDayCategory(event.target.value);
  };

  const handleCategoryChangeFreq = (event) => {
    setFreqCategory(event.target.value);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);

    let newAlignment = '';
    switch (selectedCategory) {
      case '1':
        newAlignment = 'site';
        break;
      case '2':
        newAlignment = 'link';
        break;
      case '3':
      case '4':
        newAlignment = 'site';
        break;
      default:
        break;
    }
    setAlignment(newAlignment);
  };

  const handleChanges = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      columnsField: selectedColumns
    }));
  }, [selectedColumns]);




  const handleAutocompleteChange = (event, newValue) => {
    let updatedValues;
    if (newValue && newValue.length > 0) {
      updatedValues = newValue.slice(-7);
    } else {
      updatedValues = [];
    }
    setSelectedAutocompleteValues(newValue);
    setFormData(prevFormData => ({
      ...prevFormData,
      columnsField: updatedValues
    }));
    setSelectedColumns(updatedValues);
  };






  return (
    <>
      <Dialog open={openEditTemplateDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
        <DialogTitle sx={{ py: 2 }}>
          Edit Template
          <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3, px: 3 }}>

          <form onSubmit={handleSubmit} style={{ width: '80%' }}>
            <Box sx={{
              px: 10, py:
                2
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled
                    sx={{ width: 400 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category-label-id">Category</InputLabel>
                    <Select
                      labelId="category-label-id"
                      id="category_id"
                      value={category}
                      onChange={handleCategoryChange}
                      label="Category"
                      style={{ width: "400px" }}
                      disabled
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value={"1"}>Availability</MenuItem>
                      <MenuItem value={"2"}>Link Quality</MenuItem>
                      <MenuItem value={"3"}>Wan Economics</MenuItem>
                      <MenuItem value={"4"}>Bandwidth</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ m: 3 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChanges}
                    aria-label="Platform"
                    style={{ width: "60%", display: "flex" }}
                    disabled
                  >
                    <ToggleButton value="site" style={{ width: "33%" }} disabled={category !== '1' && category !== '3' && category !== '4'}>Site</ToggleButton>
                    <ToggleButton value="vendor" style={{ width: "33%" }} disabled={category !== '1' && category !== '3'}>Vendor</ToggleButton>
                    <ToggleButton value="link" style={{ width: "33%" }} disabled={category !== '2' && category !== '4'}>Link</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>

                  {value && !Array.isArray(value.column_list) && (
                    <TextField
                      disabled
                      label="Selected Columns"
                      value={value.column_list}
                      fullWidth
                      style={{ marginBottom: '15px', width: '750px' }}
                    />
                  )}


                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={options}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option === value}
                    value={formData.columnsField}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ColumnsField"
                        placeholder="Columns List"
                        style={{ width: 300, marginBottom: 15 }}
                        value={formData.columnsField}
                      />
                    )}
                  />



                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={pdfChecked} onChange={handlePdfCheckboxChange} />}
                    label="PDF"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={csvChecked} onChange={handleCsvCheckboxChange} />}
                    label="CSV"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    color="primary"
                    value={generateNow ? 'generate_now' : 'schedule'}
                    exclusive
                    onChange={handleGenerateOptionChange}
                    aria-label="Generate Now or Schedule the report"
                    sx={{ marginBottom: '10px' }}
                  >
                    <ToggleButton value="generate_now">Generate Now</ToggleButton>
                    <ToggleButton value="schedule">Schedule the report</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {generateNow && (<Grid item xs={12}>
                  <CustomDateRangePicker onDateChange={handleDateChanges} startDateValue={value.start_date}
                    endDateValue={value.end_date} sx={{ marginLeft: '10' }} />
                </Grid>)}
                {!generateNow && (
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                      <FormControl variant="outlined" sx={{ flex: '1', marginRight: '8px' }}>
                        <InputLabel id="category-label">Schedule - Frequency</InputLabel>
                        <Select
                          labelId="category-label"
                          id="category"
                          value={freqCategory}
                          onChange={handleCategoryChangeFreq}
                          label="Schedule - Frequency"
                          fullWidth
                        >
                          <MenuItem value="daily">Daily</MenuItem>
                          <MenuItem value="weekly">Weekly</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                      </FormControl>
                      {freqCategory !== 'daily' && (
                        <FormControl variant="outlined" sx={{ flex: '1', marginLeft: '8px' }}>
                          <InputLabel id="dayCategory-label">Day</InputLabel>
                          <Select
                            labelId="dayCategory-label"
                            id="dayCategory"
                            value={dayCategory}
                            onChange={handleCategoryChangeDays}
                            label="Day"
                            fullWidth
                          >
                            <MenuItem value="Monday">Monday</MenuItem>
                            <MenuItem value="Tuesday">Tuesday</MenuItem>
                            <MenuItem value="Wednesday">Wednesday</MenuItem>
                            <MenuItem value="Thursday">Thursday</MenuItem>
                            <MenuItem value="Friday">Friday</MenuItem>
                            <MenuItem value="Saturday">Saturday</MenuItem>
                            <MenuItem value="Sunday">Sunday</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      <div style={{ display: 'flex', flexDirection: 'row', flex: '1', marginLeft: '8px' }}>
                        <FormControl variant="outlined" sx={{ flex: '1', marginRight: '8px' }}>
                          <InputLabel id="hour-label">Hour</InputLabel>
                          <Select
                            labelId="hour-label"
                            id="hour"
                            value={selectedHour}
                            onChange={(event) => handleScheduledTimeChange('hour', event.target.value)}
                            label="Hour"
                            fullWidth
                          >
                            {[...Array(12).keys()].map((hour) => (
                              <MenuItem key={hour + 1} value={(hour + 1).toString().padStart(2, '0')}>
                                {(hour + 1).toString().padStart(2, '0')}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ flex: '1', marginRight: '8px' }}>
                          <InputLabel id="minute-label">Minute</InputLabel>
                          <Select
                            labelId="minute-label"
                            id="minute"
                            value={selectedMinute}
                            onChange={(event) => handleScheduledTimeChange('minute', event.target.value)}
                            label="Minute"
                            fullWidth
                            style={{ width: '80px' }}
                          >
                            {[...Array(60).keys()].map((minute) => (
                              <MenuItem key={minute} value={minute.toString().padStart(2, '0')}>{minute.toString().padStart(2, '0')}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ flex: '1' }}>
                          <InputLabel id="ampm-label">AM/PM</InputLabel>
                          <Select
                            labelId="ampm-label"
                            id="ampm"
                            value={selectedAmPm}
                            onChange={(event) => handleScheduledTimeChange('ampm', event.target.value)}
                            label="AM/PM"
                            fullWidth
                            style={{ width: '80px' }}
                          >
                            <MenuItem value="AM">AM</MenuItem>
                            <MenuItem value="PM">PM</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    id="recipient"
                    name="recipient"
                    label="Recipient"
                    value={formData.recipient}
                    onChange={handleChange}
                    sx={{ width: 400 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
          <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={addReportstatus === 1 ? "success" : "error"} sx={{ width: '100%' }}>
              <Typography>{addReportstatus === 1 ? "Template Updated Successfully." : "Error in updating Template"}</Typography>
            </Alert>
          </Snackbar>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTemplateDialog;
