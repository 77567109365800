import axios from 'axios';

export const SiteStatusCountAPICall = async (setTotalSites, setUpSites, setDownSites, setLocations, setUpdatedTime, setLogoutAlert, setIntProbeStatus, setUnAssignedSites, setNotAssignedLocations, buState) => {
  const path = localStorage.getItem("msp_status") === "1" ? "get_site_status_count_for_msp.php" : "get_site_status_count.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const baseUrlMappedUser = `${process.env.REACT_APP_API_SERVER_URL}/get_site_status_count_for_mapped_user.php`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "bu_id": buState
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1
  });

  if (localStorage.getItem("user_map_status") === "1") {
    await axios.post(baseUrlMappedUser, regPayload).then((response) => {
      if (response.data.code === 1) {
        setTotalSites(response.data.site_cnt_data.total_sites);
        setUpSites(response.data.site_cnt_data.up_sites);
        setDownSites(response.data.site_cnt_data.down_sites);
        setLocations(response.data.down_locations);
        setUpdatedTime(response.data.update_time);
        setIntProbeStatus(response.data.int_probe_status);
        setUnAssignedSites(response.data.site_cnt_data.unknow_status_sites);
        setNotAssignedLocations(response.data.not_monitored);
      }
      else if (response.data.code === 0) {
        setLogoutAlert(true);
      }
      else {
        setTotalSites(0);
        setUpSites(0);
        setDownSites(0);
        setUnAssignedSites(0);
      }
    })
  }
  // else {

    if (localStorage.getItem("user_map_status") === "-1") {
      if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
        const url = `${process.env.REACT_APP_API_SERVER_URL}/get_site_status_count_for_bu.php`;
        const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "bu_id": buState
        });

        return axios.post(url, payload).then((response) => {
          if (response.data.code === 1) {
            setTotalSites(response.data.site_cnt_data.total_sites);
            setUpSites(response.data.site_cnt_data.up_sites);
            setDownSites(response.data.site_cnt_data.down_sites);
            setLocations(response.data.down_locations);
            setUpdatedTime(response.data.update_time);
            setIntProbeStatus(response.data.int_probe_status);
            setUnAssignedSites(response.data.site_cnt_data.unknow_status_sites);
            setNotAssignedLocations(response.data.not_monitored);
          }
          else if (response.data.code === 0) {
            setLogoutAlert(true);
          }
          else {
            setTotalSites(0);
            setUpSites(0);
            setDownSites(0);
            setUnAssignedSites(0);
          }
        })
      }

      if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
        const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
        await axios.post(baseUrl, payload).then((response) => {
          if (response.data.code === 1) {
            setTotalSites(response.data.site_cnt_data.total_sites);
            setUpSites(response.data.site_cnt_data.up_sites);
            setDownSites(response.data.site_cnt_data.down_sites);
            setLocations(response.data.down_locations);
            setUpdatedTime(response.data.update_time);
            setIntProbeStatus(response.data.int_probe_status);
            setUnAssignedSites(response.data.site_cnt_data.unknow_status_sites);
            setNotAssignedLocations(response.data.not_monitored);
          }
          else if (response.data.code === 0) {
            setLogoutAlert(true);
          }
          else {
            setTotalSites(0);
            setUpSites(0);
            setDownSites(0);
            setUnAssignedSites(0);
          }
        })
      }
    }
  }