import axios from 'axios';

// API CALL CODE HERE

const CheckWanInterfaceStatusApiCall = async(ispWanId, setEdInterfaceStatus, setGotEdInterfaceStatus, setEdInterfaceMessage, setContinueWanIpLanStatus, setDetailEdInterfaceMessage, setRcaEdInterfaceMessage) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_interface_status.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  // console.log(payload)
  return axios.post(baseUrl, payload).then((response)=>{
    // console.log(response.data)
    if(response.data.code === 1){
        setEdInterfaceStatus(true)
        setContinueWanIpLanStatus(true)
    }
    else
    {
        setEdInterfaceStatus(false)
        setContinueWanIpLanStatus(false)
    }

    

    setGotEdInterfaceStatus(true)
    setEdInterfaceMessage(response.data.message)
    setDetailEdInterfaceMessage(response.data.detailed_message) 
    setRcaEdInterfaceMessage(response.data.rca_message)
  })
}

export default CheckWanInterfaceStatusApiCall;
