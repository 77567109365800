import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';

// components

// sections

import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
// mock
import devices from '../../../_mock/devices';
import ISPTotalTableToolbar from './ISPTotalTableToolbar';
import ISPGoodTableHead from './ISPGoodTableHead';
import { ISPTotalTableDataAPI } from '../ISPManagamentRestCalls/ISPTotalTableDataAPI';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// ----------------------------------------------------------------------

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Internal IP', label: 'Internal IP', alignRight: false },
  { id: 'DG IP', label: 'DG IP', alignRight: false },
  { id: 'Uptime', label: 'Up/Down Time', alignRight: false },
  { id: 'Latency', label: 'Latency (ms)', alignRight: false },
  { id: 'Packet drops', label: 'Packet Loss (%)', alignRight: false },
  { id: 'Jitter', label: 'Jitter (ms)', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'View', label: 'View', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}



export default function ISPTotalTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalIspData, setTotalIspData] = useState([]);
  const [allIspData, setAllIspData] = useState([]);
  const [path, setPath] = useState("");
  const [hideColumn, setHideColumn] = useState(false);
  
  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")


  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setTotalIspData(allIspData);
    const filteredRows = allIspData.filter((row) => {
      return (
        row.location.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.vendor.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.circuit_id.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.public_ip.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (row.status === 1 && 'up'.includes(searchedVal.toLowerCase())) ||
        (row.status === 0 && 'down'.includes(searchedVal.toLowerCase()))
      );
    });
    setTotalIspData(filteredRows);
  };
  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;



  const _ISPTotalTableDataAPI = async () => {

    ISPTotalTableDataAPI(setTotalIspData, setAllIspData, buState);
  }

  useEffect(() => {
    _ISPTotalTableDataAPI();
  }, [globalState, buState]);

useEffect(()=>{
  _ISPTotalTableDataAPI(); 

},[])



  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }
  const [sortedTableHeader,setSortedTableHeader]=useState([])


  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPTotalTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(e) => { handleFilterByName(e, filterName) }} />
        <Scrollbar>
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <ISPGoodTableHead
                order={order}
                orderBy={orderBy}
                
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              
              <TableBody>
                {totalIspData.map((list, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.cust_name}</TableCell> : null }
                    <TableCell>{list.circuit_id}</TableCell>
                    <TableCell>{list.location}</TableCell>
                    <TableCell>{list.vendor}</TableCell>
                    <TableCell>{list.public_ip}</TableCell>
                    <TableCell>{list.internal_ip}</TableCell>
                    <TableCell>{list.default_gateway}</TableCell>
                    <TableCell>{convertMinToHourMinutes(list.status === 0 ? list.down_cnt : list.up_cnt)}</TableCell>
                    {list.status !== 0 && <TableCell>{list.avg_rtt}</TableCell>}
                    {list.status === 0 && <TableCell sx={{ fontSize: '30px' }}><span>&#8734;</span></TableCell>}
                    <TableCell>{list.packet_loss}</TableCell>
                    {list.status !== 0 && <TableCell>{list.jitter}</TableCell>}
                    {list.status === 0 && <TableCell sx={{ fontSize: '30px' }}><span>&#8734;</span></TableCell>}
                    <TableCell>{list.status === 1 ? "UP" : "Down"} </TableCell>
                    {list.status === 0 && <TableCell><Link to={{ pathname: `/dashboard/isp-management/isp-down/${list.isp_wan_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>}
                    {list.status === 1 && (parseFloat(list.avg_rtt) < 150 && parseFloat(list.jitter) < 150) && <TableCell><Link to={{ pathname: `/dashboard/isp-management/isp-good/${list.isp_wan_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>}
                    {list.status === 1 && (parseFloat(list.avg_rtt) >= 150 || parseFloat(list.jitter) >= 150) && <TableCell><Link to={{ pathname: `/dashboard/isp-management/isp-bad/${list.isp_wan_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </Grid>
  );
}
