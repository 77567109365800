import axios from "axios"

const DeleteEdgeDeviceApiCall = async(deviceIp, setDeleteEdStatus, setSeverStatus) => {
    const path = localStorage.getItem("msp_status") === "1" ? "delete_edge_device_for_msp.php" : "delete_edge_device.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "device_ip": deviceIp
        }
      );
    
      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "device_ip": deviceIp
        }
      );
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

   
    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setDeleteEdStatus(1);
            setSeverStatus("success");
        }
        else
        {
            setDeleteEdStatus(-1);
            setSeverStatus("error");
        }
    })
  
}

export default DeleteEdgeDeviceApiCall