import axios from 'axios';

// API CALL CODE HERE

export const GenerateOTP = async (email, setStatus, setGenerateOtp, setOtpLoading) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/generate_login_otp.php`;


  const payload = JSON.stringify({
    "email": email
  });

  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 1) {
        setStatus(1)
        setGenerateOtp(true)
      }
      
      if (response.data.code === -1) {
        setStatus(-1);
      } 
      if(response.data.code === 0)
      {
        setStatus(-1);
      }
    }).catch(() => {
      setStatus(-1);
    })
    setOtpLoading(false)
}

