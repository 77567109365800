import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Toolbar, OutlinedInput, InputAdornment, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Iconify from '../../Iconify';

import GetTemplatesApiCall from '../RestApis/GetTemplatesApiCall';
import AddTemplateDialog from './AddTemplateDialog';

const headCells = [
    {
        id: 'Sl No',
        numeric: false,
        disablePadding: false,
        label: 'Sl No',
    },
    {
        id: 'templateName',
        numeric: false,
        disablePadding: false,
        label: 'Template Name',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
    },
    {
        id: 'updatedAt',
        numeric: false,
        disablePadding: false,
        label: 'Updated At',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    },
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead(props) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <TableSortLabel>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { filterName, onFilterName, handleOnAddClick } = props;

    return (
        <RootStyle>
            <Typography variant='h4'>
                Templates
            </Typography>
            <Box
                width="78%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px">
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", marginRight:"20px" }}
                    onClick={handleOnAddClick}
                    >
                    Add New 
                </Button>
            </Box>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


export default function TemplateTable() {
    const isEngineer = (localStorage.getItem("role") === "2")
    const [filterName, setFilterName] = useState("");

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openAddDialog, setOpenAddDialog] = useState(false);

    const _GetTemplatesApiCall=async()=>{
        await GetTemplatesApiCall(setRows);
    }

    useEffect(()=>{
        _GetTemplatesApiCall();
    }, [])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOnEdit = (e, row) => {
        // resetAll();
        // setOpenEditDialog(true);
        // setValue(row);
    }

    const handleDelete = (e, row) => {
        // resetAll();
        // setOpenDeletDialog(true);
        // setValue(row);
    }

    const handleOnAddClick=()=>{
        // console.log('handleOnAddClick')
        setOpenAddDialog(true);
    }

    const onFilterName=()=>{
        console.log('onFilterName')
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar 
                    filterName={filterName}
                    onFilterName={onFilterName}
                    handleOnAddClick = {handleOnAddClick}
                />
                <TableContainer sx={{ width: '100%' }}>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.name}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell align="left">{index+1}</TableCell>
                                        <TableCell align="left">{row.template_name}</TableCell>
                                        <TableCell align="left">{row.created_at}</TableCell>
                                        <TableCell align="left">{row.updated_at}</TableCell>
                                        <TableCell align="left">
                                            <Grid direction="row">
                                                <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>
                                                {!isEngineer && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {openAddDialog && <AddTemplateDialog openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} />}
        </Box>
    );
}
