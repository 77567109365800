import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ForgotPasswordRestAPICall } from './ForgotPasswordRestAPICall';

// ----------------------------------------------------------------------

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
});

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between', // Adjust as needed: space-between, center, flex-start, flex-end
  paddingTop: '20px',
  // paddingBottom: '20px',
};

export default function ResetPasswordForm() {
  const navigate = useNavigate();

  const [num1, setNum1] = useState(Math.floor(Math.random() * 100));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 100));
  const [userAnswer, setUserAnswer] = useState('0');
  const [isCorrect, setIsCorrect] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const defaultValues = {
    email: '',
  };


  const methods = useForm({
    resolver: yupResolver(ForgotSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues, // Import getValues from useForm
  } = methods;
  
  const onSubmit = async () => {

    const correctAnswer = num1 + num2;
    if (parseInt(userAnswer, 10) !== correctAnswer) {
      setIsCorrect(false);
      return
    }
    const { email } = getValues(); 
    try {
      await ForgotPasswordRestAPICall(email, setErrorMessage);
      setUserAnswer("0")
      setNum1(Math.floor(Math.random() * 100))
      setNum2(Math.floor(Math.random() * 100))
    } catch (error) {
      // console.log(error);
      navigate('/');
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />
      </Stack>

      <div style={containerStyle} >
        <p style={{paddingTop:"15px", fontSize:"50"}}>
          Solve the math problem: {num1} + {num2} =
        </p>

        <TextField
          type="number"
          value={userAnswer}
          onChange={(e) => {setUserAnswer(e.target.value); setIsCorrect(true);}}
        />
      </div>
      {errorMessage !== '' && <p style={{paddingLeft:"20px", paddingTop:"20px", color:"blue"}}>{"If your email is registered with us you will shortly receive a forgot password link"}</p>}
      {!isCorrect && userAnswer !== '' && <p style={{paddingLeft:"20px", color:"red"}}>Incorrect. Please try again.</p>}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{marginTop:'10px'}}>
         Submit
      </LoadingButton>
    </FormProvider>
  );
}
