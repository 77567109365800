import axios from 'axios';

export const AppAlertApiCall = async (setAppAlerts, setAllappAlerts, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_app_alerts_details_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setAppAlerts(response.data.app_alert_data);
        setAllappAlerts(response.data.app_alert_data);
      }
    })
  }
  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
      const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_app_alerts_details_for_bu.php`;
      const payload = JSON.stringify({ 
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "bu_id": buState
      });

      return axios.post(alertBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setAppAlerts(response.data.app_alert_data);
          setAllappAlerts(response.data.app_alert_data);
        }
        else {
          setAppAlerts([]);
        }
      })
    }

    if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_app_alerts_details_for_msp.php" : "get_app_alerts_details.php";
      const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
      });


      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(alertBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setAppAlerts(response.data.app_alert_data);
          setAllappAlerts(response.data.app_alert_data);
        }
        else {
          setAppAlerts([]);
        }
      })
    }
  }
}

