import axios from 'axios';

// API CALL CODE HERE
const GetTopInterfaceUsageExporterAPICall = async (deviceIps, time, setInterfaceUsageData, setInBoudWanArr, setOutBoudWanArr, setTalkers) => {
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_interface_exporter.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "device_ips": deviceIps,
    "time": time
  });
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setInterfaceUsageData(response.data.interface_usage);
      const data = response.data.interface_usage;
      const inboundWanArr = [];
      const outboundWanArr = [];
      const talkersArr = [];

      data.forEach(item => {
        inboundWanArr.push(item.inbound);
        outboundWanArr.push(item.outbound);
        talkersArr.push(item.interface);
      });

      setInBoudWanArr(inboundWanArr)
      setOutBoudWanArr(outboundWanArr)
      setTalkers(talkersArr)


    }
    else {
      setInterfaceUsageData([]);
      setInBoudWanArr([])
      setOutBoudWanArr([])
      setTalkers([])
    }
  })
}

export default GetTopInterfaceUsageExporterAPICall;