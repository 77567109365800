import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import CircularProgress from '@mui/material/CircularProgress';
import { Paper, Typography, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import GetSitesAvgUptimeInPercentApiCall from '../ApiCalls/GetSitesAvgUptimeInPercentApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    boxPadding: {
        padding: '25px',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

export default function AvgSiteUptimePercentComponent(props) {

    const { locationId, selectedGroupIds, currentDate } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const classes = useStyles();
    const [avgUpTime, setAvgUptime] = useState(99);
    const [isLoading, setIsLoading] = useState(true);

    const options = {
        chart: {
            type: 'radialBar',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
            position: 'bottom',
            formatter: (val, opts) => `${val} - ${opts.w.globals.series[opts.seriesIndex]}`,
        },
        plotOptions: {
            radialBar: {
              startAngle: -180,
              endAngle: 180,
              dataLabels: {
                name: {
                  fontSize: '16px',
                  offsetY: 120,
                  show: false,

                },
                value: {
                    offsetY: 10,
                    color: '#111',
                    fontSize: '20px',
                    show: true,
                }
              }
            },
          },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shade: 'light',
        //         type: 'horizontal',
        //         shadeIntensity: 0.5,
        //         gradientToColors: ['#ABE5A1'],
        //         inverseColors: true,
        //         opacityFrom: 1,
        //         opacityTo: 1,
        //         stops: [0, 100]
        //     }
        // },
        stroke: {
            dashArray: 4
          },
        series: [avgUpTime],
        labels: ["Percent"],
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        GetSitesAvgUptimeInPercentApiCall(locationId, selectedGroupIds, setAvgUptime, buState);
        // setInterval(() => {
        //     setIsLoading(true);
        //     GetSitesAvgUptimeInPercentApiCall(locationId, setAvgUptime);
        //     setTimeout(() => {
        //         setIsLoading(false);
        //     }, 2000);
        // }, 60 * 1000);
    }, [avgUpTime, locationId, selectedGroupIds, globalState, buState]);


    return (
        <Paper elevation={8}>
            <div className={classes.titleStyle}>
                <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
                    Avg Site UpTime
                </Typography>
            </div>
            <Divider />
            <Box display="flex"
                justifyContent="center"
                alignItems="center"
                height={"200px"}>
                {isLoading ? (
                    <CircularProgress />
                ) : <Box sx={{ padding: "20px" }}>
                    <Chart type="radialBar" options={options} series={options.series} height={250} />
                </Box>}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="right" sx={{paddingRight:"20px"}}>
                <Typography variant='h12' sx={{ color: '#003366' }} component={'span'}>
                    On {currentDate}
                </Typography>
            </Box>
        </Paper>
    )
}
