import axios from 'axios';

const GetSiteGroupNamesApiCall = async (setGroups, buState) => {

    if (localStorage.getItem("msp_status") === "1") {

        const url = `${process.env.REACT_APP_API_SERVER_URL}/get_site_group_names_for_msp.php`;

        const mspPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "msp_client_id": localStorage.getItem("cust_id"),
            "reg_client_id": localStorage.getItem("reg_cust_id")
        });

        return axios.post(url, mspPayload).then((response) => {
            if (response.data.code === 1) {
                const groupNames = response.data.groups_data.map(group => group.group_name);
                setGroups(response.data.groups_data);
            }
            else {
                setGroups([]);
            }
        }).catch(() => {

            setGroups([]);
        });
    }

        const url = `${process.env.REACT_APP_API_SERVER_URL}/get_site_group_names.php`;

        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "bu_id": buState
        });


        return axios.post(url, regPayload).then((response) => {
            if (response.data.code === 1) {
                const groupNames = response.data.groups_data.map(group => group.group_name);
                setGroups(response.data.groups_data);
            }
            else {
                setGroups([]);
            }
        }).catch(() => {

            setGroups([]);
        });
}

export default GetSiteGroupNamesApiCall;