import axios from 'axios';

// API CALL CODE HERE


const GetBusinessUnitsDataApiCall = async(probeId, setPresentBusinessUnits, setAvailableBusinessUnits, buState) => {

  const path = localStorage.getItem("msp_status") === "1" ? "file.php" : "get_business_units_by_probe.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
 
    if (response.data.code === 1) {
        setAvailableBusinessUnits(response.data.probe_business_units_res.map(bu =>bu.bu_name));
        setPresentBusinessUnits(response.data.spare_business_units_res.map(bu =>bu.bu_name));
    }
    else {
        setAvailableBusinessUnits([]);
        setPresentBusinessUnits([]);
    }
  }).catch(()=>{
    setAvailableBusinessUnits([]);
    setPresentBusinessUnits([]);
  })
}

export default GetBusinessUnitsDataApiCall;