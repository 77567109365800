import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTemplateApiCall from '../ReportApiCall/DeleteTemplateApiCall';   



// Delete Template Dailog function
export default function DeleteTemplateDialog(props) {     

    const { value, openDeleteTemplateDialog, setOpenDeleteTemplateDialog, setDeleteTemplateStatus } = props;



    const [addReportstatus, setAddReportStatus] = useState(0);
    const [serverStatus, setServerStatus] = useState('');

    const handleClose=(e)=>{
        setOpenDeleteTemplateDialog(false);
    }
    const handleDelete = async () => {
       await DeleteTemplateApiCall(value.id, setAddReportStatus, setServerStatus);
        setOpenDeleteTemplateDialog(false);
      window.location.reload();
    }
    return (
        <>
            <Dialog
                open={openDeleteTemplateDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Template
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to Delete Template {value.report_name} ??
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
