import axios from 'axios';

// API CALL CODE HERE
const PauseLinkApiCall = async (alertNotifyLinkWanId, alertNotifyLinkIp, newPauseStatus, alertNotifyPauseStatus, setAlertNotifyStatus,setAlertNotifyMessage) => {
  // await PauseLinkApiCall(alertNotifyLinkIp, alertNotifyLinkWanId,alertNotifyPauseStatus, alertNotifyUtilAlertStatus )
// const pauseBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/pause_isp_link.php`;
const path = localStorage.getItem("msp_status") === "1" ? "pause_isp_link_for_msp.php" : "pause_isp_link.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


    const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": alertNotifyLinkWanId,
    "link_ip": alertNotifyLinkIp,
    "pause_status": newPauseStatus
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "isp_wan_id": alertNotifyLinkWanId,
    "link_ip": alertNotifyLinkIp,
    "pause_status": newPauseStatus
  });
  
  
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setAlertNotifyStatus (response.data.code);
            setAlertNotifyMessage(response.data.message);
        } else {
          setAlertNotifyStatus(response.data.code);
            setAlertNotifyMessage(response.data.message);
        }
    }).catch(()=>{
      setAlertNotifyStatus(-1);
      setAlertNotifyMessage("Error in Setting")
    })
}

export default PauseLinkApiCall;
