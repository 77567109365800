import axios from 'axios';

// API CALL CODE HERE
const GetRegisteredUserDataAPI = async (setRows, setFilteredRows,buState) => {

  const path = localStorage.getItem("msp_status") === "1" ?"file.php": "get_registered_user.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id")
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  // console.log(createBaseURL)
  // console.log(payload)

  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setRows(response.data.user_data);  
        setFilteredRows(response.data.user_data); 
    }
    else {
      setRows([]);  
      setFilteredRows([]); 
    }
  })
  
}

export default GetRegisteredUserDataAPI;
