import axios from "axios";


export const DeleteAgentAPICall = async (agentId, serialNumber, setDeleteStatus, setSeverStatus) => {


const baseurl = `${process.env.REACT_APP_API_SERVER_URL}/delete_raspi_agent.php`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "agent_id": agentId,
        "serial_number": serialNumber
    })

    return axios.post(baseurl, payload).then((response) => {
        if(response.data.code === 1){
            setDeleteStatus(1);
            setSeverStatus("success");
        }
        else{
            setDeleteStatus(-1);
            setSeverStatus("error");
        }
    })
}
