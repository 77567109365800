import axios from 'axios';

const GetSitewiseLinkCntApiCall = async (locationId, setUpLinkCnt, setTotalLinkCnt) => {


    const path = localStorage.getItem("msp_status") === "1" ? "get_link_count_by_location_for_msp.php" : "get_link_count_by_location.php";
    const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "location_id": locationId
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "location_id": locationId,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
            setUpLinkCnt(response.data.link_cnt_data.up_cnt);
            setTotalLinkCnt(response.data.link_cnt_data.total_cnt);
        }
        else {
            setUpLinkCnt(0);
            setTotalLinkCnt(0);
        }
    });
}

export default GetSitewiseLinkCntApiCall