import React, { useState, useEffect } from 'react'
import {
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    Alert,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CreateSite({
    locationName, setLocationName,
    businessHrStart, setBusinessHrStart,
    businessHrEnd, setBusinessHrEnd,
    remarks, setRemarks,
    city, setCity,
    state, setState,
    country, setCountry,
    status, setStatus,
    scMail, setEscMail,
    managerId, setManagerId,
    managerName, setManagerName,
    managerContact, setManagerContact,
    managerEmail, setManagerEmail,
    latency, setLatency,
    jitter, setJitter,
    packetLoss, setPacketLoss,
}) {



    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [disableManagerFields, setDisableManagerFields] = useState(false);



    const [locationNameHelperText, setLocationNameHelperText] = useState("");
    const [businessHrStartHelperText, setBusinessHrStartHelperText] = useState();
    const [businessHrEndHelperText, setBusinessHrEndHelperText] = useState();
    const [escMailHelperText, setEscMailHelperText] = useState("");
    const [latencyHelperText, setLatencyHelperText] = useState("");
    const [jitterHelperText, setJitterHelperText] = useState("");
    const [packetLossHelperText, setPacketLossHelperText] = useState("");
    const [cityHelperText, setCityHelperText] = useState("");
    const [stateHelperText, setStateHelperText] = useState("");
    const [countryHelperText, setCountryHelperText] = useState("");
    

    const locationRegex =/^[a-zA-Z][a-zA-Z0-9-_\s]*$/;
    const businessHrRegex = /^(?:[0-9]|1[0-9]|2[0-4])$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const latencyRegex = /^\d+(\.\d+)?$/;
    const packetLossRegex =  /^(?:\d{1,2}(?:\.\d+)?|\d{1,2}|100(?:\.0+)?)$/;
    const cityRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;

    // useEffect(() => {
    //     GetUserDetailsApiCall(setUsers);
    // }, [])

    const formData = {
        locationName,
        businessHrStart,
        businessHrEnd,
        remarks,
        city,
        state,
        country,
        status,
        scMail,
        managerId,
        managerName,
        managerContact,
        managerEmail,
        latency,
        jitter,
        packetLoss,
      };
    
      // ...
    
      useEffect(() => {
        // Convert the formData object to JSON string
        const formDataJson = JSON.stringify(formData);
    
        // Save the JSON string in local storage
        localStorage.setItem('SiteFormData', formDataJson);
      }, [formData]);
    
      // ...

    const handleOnCancel = () => {
        navigate('/dashboard/view-isp');
    }

    const onChangeManager = (e) => {
        switch (e.target.id) {
            case "location_name":   if (!e.target.value.match(locationRegex)) {
                                setLocationNameHelperText("Location Should be Valid");
                            }
                            else
                            {
                                setLocationNameHelperText("");
                                
                            }
                            setLocationName(e.target.value);
                            break;

            case "business_hr_start":   if (!e.target.value.match(businessHrRegex)) {
                                setBusinessHrStartHelperText("Provide hours between 0-24");
                            }
                            else
                            {
                                setBusinessHrStartHelperText("");
                                
                            }
                            setBusinessHrStart(e.target.value);
                            break;

            case "business_hr_end":   if (!e.target.value.match(businessHrRegex)) {
                                setBusinessHrEndHelperText("Provide hours between 0-24");
                            }
                            else
                            {
                                setBusinessHrEndHelperText("");
                                
                            }
                            setBusinessHrEnd(e.target.value);
                            break;

            case "escaltion_mail":   if (!e.target.value.match(emailRegex)) {
                                setEscMailHelperText("Email ID Should be Valid");
                            }
                            else
                            {
                                setEscMailHelperText("");
                                
                            }
                            setEscMail(e.target.value);
                            break;

            case "latency":   if (!e.target.value.match(latencyRegex)) {
                                setLatencyHelperText("Enter Latency Correctly");
                            }
                            else
                            {
                                setLatencyHelperText("");
                                
                            }
                            setLatency(e.target.value);
                            break;
            
            case "jitter":   if (!e.target.value.match(latencyRegex)) {
                                setJitterHelperText("Enter Jitter Correctly");
                            }
                            else
                            {
                                setJitterHelperText("");
                                
                            }
                            setJitter(e.target.value);
                            break;
            
            case "packet_loss":   if (!e.target.value.match(packetLossRegex)) {
                                setPacketLossHelperText("Enter Packet Loss Correctly");
                            }
                            else
                            {
                                setPacketLossHelperText("");
                                
                            }
                            setPacketLoss(e.target.value);
                            break;

            case "city":   if (!e.target.value.match(cityRegex)) {
                                setCityHelperText("Enter City Name Correctly");
                            }
                            else
                            {
                                setCityHelperText("");
                                
                            }
                            setCity(e.target.value);
                            break;
            
            case "state":   if (!e.target.value.match(cityRegex)) {
                                setStateHelperText("Enter State Name Correctly");
                            }
                            else
                            {
                                setStateHelperText("");
                                
                            }
                            setState(e.target.value);
                            break;

            case "country":   if (!e.target.value.match(cityRegex)) {
                                setCountryHelperText("Enter Country Name Correctly");
                            }
                            else
                            {
                                setCountryHelperText("");
                                
                            }
                            setCountry(e.target.value);
                            break;

        default: setManagerId(e.target.value)
        }
        // if (e.target.value !== -1) {
        //     setDisableManagerFields(true)
        //     const filteredUsers = users.filter((item) =>
        //         item.user_id.includes(e.target.value)
        //     );
        //     const user = filteredUsers.find(() => true);
        //     // console.log(user.first_name);
        //     setManagerName(user.first_name);
        //     setManagerContact(user.phone);
        //     setManagerEmail(user.email)
        // }
        // else {
        //     setDisableManagerFields(false)
        //     setManagerName("");
        //     setManagerContact("");
        //     setManagerEmail("")
        // }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh",  paddingTop:"15px" }}>
            <div style={{ width: "1000px"}}>
            {status === 1 && <Alert severity="success" onClose={() => { setStatus(0) }}>Site is Successfully Uploaded</Alert>}
            {status === -1 && <Alert severity="error" onClose={() => { setStatus(0) }}>Error in uploading Site </Alert>}
            {status === 2 && <Alert severity="warning" onClose={() => { setStatus(0) }}>Site Already Exists</Alert>}
            <br />
            <form >
                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={10}>

                <h1 style={{ alignItems: "center", paddingBottom: "20px" , width:"60vh" ,margin:"20px" }}>
                Add First Site</h1>
                </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Site Name"
                            id="location_name"
                            value={locationName}
                            helperText={locationNameHelperText}
                            error={locationNameHelperText}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={onChangeManager}
                            required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Business Hour Start (0-24)"
                            id="business_hr_start"
                            value={businessHrStart}
                            helperText={businessHrStartHelperText}
                            error={businessHrStartHelperText}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={onChangeManager} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Business Hour End (0-24)"
                            id="business_hr_end"
                            value={businessHrEnd}
                            helperText={businessHrEndHelperText}
                            error={businessHrEndHelperText}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={onChangeManager} required />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            id="city"
                            value={city}
                            variant="outlined"
                            style={{ width: "400px" }}
                            helperText={cityHelperText}
                            error={cityHelperText}
                            onChange={onChangeManager} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="State"
                            id="state"
                            value={state}
                            variant="outlined"
                            style={{ width: "400px" }}
                            helperText={stateHelperText}
                            error={stateHelperText}
                            onChange={onChangeManager} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Country"
                            id="country"
                            value={country}
                            variant="outlined"
                            style={{ width: "400px" }}
                            helperText={countryHelperText}
                            error={countryHelperText}
                            onChange={onChangeManager} />
                    </Grid>


                    {/* <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel required id="location-manager">Location Manager</InputLabel>
                            <Select
                                labelId="location-manager-label"
                                id="location-manager-id"
                                label="Location Manager"
                                value={managerId}
                                onChange={onChangeManager}
                                // onChange={(e) => { setManagerId(e.target.value) }}
                                style={{ width: "400px" }}
                            >
                                <MenuItem value={-1}>Other User</MenuItem>
                                {users.map((user, index) => (
                                    user.role === "0" && <MenuItem value={user.user_id}>{user.first_name} {user.last_name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={6}>
                        <TextField
                            disabled={disableManagerFields}
                            label="Manager Name"
                            id="manager_name"
                            value={managerName}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={(e) => { setManagerName(e.target.value) }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            disabled={disableManagerFields}
                            label="Manager Contact"
                            id="manager_contact"
                            value={managerContact}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={(e) => { setManagerContact(e.target.value) }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            disabled={disableManagerFields}
                            label="Manager Email"
                            id="manager_email"
                            value={managerEmail}
                            variant="outlined"
                            style={{ width: "400px" }}
                            onChange={(e) => { setManagerEmail(e.target.value) }} />
                    </Grid>
            
                </Grid>
            </form>
        </div>
        </div>
    )
}
