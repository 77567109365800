import { useState, useEffect } from 'react';
import { Paper, Grid, Autocomplete, TextField, Button, ButtonGroup } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import SpeedTest from './SpeedTest';
import { ISPLocationsDataAPICall } from "../../pages/SitewiseRestAPICalls";
import ApplicationMonitorTables from './ApplicationMonitorTables';
import { useGlobalState } from '../../globalHooks/GlobalState';
import CheckAgentAssignedForLocationAPICall from './CheckAgentAssignedForLocationAPICall';
import { GetSiteDataApiCall } from './GetSiteDataApiCall';

export default function AppMonitorDashboard() {
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const { locationId } = useParams(); // Get locationId from URL

  const [locationData, setLocationdata] = useState(null);
  const [ispLocations, setIspLocations] = useState([{}])
  const [firstLocationName, setFirstLocationName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [appType, setAppType] = useState(1);

  const [agentStatus, setAgentStatus] = useState(0);
  const [saasVariant, setSaasVariant] = useState('contained');
  const [internalVariant, setInternalVariant] = useState('outlined');

  const [buttonClicked, setButtonClicked] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const _ISPLocationsDataAPICall = async () => {
      // ISPLocationsDataAPICall(setIspLocations, setLocationdata, setFirstLocationName, setLocationId, buState,setWarning, setCritical);
    };

    const _CheckAgentAssignedForLocationAPICall = async () => {
      // Check agent assigned for the current locationId
      CheckAgentAssignedForLocationAPICall(locationId, setAgentStatus);
    };

    _ISPLocationsDataAPICall();
    _CheckAgentAssignedForLocationAPICall();
    setFirstLocationName("");
    setSelectedLocation(null);
  }, [globalState, buState, locationId]);

  useEffect(() => {
    // Re-check agent status if locationId changes
    CheckAgentAssignedForLocationAPICall(locationId, setAgentStatus);
  }, [locationId]);

  const handleButtonClick = (e) => {
    if (e.target.id === "saasButton") {
      setSaasVariant('contained');
      setInternalVariant('outlined');
      setAppType(1);
      setShowTable(true);
    }
    if (e.target.id === "internalButton") {
      setSaasVariant('outlined');
      setInternalVariant('contained');
      setAppType(0);
      setShowTable(true);
    }
    setButtonClicked(true);
  };


  
  const handelChange = (e, value) => {
    setLocationdata(value);
    // setLocationId(value ? value.location_id : null);
    setFirstLocationName(value ? value.location : "");
    setSelectedLocation(value);
  };

  const styles = {
    redBackground: {
      backgroundColor: '#ffe6e6', // light red background
      color: '#cc0000', // dark red text
      border: '1px solid #cc0000', // border to match text color
      borderRadius: '8px', // rounded corners
      padding: '20px',
      margin: '20px auto',
      textAlign: 'center',
      maxWidth: '600px',
      boxShadow: '0px 10px 20px rgba(204, 0, 0, 0.2)' // subtle shadow for elevation
    },
    title: {
      margin: '0',
      paddingBottom: '10px',
      fontSize: '1.5em'
    },
    bodyText: {
      margin: '0',
      fontSize: '1.1em'
    }
  };

  return (
    <>
  
      <Button variant="contained" onClick={() => navigate('/dashboard/sa-monitor')}>
        Back To Dashboard
      </Button>
        <>
          <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{ py: 1 }} style={{ paddingBottom: '30px' }}>
            <SpeedTest locationId={locationId} />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2} sx={{ py: 2 }}>
            <ButtonGroup size="large" sx={{ width: "200%" }}>
              <Button id="saasButton" variant={saasVariant} sx={{ width: "100%" }} onClick={handleButtonClick}>
                SaaS Applications
              </Button>
              <Button id="internalButton" variant={internalVariant} sx={{ width: "100%" }} onClick={handleButtonClick}>
                Internal Applications
              </Button>
            </ButtonGroup>
          </Grid>
          <br />

          <Grid item lg={12} sm={12} xs={12} md={12} xl={12} sx={{ py: 1 }} style={{ paddingBottom: '30px' }}>
            {showTable && <ApplicationMonitorTables locationId={locationId} appType={appType} />}
          </Grid>
        </>
    </>
  );
}
