import axios from 'axios';

// API CALL CODE HERE

export const EditCustomerApiCall = async (custId, customerName, customerAddr, customerEmail, customerPhoneNo, setEditStatus) => {
  const baseURL = `${process.env.REACT_APP_API_SERVER_URL}/edit_customer_for_msp.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "cust_id": custId,
    "customer_name": customerName,
    "customer_address": customerAddr,
    "customer_phone": customerEmail,
    "customer_email": customerPhoneNo
  });

  
  return axios.post(baseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setEditStatus(1);
    }
    else if(response.data.code === 2){
      setEditStatus(2);
    }
    else{
      setEditStatus(-1);
    }
  }).catch(()=>{
    setEditStatus(-1);
  })
}
