import axios from 'axios';

// API CALL CODE HERE
const UpdateUserDataApi = async (userId, firstName, lastName, phoneNo, emailId, userName, userRole, setUpdateStatus, setSeverStatus, setUpdateUserMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_user_details_for_msp.php" : "update_user_details.php";
  const updateBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "user_id": userId,
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "user_role": userRole
    }
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "msp_status": 1,
    "user_data": {
      "cust_id": localStorage.getItem("cust_id"),
      "user_id": userId,
      "first_name": firstName,
      "last_name": lastName,
      "phone_no": phoneNo,
      "email_id": emailId,
      "user_name": userName,
      "user_role": userRole
    }
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(updateBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setUpdateStatus(1);
      setSeverStatus("success");
      setUpdateUserMessage(response.data.message)
    }
    else {
      setUpdateStatus(-1);
      setSeverStatus("error");
      setUpdateUserMessage(response.data.message)
    }
  }).catch((error) => {
    setUpdateStatus(-1);
    setSeverStatus("error");
  })
}

export default UpdateUserDataApi;
