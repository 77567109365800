import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import DeleteEdgeDeviceApiCall from './DeleteEdgeDeviceApiCall';

export default function DeleteVendorDialog(props) {
    const { value, openDeleteEdDialog, setOpenDeleteEdDialog, setDeleteEdStatus } = props;

    const handleClose = () => {
        setOpenDeleteEdDialog(false);
    };

    const handleonDelete = () => {
        DeleteEdgeDeviceApiCall(value.device_id, setDeleteEdStatus)
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={openDeleteEdDialog}
                onClose={handleClose}
                fullWidth maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Edge Device
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure to Delete Edge Device - {value.device_ip} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleonDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}