// @mui
import { useEffect } from 'react';
import { useTour } from '@reactour/tour'

import {
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link, Button
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { NavLink, useNavigate } from "react-router-dom";

// components
import Page from '../components/Page';
import HomeComponent from '../components/HomeComponents/HomeComponent';


const HomePage = ({ setStep }) => {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()


  useEffect(() => {
        startTour();
  },
    [])
  const redirect = useNavigate();

  const startTour = () => {
    const tourStatus = localStorage.getItem("tour_status");
      if (tourStatus === "0") {
        setIsOpen(true);
      } 
      else{
        setIsOpen(false);
      }
  };
  return (
    <Page title="Overview">
      <Container maxWidth={false}>
        {/* <button className="btn btn-primary" onClick={startTour}>
          start tour
        </button> */}
        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/home">
              Overview
            </Link>
          </Breadcrumbs>
        </div>
        <Divider />
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: "20px" }}>
          <HomeComponent />
        </Grid>
      </Container>
    </Page>
  );
};
export default HomePage;