// import axios from 'axios';

// const GetLocationsApiCall = async (setLocations) => {
//   const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_client.php`;

//   const payload = JSON.stringify({
//     "auth_token": localStorage.getItem("auth_token"),
//     "client_id": localStorage.getItem("cust_id"),
//   });

//   try {
//     const response = await axios.post(baseUrl, payload);
//     if (response.data.code === 1) {
//       const locationMap = {};

//       response.data.location_data.forEach((location) => {
//       if (!locationMap[location.location_id]) {
//         locationMap[location.location_id] = {
//           location_id: location.location_id,
//           location_name: location.location_name,
//         };
//       }
//       setLocations(Object.values(locationMap))
//     });
//     } else {
//       console.error("Failed to fetch locations:", response.data.message);
//     }
//   } catch (error) {
//     console.error("Error in fetching locations:", error);
//   }
// };

// export default GetLocationsApiCall;


import axios from 'axios';

const GetLocationsApiCall = async (setLocations) => {
  

  const path = localStorage.getItem("msp_status") === "1" ? "get_locations_by_client_for_msp.php" : "get_locations_by_client.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id")
      })

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id")
      })
      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  try {
    const response = await axios.post(baseUrl, payload);
    if (response.data.code === 1) {
      const locationMap = {};

      response.data.location_data.forEach((location) => {
      if (!locationMap[location.location_id]) {
        locationMap[location.location_id] = {
          location_id: location.location_id,
          location_name: location.location_name,
        };
      }
      setLocations(Object.values(locationMap))
    });
    } else {
      console.error("Failed to fetch locations:", response.data.message);
    }
  } catch (error) {
    console.error("Error in fetching locations:", error);
  }
};

export default GetLocationsApiCall;
