import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, Checkbox, FormControlLabel, Typography, } from '@mui/material';
import AddEdgeDeviceApiCall from '../LocationComponents/ViewLocationComponents/AddEdgeDeviceApiCall';
import GetEdgeDeviceInfoApi from '../LocationComponents/ViewLocationComponents/GetEdgeDeviceInfoApi';
import UpdateEdgeDeviceApiCall from '../LocationComponents/ViewLocationComponents/UpdateEdgeDeviceApiCall';
import GetEdgeDeviceVendorAndTypesApiCall from '../LocationComponents/ViewLocationComponents/GetEdgeDeviceVendorAndTypesApiCall';

export default function AddEdgeDeviceDialog({
    locationName, setLocationName,
    locationId, setLocationId,
    deviceId, setDeviceId,
    deviceIp, setDeviceIp,
    snmpStr, setSnmpStr,
    sshUserName, setSshUserName,
    sshPassword, setSshPassword,
    apiKey, setApiKey,
    sshPort, setSshPort,
    apiPort, setApiPort,
    deviceType, setDeviceType,
    deviceVendor, setDeviceVendor,
    deviceStatus, setDeviceStatus,
}) {


    const [locationNameHelerText, setLocationNameHelerText] = useState("");
    const [deviceIpHelerText, setDeviceIpHelerText] = useState("");
    const [snmpStrHelerText, setSnmpStrHelerText] = useState("");
    const [apiKeyHelperText, setApiKeyHelperText] = useState("");
    const [sshUsernameHelperText, setSshUsernameHelperText] = useState("");
    const [sshPassHelperText, setSshPassHelperText] = useState("");
    const [sshPortHelperText, setSshPortHelperText] = useState("");
    const [apiPortHelperText, setApiPortHelperText] = useState("");

    const [typeList, setTypeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    const snmpStrRegex = /^[^\s].*$/;
    const portRegex = /^[0-9]+$/;
    const locationRegex =/^[a-zA-Z][a-zA-Z0-9-_\s]*$/;
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

    const [checked, setChecked] = useState(false);

    const formData = {
        locationName,
        locationId,
        deviceId,
        deviceIp,
        snmpStr,
        sshUserName,
        sshPassword,
        apiKey,
        sshPort,
        apiPort,
        deviceType,
        deviceVendor,
    };

    // ...

    useEffect(() => {
        // Convert the formData object to JSON string
        const formDataJson = JSON.stringify(formData);

        // Save the JSON string in local storage
        localStorage.setItem('EdgeDeviceFormData', formDataJson);
    }, [formData]);

    useEffect(() => {
        GetEdgeDeviceInfoApi(locationId, setDeviceId, setDeviceIp, setSnmpStr, setSshUserName, setSshPassword, setApiKey, setSshPort, setApiPort, setDeviceVendor, setDeviceType);
        GetEdgeDeviceVendorAndTypesApiCall(setVendorList, setTypeList);
    }, [])

    const handleOnChange = (e) => {
        const { id, value } = e.target;
    
        if (e.target.id === "location_name") {
          if (!value.match(locationRegex)) {
            setLocationNameHelerText("Enter valid Location Name")
          }
          else {
            setLocationNameHelerText("");
          }
          setLocationName(e.target.value);
        }
    
        if (e.target.id === "device_ip") {
            if (!value.match(ipRegex)) {
                setDeviceIpHelerText("Device IP Should be Valid");
            }
              else {
                setDeviceIpHelerText("");
            }
            setDeviceIp(e.target.value);
        }
    
        if (e.target.id === "snmp_str") {
            if (!value.match(snmpStrRegex)) {
                setSnmpStrHelerText("Enter valid SNMP String");
            }
              else {
                setSnmpStrHelerText("");
            }
            setSnmpStr(e.target.value);
        }
    
        if (e.target.id === "api_key") {
            if (!value.match(snmpStrRegex)) {
                setApiKeyHelperText("Enter valid API Key");
            }
              else {
                setApiKeyHelperText("");
            }
            setApiKey(e.target.value);
        }
    
    
        if (e.target.id === "ssh_username") {
            if (!value.match(locationRegex)) {
              setSshUsernameHelperText("Enter valid SSH User name");
            }
            else {
              setSshUsernameHelperText("");
            }
            setSshUserName(e.target.value);
          }
    
    
        if (e.target.id === "ssh_password") {
          if (!value.match(snmpStrRegex)) {
            setSshPassHelperText("Enter valid SSH password");
          }
          else {
            setSshPassHelperText("");
          }
          setSshPassword(e.target.value);
        }
    

        if (e.target.id === "api_port") {
            if (!value.match(portRegex)) {
              setApiPortHelperText("Enter valid Port number");
            }
            else {
              setApiPortHelperText("");
            }
            setApiPort(e.target.value);
          }
      
          if (e.target.id === "ssh_port") {
            if (!value.match(portRegex)) {
              setSshPortHelperText("Enter valid SSH Port number");
            }
            else {
              setSshPortHelperText("");
            }
            setSshPort(e.target.value);
          }
    
      }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh", paddingTop: "8vh" }}>
            <div style={{ width: "1000px" }} >
                <form>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                        <Grid item xs={10}>

                            <h1 style={{ alignItems: "center", paddingBottom: "20px", width: "60vh", margin: "20px" }}>
                            Add WAN edge device of that site </h1>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Location Name"
                                id="location_name"
                                value={locationName}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={locationNameHelerText}
                                error={locationNameHelerText}
                                disabled
                                required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Edge Device IP"
                                id="device_ip"
                                value={deviceIp}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={deviceIpHelerText}
                                error={deviceIpHelerText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="SNMP String"
                                id="snmp_str"
                                value={snmpStr}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={snmpStrHelerText}
                                error={snmpStrHelerText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="API Key"
                                id="api_key"
                                value={apiKey}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={apiKeyHelperText}
                                error={apiKeyHelperText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="SSH UserName"
                                id="ssh_username"
                                value={sshUserName}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={sshUsernameHelperText}
                                error={sshUsernameHelperText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="SSH Password"
                                type="password"
                                id="ssh_password"
                                value={sshPassword}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={sshPassHelperText}
                                error={sshPassHelperText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="SSH Port"
                                id="ssh_port"
                                value={sshPort}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={sshPortHelperText}
                                error={sshPortHelperText}
                                required />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="API Port"
                                id="api_port"
                                value={apiPort}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                helperText={apiPortHelperText}
                                error={apiPortHelperText}
                                required />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="vendor-label-id">Vendor</InputLabel>
                                <Select
                                    labelId="vendor-label-id"
                                    id="vendor"
                                    value={deviceVendor}
                                    label="Vendor"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setDeviceVendor(e.target.value); }}
                                >
                                    <MenuItem value={0}>Select</MenuItem>
                                    {vendorList.map((value) => (
                                        <MenuItem value={value.ed_vendor_id}>{value.vendor_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="type-label-id">Type</InputLabel>
                                <Select
                                    labelId="type-label-id"
                                    id="type"
                                    value={deviceType}
                                    label="Type"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setDeviceType(e.target.value); }}
                                >
                                    <MenuItem value={0}>Select</MenuItem>
                                    {typeList.map((value) => (
                                        <MenuItem value={value.type_id}>{value.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}
