import axios from 'axios';

export const saveGoogleUserData = async (authToken, setLoginStatus, setCustomers, setAuthToken, setOpencustomerSelectionModal, navigate, setStatus) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/google_sso_callback.php`;

  const payload = JSON.stringify({
    "token": authToken
  });
  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 2) {
        const mspCustId = response.data.msp_status === 1 ? response.data.cust_id : -1;
        localStorage.setItem("cust_id", response.data.cust_id);
        localStorage.setItem("reg_cust_id", response.data.cust_id);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("auth_token", response.data.auth_token);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("onboard_status", response.data.onboard_status);
        localStorage.setItem("tour_status", response.data.tour_status);
        localStorage.setItem("msp_status", response.data.msp_status);
        localStorage.setItem("msp_cust_id", mspCustId);
        localStorage.setItem("user_map_status", response.data.user_map_status);
        localStorage.setItem("bu_status", response.data.bu_status);
        navigate('/dashboard/home', { replace: true });
      }else if (response.data.code === 3) {
        const customers = Object.entries(response.data.customers).map(([custId, custName]) => ({
          custId,
          custName
        }));
        setCustomers(customers);
        setAuthToken(response.data.auth_token);
        setOpencustomerSelectionModal(true);
      } else if (response.data.code === 1) {
        setStatus(response.data.message)
      } else if (response.data.code === -1) {
        setLoginStatus(-1);
        // setStatus(response.data.message)
      }else if (response.data.code === 0) {
        setLoginStatus(-1);
      }
    }).catch(() => {
      setLoginStatus(-1);
    });
};