import axios from 'axios';

// API CALL CODE HERE
export const EdLogsAPICall = async (setEdgeAlerts,setAllEdgeAlerts, buState) => {

      const path = localStorage.getItem("msp_status") === "1" ? "get_decommissioned_edge_devices_for_msp.php" : "get_decommissioned_edge_devices.php";
      const logsBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id")
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(logsBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
          setEdgeAlerts(response.data.ed_data);
          setAllEdgeAlerts(response.data.ed_data)
        }
        else {
          setEdgeAlerts([]);
          setAllEdgeAlerts([]);
        }
      })
    }

