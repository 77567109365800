import axios from 'axios';

// API CALL CODE HERE

export const CreateCustomerApiCall = async (customerName, customerAddr, customerEmail, customerPhoneNo, setCreateStatus) => {
  const baseURL = `${process.env.REACT_APP_API_SERVER_URL}/create_new_customer_for_msp.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "customer_data":{
      "customer_name": customerName,
      "customer_address": customerAddr,
      "customer_phone": customerEmail,
      "customer_email": customerPhoneNo
    }
  });
  
  return axios.post(baseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setCreateStatus(1);
    }
    else{
      setCreateStatus(-1);
    }
  }).catch(()=>{
    setCreateStatus(-1);
  })
}
