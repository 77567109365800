import axios from 'axios';

// API CALL CODE HERE
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/create_new_license_request.php`;

const AddNewLicenseApiCall = async (CustId,LicenseType, LicenseKey, RequestedType, setAddLicenseStatus) => {
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id"),
    "data": {
        "cust_id":CustId,
        "present_license_type":LicenseType,
        "present_license_key": LicenseKey,
        "requested_type": RequestedType
      
	}
  });  

  return axios.post(createBaseURL, regPayload).then((response) => {
   
    if (response.data.code === 1) {
      setAddLicenseStatus(1);
    }
    else {
      setAddLicenseStatus(-1);
    }
  }).catch((error)=>{
    setAddLicenseStatus(-1);
  })
}

export default AddNewLicenseApiCall;