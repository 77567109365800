import React, { useState, useEffect } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    MenuItem,
    Checkbox,
    FormControl,
    Autocomplete,
    FormHelperText
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import GetSiteGroupDetailsApiCall from '../../SiteGroupComponents/Components/GetSiteGroupDetailsApiCall';
import { UpdateApplicationDeatilsAPICall } from './UpdateApplictionDetailsAPICall';


export default function EditSaasDialog(props) {
    const { app, appType, openEditDialog, setOpenEditDialog, setUpdateStatus, setOpen, setSeverStatus ,setUpdateStatusMessage} = props;

    const [AppUrl, setAppUrl] = useState(app.app_url);
    const [AppName, setAppName] = useState(app.app_name);
    const [previousAppName, setPreviousAppName] = useState(app.app_name);

    const [groups, setGroups] = useState([]);
    const [groupNames, setGroupNames] = useState([]);
    const [groupIds, setGroupIds] = useState([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState(app.group_id);
    const [selectedGroupNames, setSelectedGroupNames] = useState(app.group_name);

    const [AppUrlHelperText, setAppUrlHelperText] = useState("");
    const [AppNameHelperText, setAppNameHelperText] = useState("");
    const [AppGroupNameHelperText, setAppGroupNameHelperText] = useState("");
    const [warning, setWarning] = useState("");
    const [critical, setCritical] = useState("");

    const appurlRegex = /^(www\.)?([-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})\b([-a-zA-Z0-9@:%_~#?&//=]*)$/;
    const appNameRegex = /^[^"']*[A-Za-z0-9]$/;

    const handleUpdateClick = (e) => {
        if (appType === 0) {
            if (!AppUrl.match(appurlRegex)) {
                setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                return;
            }
        }
        if (!AppName.match(appNameRegex)) {
            setAppNameHelperText("App Name Should be Valid");
            return;
        }
        if (!selectedGroupNames || selectedGroupNames.length === 0) {
            setAppGroupNameHelperText("Select the Group Name from the dropdown");
            return;
        }
        UpdateApplicationDeatilsAPICall(app.app_id, previousAppName, appType, AppUrl, AppName, selectedGroupIds, setUpdateStatus, setSeverStatus,setUpdateStatusMessage);
        setOpen(true);
        setOpenEditDialog(false);
    }
    
    const handleGroupSelectionChange = (event, values, reason) => {
        const selectedIds = values.map((groupName) => {
            const selectedGroup = groups.find((group) => group.group_name === groupName);
            return selectedGroup ? selectedGroup.group_id : null;
        });

        const filteredIds = selectedIds.filter((id) => id !== null);

        setSelectedGroupIds(filteredIds);
        setSelectedGroupNames(values);

        if (reason === 'select-option') {
            const clickedGroupName = values[values.length - 1];
            const clickedGroup = groups.find((group) => group.group_name === clickedGroupName);

            if (clickedGroup) {
                setSelectedGroupIds((prevIds) => [...prevIds, clickedGroup.group_id]);
            }
            
        }
            setAppGroupNameHelperText("");
        
    };

    const handleOnClose = () => {
        setOpenEditDialog(false);
    }
    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "app_url":
                if (appType === 0) {
                    if (!e.target.value.match(appurlRegex)) {
                        setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                    } else {
                        setAppUrlHelperText("");
                    }
                    setAppUrl(e.target.value);
                }
                break;

            case "app_name":
                if (!e.target.value.match(appNameRegex)) {
                    setAppNameHelperText("App Name Should be Valid");
                } else {
                    setAppNameHelperText("");
                }
                setAppName(e.target.value);
                break;
            case "group_name":
                if (!selectedGroupNames || selectedGroupNames.length === 0) {
                    setAppGroupNameHelperText("Select the Group Name from the dropdown");
                } else {
                    setAppGroupNameHelperText("");
                }
                selectedGroupNames(e.target.value);
                break;
           
                default:
                setAppName(e.target.value);
                break;
        }
    };

    const _GetSiteGroupDetailsApiCall = async () => {
        await GetSiteGroupDetailsApiCall(setGroups, setWarning, setCritical);

        setGroupNames(groups.map(group => group.group_name));
    }
    
    useEffect(() => {
        _GetSiteGroupDetailsApiCall();
    }, [])

    useEffect(() => {
        setGroupNames(groups.map(group => group.group_name));
    }, [groups, selectedGroupNames]);

    useEffect(() => {
        if (groups.length > 0) {
            setGroupNames(groups.map(group => group.group_name));
        }
    }, [groups])

    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    {appType === 0 ? ' Update Internal Application' : 'Update SaaS Application'}
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1px' }}>
                        <Grid item xs={6}>
                            <TextField
                                label="App Url"
                                id="app_url"
                                value={AppUrl}
                                helperText={AppUrlHelperText}
                                error={!!AppUrlHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                required
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="App Name"
                                id="app_name"
                                value={AppName}
                                helperText={AppNameHelperText}
                                error={!!AppNameHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                required
                                disabled={appType === 1}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!AppGroupNameHelperText}>
                                <Autocomplete
                                    id="group_name"
                                    multiple
                                    options={groupNames}
                                    disableCloseOnSelect
                                    value={selectedGroupNames}
                                    helperText={AppGroupNameHelperText}
                                    error={!!AppGroupNameHelperText}
                                    onChange={handleGroupSelectionChange}
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                key={option}
                                                value={option}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Site Group"
                                            error={!!AppGroupNameHelperText}
                                        />
                                    )}
                                />
                                <FormHelperText>{AppGroupNameHelperText}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleUpdateClick} disabled={!selectedGroupNames || selectedGroupNames.length === 0}>Update</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}