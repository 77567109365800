import axios from 'axios';

// API CALL CODE HERE

const CheckEdHealthStatusApiCall = async (ispWanId, setEdHealthStatus, setGotEdHealthStatus, setEdHealthStatusMessage, setContinueEdInterfaceStatus) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_edge_device_health.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === -1) {
      setEdHealthStatus(true)
      setContinueEdInterfaceStatus(true)
      setGotEdHealthStatus(true)
      setEdHealthStatusMessage("Health info Not Available.. Contact Admin")
    }
    else {
      if (response.data.cpu_usage < 75) {
        setEdHealthStatus(true)
        setContinueEdInterfaceStatus(true)
      }
      else {
        setEdHealthStatus(false)
        setContinueEdInterfaceStatus(false)
      }
      setGotEdHealthStatus(true)
      setEdHealthStatusMessage(response.data.message)
    }
  })
}

export default CheckEdHealthStatusApiCall;
