import axios from "axios"

const AddBusinessHourApiCall = async(data, locationId, setAddBusinessHrStatus, setSeverStatus) => {
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/push_location_business_hrs.php`;
    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "cust_id": localStorage.getItem("cust_id"),
        "location_id": locationId,
        "data": data
    });
    await axios.post(baseUrl, payload).then((response)=>{
        if(response.data.code === 1)
        {
            setAddBusinessHrStatus(1);
            setSeverStatus("success");
        }
        else
        {
            setAddBusinessHrStatus(-1);
            setSeverStatus("error");
        }
       
    }).catch(()=>{
        setAddBusinessHrStatus(-1);
        setSeverStatus("error");
    })
  
}

export default AddBusinessHourApiCall