import axios from 'axios';

// API CALL CODE HERE

const TemplateFolderApiCall = async (reportDate, setCurrentFiles) => {
    const path = localStorage.getItem("msp_status") === "1" ? "get_report_template_file_path_for_msp.php" : "get_report_template_file_path.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
                            
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "report_date": reportDate
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "report_date": reportDate
    })

    
    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            const sorted = [...response.data.files].sort((a, b) => b.created - a.created);
            setCurrentFiles(sorted)
        }
        else {
            setCurrentFiles([])
        }
    })
}

export default TemplateFolderApiCall;