import axios from 'axios';

// API CALL CODE HERE

const ChangeProbeStatusApiCall = async (status, probeUniqueId, probeName, setEnableDisableStatus, setEnableDisbaleMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_internal_probe_status_for_msp.php" : "update_internal_probe_status.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "probe_id": probeUniqueId,
    "probe_name": probeName,
    "probe_status": status === "1" ? 0 : 1
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeUniqueId,
    "probe_name": probeName,
    "probe_status": status === "1" ? 0 : 1
  });

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setEnableDisableStatus(1);
      setEnableDisbaleMessage(response.data.message)
    }
    else if (response.data.code === -1) {
      setEnableDisableStatus(-1);
      setEnableDisbaleMessage(response.data.message)
    }
    else if (response.data.code === -2) {
      setEnableDisableStatus(-2);
      setEnableDisbaleMessage(response.data.message)

    }

  }).catch((error) => {
    setEnableDisableStatus(-1);
    setEnableDisbaleMessage(error.response.data.message)

  })
}

export default ChangeProbeStatusApiCall;