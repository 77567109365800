// @mui
import {
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EdGood1Card1 from './EdGood1Card1';
// components
// ----------------------------------------------------------------------

export default function EdGood1MainPage() {
  return (
    <>
      <Grid container spacing={1} sx={{ py: 1 }}>
        <EdGood1Card1/>
      </Grid>
    </>
  );
}
