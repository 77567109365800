import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid,Paper, Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';
// components
import { filter } from 'lodash';
// sections

import Scrollbar from '../../Scrollbar';

// mock
import devices from '../../../_mock/devices';
import ISPGoodTableToolbar from './ISPGoodTableToolbar';
import ISPGoodTableHead from './ISPGoodTableHead';
import { ISPGoodTableDataAPI } from '../ISPManagamentRestCalls/ISPGoodTableDataAPI';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Internal IP', label: 'Internal IP', alignRight: false },
  { id: 'DG IP', label: 'DG IP', alignRight: false },
  { id: 'Uptime', label: 'Up-Time', alignRight: false },
  { id: 'Latency', label: 'Latency (ms)', alignRight: false },
  { id: 'Packet drops', label: 'Packet Loss (%)', alignRight: false },
  { id: 'Jitter', label: 'Jitter (ms)', alignRight: false },
  { id: 'View', label: 'View', alignRight: false },
];


const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

//----------------------------------------------------------------------
export default function ISPGoodTable() {
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [goodIspData, setGoodIspData] = useState([]);

  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const _ISPGoodTableDataAPI =async()=>{
    await ISPGoodTableDataAPI(setGoodIspData, buState);
  }
  useEffect(()=>{
    _ISPGoodTableDataAPI();
    setInterval(() => {
      _ISPGoodTableDataAPI();
    }, 1000 * 60);
  },[globalState, buState]);

  useEffect(()=>{
    _ISPGoodTableDataAPI();
  },[])

  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }


  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPGoodTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }} className={classes.container}>
            <Table stickyHeader>
              <ISPGoodTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
             <TableBody>
              {goodIspData.map((list, index) => (
                <TableRow key={index} hover>
                  <TableCell>{index+1}</TableCell>
                  {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.cust_name}</TableCell> : null }
                  <TableCell>{list.circuit_id}</TableCell>
                  <TableCell>{list.location}</TableCell>
                  <TableCell>{list.vendor}</TableCell>
                  <TableCell>{list.public_ip}</TableCell>
                  <TableCell>{list.internal_ip}</TableCell>
                  <TableCell>{list.default_gateway}</TableCell>
                  <TableCell>{convertMinToHourMinutes(list.up_cnt)}</TableCell>
                  <TableCell>{list.avg_rtt}</TableCell>
                  <TableCell>{list.packet_loss}</TableCell>
                  <TableCell>{list.jitter}</TableCell>
                  <TableCell><Link to={{pathname: `/dashboard/isp-management/isp-good/${list.isp_wan_id}`}}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </Grid>
  );
}
