import axios from 'axios';

// API CALL CODE HERE


export const SelectUsersRestAPICall = async (authVal, setEmail, setUsers, setFirstUsersName, setErrorCode) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_usernames_by_auth.php`;


  const payload = JSON.stringify({
      "auth_token": authVal
  }
  );

  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 1) {
        setEmail(response.data.email)
        setUsers(response.data.users);
        setFirstUsersName(response.data.users[0].name);
      }
      else {
        setErrorCode(-1);
    }
  })
}
