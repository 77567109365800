import {
    Breadcrumbs, Container, Link,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
import ViewAgentsComponents from '../components/SAMonitorComponents/ApplicationManagementComponents/ViewAgentsComponents';

export default function ViewAgents() {
    return (
        <Page title="view agents">
            <Container maxWidth={false}>
                <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard/view-agents">
                            <BuildIcon />
                        </Link>
                        <Link underline="hover" color="inherit">
                            App Management
                        </Link>
                        <Link underline="hover" color="inherit" href="/dashboard/view-agents">
                            View Agents
                        </Link>
                    </Breadcrumbs>
                </div>

                <ViewAgentsComponents />

            </Container>
        </Page>
    );
}
