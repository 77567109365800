import React from 'react'
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

import Page from '../components/Page';
import BusinessComponent from '../components/BusinessUnitComponents/BusinessUnitComponent'

export default function BusinessUnit() {
    return (
        <Page title="BusinessUnit">
            <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                        Business Unit Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/business-unit">
                    Business Unit
                    </Link>
                </Breadcrumbs>
            </div>
            <Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper elevation={15}>
                        <BusinessComponent />
                    </Paper>
                </Grid>
            </Grid>

        </Page>
    )
}
