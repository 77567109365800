import { useState, useEffect, forwardRef } from 'react';
// material
import {
  Grid,
  Table,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Paper,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

// components
import Scrollbar from '../../Scrollbar';
import CustomersTableHead from './CustomersTableHead';
import CustomersTableToolbar from './CustomersTableToolbar';


import { GetMspMappedCustomersDetailsApiCall } from './GetMspMappedCustomersDetailsApiCall';
import AddCustomerDialogComponent from './AddCustomerDialogComponent';
import EditCustomerDialogComponent from './EditCustomerDialogComponent';
import DeleteCustomerDialog from './DeleteCustomerDialog';
// ----------------------------------------------------------------------


const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});


const TABLE_HEAD = [
  { id: 'slNo', label: 'Sl No', alignRight: false },
  { id: 'Customer Name', label: 'Customer Name', alignRight: false },
  { id: 'Address', label: 'Address', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Phone No', label: 'Phone No', alignRight: false },
  { id: 'No of Links', label: 'No of Links', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'Actions', label: 'View', alignRight: false },
];

// ----------------------------------------------------------------------


export default function CustomersTable() {
  const classes = useStyles();

  const [custDetails, setCustDetails] = useState([]);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState(false);
  const [openEditCustomerDialog, setOpenEditCustomerDialog] = useState(false);
  const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] = useState(false);

  const [createStatus, setCreateStatus] = useState(0);
  const [editStatus, setEditStatus] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);

  const [value, setValue] = useState({});

  const Alert = forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const _GetMspMappedCustomersDetailsApiCall = async () => {
    await GetMspMappedCustomersDetailsApiCall(setCustDetails);
  }

  useEffect(() => {
    _GetMspMappedCustomersDetailsApiCall();
    // setInterval(() => {
    //   _GetMspMappedCustomersDetailsApiCall();
    //   setFilterName("");
    // }, 60 * 1000);
  }, [createStatus, editStatus, deleteStatus]);

  const handleFilterByName = (event) => {
    const searchedVal = event.target.value;
    setFilterName(searchedVal);
   
    if (searchedVal === "") {
      _GetMspMappedCustomersDetailsApiCall(); 
    } else {
      const filteredRows = custDetails.filter((row) => {
        return row.cust_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.cust_address.toLowerCase().includes(searchedVal.toLowerCase()) || row.cust_email.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setCustDetails(filteredRows);
    }
  };
  
  
  
  const handleAddClick = () => {
    setOpenAddCustomerDialog(true)
  }

  const handleEditClick = (e, row) => {
    resetAll()
    setValue(row);
    setOpenEditCustomerDialog(true)
  }

  const handleDeleteClick = (e, row) => {
    resetAll()
    setValue(row);
    setOpenDeleteCustomerDialog(true)
  }

  const resetAll = () => {
    setCreateStatus(0);
    setEditStatus(0)
    setDeleteStatus(0)
    setOpenAddCustomerDialog(false);
    setOpenEditCustomerDialog(false);
    setOpenDeleteCustomerDialog(false);
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    resetAll();
  };

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Grid container>
          <Grid item xs={10} sm={10} md={10} lg={10} sx={{ px: 1, py: 1 }}>
            <div className={classes.titleStyle}>
              <Typography variant='h4'>
                <strong>{ }</strong>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Paper elevation={15}>
          <CustomersTableToolbar
            tableHeading={'Locations'}
            numSelected={selected.length}
            filterName={filterName}
            tableTitle={"Clients"}
            onFilterName={(e) => { handleFilterByName(e, filterName) }}
            handleAddClick={handleAddClick}
          />

          <Scrollbar>
            <TableContainer className={classes.container}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <CustomersTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                // rowCount={linkAlerts.length}
                />
                <TableBody>
                  {custDetails.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.cust_name}</TableCell>
                        <TableCell align="left">{row.cust_address}</TableCell>
                        <TableCell align="left">{row.cust_email}</TableCell>
                        <TableCell align="left">{row.cust_phone_no}</TableCell>
                        <TableCell align="left">{row.no_of_links}</TableCell>
                        <TableCell align="left">{row.ent_date}</TableCell>
                        <TableCell align="center">
                          <Grid container direction="row" >
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <IconButton variant="outlined" color="primary" onClick={(e)=>{handleEditClick(e, row)}}><EditIcon /></IconButton>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <IconButton variant="outlined" color="error" onClick={(e)=>{handleDeleteClick(e, row)}}><DeleteIcon /></IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {openAddCustomerDialog && <AddCustomerDialogComponent openAddCustomerDialog={openAddCustomerDialog} setOpenAddCustomerDialog={setOpenAddCustomerDialog} setCreateStatus={setCreateStatus} />}
          {openEditCustomerDialog && <EditCustomerDialogComponent value={value} openEditCustomerDialog={openEditCustomerDialog} setOpenEditCustomerDialog={setOpenEditCustomerDialog} setEditStatus={setEditStatus} />}
          {openDeleteCustomerDialog && <DeleteCustomerDialog value={value} openDeleteCustomerDialog={openDeleteCustomerDialog} setOpenDeleteCustomerDialog={setOpenDeleteCustomerDialog} setDeleteStatus={setDeleteStatus}/>}
        </Paper>
        {createStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
            Customer Created Successfully.
          </Alert>
        </Snackbar>}
        {createStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            Error in Creating Customer.
          </Alert>
        </Snackbar>}
        {editStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
            Customer Updated Successfully.
          </Alert>
        </Snackbar>}
        {editStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            Error in Creating Customer.
          </Alert>
        </Snackbar>}
        {editStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            No Change in data
          </Alert>
        </Snackbar>}

        {deleteStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
            Customer Deleted Successfully.
          </Alert>
        </Snackbar>}
        {deleteStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            Error in Deleting Customer.
          </Alert>
        </Snackbar>}
        {deleteStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            No Change in data
          </Alert>
        </Snackbar>}
      </Grid>
    </>
  );
}
