// @mui
import {
  Breadcrumbs,
  Container,
  Link,
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// components
import Page from '../components/Page';
import ReportMainComponent from '../components/ISPInsightComponents/ReportsMainComponent';

export default function ISPReports(props) {

  return (
    <Page title="ISP-Reports">
      <Container maxWidth={false}>
        <div role="presentation" style={{ paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/isp-reports">
              Link - Reports
            </Link>
          </Breadcrumbs>
        </div>

        <ReportMainComponent />

      </Container>
    </Page>
  );
}
