import axios from 'axios';

// API CALL CODE HERE
const GetTopDomainUsageExporterAPICall = async (deviceIps, time, setDomainUsageData, setInBoudWanArr, setOutBoudWanArr, setDomains) => {
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_domain_exporter.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "device_ips": deviceIps,
    "time": time
  });
  // console.log(payload);
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setDomainUsageData(response.data.domain_usage);

      const data = response.data.domain_usage;
      // console.log(data)
      const inboundWanArr = [];
      const outboundWanArr = [];
      const domainsArr = [];

      data.forEach(item => {
        inboundWanArr.push(item.inbound_wan);
        outboundWanArr.push(item.outbound_wan);
        domainsArr.push(item.domain);
      });

      setInBoudWanArr(inboundWanArr)
      setOutBoudWanArr(outboundWanArr)
      setDomains(domainsArr)

    }
    else {
      setDomainUsageData([]);
      setInBoudWanArr([])
      setOutBoudWanArr([])
      setDomains([])
    }
  })
}

export default GetTopDomainUsageExporterAPICall;