// @mui
import { Breadcrumbs, Container, Link, Grid, Paper, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';
import LocationSpecificDownData from '../components/SidewiseComponents/Components/LocationSpecificDownData';
import GetSiteTroubleShootDetailsApiCall from './ApiCalls/GetSiteTroubleShootDetailsApiCall';

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function SiteUp(props) {
  const [locationData, setLocationdata] = useState(null);
  const classes = useStyles();
  const location = useLocation();
  const [messages, setMessages] = useState([]);

  const setLoc = async () => {
    setLocationdata(location.state);
    // console.log(location.state.location.location_id)
  }

  useEffect(() => {
    setLoc();
    GetSiteTroubleShootDetailsApiCall(location.state.location.location_id, setMessages)
  }, [location.state]);


  return (
    <Page title="SiteDown">
      <Container maxWidth={false} style={{ marginTop: 20 }}>
        <div role="presentation" style={{ paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href='dashboard/isp-management'>
              Link Management
            </Link>
            <Link underline="hover" color="inherit" href="/site-down" >
              Site-Up
            </Link>
            <Link underline="hover" color="inherit" href="/site-down" >
              {location.state.location.location}
            </Link>
          </Breadcrumbs>
        </div>

        {locationData && (
          <>
            <LocationSpecificDownData locationData={locationData} />
          </>
        )}
      </Container>
      {/* <DashboardSidebar /> */}
    </Page>

  );
}
