import React from 'react';
// material
import {
    Link,
    Breadcrumbs,
    Paper,
    Grid
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
 import LinkTableMgmtComponents from '../components/ViewManagementComponents/LinkTableMgmtComponents'


export default function LinksTableManagement() {
    return (
        <Page title="Links Table Management">
            <div role="presentation" style={{ cursor: 'pointer', padding: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit">
                        <BuildIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                    View Management
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/create-new-tag">
                        Link Table Management
                    </Link>
                </Breadcrumbs>
            </div>
            <Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Paper elevation={15}>
                        <LinkTableMgmtComponents />
                    </Paper>
                </Grid>
            </Grid>


        </Page>
    );

}
