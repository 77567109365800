import axios from 'axios';

// API CALL CODE HERE


const GetZonesProbeDataApiCall = async(probeId, setPresentZones, setAvailableZones, buState) => {

  const path = localStorage.getItem("msp_status") === "1" ? "file.php" : "get_zones_by_probe.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
 
    if (response.data.code === 1) {
      setAvailableZones(response.data.probe_zones_res.map(zone => zone.zone_name));
      setPresentZones(response.data.spare_zones_res.map(zone => zone.zone_name));
    }
    else {
        setAvailableZones([]);
      setPresentZones([]);
    }
  }).catch(()=>{
    setAvailableZones([]);
      setPresentZones([]);
  })
}

export default GetZonesProbeDataApiCall;