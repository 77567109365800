import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import DeleteCustomerApiCall from './DeleteCustomerApiCall';

export default function DeleteCustomerDialog(props) {
    const { value, openDeleteCustomerDialog, setOpenDeleteCustomerDialog, setDeleteStatus } = props;

    const handleClose = () => {
        setOpenDeleteCustomerDialog(false);
    };

    const handleonDelete = () => {
        DeleteCustomerApiCall(value.cust_id, value.cust_name, setDeleteStatus);
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={openDeleteCustomerDialog}
                onClose={handleClose}
                fullWidth maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Customer
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure to Delete Customer - {value.cust_name} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleonDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}