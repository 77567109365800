import React from 'react'
 import LinkTableSettings from './LinkComponents/LinkTableSettings';

export default function LinkTableMgmtComponents() {
  return (
      < LinkTableSettings/>
    // <>
    //   Hi Ganesh
    // </>
  )
}