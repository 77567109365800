import React, { useState, useEffect  } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Grid, TextField, FormControl } from '@mui/material';

import UpdateUtilizationConfigApiCall from './UpdateUtilizationConfigApiCall'
import GetLocationsApiCall from './GetLocationsApiCall';




export default function UpdateUtilizationConfigDialog(props) {
    const { data, selectedRow, openUpdateUtilization, setOpenUpdateUtilization, setUpdateUtilizationStatus, setUpdateUtilizationMessage , utiConfig} = props;

    const [warning, setWarning] = useState(utiConfig.util_warn_th || "80");
    const [warningHelperText, setWarningHelperText] = useState("");
    const [critical, setCritical] = useState(utiConfig.util_critical_th || "100"); 
    const [criticalHelperText, setCriticalHelperText] = useState("");
    const [locations, setLocations] = useState([]);

    // const numericPattern = /^(\d+(\.\d+)?)$/;
    const numericPattern = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)$/;

    const validateNumber = (value) => numericPattern.test(value);

    const handleSubmit = async () => {
        
        if (!validateNumber(warning)) {
            setWarningHelperText("Warning Threshold must be a valid number");
            return;
        }

        
        if (!validateNumber(critical)) {
            setCriticalHelperText("Critical Threshold must be a valid number");
            return;
        }
        const locationIds = locations.map(location => location.location_id);
        const updateData = {
            locationId: locationIds,
            warningThreshold: warning,
            criticalThreshold: critical,
        };

        console.log("Update data", updateData);

         await UpdateUtilizationConfigApiCall(updateData, setUpdateUtilizationStatus, setUpdateUtilizationMessage);

        setOpenUpdateUtilization(false);
        setWarning("");
        setCritical("");
    };
    // useEffect(() => {
    //     if (openUpdateUtilization)
    //          {
    //         GetLocationsApiCall((data) => {
    //             setLocations(data);
    //         });
    //     }
    // }, [openUpdateUtilization]); 
    useEffect(() => {
        if (openUpdateUtilization) {
            
            setWarning(utiConfig.util_warn_th || "80"); 
            setCritical(utiConfig.util_critical_th || "100"); 

            GetLocationsApiCall((data) => {
                setLocations(data);
            });
        }
    }, [openUpdateUtilization, utiConfig]); //

    return (
        <Dialog
            open={openUpdateUtilization}
            onClose={() => { setOpenUpdateUtilization(false); }}
            fullWidth
            maxWidth="md"
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                Update Utilization Config

                <IconButton onClick={() => { setOpenUpdateUtilization(false); }} sx={{ float: 'right' }}>
                    <CloseIcon sx={{ fontSize: '1.5rem' }} />
                </IconButton>
            </DialogTitle>


            <form>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={4}
                    sx={{ py: 1, px: 4 }}
                >
                    {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="location-name"
                                label="Location Name"
                                variant="outlined"
                                value={data.location_name} 
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                sx={{ width: "400px" }} 
                            />
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="warning-thresh"
                                label="Warning Threshold"
                                variant="outlined"
                                value={warning}
                                placeholder="Enter Warning Threshold(in %)"
                                onChange={(e) => {
                                    setWarningHelperText("");
                                    setWarning(e.target.value);
                                }}
                                helperText={warningHelperText}
                                error={!!warningHelperText}
                                sx={{ width: "400px" }} 
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="critical-thresh"
                                label="Critical Threshold"
                                variant="outlined"
                                value={critical}
                                placeholder="Enter Critical Threshold(in %)"
                                onChange={(e) => {
                                    setCriticalHelperText("");
                                    setCritical(e.target.value);
                                }}
                                helperText={criticalHelperText}
                                error={!!criticalHelperText}
                                sx={{ width: "400px" }} 
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>


            <DialogActions>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Update
                </Button>
                <Button onClick={() => { setOpenUpdateUtilization(false); }} variant="outlined" color="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
