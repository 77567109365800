// @mui
import { Breadcrumbs, Container, Link, Grid, Paper, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page';
import LocationSpecificDownData from '../components/SidewiseComponents/Components/LocationSpecificDownData';
import GetSiteTroubleShootDetailsApiCall from './ApiCalls/GetSiteTroubleShootDetailsApiCall';

const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function SiteDown(props) {
  const [locationData, setLocationdata] = useState(null);
  const classes = useStyles();
  const location = useLocation();
  const [messages, setMessages] = useState([]);

  const setLoc = async () => {
    setLocationdata(location.state);
    // console.log(location.state)
    // console.log(location.state.location.location_id)
  }

  useEffect(() => {
    setLoc();
    GetSiteTroubleShootDetailsApiCall(location.state.location.location_id, setMessages)
  }, [location.state]);


  return (
    <Page title="SiteDown">
      <Container maxWidth={false} style={{ marginTop: 20 }}>
        <div role="presentation" style={{ paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/home">
              <HomeOutlinedIcon />
            </Link>
            <Link underline="hover" color="inherit" href='dashboard/isp-management'>
              Link Management
            </Link>
            <Link underline="hover" color="inherit" href="/site-down" >
              Site-Down
            </Link>
            <Link underline="hover" color="inherit" href="/site-down" >
              {location.state.location.location}
            </Link>
          </Breadcrumbs>
        </div>

        {locationData && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper elevation={15} sx={{ mt: 2 }}>
                <div className={classes.titleStyle}>
                  <Typography variant="h6" style={{ paddingBottom: "10px" }}>
                    Troubleshooting Details
                  </Typography>

                  {messages.map((message, index) => {
                    // Split the message by '/', then join by '<br>'
                    const formattedMessage = message.split('/').join('<br>');

                    return (
                      <div style={{ paddingLeft: "10px", paddingBottom: "10px" }} key={index}>
                        <Typography variant="h8" component="div">
                          <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </Paper>
            </Grid>
            <LocationSpecificDownData locationData={locationData} />
          </>
        )}
      </Container>
      {/* <DashboardSidebar /> */}
    </Page>

  );
}
