import axios from "axios";


const GetSiteTroubleShootDetailsApiCall = async (locationId, setMessages) => {

const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_site_status.php`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId
    });
    // console.log(payload);
    await axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setMessages(response.data.detailed_message.split("\n"));
        }
        else {
            setMessages([])
        }
    })
}

export default GetSiteTroubleShootDetailsApiCall;
