import axios from "axios";


export const ISPCard1RestCallForCard = async (ispWanID, setIspParams, setUpStatus = null, setGoodStatus = null) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_single_isp_present_data_for_msp.php" : "get_single_isp_present_data.php";
    const baseURLPresentData = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLPresentData, payload).then((response) => {
        if (response.data.code === 1) {
            setIspParams(response.data.isp_data);
            if (setUpStatus !== null) {
                if (response.data.isp_data.packet_loss === 100) {
                    setUpStatus(false);
                }
                else {
                    setUpStatus(true);
                }

                if (parseFloat(response.data.isp_data.jitter) < 150 && parseFloat(response.data.isp_data.avg_rtt) < 150) {
                    setGoodStatus(true);
                }
                else {
                    setGoodStatus(false);
                }
            }
        }
        else
        {
            setIspParams({});
        }
    });
}

export const ISPCard1RestCallForGraphCard = async (ispWanID, timeValue, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {

    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_latency_jitter_for_msp.php" : "get_isp_latency_jitter.php";
    const baseURLGraphData = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "time": timeValue
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "time": timeValue,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLGraphData, payload).then((response) => {
        if (response.data.code === 1) {
            setJitterData(response.data.jitter_data);
            setLatencyData(response.data.latency_data);
            setPacketLossData(response.data.packet_loss_data);
            setStatusData(response.data.up_down_status_data);
            setTimeData(response.data.time_data);
            setStatusTimeData(response.data.up_down_time);
            setUpDownSeriesData(response.data.up_down_series_data);
            setOpenBackdrop(false);
        }
        else
        {
            setJitterData([]);
            setLatencyData([]);
            setPacketLossData([]);
            setStatusData([]);
            setTimeData([]);
            setStatusTimeData([]);
            setUpDownSeriesData([]);
            setOpenBackdrop(false);
        }
    }).catch(()=>{
            setJitterData([]);
            setLatencyData([]);
            setPacketLossData([]);
            setStatusData([]);
            setTimeData([]);
            setStatusTimeData([]);
            setUpDownSeriesData([]);
            setOpenBackdrop(false);
    });
}

export const ISPCard1BandWidthData = async (ispWanID, timeValue, setInBandWidthData, setOutBandWidthData, setBandWithTimeData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_bandwidth_for_msp.php" : "get_isp_bandwidth.php";
    const baseURLBandWidthData = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "time": timeValue
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "time": timeValue,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseURLBandWidthData, payload).then((response) => {
        if (response.data.code === 1) {
            setInBandWidthData(response.data.in_bandwidth_data);
            setOutBandWidthData(response.data.out_bandwidth_data);
            setBandWithTimeData(response.data.time_data);
            setOpenBackdrop(false)
        }
    });
}

export const ISPCard1RestCallForGraphCardByDate = async (ispWanID, date, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_latency_jitter_by_date_for_msp.php" : "get_isp_latency_jitter_by_date.php";
    const baseURLGraphDataByDate = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "date": date
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "date": date,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLGraphDataByDate, payload).then((response) => {
        if (response.data.code === 1) {
            setJitterData(response.data.jitter_data);
            setLatencyData(response.data.latency_data);
            setPacketLossData(response.data.packet_loss_data);
            setStatusData(response.data.up_down_status_data);
            setTimeData(response.data.time_data);
            setStatusTimeData(response.data.up_down_time);
            setUpDownSeriesData(response.data.up_down_series_data);
            setOpenBackdrop(false);
        }
    });
}

export const ISPCard1BandWidthDataByDate = async (ispWanID, date, setInBandWidthData, setOutBandWidthData, setBandWithTimeData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_bandwidth_by_date_for_msp.php" : "get_isp_bandwidth_by_date.php";
    const baseURLBandWidthDataByDate = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "date": date
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "date": date,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseURLBandWidthDataByDate, payload).then((response) => {
        if (response.data.code === 1) {
            setInBandWidthData(response.data.in_bandwidth_data);
            setOutBandWidthData(response.data.out_bandwidth_data);
            setBandWithTimeData(response.data.time_data);
            setOpenBackdrop(false);
        }
    });
}

export const ISPCard1RestCallForGraphCardByDateTime = async (ispWanID, timeValue, startDateTime, endDateTime, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_latency_jitter_by_date_time_for_msp.php" : "get_isp_latency_jitter_by_date_time.php";
    const baseURLGraphDataByDate = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "start_date_time": startDateTime,
        "end_date_time": endDateTime,
        "week_selected": timeValue === "168 HOUR"
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "start_date_time": startDateTime,
        "end_date_time": endDateTime,
        "msp_status": 1,
        "week_selected": timeValue === "168 HOUR"
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLGraphDataByDate, payload).then((response) => {
        if (response.data.code === 1) {
            setJitterData(response.data.jitter_data);
            setLatencyData(response.data.latency_data);
            setPacketLossData(response.data.packet_loss_data);
            setStatusData(response.data.up_down_status_data);
            setTimeData(response.data.time_data);
            setStatusTimeData(response.data.up_down_time);
            setUpDownSeriesData(response.data.up_down_series_data);
            setOpenBackdrop(false);
        }
    });
}

export const ISPCard1BandWidthDataByDateTime = async (ispWanID, timeValue, startDateTime, endDateTime, setInBandWidthData, setOutBandWidthData, setBandWithTimeData, setOpenBackdrop) => {
    
    const path = localStorage.getItem("msp_status") === "1" ? "get_isp_bandwidth_by_date_time_for_msp.php" : "get_isp_bandwidth_by_date_time.php";
    const baseURLBandWidthDataByDateTime = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
    
    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "start_date_time": startDateTime,
        "end_date_time": endDateTime,
        "week_selected": timeValue === "168 HOUR"
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "isp_wan_id": ispWanID,
        "start_date_time": startDateTime,
        "end_date_time": endDateTime,
        "msp_status": 1,
        "week_selected": timeValue === "168 HOUR"
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseURLBandWidthDataByDateTime, payload).then((response) => {
        if (response.data.code === 1) {
            setInBandWidthData(response.data.in_bandwidth_data);
            setOutBandWidthData(response.data.out_bandwidth_data);
            setBandWithTimeData(response.data.time_data);
            setOpenBackdrop(false);
        }
    });
    
}