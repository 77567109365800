import axios from 'axios';

// API CALL CODE HERE

export const ISPTotalTableDataAPI = async (setTotalIspData, setAllIspData, buState) => {

  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_details_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "link_status": -1,
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setTotalIspData(response.data.isp_data);
        setAllIspData(response.data.isp_data);
      }
      else {
        setTotalIspData([]);
        setAllIspData([]);
      }
    })
  }


  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_isp_details_for_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "link_status": -1,
        "bu_id": buState
      });

      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setTotalIspData(response.data.isp_data);
          setAllIspData(response.data.isp_data);
        }
        else {
          setTotalIspData([]);
          setAllIspData([]);
        }
      })
    }


    if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_isp_details_for_msp.php" : "get_isp_details.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "link_status": -1
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "link_status": -1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setTotalIspData(response.data.isp_data);
          setAllIspData(response.data.isp_data);
        }
        else {
          setTotalIspData([]);
          setAllIspData([]);
        }
      })
    }
  }
}
