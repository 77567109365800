import { useState, useEffect, forwardRef, useRef, createRef } from 'react';
// material
import {
  Grid,
  Table,
  FormControlLabel,
  FormGroup,
  TextField,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Toolbar,
  Paper,
  Button,
  CircularProgress,
  Backdrop
} from '@mui/material';
// components
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Scrollbar from '../Scrollbar';
import SystemAlertsEmailTableHead from './SystemAlertsEmailTableHead';
import { UpdateSystemAlertMailSettingsAPICall } from './UpdateSystemAlertMailSettingsAPICall';
import { GetSystemEmailConfig } from './GetSystemEmailConfig';
import { useGlobalState } from '../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../WarningComponents/SelectCustomerWarningDialog';


const TABLE_HEAD = [
  { id: 'alertType', label: 'Alert Type', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
];


const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function SystemAlertEmailConfigComp() {
  const classes = useStyles();

  const { globalState, setGlobalState } = useGlobalState();
  const custId = parseInt(localStorage.getItem("cust_id"), 10);
  const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);

  const [toMails, setToMails] = useState("");

  const [updateSuccessStatus, setUpdateSuccessStatus] = useState(false);
  const [presentSettings, setPresentSettings] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  })

  const _UpdateAlertMailSettingsAPICall = async () => {
    await UpdateSystemAlertMailSettingsAPICall(toMails, setUpdateSuccessStatus);
  }

  const handleSaveSettings = () => {
    _UpdateAlertMailSettingsAPICall()
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSuccessStatus(false);
  };

  const _GetPresentSettings = async () => {
    await GetSystemEmailConfig(setToMails, setOpenBackdrop);
  }

  useEffect(() => {
    if (globalState === custId && mspStatus === 1) {
      setOpenWarnDialog(true);
      setOpenBackdrop(false);
      return;
    }
    setToMails("");
    _GetPresentSettings();
  }, [globalState])


  const onWarningClose = () => {
    setOpenWarnDialog(false);

  }

  const handleToMailsChange = (e) => {
    setToMails(e.target.value);
  }


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography position='absolute'>Loading Settings</Typography>
      </Backdrop>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9} sx={{ px: 1, py: 1 }}>
              <div className={classes.titleStyle} >
                <Typography variant='h4'>
                  System Email Settings
                </Typography>
              </div>
            </Grid>

            {(globalState !== custId || mspStatus !== 1) &&  <Grid item xs={3} sm={3} md={3} lg={3} sx={{ px: 2, py: 1 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className={classes.titleStyle}>
                <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSaveSettings}>
                  Save Settings
                </Button>
              </div>
            </Grid>}
          </Grid>

          {(globalState !== custId || mspStatus !== 1) && <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SystemAlertsEmailTableHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  <TableRow hover>
                    <TableCell align="left"><strong>Sytem Alerts</strong></TableCell>
                    <TableCell align="left">
                      <FormGroup>
                        <TextField
                          id="to_mails"
                          variant="standard"
                          value={toMails}
                          onChange={(handleToMailsChange)}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell align="left">All Server/System Events</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>}

          <Snackbar open={updateSuccessStatus} autoHideDuration={6000} onClose={handleSnackClose} key={'bottom + right'}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Settings Uploaded Successfully
            </Alert>
          </Snackbar>
          {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} onWarningClose={onWarningClose} />}
        </Paper>
      </Grid>
    </>
  );
}
