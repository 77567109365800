import axios from 'axios';

// API CALL CODE HERE

export const EdTotalTableDataAPI = async (setTotalEdData, setAllEdData, buState) => {
  if (localStorage.getItem("user_map_status") === "1") {
    const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_details_by_client_for_mapped_user.php`;
    const regPayload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("reg_cust_id"),
      "ed_status": -1,
      "bu_id": buState
    });

    return axios.post(mappedUrl, regPayload).then((response) => {
      if (response.data.code === 1) {
        setTotalEdData(response.data.ed_data);
        setAllEdData(response.data.ed_data);
      }

    });
  }
  if (localStorage.getItem("user_map_status") === "-1") {
    if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
      const url = `${process.env.REACT_APP_API_SERVER_URL}/get_edge_device_details_by_client_for_bu.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "ed_status": -1,
        "bu_id": buState
      });

      return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
          setTotalEdData(response.data.ed_data);
          setAllEdData(response.data.ed_data);
        }
        else {
          setTotalEdData([]);
          setAllEdData([]);
        }
      }).catch(() => {
        setTotalEdData([]);
        setAllEdData([]);
      });
    }
    if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
      const path = localStorage.getItem("msp_status") === "1" ? "get_edge_device_details_by_client_for_msp.php" : "get_edge_device_details_by_client.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "ed_status": -1
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "ed_status": -1
      });

      const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

      return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
          setTotalEdData(response.data.ed_data);
          setAllEdData(response.data.ed_data);
        }
        else {
          setTotalEdData([]);
          setAllEdData([]);
        }
      })
    }
  }
}