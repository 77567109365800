import axios from 'axios';

const customColors = ['#5733FF', '#FFD700', '#00FA9A', '#40E0D0', '#FF69B4', '#8A2BE2', '#20B2AA', '#FFA07A', '#7FFF00', '#FF00FF', '#00CED1', '#9370DB', '#00FF00', '#6B4226'];

const GetSaasPortDetailsGraphAPICall = async (locationId, appId, time, setJitterStateData, setLatencyStateData, setPacketLossStateData, setAppTimeData, setOpenBackdrop) => {

const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_port_metrics_graph.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "location_id": locationId,
    "app_id": appId,
    "time": time
  });

  return axios.post(url, payload).then((response) => {
    if (response.data.code === 1) {
      const dataarr = response.data.port_data;

      setLatencyStateData([]);
      setJitterStateData([]);
      setPacketLossStateData([]);

      dataarr.forEach((data, index) => {
        const portNo = data.port_number;

        // Add new data
        setLatencyStateData(prevState => [...prevState, {
          name: `TCP PORT Latency (${portNo})`,
          type: "line",
          data: data.tcp_port_latency_data,
          color: customColors[index]
        }]);
        setJitterStateData(prevState => [...prevState, {
          name: `TCP PORT Jitter (${portNo})`,
          type: "line",
          data: data.tcp_port_jitter_data,
          color: customColors[index]
        }]);
        setPacketLossStateData(prevState => [...prevState, {
          name: `TCP PORT Packet Loss (${portNo})`,
          type: "line",
          data: data.tcp_port_packet_loss_data,
          color: customColors[index]
        }]);
      });
      setOpenBackdrop(false);

    }
    else {
      setLatencyStateData([]);
      setJitterStateData([]);
      setPacketLossStateData([]);
      setOpenBackdrop(false);
    }
  });
}

export default GetSaasPortDetailsGraphAPICall