import axios from 'axios';

// API CALL CODE HERE

const AddZoneDetailsApiCall = async (zoneName,  jitter, latency, packetLoss, setAddZoneStatus, setAddZoneMessage) => {

  const path = localStorage.getItem("msp_status") === "1" ? "create_new_zone_for_msp.php" : "create_new_zone.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "zone_data": {
		"zone_name": zoneName,
    "jitter": jitter,
    "latency": latency,
    "packet_loss": packetLoss
	}
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "zone_data": {
      "zone_name": zoneName,
      "jitter": jitter,
      "latency": latency,
      "packet_loss": packetLoss
    }
  })
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAddZoneStatus(1);
      setAddZoneMessage(response.data.message)
    }
    else {
      setAddZoneStatus(-1);
      setAddZoneMessage(response.data.message)
    }
  }).catch((error) => {
    setAddZoneStatus(-1);
    setAddZoneMessage(error.response.data.message)

  })
}

export default AddZoneDetailsApiCall;