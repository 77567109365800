import axios from 'axios';

const UploadLogoAPICall = async (logoFile) => {

const apiUrl = `${process.env.REACT_APP_API_SERVER_URL}/update_logo.php`;


  const formData = new FormData();
  formData.append('logo', logoFile);
  formData.append('cust_id', localStorage.getItem("cust_id"));
  formData.append('auth_token', localStorage.getItem("auth_token"));
  
  try {
    const response = await axios.post(apiUrl, formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading logo:', error);
    return { code: -1, message: 'Error uploading logo' };
  } 
};

export default UploadLogoAPICall;
