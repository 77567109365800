import axios from 'axios';


const GetTopBestWorstLinksApiCall = async (locationId, selectedGroupIds, setBestLinks, setWorstLinks, bestLinkCnt, setBestLinkCnt, worstLinkCnt, setWorstLinkCnt, buState) => {
    if (localStorage.getItem("user_map_status") === "1") {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_top_best_worst_links_for_mapped_user.php`;
        const regPayload = JSON.stringify({
            "auth_token": localStorage.getItem("auth_token"),
            "client_id": localStorage.getItem("reg_cust_id"),
            "group_ids": selectedGroupIds,
            "location_id": locationId,
            "bu_id": buState
        });

        return axios.post(mappedUrl, regPayload).then((response) => {
            if (response.data.code === 1) {
                const tableLength = response.data.best_links.length >= response.data.worst_links.length ? response.data.best_links.length : response.data.worst_links.length;
                setBestLinks(response.data.best_links);
                setBestLinkCnt(Array.from({ length: (tableLength - response.data.best_links.length) }, (_, index) => 0 + index))

                setWorstLinks(response.data.worst_links);
                setWorstLinkCnt(Array.from({ length: (tableLength - response.data.worst_links.length) }, (_, index) => 0 + index))
            }
        })
    }
    if (localStorage.getItem("user_map_status") === "-1") {
        if (localStorage.getItem("bu_status") === "1" && buState !== -1 ) {
            const url = `${process.env.REACT_APP_API_SERVER_URL}/get_top_best_worst_links_for_bu.php`;
            const payload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "location_id": locationId,
                "bu_id": buState
            });

            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    const tableLength = response.data.best_links.length >= response.data.worst_links.length ? response.data.best_links.length : response.data.worst_links.length;
                    setBestLinks(response.data.best_links);
                    setBestLinkCnt(Array.from({ length: (tableLength - response.data.best_links.length) }, (_, index) => 0 + index))

                    setWorstLinks(response.data.worst_links);
                    setWorstLinkCnt(Array.from({ length: (tableLength - response.data.worst_links.length) }, (_, index) => 0 + index))
                }
                else {
                    setBestLinks([]);
                    setWorstLinks([]);
                }
            });
        }

        
        if (localStorage.getItem("bu_status") === "-1"  || buState === -1) {
            const path = localStorage.getItem("msp_status") === "1" ? "get_top_best_worst_links_for_msp.php" : "get_top_best_worst_links.php";
            const url = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


            const regPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "location_id": locationId
            });

            const mspPayload = JSON.stringify({
                "auth_token": localStorage.getItem("auth_token"),
                "msp_client_id": localStorage.getItem("cust_id"),
                "reg_client_id": localStorage.getItem("reg_cust_id"),
                "group_ids": selectedGroupIds,
                "msp_status": 1,
                "location_id": locationId
            });

            const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
            return axios.post(url, payload).then((response) => {
                if (response.data.code === 1) {
                    const tableLength = response.data.best_links.length >= response.data.worst_links.length ? response.data.best_links.length : response.data.worst_links.length;
                    setBestLinks(response.data.best_links);
                    setBestLinkCnt(Array.from({ length: (tableLength - response.data.best_links.length) }, (_, index) => 0 + index))

                    setWorstLinks(response.data.worst_links);
                    setWorstLinkCnt(Array.from({ length: (tableLength - response.data.worst_links.length) }, (_, index) => 0 + index))
                }
                else {
                    setBestLinks([]);
                    setWorstLinks([]);
                }
            });
        }
    }
}
export default GetTopBestWorstLinksApiCall