import React from 'react'
import SaasApplicationTable from './SaasApplicationTable'

export default function SaaSApplicationComponents() {
  return (
    <>
        <SaasApplicationTable />
    </>
  )
}
