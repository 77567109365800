import axios from 'axios';
import dayjs from 'dayjs';
import { valuesIn } from 'lodash';

// API CALL CODE HERE
export const PushSingleISPInfo = async (publicIp, internalIp, circuitId, location, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, price, city, renewalDate, warning, critical, setData, setAddLinkMessage) => {

    const path = localStorage.getItem("msp_status") === "1" ? "push_single_isp_wan_info_for_msp.php" : "push_single_isp_wan_info.php";
    const baseUrlToPushISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_data": {
            "cust_id": localStorage.getItem("cust_id"),
            "circuit_id": circuitId,
            "location": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            "warning_th_value": warning,
            "critical_th_value": critical,
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_data": {
            "cust_id": localStorage.getItem("cust_id"),
            "circuit_id": circuitId,
            "location": location,
            "public_ip": publicIp,
            "internal_ip": internalIp,
            "vendor_id": selectedVendorId,
            "vendor_name": vendorName,
            "vendor_email": vendorEmail,
            "default_gateway": defaultGateway,
            "promised_uptime": promiseUptime,
            "resolution_time": resolutionTime,
            "link_type": link,
            "provisioned_speed": provisionedSpeed,
            "price": price,
            "city": city,
            "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            "warning_th_value": warning,
            "critical_th_value": critical,
        }
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    await axios.post(baseUrlToPushISPInfo, payload).then((response) => {
        if (response.data.code === 1) {
            setData(1);
            setAddLinkMessage(response.data.message)
        }
        else if (response.data.code === 2) {
            setData(2);
            setAddLinkMessage(response.data.message)
        }
        else {
            setData(-1);
            setAddLinkMessage(response.data.message)

        }
    })
}

export const GetSingleISPInfo = async (id, setIspWanId, setCircuitId, setVendorName, setVendorEmail, setDefaultGateway, setPublicIp, setInternalIp, setPromisedUptime, setResolutionTime, setLink, setProvisionedSpeed, setCity, setState, setCountry, setManagerName, setManagerEmail, setPrice, setVendorId, setValue, setSiteName, setRenewalDate, setWarning, setCritical, setCustomTagsData) => {


    const path = localStorage.getItem("msp_status") === "1" ? "get_single_isp_info_for_msp.php" : "get_single_isp_info.php";
    const baseUrlToGetSingleISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_wan_id": id
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_wan_id": id
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;


    await axios.post(baseUrlToGetSingleISPInfo, payload).then((response) => {
       
        if (response.data.code === 1) {
            setIspWanId(response.data.isp_data[0].isp_wan_id);
            setCircuitId(response.data.isp_data[0].circuit_id);
            setVendorName(response.data.isp_data[0].vendor);
            setVendorEmail(response.data.isp_data[0].vendor_email);
            setDefaultGateway(response.data.isp_data[0].default_gateway);
            setPublicIp(response.data.isp_data[0].public_ip);
            setInternalIp(response.data.isp_data[0].internal_ip);
            setPromisedUptime(response.data.isp_data[0].promised_uptime);
            setResolutionTime(response.data.isp_data[0].resolution_time);
            setLink(response.data.isp_data[0].link_type);
            setProvisionedSpeed(response.data.isp_data.provisioned_speed === null ? " " : response.data.isp_data[0].provisioned_speed);
            setCity(response.data.isp_data[0].city);
            setState(response.data.isp_data[0].state);
            setCountry(response.data.isp_data[0].country);
            setManagerName(response.data.isp_data[0].manager_name);
            setManagerEmail(response.data.isp_data[0].manager_email);
            setPrice(response.data.isp_data[0].price);
            setVendorId(response.data.isp_data[0].vendor_id);
            setValue({ "location_id": response.data.isp_data[0].location_id, "location": response.data.isp_data[0].location });
            setSiteName(response.data.isp_data[0].location);
            setRenewalDate(dayjs(new Date(response.data.isp_data[0].renewal_date)));
            setWarning(response.data.isp_data[0].util_warn_th);
            setCritical(response.data.isp_data[0].util_critical_th)
            if (Array.isArray(response.data.isp_data[0].tag_data)) {
                setCustomTagsData(response.data.isp_data[0].tag_data.map(tag => ({
                    label: tag.label,
                    value: tag.value,
                    data_type: tag.data_type,
                    is_mandatory: tag.is_mandatory,
                    values:tag.values
                })));
            }
        }
    })
}

// API CALL UPDATE  CODE HERE
// export const UpdateSingleISPInfo = async (ispWanId, publicIp, internalIp, circuitId, locationId, location, selectedVendorId, vendorName, vendorEmail, defaultGateway, promiseUptime, resolutionTime, link, provisionedSpeed, city, state, country, managerName, managerEmail, price, renewalDate,warning, critical, setUpdateStatus, setEditMessage) => {
export const UpdateSingleISPInfo = async (ispData, customFields, setUpdateStatus, setEditMessage) => {
    const path = localStorage.getItem("msp_status") === "1" ? "update_single_isp_wan_info_for_msp.php" : "update_single_isp_wan_info.php";
    const baseUrlToUpdateISPInfo = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "isp_data": {
            // "cust_id": localStorage.getItem("cust_id"),
            // "isp_wan_id": ispWanId,
            // "circuit_id": circuitId,
            // "location_id": locationId,
            // "location_name": location,
            // "public_ip": publicIp,
            // "internal_ip": internalIp,
            // "vendor_id": selectedVendorId,
            // "vendor_name": vendorName,
            // "vendor_email": vendorEmail,
            // "default_gateway": defaultGateway,
            // "promised_uptime": promiseUptime,
            // "resolution_time": resolutionTime,
            // "link_type": link,
            // "provisioned_speed": provisionedSpeed,
            // "price": price,
            // "city": city,
            // "state": state || "",
            // "country": country || "",
            // "manager_name": managerName || "",
            // "manager_email": managerEmail || " ",
            // "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            // "warning_th_value": warning,
            // "critical_th_value" : critical,
            ...ispData,
            ...customFields
        }
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "isp_data": {
            // "cust_id": localStorage.getItem("cust_id"),
            // "isp_wan_id": ispWanId,
            // "circuit_id": circuitId,
            // "location_id": locationId,
            // "location_name": location,
            // "public_ip": publicIp,
            // "internal_ip": internalIp,
            // "vendor_id": selectedVendorId,
            // "vendor_name": vendorName,
            // "vendor_email": vendorEmail,
            // "default_gateway": defaultGateway,
            // "promised_uptime": promiseUptime,
            // "resolution_time": resolutionTime,
            // "link_type": link,
            // "provisioned_speed": provisionedSpeed,
            // "price": price,
            // "city": city,
            // "state": state  || "",
            // "country": country || "",
            // "manager_name": managerName || "",
            // "manager_email": managerEmail || "",
            // "renewal_date": dayjs(renewalDate).format('YYYY-MM-DD'),
            // "warning_th_value": warning,
            // "critical_th_value" : critical,
            ...ispData,
            ...customFields
        }
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    await axios.post(baseUrlToUpdateISPInfo, payload).then((response) => {
        if (response.data.code === 1) {
            setUpdateStatus(1);
            setEditMessage(response.data.message)

        } else if (response.data.code === 2) {
            setUpdateStatus(2);
            setEditMessage(response.data.message)

        } else {
            setUpdateStatus(-1);
            setEditMessage(response.data.message)

        }
    }).catch((e) => {
        setUpdateStatus(-1);
        setEditMessage("Error")
    })
}