import axios from 'axios';

// API CALL CODE HERE

const ReportFilesAPiCall = async (monthYear, setCurrentFiles) => {
    const path = localStorage.getItem("msp_status") === "1" ? "get_reports_path_for_msp.php" : "get_reports_path.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "month": monthYear
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "month": monthYear
    })

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setCurrentFiles(response.data.files)
        }
        else {
            setCurrentFiles([])
        }
    })
}

export default ReportFilesAPiCall;