import { useEffect, useState } from 'react';

// @mui
import { Box, Grid, Paper, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import useWindowSize from './useWindowSize';
import { SitewiseParamsAPICall } from './SitewiseParamsAPICall';
import LinkLogo from '../../../Assets/link_logo.jpg';
import DownTimeLogo from '../../../Assets/down_time_logo.png';
import SiteLogo from '../../../Assets/site_logo_bg_removed.png';
import slalogo from '../../../Assets/sla_logo.png';
import UptimeLogo from '../../../Assets/uptime_logo.jpeg';
import businesslogo from '../../../Assets/business_logo.png';
import avgtimelogo from '../../../Assets/avg_time_restore_logo.png';
import monthlogo from '../../../Assets/month_logo.png';
import edgeLogo from '../../../Assets/edge_device.jpg';

import GetSitewiseLinkCntApiCall from '../API/GetSitewiseLinkCntApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';


// ---------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function SidewiseParamsCards(props) {
  const { locationId } = props;
  const { globalState, setGlobalState } = useGlobalState();

  const classes = useStyles();
  const [code, setCode] = useState(0);
  const [location, setLocation] = useState(0);
  const [avgUptime, setAvgUptime] = useState(0);
  const [siteUptimePercent, setSiteUptimePercent] = useState(0);
  const [avgTimeToRestore, setAvgTimeToRestore] = useState(0);
  const [slaBreaches, setSlaBreaches] = useState('');
  const [businessHourImpact, setBusinessHourImpact] = useState('');
  const [downTime, setDownTime] = useState('');
  const [month, setMonth] = useState('');
  const [siteStatus, setSiteStatus] = useState(-1);
  const [edStatus, setEdStatus] = useState(-1);

  const [totalLinkCnt, setTotalLinkCnt] = useState(0);
  const [upLinkCnt, setUpLinkCnt] = useState(0);



  const _SitewiseParamsAPICall = async () => {
    await SitewiseParamsAPICall(
      locationId,
      setLocation,
      setAvgUptime,
      setSiteUptimePercent,
      setAvgTimeToRestore,
      setSlaBreaches,
      setBusinessHourImpact,
      setDownTime,
      setMonth,
      setSiteStatus,
      setEdStatus
    );
  };

  const _GetSitewiseLinkCntApiCall = async () => {
    await GetSitewiseLinkCntApiCall(locationId, setUpLinkCnt, setTotalLinkCnt);
  }

  useEffect(() => {
    _SitewiseParamsAPICall();
    _GetSitewiseLinkCntApiCall();
  }, [locationId, globalState]);


  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', marginBottom: '-10px' }}>
        <Paper sx={{ minWidth: '100%', px: 2, py: 2 }} elevation={15}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" component="div" style={{ fontWeight: "normal" }}>
              Site Statistics for Current Month
            </Typography>
          </div>

          <div style={{ display: 'flex', gap: '10px', paddingBottom: "20px", paddingTop: "20px" }}>
            <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={avgtimelogo} alt="logo" className='card-logo' style={{ width: '40px', height: '40px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Avg Time To Restore Links
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {avgTimeToRestore}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper>
            <Paper sx={{ minWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={UptimeLogo} alt="logo" className='card-logo' style={{ width: '40px', height: '30px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Site Uptime
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {siteUptimePercent} %
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper>
            <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={slalogo} alt="logo" className='card-logo' style={{ width: '40px', height: '30px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Sla Breaches
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {slaBreaches}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper>
            <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={DownTimeLogo} alt="logo" className='card-logo' style={{ width: '40px', height: '30px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Site Down Time
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {downTime}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper>
          </div>
        </Paper>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px', marginBottom: '-10px' }}>
        <Paper sx={{ minWidth: '100%', px: 2, py: 2 }} elevation={15}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" component="div" style={{ fontWeight: "normal" }}>
              Site Current Status -
            </Typography>
            <Typography variant="h6" component="div" style={{ fontWeight: "normal", color: siteStatus === 1 ? "green" : "red" }}>
              {siteStatus === 1 ? "Up" : "Down"}
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '10px', paddingBottom: "20px", paddingTop: "20px" }}>
            {/* <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={slalogo} alt="logo" className='card-logo' style={{ width: '40px', height: '30px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Sla Breaches
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {slaBreaches}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper> */}
            {/* <Paper sx={{ minWidth: 'fit-content', px: 2, py: 2 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={businesslogo} alt="logo" className='card-logo' style={{ width: '40px', height: '30px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Business Hour Impact
                    </Typography>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {businessHourImpact}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper> */}
            <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 3 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={edgeLogo} alt="logo" className='card-logo' style={{ width: '60px', height: '50px', marginRight: '15px' }} />
                  <div>
                    <Typography variant="subtitle1" component="div">
                      Edge Device Status
                    </Typography>
                    {edStatus === 1 && (
                      <Typography sx={{ color: "green" }} variant="subtitle1" component="div">
                        Online and Operational
                      </Typography>
                    )}
                    {(edStatus === 0 || edStatus===-1 ) && (
                      <Typography sx={{ color: "orange" }} variant="subtitle1" component="div">
                        Online, but Experiencing Authentication Issues
                      </Typography>
                    )}
                    {/* {edStatus === -1 && (
                      <Typography sx={{ color: "red" }} variant="subtitle1" component="div">
                        Configuration Required
                      </Typography>
                    )} */}
                    {edStatus === 3 && (
                      <Typography sx={{ color: "red" }} variant="subtitle1" component="div">
                        Offline
                      </Typography>
                    )}
                    {edStatus === 2 && <Typography sx={{ color: "blue" }} variant="subtitle1" component="div">
                      "No Device Added - Status Unknown"
                    </Typography>}
                  </div>
                </Box>
              </Box>
            </Paper>

            <Paper sx={{ maxWidth: 'fit-content', px: 2, py: 3 }} elevation={15}>
              <Box className={classes.boxPadding}>
                <Box className={classes.boxItems}>
                  <img src={LinkLogo} alt="logo" className='card-logo' style={{ width: '60px', height: '50px', marginRight: '15px' }} />
                  <div>
                    <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                      {upLinkCnt} out of {totalLinkCnt} links are up.
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Paper>
          </div>
        </Paper>
      </div>
    </>
  );
}
