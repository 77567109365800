import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    InputAdornment,
    Typography
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

// Api Import
// import AddNewSiteGroupApiCall from './AddNewSiteGroupApiCall';
import GetLicenseApiCall from './GetLicenseApiCall';
import AddNewLicenseApiCall from './AddNewLicenseApiCall';
// import { GetLicenseTypeDetailsApiCall } from './GetLicenseTypeApiCall';
import { GetLicenseTypeApiCall } from './GetLicenseTypeApiCall';

export default function UpgradeLicenseComponent(props) {
    const { openAddSiteGroupDialog, setOpenAddSiteGroupDialog, setAddLicenseStatus,defaultLicenseType  } = props;

  const [datas,setDatas]=useState([]);
    const [LicenseTypeData, setLicenseTypeData] = useState([]);
    const handleOnClose = () => {
        setOpenAddSiteGroupDialog(false);
    }
 
   
     

    const handleCriticalChange=(e)=>{
    //     switch (e.target.id) {
    //         case "group_name":   if (!e.target.value.match(groupNameRegex)) {
    //                             setGroupNameHelperText("Group Name Should be Valid");
    //                         }
    //                         else
    //                         {
    //                             setGroupNameHelperText("");
                                
    //                         }
    //                         setGroupName(e.target.value);
    //                         break;

    //         case "group_type":   if (!e.target.value.match(groupTypeRegex)) {
    //                             setGroupTypeHelperText("Group Type Should be Valid");
    //                         }
    //                         else
    //                         {
    //                             setGroupTypeHelperText("");
                                
    //                         }
    //                         setGroupType(e.target.value);
    //                         break;

    //         case "latency":   if (!e.target.value.match(latencyRegex)) {
    //                             setLatencyHelperText("Enter Latency Correctly");
    //                         }
    //                         else
    //                         {
    //                             setLatencyHelperText("");
                                
    //                         }
    //                         setLatency(e.target.value);
    //                         break;
            
    //         case "jitter":   if (!e.target.value.match(latencyRegex)) {
    //                             setJitterHelperText("Enter Jitter Correctly");
    //                         }
    //                         else
    //                         {
    //                             setJitterHelperText("");
                                
    //                         }
    //                         setJitter(e.target.value);
    //                         break;
            
    //         case "packet_loss":   if (!e.target.value.match(packetLossRegex)) {
    //                             setPacketLossHelperText("Enter Packet Loss Correctly");
    //                         }
    //                         else
    //                         {
    //                             setPacketLossHelperText("");
                                
    //                         }
    //                         setPacketLoss(e.target.value);
    //                         break;
    //         default: setCriticality(e.target.value);
    //                 break;
    // }
}
const [selectedOption, setSelectedOption] = useState('');
// const [options, setOptions] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
const _GetLicenseTypeAPICall = () => {
    GetLicenseTypeApiCall((data) => {
        // console.log('LicenseTypeData:', data);
        setLicenseTypeData(data);
        setSelectedOption(defaultLicenseType || (data.length > 0 ? data[0].license_type : ''));
    });

}
const _GetLicenseDetailsApiCall = async () => {
  await GetLicenseApiCall(setDatas);
}
const handleOnSave = async () => {
  if (datas.length > 0) {
    
    const LicenseType = datas[0].license_type;
    const LicenseKey = datas[0].license_key;
    const CustId = datas[0].cust_id;
    await AddNewLicenseApiCall(CustId, LicenseType, LicenseKey, selectedOption, setAddLicenseStatus);
    props.handleDataReLoad();
    handleOnClose();
  } else {
    // Handle the case when there are no license details in datas array
    console.error("No license details available.");
  }
}

useEffect(() => {      
    _GetLicenseTypeAPICall();
    _GetLicenseDetailsApiCall();
}, [defaultLicenseType]);


    return (
        <>
            <Dialog open={openAddSiteGroupDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Upgrade License
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Licenses</FormLabel> */}
      <Typography variant='h6'>
        Licenses
      </Typography>
      <RadioGroup
        aria-label="options"
        name="options"
        // value={selectedOption}
        // onChange={handleChange}
        value={selectedOption || defaultLicenseType} // Use the default value if selectedOption is falsy
        defaultValue={defaultLicenseType}
        onChange={(e) => setSelectedOption(e.target.value)}
        column // Change to "column" if you want vertical alignment
      >
        {LicenseTypeData.map((option) => (
          <FormControlLabel
            key={option.license_type}
            value={option.license_type}
            control={<Radio />}
            label={option.license_type}
          />
        ))}
      </RadioGroup>
    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button onClick={handleOnSave}>Send Request</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
