import axios from 'axios';

const GetBusinessUnitsApiCall = async (setBusinessUnits) => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_business_unit.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });

  return axios.post(baseUrl, payload).then((response) => {
    if (response.data.code === 1) {
      setBusinessUnits(response.data.business_unit);
    } else {
      console.error("Failed to fetch business units");
    }
  }).catch((error) => {
    console.error("Error in fetching business units:", error);
  });
}




export default GetBusinessUnitsApiCall;
