import axios from 'axios';

const GetRaspiAgentDataAPICall = async (setAgentData, buState) => {
  try {
    if (localStorage.getItem("user_map_status") === "1") {
      const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_agent_data_for_mapped_user.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "bu_id": buState
      });
      const response = await axios.post(mappedUrl, payload);
      if (response.data.code === 1) {
        setAgentData(response.data.agent_data);
      }
      else {
        setAgentData([]);
      }
    } else if (localStorage.getItem("user_map_status") === "-1") {
      if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
        const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_agent_data_for_bu.php`;
        const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("cust_id"),
          "bu_id": buState
        });
        const response = await axios.post(mappedUrl, payload);
        if (response.data.code === 1) {
          setAgentData(response.data.agent_data);
        }
        else {
          setAgentData([]);
        }
      }
      if (localStorage.getItem("bu_status") === "-1" || buState === -1) {
        const path = localStorage.getItem("msp_status") === "1" ? "get_agent_data_for_msp.php" : "get_agent_data.php";
        const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
        const regPayload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("cust_id"),
        });
        const mspPayload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "msp_client_id": localStorage.getItem("cust_id"),
          "reg_client_id": localStorage.getItem("reg_cust_id"),
          "msp_status": 1,
        });
        const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
        const response = await axios.post(baseUrl, payload);
        if (response.data.code === 1) {
          setAgentData(response.data.agent_data);
        } else {
          setAgentData([]);
        }
      }
    }
  } catch (error) {
    setAgentData([]);
  }
}

export default GetRaspiAgentDataAPICall;
