import axios from 'axios';

const AddNewRaspiAgentAPICall = async (locationId, serialNumber, macAddress, setAddAgentStatus, setSeverStatus, setAddRaspiMessage) => {

  const url = `${process.env.REACT_APP_API_SERVER_URL}/add_new_raspi_agent_for_location.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "location_id": locationId,
    "serial_number": serialNumber,
    "mac_address": macAddress
  });

  return axios.post(url, payload).then((response) => {
    if (response.data.code === 1) {
      setAddAgentStatus(1);
      setSeverStatus("success");
      setAddRaspiMessage(response.data.message)
    }
    else {
      setAddAgentStatus(-1);
      setSeverStatus("error");
      setAddRaspiMessage(response.data.message)
    }
  });
}

export default AddNewRaspiAgentAPICall