import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Typography,
  Container,
  Divider,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';


const ReviewPage = () => {
    // for CreateSite form and API call
    const [locationName, setLocationName] = useState("");
    const [businessHrStart, setBusinessHrStart] = useState(9);
    const [businessHrEnd, setBusinessHrEnd] = useState(18);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [managerEmail, setManagerEmail] = useState("");
    const [country, setCountry] = useState("");
    const [managerContact, setManagerContact] = useState("");
    const [managerName, setManagerName] = useState("");


    // for Create Link 
    const [ispLocations, setIspLocations] = useState([{}]);
    const [circuitId, setCircuitId] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorEmail, setVendorEmail] = useState("");
    const [defaultGateway, setDefaulGateway] = useState("");
    const [publicIp, setPublicIp] = useState("");
    const [internalIp, setInternalIp] = useState("");
    const [promiseUptime, setPromisedUptime] = useState("");
    const [resolutionTime, setResolutionTime] = useState("");
    const [data, setData] = useState(0);
    const [link, setLink] = useState("");
    const [price, setPrice] = useState("");
    const [provisionedSpeed, setProvisionedSpeed] = useState("");

    // for edge device form 
    const [deviceIp, setDeviceIp] = useState("");
    const [snmpStr, setSnmpStr] = useState("");
    const [sshUserName, setSshUserName] = useState("");
    const [sshPassword, setSshPassword] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [sshPort, setSshPort] = useState("");
    const [apiPort, setApiPort] = useState("");
    const [deviceType, setDeviceType] = useState(0);
    const [deviceVendor, setDeviceVendor] = useState(0);

    const GreyTableCell = styled(TableCell)(({ theme }) => ({
      backgroundColor: theme.palette.grey[400],
    }));

  // for Site form
  useEffect(() => {
    const siteFormString = localStorage.getItem('SiteFormData');
    
    if (siteFormString) {
      const siteForm = JSON.parse(siteFormString);
      
      // Access individual properties
      setLocationName(siteForm.locationName);
      setBusinessHrStart(siteForm.businessHrStart);
      setBusinessHrEnd(siteForm.businessHrEnd);
      setCity(siteForm.city);
      setState(siteForm.state);
      setManagerEmail(siteForm.managerEmail);
      setCountry(siteForm.country);
      setManagerName(siteForm.managerName);
      setManagerContact(siteForm.managerContact);
    }
  }, []);

  // for link form
  useEffect(() => {
    const linkFormString = localStorage.getItem('LinkFormData');
    
    if (linkFormString) {
      const linkForm = JSON.parse(linkFormString);
      
      // Access individual properties
      setIspLocations(linkForm.ispLocations);
      setCircuitId(linkForm.circuitId);
      setVendorName(linkForm.vendorName);
      setVendorEmail(linkForm.vendorEmail);
      setDefaulGateway(linkForm.defaultGateway);
      setPublicIp(linkForm.publicIp);
      setInternalIp(linkForm.internalIp);
      setPromisedUptime(linkForm.promiseUptime);
      setResolutionTime(linkForm.resolutionTime);
      setLink(linkForm.link);
      setPrice(linkForm.price);
      setProvisionedSpeed(linkForm.provisionedSpeed);
      
    }
  }, []);

 // for edge device form
  useEffect(() => {
    const edgeFormString = localStorage.getItem('EdgeDeviceFormData');
    
    if (edgeFormString) {
      const edgeForm = JSON.parse(edgeFormString);
      
      // Access individual properties
      setDeviceIp(edgeForm.deviceIp);
      setSnmpStr(edgeForm.snmpStr);
      setSshUserName(edgeForm.sshUserName);
      setSshPassword(edgeForm.sshPassword);
      setApiKey(edgeForm.apiKey);
      setSshPort(edgeForm.sshPort);
      setApiPort(edgeForm.apiPort);
      setDeviceType(edgeForm.deviceType);
      setDeviceVendor(edgeForm.deviceVendor);      
    }
  }, []);



  return (
    <div>
      <Container style={{ alignItems: "center", minHeight: "50vh",  paddingTop:"5vh" }} >
      <Divider/>

      <Box sx={{ mb: 4 }} >
        <TableContainer >
              <Table  >
                <TableHead>
                  <TableRow>
                  <GreyTableCell align="center" colSpan={2} style={{height:"5px"}}>
                    <Typography variant="h4">Site Info</Typography>
                  </GreyTableCell>
                  </TableRow>
                  
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Location ame</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{locationName}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Business Hour Start</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{businessHrStart}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Business Hour End</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{businessHrEnd}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>City</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{city}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>State</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{state}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Country</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{country}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Manager Name</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{managerName}</Typography>
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Manager Email</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{managerEmail}</Typography>
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Manager Contact</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{managerContact}</Typography>
                    </TableCell>
                  </TableRow>


                  {/* Other fields in the Site Page */}
                </TableBody>
              </Table>
      </TableContainer>
      </Box>

      <Divider/>
     <Box sx={{ mb: 4 }} >
     <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <GreyTableCell align="center" colSpan={2} style={{height:"5px"}}>
                  <Typography variant="h4">Link Info</Typography>
                </GreyTableCell>
              
              </TableRow>
              
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Public IP</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{publicIp}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Internal IP</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{internalIp}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Circuit ID</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{circuitId}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Location Name</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{locationName}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Vendor Name</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{vendorName}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Vendor Email</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{vendorEmail}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Default Gateway</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{defaultGateway}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1" component="span"><strong>Link Type</strong></Typography>
                </TableCell>
                <TableCell align="center" style={{ border: "none"}}>
                  <Typography variant="body1">{link}</Typography>
                </TableCell>
              </TableRow>

    
              {/* Other fields in the Site Page */}
            </TableBody>
          </Table>
      </TableContainer>
     </Box>

      <Divider/>

   <Box sx={{ mb: 4 }} >
   <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <GreyTableCell align="center" colSpan={2} style={{height:"5px"}}>
                      <Typography variant="h4">Edge Device Info</Typography>
                    </GreyTableCell>  
                  </TableRow>
                  
                </TableHead>
                <TableBody> 
                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Location Name</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{locationName}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Edge Device IP</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{deviceIp}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>SNMP String</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{snmpStr}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>API Key</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{apiKey}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>SSH Username</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{sshUserName}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>Country</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{country}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>SSH Port</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{sshPort}</Typography>
                    </TableCell>
                  </TableRow>


                  <TableRow>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1" component="span"><strong>API Port</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: "none"}}>
                      <Typography variant="body1">{apiPort}</Typography>
                    </TableCell>
                  </TableRow>


                  {/* Other fields in the Site Page */}
                </TableBody>
              </Table>
      </TableContainer>
   </Box>      
      </Container>
    
    </div>
  );
};

export default ReviewPage;
