import axios from 'axios';

const GetAppPortStateAPICall = async(locationId, appType, setSaasAppData) => {

const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_present_metric.php`;

    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "location_id": locationId,
        "app_type": appType
    });
    
    return axios.post(url, payload).then((response) => {
        if (response.data.code === 1) {
            const saasdata = response.data.app_data;
            setSaasAppData(saasdata);
        }
        else {
            setSaasAppData(0)
        }
    });
}

export default GetAppPortStateAPICall