import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';

export default function DonutChart(props) {
    const { label, dseries, dLabels, directValue } = props;
    // console.log(dseries, dLabels, directValue)

    const chartOptions = {
        series: dseries,
        options: {
            chart: {
                width: "100%",
                type: 'donut',
            },
            labels: dLabels,
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                position: 'bottom'
            },
            title: {
                text: label
            },
            tooltip: {
                y: {
                  formatter: (value) => {
                    const bytes = value;
                    const k = 1024;
                    const dm = 2; // Number of decimal places
                    if(!directValue)
                    {
                        if (bytes < k ** 3) { // Check for MB
                            return `${(bytes / (k ** 2)).toFixed(dm)} MB`;
                          } 
                          return `${(bytes / (k ** 3)).toFixed(dm)} GB`;
                    }
                    return `${bytes} GB`
                  }
                }
              },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    };


    return (
        <>
            <ApexCharts
                options={chartOptions.options}
                series={chartOptions.series}
                type="donut"
                width={chartOptions.options.chart.width}
            />
        </>
    );
}

