import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';

// components
import { filter } from 'lodash';
// sections

import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
// mock
import devices from '../../../_mock/devices';
import ISPTotalTableToolbar from './EdTotalTableToolbar';
import ISPGoodTableHead from './EdGoodTableHead';
import { EdTotalTableDataAPI } from '../EdgeDevicesManagamentRestCalls/EdTotalTableDataAPI';
import { useGlobalState } from '../../../globalHooks/GlobalState';
// ----------------------------------------------------------------------

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px', // Adjust the width as desired
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
});

const mspStatus = localStorage.getItem("msp_status")
const mspClientId = localStorage.getItem("cust_id")
const regClientId = localStorage.getItem("reg_cust_id")

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'deviceIp', label: 'Device IP', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'vendor', label: 'Vendor', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'device Status', label: 'Device Status', alignRight: false },
  { id: 'Time', label: 'Up/Down Time', alignRight: false },
  { id: 'View', label: 'View', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

//----------------------------------------------------------------------
export default function EdTotalTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();


  const [page, setPage] = useState(0);

  const classes = useStyles();

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalEdData, setTotalEdData] = useState([]);

  const [allEdData, setAllEdData] = useState([]);

  const [path, setPath] = useState("");

  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleFilterByName = (event, searchedVal) => {
    setFilterName(event.target.value);
    setTotalEdData(allEdData);
    const filteredRows = allEdData.filter((row) => {
      return (
        row.device_ip.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.location_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.type.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.vendor_name.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setTotalEdData(filteredRows);
  };


  const calculateDifference = (inputDate) => {
    const startDate = new Date(inputDate);
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(now - startDate);

    // Convert milliseconds to days, hours, minutes
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);

    return `${days} days, ${hours} hours, ${minutes} minutes`;
  };


  const _EdTotalTableDataAPI = async () => {
    EdTotalTableDataAPI(setTotalEdData, setAllEdData, buState);
  }

  useEffect(() => {
    _EdTotalTableDataAPI();
  }, [globalState, buState]);

  useEffect(() => {
    _EdTotalTableDataAPI();
  }, [])

  return (

    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPTotalTableToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(e) => { handleFilterByName(e, filterName) }} />
        <Scrollbar>
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <ISPGoodTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {totalEdData.map((list, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.customer_name}</TableCell> : null}
                    <TableCell>{list.device_ip}</TableCell>
                    <TableCell>{list.location_name}</TableCell>
                    <TableCell>{list.vendor_name}</TableCell>
                    <TableCell>{list.type}</TableCell>


                    {list.status === "1" && <TableCell>{"Up"}</TableCell>}
                    {list.status === "0" && <TableCell>{"Down"}</TableCell>}
                    <TableCell>{calculateDifference(list.last_ent)}</TableCell>

                    {list.status === "0" && <TableCell><Link to={{ pathname: `/dashboard/isp-management/ed-down/${list.device_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>}
                    {list.status === "1" && <TableCell><Link to={{ pathname: `/dashboard/isp-management/ed-good/${list.device_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
    </Grid>
  );
}
