import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
  Toolbar,
  OutlinedInput,
  TableHead,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@mui/material/styles/styled';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import MuiAlert from '@mui/material/Alert';
import Iconify from '../Iconify';
import GetRegisteredUserDataAPI from './GetRegisteredUserDataAPI';
import UpdateRegisteredUserDataAPI from './UpdateRegisteredUserDataAPI'; // Import the API
import DeleteRegisterUserDataApiCall from './DeleteRegisterUserDataAPI'; // Import the delete API

const useStyles = makeStyles({
  container: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
  },
  select: {
    minWidth: '180px', // Make dropdown wider
  },
});

const headCells = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'UserName', label: 'User Name', alignRight: false },
  { id: 'Role', label: 'Role', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];

const roleOptions = [
  { value: 0, label: 'Superadmin' }, // Send 0 for Superadmin
  { value: 1, label: 'Read-only' }, // Send 1 for Read-only
  { value: 2, label: 'Engineer' }, // Send 2 for Engineer
];

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 40,
  marginLeft: 'auto',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function EnhancedTableHead({ showCustomer }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (headCell.id !== 'cust_name' || showCustomer) && (
              <TableCell
                sx={{ paddingLeft: '20px', background: '#f0f0f0', padding: '10px' }}
                key={headCell.id}
                align={headCell.numeric ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  showCustomer: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName }) {
  return (
    <RootStyle>
      <Typography variant="h4">Registered Users</Typography>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  isCompact: PropTypes.bool.isRequired,
};

export default function RegisterUserTable() {
  const [filterName, setFilterName] = useState('');
  const [approveStatus, setApproveStatus] = useState(0);
  const [rejectStatus, setRejectStatus] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [rejectopen, setRejectOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [severStatus, setSeverStatus] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const mspClientId = localStorage.getItem('cust_id');
  const regClientId = localStorage.getItem('reg_cust_id');
  const mspStatus = parseInt(localStorage.getItem('msp_status'), 10);
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [role, setRole] = useState('');
  const [loadingRows, setLoadingRows] = useState({});
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApproveStatus(0);
    setRejectStatus(0);
    setDeleteStatus(0);
    setOpen(false);
    setDeleteOpen(false);
    setRejectOpen(false)
    setOpen(false)
  };

  const handleFilterByName = (event, searchedVal) => {
    const searchValue = searchedVal || event.target.value || '';
    setFilterName(searchValue);
    setFilteredRows(rows);

    if (searchValue.trim()) {
      const filteredRows = rows.filter((row) => {
        return (
          (row.user_name && row.user_name.toLowerCase().includes(searchValue.toLowerCase())) ||
          (row.email && row.email.toLowerCase().includes(searchValue.toLowerCase()))
        );
      });
      setFilteredRows(filteredRows);
    } else {
      setFilteredRows(rows);
    }
  };

  const _GetRegisteredUserDataAPI = async () => {
    await GetRegisteredUserDataAPI(setRows, setFilteredRows);
  };

  useEffect(() => {
    _GetRegisteredUserDataAPI();
  }, [approveStatus, rejectStatus, deleteStatus]);

  const handleRoleChange = (email, newRole) => {
    setSelectedRoles((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [email]: newRole,
    }));
  };

  const handleApproveClick = async (user) => {
    const rowId = user.email; // Use email as the unique identifier
    setLoadingRows((prev) => ({ ...prev, [rowId]: true })); // Set loading for this row
    const role = selectedRoles[user.email] || '0';
    try {
      await UpdateRegisteredUserDataAPI(user.email, user.user_name, role, '1', setApproveStatus, () => {});
      setSeverStatus('success');
      setOpen(true);
    } catch {
      setApproveStatus(-1);
      setSeverStatus('error');
      setOpen(true);
    } finally {
      setLoadingRows((prev) => ({ ...prev, [rowId]: false })); 
    }
  };

  const handleRejectClick = (user) => {
    const role = selectedRoles[user.email] || '1';
    UpdateRegisteredUserDataAPI(user.email, user.user_name, role, '-1', setRejectStatus, (message) => {
      setSeverStatus('success');
      setRejectOpen(true);
    }).catch(() => {
      setRejectStatus(-1);
      setSeverStatus('error');
      setRejectOpen(true);
    });
  };

  const handleDeleteClick = (user) => {
    DeleteRegisterUserDataApiCall(user.email, setDeleteStatus, (message) => {
      setSeverStatus('success');
      setDeleteOpen(true);
    }).catch(() => {
      setDeleteStatus(-1);
      setSeverStatus('error');
      setDeleteOpen(true);
    });
  };
  const handleChangedropdown = (event) => {
    setRole(event.target.value);
  };

  const renderStatus = (status) => {
    if (status === 1) return 'Approved';
    if (status === -1) return 'Rejected';
    return 'Pending';
  };

  return (
    <>
      <Grid item lg={12} md={12} sm={12}>
        <Paper elevation={15}>
          <EnhancedTableToolbar filterName={filterName} onFilterName={handleFilterByName} />
          <TableContainer className={classes.container}>
            <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                showCustomer={mspClientId === regClientId && mspStatus === 1}
                numSelected={selected.length}
                rowCount={filteredRows.length}
              />
              <TableBody>
                {filteredRows.map((row, index) => (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.user_name}</TableCell>
                    <TableCell align="left">
                      <Select
                        id={`select-${index}`}
                        value={selectedRoles[row.email] || '0'}
                        onChange={(e) => handleRoleChange(row.email, e.target.value)} // Update the state for the specific row
                        className={classes.select}
                      >
                        
                        {roleOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <Select labelId="role-label" id="role-select" value={role} label="Role" onChange={handleChangedropdown}>
                        <MenuItem value={0}>Super Admin</MenuItem>
                        <MenuItem value={1}>Read-Only</MenuItem>
                        <MenuItem value={2}>Engineer</MenuItem>
                      </Select> */}
                    </TableCell>
                    <TableCell align="left">{renderStatus(row.status)}</TableCell>
                    <TableCell align="left">
                    <IconButton
                        color="primary"
                        onClick={() => handleApproveClick(row)}
                        disabled={loadingRows[row.email]}
                      >
                        {loadingRows[row.email] ? (
                          <CircularProgress size={24} />
                        ) : (
                          <ThumbUpAltRoundedIcon />
                        )}
                      </IconButton>
                      <IconButton color="error" onClick={() => handleRejectClick(row)}>
                        <ThumbDownAltRoundedIcon />
                      </IconButton>
                      {/* <IconButton color="error" onClick={() => handleDeleteClick(row)}>
                        <DeleteIcon />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={severStatus}>
          {severStatus === 'success' ? 'Updated Successfully' : 'Error'}
        </Alert>
      </Snackbar>
      <Snackbar open={deleteOpen} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={severStatus}>
          {severStatus === 'success' ? 'Deleted Successfully' : 'Error'}
        </Alert>
      </Snackbar>
      <Snackbar open={rejectopen} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={severStatus}>
          {severStatus === 'success' ? 'Rejected Successfully' : 'Error'}
        </Alert>
      </Snackbar>
    </>
  );
}



