import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DeleteAgentAPICall } from './DeleteAgentAPICall';


export default function DeleteAgentDetailsDialog(props) {

    const { agent, openDeleteDialog, setOpenDeletDialog, setDeleteStatus, setDeleteOpen, setSeverStatus } = props;

    const handleClose = (e) => {
        setOpenDeletDialog(false);
    }

    const handleDelete = (e) => {
        DeleteAgentAPICall(agent.agent_id, agent.raspi_serial_number , setDeleteStatus, setSeverStatus);
        setDeleteOpen(true);
        setOpenDeletDialog(false);
    }

    return (
        <>
            <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Agent
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to delete Agent {agent.raspi_serial_number} ??
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
