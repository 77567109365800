import React, { useState, useRef, useEffect } from 'react';
import { Typography, Grid, Paper, Box, Card, RadioGroup, FormControlLabel, Radio, FormLabel, Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../chart';
import timeArray from './TimeData';

import TracerouteGraph from './TracerouteGraph';
import GetSaasAppDetailsGraphAPICall from './GetSaasAppDetailsGraphAPICall';
import GetSaasPortDetailsGraphAPICall from './GetSaasPortDataGraphAPICall';
// ---------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    textDecoration: 'none',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});
const ApplicationMonitorGraphs = (props) => {
  const { locationId, appId } = props;
  const [value, setValue] = useState(0);
  const [appJitterData, setAppJitterData] = useState([]);
  const [appLatencyData, setAppLatencyData] = useState([]);
  const [appPacketLossData, setAppPacketLossData] = useState([]);
  const [appTimeData, setAppTimeData] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [jitterStateData, setJitterStateData] = useState([]);
  const [latencyStateData, setLatencyStateData] = useState([]);
  const [packetLossStateData, setPacketLossStateData] = useState([]);
  const [appDetailsStatus, setAppDetailsStatus] = useState(false)
  const classes = useStyles();
  const intervalRef = useRef();
  const handleTimeChange = (event, newTimeValue) => {
    intervalRef.time = newTimeValue;
    setOpenBackdrop(true);
    _GetSaasAppDetailsGraphAPICall();
    if(appDetailsStatus){
      _GetSaasPortDetailsGraphAPICall();
    }
  };
  
  useEffect(() => {
    intervalRef.time = timeArray[0].value;
    _GetSaasAppDetailsGraphAPICall();
    if(appDetailsStatus){
      _GetSaasPortDetailsGraphAPICall();
    }
    
  }, [appDetailsStatus]);

  useEffect(() => {
    _GetSaasAppDetailsGraphAPICall();
    if(appDetailsStatus){
      _GetSaasPortDetailsGraphAPICall();
    }
  }, [locationId]);
  // useEffect(() => {
  //   _GetSaasAppDetailsGraphAPICall();
  //   _GetSaasPortDetailsGraphAPICall();
  // }, [appTimeData.length]);

  const _GetSaasAppDetailsGraphAPICall = async () => {
    await GetSaasAppDetailsGraphAPICall(locationId, appId, intervalRef.time, setAppJitterData, setAppLatencyData, setAppPacketLossData, setAppTimeData, setOpenBackdrop, setAppDetailsStatus);
  }
  
  const _GetSaasPortDetailsGraphAPICall = async () => {
    await GetSaasPortDetailsGraphAPICall(locationId, appId, intervalRef.time, setJitterStateData, setLatencyStateData, setPacketLossStateData, setAppTimeData, setOpenBackdrop);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const combinedLatencyChartData = [
    ...appLatencyData,
    ...latencyStateData,
  ];
  const combinedJitterChartData = [
    ...appJitterData,
    ...jitterStateData,
  ];
  const combinedPacketLossChartData = [
    ...appPacketLossData,
    ...packetLossStateData,
  ];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const chartLabels = appTimeData;

  const getChartOptions = (annotationY) => {    
    const options = merge(BaseOptionChart(), {
      plotOptions: { bar: { columnWidth: '16%' } },
      fill: { type: latencyStateData.map((i) => i.fill) },
      labels: chartLabels,
      xaxis: {
        type: "time",
        tickAmount: 12
      },
      yaxis: {
        title: {
          text: 'Mili Seconds'
        },
      },
      legend: {
        show: true,
        position: "bottom",
        showForSingleSeries: true,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y} ms`;
            }
            return y;
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: annotationY,
            borderColor: 'red',
            strokeWidth: 10,
            strokeDashArray: 0,
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red',
              },
              text: `Base Value (${annotationY}ms)`,
            },
          },
        ],
      },
    });
  
    return options;
  };
  
  const state2 = {
    options:
    {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'time',
        tickAmount: 12,
        categories: appTimeData,
      },
      yaxis: {
        title: {
          text: 'Packet Loss(%)'
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: "100%"
            },
            legend: {
              showForSingleSeries: true,
            }
          }
        }
      ],
      annotations: {
        yaxis: [
          {
            y: 150,
            borderColor: '#FF4560',
            strokeWidth: 10,
            strokeDashArray: 0,
            label: {
              borderColor: '#FF4560',
              style: {
                color: '#fff',
                background: '#FF4560',
              },
              text: 'Base Value (150ms)',
            },
          },
        ],
      },
    },
  };
  const state3 = {
    options:
    {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'time',
        tickAmount: 12,
        categories: appTimeData,
      },
      yaxis: {
        title: {
          text: 'Uptime'
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: "100%"
            },
            legend: {
              showForSingleSeries: true,
            }
          }
        }
      ]
    },
  };
  return (
    <Paper
      elevation={20}
      sx={{ margin: "15px" }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography position='absolute'><strong>Loading.....</strong></Typography>
      </Backdrop>
      <div className={classes.titleStyle}>
        <FormLabel id="demo-row-radio-buttons-group-label">Select Time</FormLabel>
        <Grid container>
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={intervalRef.time ? intervalRef.time : timeArray[0].value}
              onChange={handleTimeChange}
            >
              {timeArray.map((data, index) =>
              (
                <FormControlLabel value={data.value} control={<Radio />} label={data.label} />
              )
              )}
              <FormControlLabel style={{ display: 'none' }} value={"-1"} control={<Radio />} label={"Custom Date"} disabled />
            </RadioGroup>
          </Grid>
          
        </Grid>
      </div>
      <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2 }}>
          <Card variant="outlined" elevation={15}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Latency" />
              <Tab label="Jitter" />
              <Tab label="Packet loss" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box paddingX={2}>
              {(appLatencyData !== null && appLatencyData.length !== 0) && <ReactApexChart options={getChartOptions(150)} series={combinedLatencyChartData} type="line" height={350} />}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box paddingX={2}>
             <ReactApexChart options={getChartOptions(30)} series={combinedJitterChartData} type="line" height={350} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box paddingX={2}>
        <ReactApexChart options={state2.options} series={combinedPacketLossChartData} type="line" height={350} />
              </Box>
            </TabPanel>
      
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2, py: 2 }}>
          <TracerouteGraph locationId={locationId} appId={appId} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ApplicationMonitorGraphs;
