import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import Router from './routes';
import ThemeProvider from './theme';
import { msalInstance } from './sections/auth/login/msalConfig';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { GlobalStateProvider } from './globalHooks/GlobalState';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <GlobalStateProvider>
      <ThemeProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <MsalProvider instance={msalInstance}>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </MsalProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </GlobalStateProvider>
  );
}

