import axios from 'axios';

// API CALL CODE HERE

const BestSitesApiCall = async (buState, setBestSites, setSiteReportData, monthYear, generateSiteReportCSV) => {
    const path = localStorage.getItem("msp_status") === "1" ? "get_best_sites_report_for_msp_v1.php" : "get_best_sites_report_v1.php";
    const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("reg_cust_id"),
        "bu_id": buState,
        "month": monthYear
    });

    const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "month": monthYear,
        "msp_status": 1
    });

    const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
    return axios.post(baseUrl, payload).then((response) => {
        if (response.data.code === 1) {
            setBestSites(response.data.sites_bh_data)
            setSiteReportData({
                bh_data: response.data.sites_bh_data,
                all_data: response.data.sites_total_data
              });
            generateSiteReportCSV({
                bh_data: response.data.sites_bh_data,
                all_data: response.data.sites_total_data
              })
        }
        else {
            setSiteReportData({
                bh_data: [],
                all_data: []
              });
        }
    })
}

export default BestSitesApiCall;