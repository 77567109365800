import {
    Breadcrumbs, Container, Link,
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Page from '../components/Page';
import TemplateComponent from '../components/TemplateComponents/components/TemplateComponent'

export default function Templates() {
    return (
        <Page title="View Users">
            <Container maxWidth={false}>
                <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }} >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard/">
                        <HomeOutlinedIcon />
                    </Link>
                    <Link underline="hover" color="inherit">
                        Settings
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/templates">
                        Templates
                    </Link>
                </Breadcrumbs>
                </div>

                <TemplateComponent />

            </Container>
        </Page>
    );
}
