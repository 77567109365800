import axios from 'axios';

// API CALL CODE HERE


const AddNewSiteGroupApiCall = async (groupName, groupType, criticality, latency, jitter, packetLoss,warning, critical, setAddSiteGroupStatus,setAddSiteMessage) => {

const path = localStorage.getItem("msp_status") === "1" ? "create_new_site_group_for_msp.php" : "create_new_site_group.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;


  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "group_data": {
        "group_name":groupName,
        "group_type": groupType,
        "criticality": criticality,
        "latency": latency,
        "jitter": jitter,
        "packet_loss": packetLoss,
        "warning_th_value": warning,
        "critical_th_value" : critical
	}
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
        "group_data": {
        "group_name":groupName,
        "group_type": groupType,
        "criticality": criticality,
        "latency": latency,
        "jitter": jitter,
        "packet_loss": packetLoss,
        "warning_th_value": warning,
        "critical_th_value" : critical
	}
    
});

  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setAddSiteGroupStatus(1);
      setAddSiteMessage(response.data.message)
    }
    else {
      setAddSiteGroupStatus(-1);
      setAddSiteMessage(response.data.message)
    }
  }).catch(()=>{
    setAddSiteGroupStatus(-1);
  })
}

export default AddNewSiteGroupApiCall;