import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Divider,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    Snackbar,
} from '@mui/material';

// Components
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import UpdateSiteGroupDataApiCall from './UpdateSiteGroupDataApiCall';
import GetAllSiteGroupsApiCall from './GetAllSiteGroupsApiCall';
import GetSiteLocationsDataApiCall from './GetSiteLocationsDataApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';


function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export default function MapComponents() {


    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [siteGroups, setSiteGroups] = useState([]);
    const [SiteId, setSiteId] = useState(-1);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [addLocationmessage, setAddLocationMessage] = useState("")

    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();


    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [showDroppable, setShowDroppable] = useState(true);


    const handleSiteGroupName = (e) => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
        }
        setSiteId(e.target.value);
        GetSiteLocationsDataApiCall(e.target.value, setLeft, setRight, buState)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateStatus(0);
    };
    const getUtilizationThresholds = () => {
        const selectedGroup = siteGroups.find(group => group.group_id === SiteId);
        if (selectedGroup) {
            return {
                utilWarnTh: selectedGroup.util_warn_th || null,
                utilCriticalTh: selectedGroup.util_critical_th || null,
            };
        }
        return {
            utilWarnTh: null,
            utilCriticalTh: null,
        };
    };
    

    const updateSite = () => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        const { utilWarnTh, utilCriticalTh } = getUtilizationThresholds();
        UpdateSiteGroupDataApiCall(SiteId, right, left, utilWarnTh, utilCriticalTh, setUpdateStatus, setAddLocationMessage);
    };


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            setShowDroppable(false);
            return
        }

        GetAllSiteGroupsApiCall(setSiteGroups);
        setShowDroppable(true);
    }, [globalState]);

    useEffect(() => {
        // Set the default selected value when siteGroups change
        if (siteGroups.length > 0) {
            setSiteId(siteGroups[0].group_id);
        }
    }, [siteGroups, globalState]);

    useEffect(() => {
        if (SiteId !== -1) {
            GetSiteLocationsDataApiCall(SiteId, setLeft, setRight, buState);
        }
    }, [SiteId, globalState, buState]);


    const handleDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const sourceList = source.droppableId === 'Available' ? left : right
        const destinationList = destination.droppableId === 'Allocated' ? right : left;

        const draggedItem = sourceList[source.index];

        sourceList.splice(source.index, 1);

        destinationList.splice(destination.index, 0, draggedItem);

        setLeft(left.slice());
        setRight(right.slice());
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, bgcolor: '#EEEBEB' }} elevation={15}>
                <Grid container>
                    <Grid item xs={3} sm={3} md={3} lg={3} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <div>
                            <Typography variant='h4'>
                                <strong>Site Group Mapping</strong>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} sx={{ paddingTop: 2, paddingBottom: 2 }} >
                        <Box sx={{ width: "100%", display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <FormControl sx={{ width: "40%" }}>
                                <InputLabel id="demo-simple-select-label">Select Site Group</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Site Group"
                                    onChange={handleSiteGroupName}
                                    value={SiteId} // Make sure this is present

                                >
                                    {siteGroups.map((row, index) => (
                                        <MenuItem value={row.group_id}>{row.group_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 2 }}>
                        <Button variant="contained" size="large" onClick={updateSite}>Update</Button>
                    </Grid>
                </Grid>
                <Divider />

                {showDroppable && (
                    <div key={1} style={{ display: 'flex', justifyContent: 'center', height: '100%', overflowY: 'auto' }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            {[left, right].map((zones, idx) => (
                                <DragAndDropGroup key={idx} zones={zones} isAvailable={idx === 0} />
                            ))}
                        </DragDropContext>
                    </div>
                )}


                {updateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%', backgroundColor: '#00b100', color: 'white' }}>
                        {addLocationmessage}
                    </Alert>
                </Snackbar>
                }
                {
                    updateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                            Error:{addLocationmessage}
                        </Alert>
                    </Snackbar>
                }

                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}

            </Paper >
        </Box >

    );
}
const DragAndDropGroup = ({ zones, isAvailable }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{isAvailable ? 'Available Locations' : 'Allocated Locations'}</h2>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={isAvailable ? 'Available' : 'Allocated'}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                                padding: 10,
                                width: 350,
                                minHeight: 500,  // Set an upper limit for height
                                overflowY: 'auto', // Add this line for vertical scrolling
                                maxHeight: 500  // Set the maximum height

                            }}
                        >
                            {zones.map((item, index) => (
                                <Draggable key={item} draggableId={item} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                color: 'white',
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            {item}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};
