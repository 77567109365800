import PowerBiInsightsComponent from '../components/PowerBiReportsComponents.js/PowerBiInsightsComponent';


// ----------------------------------------------------------------------

export default function PowerBiInsights() {

    return (
        <PowerBiInsightsComponent />
    );
}
