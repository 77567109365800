import React, { useState, useEffect } from 'react';
// material
import {
  Link,
  Breadcrumbs,
  Paper,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Alert,
  Typography,
} from '@mui/material';


import BuildIcon from '@mui/icons-material/Build';
import { createFilterOptions } from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import Page from '../Page';
import { ISPLocationsDataAPICall } from "../../pages/SitewiseRestAPICalls"
import { PushSingleISPInfo, GetSingleISPInfo } from "../../pages/ISPAddingAPICall";
import GetVendorDetailsApiCall from '../../pages/ApiCalls/GetVendorDetailsApiCall';
import linkType from "../../Assets/LinkTypes";



const filter = createFilterOptions();

export default function CreateLink({
  ispLocations, setIspLocations,
  value, setValue,
  circuitId, setCircuitId,
  vendorName, setVendorName,
  vendorEmail, setVendorEmail,
  defaultGateway, setDefaulGateway,
  publicIp, setPublicIp,
  internalIp, setInternalIp,
  promiseUptime, setPromisedUptime,
  resolutionTime, setResolutionTime,
  data, setData, link, setLink,
  price, setPrice,
  provisionedSpeed, setProvisionedSpeed,
  city, setCity,
  vendors, setVendors


}) {

  const [cityStatus, setCityStatus] = useState(false);
  const [selectedVendorIndex, setSelectedVendorIndex] = useState(-2);
  const [selectedVendorId, setSelectedVendorId] = useState(-1);
  const [vendorDisable, setVendorDisable] = useState(false);
  const [locationName, setLocationName] = useState("");

  const [internalIpLabelShrink, setInternalIpLabelShrink] = useState(false);
  const [circuitIdHelperText, setCircuitIdHelperText] = useState("");
  const [publicIpHelerText, setPublicIpHelerText] = useState("");
  const [InternalIpHelerText, setInterIpHelerText] = useState("");
  const [provisionedSpeedHelperText, setProvisionedSpeedHelperText] = useState("");
  const [cityHelperText, setCityHelperText] = useState("");
  const [priceHelperText, setPriceHelperText] = useState("");

  const navigate = useNavigate();

  const errorRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  const provisionedSpeedRegex = /^\d+$/;
  const cityRegex = /^[a-zA-Z][a-zA-Z0-9\s]*$/;
  const priceRegex = /^\d+$/;
  const circuitIdRegex = /^[^\s].*$/;

  const formData = {
    ispLocations,
    value,
    circuitId,
    vendorName,
    vendorEmail,
    defaultGateway,
    publicIp,
    internalIp,
    promiseUptime,
    resolutionTime,
    data, link,
    price,
    provisionedSpeed,
    city,
    vendors
  };

  // ...

  useEffect(() => {
    // Convert the formData object to JSON string
    const formDataJson = JSON.stringify(formData);

    // Save the JSON string in local storage
    localStorage.setItem('LinkFormData', formDataJson);
  }, [formData]);

  useEffect(() => {
    const siteFormString = localStorage.getItem('SiteFormData');

    if (siteFormString) {
      const siteForm = JSON.parse(siteFormString);

      // Access individual properties
      setLocationName(siteForm.locationName);
    }
  }, []);

  const _ISPLocationsDataAPICall = async () => {
    ISPLocationsDataAPICall(setIspLocations);
    GetVendorDetailsApiCall(setVendors);
  }

  useEffect(() => {
    _ISPLocationsDataAPICall();
  }, [])

  const handleOnChange = (e) => {
    const { id, value } = e.target;

    if (e.target.id === "circuitid") {
      if (!value.match(circuitIdRegex)) {
        setCircuitIdHelperText("Enter Valid Circuit ID")
      }
      else {
        setCircuitIdHelperText("");
      }
      setCircuitId(e.target.value);
    }

    if (e.target.id === "vendorname") {
      setVendorName(e.target.value);
    }

    if (e.target.id === "vendoremail") {
      setVendorEmail(e.target.value);
    }

    if (e.target.id === "defaultgateway") {
      setDefaulGateway(e.target.value);
    }


    if (e.target.id === "publicip") {
      setInternalIpLabelShrink(true);
      if (!value.match(errorRegex)) {
        setPublicIpHelerText("Enter Valid IP Address")
      }
      else {
        setPublicIpHelerText("");
      }
      setPublicIp(e.target.value);
      setInternalIp(e.target.value);
    }


    if (e.target.id === "internal_ip") {
      setInternalIpLabelShrink(true)
      if (!value.match(errorRegex)) {
        setInterIpHelerText("Enter Valid IP Address")
      }
      else {
        setInterIpHelerText("");
      }
      setInternalIp(e.target.value);
    }

    // if (e.target.id === "promised_uptime") {
    //   setPromisedUptime(e.target.value);
    // }

    // if (e.target.id === "resoluation_time") {
    //   setResolutionTime(e.target.value);
    // }

    if (e.target.id === "provisioned_speed") {
      if (!value.match(provisionedSpeedRegex)) {
        setProvisionedSpeedHelperText("Enter Valid Value")
      }
      else {
        setProvisionedSpeedHelperText("");
      }
      setProvisionedSpeed(e.target.value);
    }

    if (e.target.id === "city") {
      if (!value.match(cityRegex)) {
        setCityHelperText("Enter Valid City Name")
      }
      else {
        setCityHelperText("");
      }
      setCity(e.target.value);
    }

    if (e.target.id === "price") {
      if (!value.match(priceRegex)) {
        setPriceHelperText("Enter Valid Price Value")
      }
      else {
        setPriceHelperText("");
      }
      setPrice(e.target.value);
    }
  }

  const handleLinkTypeChange = (e) => {
    setLink(e.target.value);
  }

  const handleVendorChange = (e) => {
    setSelectedVendorIndex(e.target.value);
    if (e.target.value >= 0) {
      setSelectedVendorId(vendors[e.target.value].vendor_id);
      setVendorName(vendors[e.target.value].vendor_name);
      setVendorEmail(vendors[e.target.value].helpdesk_email);
      setResolutionTime(vendors[e.target.value].resolution_time);
      setPromisedUptime(vendors[e.target.value].promised_uptime);
      setVendorDisable(true);
    }
    else {
      setSelectedVendorId(-1);
      setVendorDisable(false);
      setVendorName("");
      setVendorEmail("");
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh", paddingTop: "5vh" }}>
      <div style={{ width: "1000px" }}>
        <form>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10}>

              <h1 style={{ alignItems: "center", paddingBottom: "20px", width: "60vh", margin: "20px" }}>
              Add a link to monitor from your first site </h1>
            </Grid>
            <Grid item xs={6}>
              { /* eslint-disable-next-line */}
              <TextField label="Public IP" id="publicip" value={publicIp} helperText={publicIpHelerText} error={publicIpHelerText} inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
            </Grid>
            <Grid item xs={6}>
              { /* eslint-disable-next-line */}
              <TextField label="Internal IP" id="internal_ip" value={internalIp} helperText={InternalIpHelerText} error={InternalIpHelerText} InputLabelProps={{ shrink: internalIpLabelShrink }} inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Ciruit ID" id="circuitid" value={circuitId} variant="outlined" style={{ width: "400px" }} helperText={circuitIdHelperText} error={circuitIdHelperText} onChange={handleOnChange} required />
            </Grid>
            {/* <Grid item xs={6}>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setCity("");
                  setCityStatus(false);
                  if (typeof newValue === 'string') {
                    setValue({
                      location: newValue,
                    });

                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      location: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                    setCity(newValue.city);
                    setCityStatus(true);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.location);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      location: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // id="free-solo-with-text-demo"
                options={ispLocations}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {

                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.location;
                }}
                renderOption={(props, option) => <li {...props}>{option.location}</li>}
                sx={{ width: 250 }}
                location
                renderInput={(params) => (
                  <TextField {...params} label="Site" id="location" required style={{ width: "400px" }} />
                )}
              />

            </Grid> */}
            <Grid item xs={6}>
              <TextField label="Site" id="site" value={locationName} variant="outlined" style={{ width: "400px" }} helperText={circuitIdHelperText} error={circuitIdHelperText} onChange={handleOnChange} required disabled />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="Vendor-label">Vendor</InputLabel>
                <Select
                  labelId="Vendor-label"
                  id="Vendor"
                  style={{ width: "400px" }}
                  label="Vendor"
                  value={selectedVendorIndex}
                  onChange={handleVendorChange}
                >
                  <MenuItem value={-2}>Select Vendor</MenuItem>
                  {vendors.map((val, index) => (
                    <MenuItem value={index}>{val.vendor_name}</MenuItem>
                  ))}
                  <MenuItem value={-1}>New Vendor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField label="Vendor Name" id="vendorname" value={vendorName} variant="outlined" style={{ width: "400px" }} type="text" required onChange={handleOnChange} disabled={vendorDisable} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Vendor E-Mail" id="vendoremail" value={vendorEmail} variant="outlined" type="email" style={{ width: "400px" }} required onChange={handleOnChange} disabled={vendorDisable} />
            </Grid>
            <Grid item xs={6}>
              { /* eslint-disable-next-line */}
              <TextField label="WAN Gateway" id="defaultgateway" inputProps={{ pattern: "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$", title: "For example:192.158.1.1" }} value={defaultGateway} variant="outlined" style={{ width: "400px" }} onChange={handleOnChange} required />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField label="Promised Uptime(in %)" id="promised_uptime" value={promiseUptime} type="number" InputProps={{ inputProps: { min: 0, max: 100 } }} variant="outlined" style={{ width: "400px" }} required onChange={handleOnChange} disabled={vendorDisable} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Resolution Time(in Hours)" id="resoluation_time" value={resolutionTime} type="number" variant="outlined" style={{ width: "400px" }} required onChange={handleOnChange} disabled={vendorDisable} />
            </Grid> */}
            <Grid item xs={6} >
              <FormControl fullWidth>
                <InputLabel required id="link-type">Link Type</InputLabel>
                <Select
                  labelId="link-type-label"
                  id="link-type-id"
                  label="Link Type"
                  style={{ width: "400px" }}
                  value={link}
                  onChange={handleLinkTypeChange}
                  required

                >
                  {linkType.map((value, index) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <TextField label="Provisioned Speed(in MBps)" id="provisioned_speed" variant="outlined" type="number" value={provisionedSpeed} style={{ width: "400px" }} helperText={provisionedSpeedHelperText} error={provisionedSpeedHelperText} required onChange={handleOnChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Price" id="price" value={price} variant="outlined" type="text" style={{ width: "400px" }} helperText={priceHelperText} error={priceHelperText} required onChange={handleOnChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="City" id="city" value={city} variant="outlined" type="text" style={{ width: "400px" }} required onChange={handleOnChange} helperText={cityHelperText} error={cityHelperText} disabled={cityStatus} />
            </Grid> */}
          </Grid>
          <br />
        </form>
      </div>
    </div>
  );

}
