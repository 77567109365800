import axios from 'axios';

// API CALL CODE HERE


const GetSiteLocationsDataApiCall = async(probeId, setPresentLocations, setAvailableLocations, buState) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_locations_by_probe_for_msp.php" : "get_locations_by_probe.php";
  const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId,
    "bu_id": buState
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "probe_id": probeId
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

  return axios.post(createBaseURL, payload).then((response) => {
 
    if (response.data.code === 1) {
      setAvailableLocations(response.data.probe_locations_res.map(location => location.location_name));
      setPresentLocations(response.data.spare_locations_res.map(location => location.location_name));
    }
    else {
      setAvailableLocations([]);
      setPresentLocations([]);
    }
  }).catch(()=>{
      setAvailableLocations([]);
      setPresentLocations([]);
  })
}

export default GetSiteLocationsDataApiCall;