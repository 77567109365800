import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton, Typography, Card,InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Iconify from '../Iconify';
import Logo from '../Logo';
import useResponsive from '../../hooks/useResponsive';
import LoginImg from '../../Assets/illustration_login.png';



const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 564,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 5, 2),
}));
export default function CreateNewPassowrd({
  oldPassword, setOldPassword,
  newPassword, setNewPassword,
  confirmPassword, setConfirmPassword,
  updateStatus, setUpdateStatus }) {

  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [oldPasswordHelperText, setOldPasswordHelperText] = useState('');
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');

  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[()@#$%^:;&+=!])[A-Za-z\d@():;#$%^&+=!]{8,}$/;
  const oldPassRegex = /^\S+$/;

  const formData = {
    oldPassword,
    newPassword,
    confirmPassword,
    updateStatus,
  };

  // ...

  useEffect(() => {
    // Convert the formData object to JSON string
    const formDataJson = JSON.stringify(formData);

    // Save the JSON string in local storage
    localStorage.setItem('PasswordFormData', formDataJson);
  }, [formData]);

  const handleOnCancel = () => {
    navigate('/dashboard/view-isp');
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'oldPassword':
        if (!value.match(oldPassRegex)) {
          setOldPasswordHelperText("Enter Old Password Correctly");
        }
        else{
          setOldPasswordHelperText("");
        }
        setOldPassword(value);
        break;

      case 'newPassword':
        if (!value.match(passwordRegex)) {
            setNewPasswordHelperText("Password must contain at least 8 characters, one letter, one number and one special character");
        }
        else
        {
            setNewPasswordHelperText("");
            
        }
        setNewPassword(value);
        break;

      case 'confirmPassword':
         if (!value.match(newPassword)) {
            setConfirmPasswordHelperText("Password should be match with New Password");
        }
        else
        {
            setConfirmPasswordHelperText("");
            
        }
        setConfirmPassword(value);
        break;

      default:
        break;
    }
  };


  return (
    <div style={{ padding: "50px" }}>
      <form>

        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
          <Grid item xs={3}>
            {mdUp && (
              <SectionStyle>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                  Hi, Welcome
                </Typography>
                <img src={LoginImg} alt="login" />
              </SectionStyle>
            )}
          </Grid>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "30vh", marginLeft: "300px" }} >
            <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
              <Grid item xs={10}>

                <h1 style={{ alignItems: "center", paddingBottom: "20px" , width:"60vh" ,margin:"20px" }}>
                  Change the Temporary password.</h1>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Old Password"
                  id="oldpassword"
                  name="oldPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={oldPassword}
                  helperText={oldPasswordHelperText}
                  error={!!oldPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  style={{ width: '400px', height: "100px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6} >
                <TextField
                  label="New Password"
                  id="newpassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  helperText={newPasswordHelperText}
                  disabled={formSubmitted}
                  error={!!newPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                          <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '400px', height: "100px" }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Confirm Password"
                  id="cpassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  disabled={formSubmitted}
                  helperText={confirmPasswordHelperText}
                  error={!!confirmPasswordHelperText}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: '400px' }}
                  required
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </form>

    </div>
  );
}