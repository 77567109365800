import React, { useEffect, useState } from 'react';
import {
    Paper,
    Box,
    Typography,
    Divider,
    Grid,
    MenuItem,
    FormControl,
    FormControlLabel,
    Switch,
    FormGroup,
    Select
} from '@mui/material';
import TopSingleAppComponent from './TopSingleAppComponent';
import TopAppsDonutChart from '../DonutChart';
import GetTopAppsUsageExporterAPICall from './GetTopAppsUsageAPICall';

export default function TopAppsInfoComponent(props) {
    const { deviceIps } = props;
    const [time, setTime] = useState(1);
    const [appsUsageData, setAppsUsageData] = useState([]);
    const [showPie, setShowPie] = useState(true);

    const [inBoundWanArr, setInBoudWanArr] = useState([]);
    const [OutBoundWanArr, setOutBoudWanArr] = useState([]);
    const [applicationsArr, setApplicationsArr] = useState([]);


    const _GetTopAppsUsageExporterAPICall = () => {
        GetTopAppsUsageExporterAPICall([deviceIps], time, setAppsUsageData, setInBoudWanArr, setOutBoudWanArr, setApplicationsArr);
    };

    useEffect(() => {
        _GetTopAppsUsageExporterAPICall();
    }, [deviceIps, time]);

    const maxInbound = Math.max(...appsUsageData.map(app => app.inbound_wan), 0);
    const maxOutbound = Math.max(...appsUsageData.map(app => app.outbound_wan), 0);

    // Helper function to format bytes into MB or GB
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes < 1024 * 1024 * 1024) {
            return `${(bytes / (1024 * 1024)).toFixed(decimals)} MB`;
        }
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(decimals)} GB`;
    };


    return (
        <>
            <Paper elevation={15}>
                <Box sx={{ height: '800px', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={{ padding: '1rem' }} variant='h6'>Top Apps</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch defaultChecked
                                        onChange={() => { setShowPie(!showPie) }}
                                    />
                                }
                                label="Pie" />
                        </FormGroup>
                        <FormControl sx={{ paddingTop: '1rem', paddingLeft: '5rem' }}>
                            <Select
                                value={time}
                                sx={{
                                    maxHeight: '40px',
                                    minWidth: '10rem',
                                    marginRight: '1rem',
                                    border: 'none',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                onChange={(e) => setTime(e.target.value)}
                            >
                                <MenuItem value={1}>Last 1 Hour</MenuItem>
                                <MenuItem value={6}>Last 6 Hours</MenuItem>
                                <MenuItem value={12}>Last 12 Hours</MenuItem>
                                <MenuItem value={24}>Last 24 Hours</MenuItem>
                                {/* Additional time options can be added here */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider />
                    <Grid container>
                        {!showPie && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ width: '100%', paddingBottom: '2rem' }}>
                                {appsUsageData.length > 0 ? appsUsageData.map((app, index) => {
                                    // Normalize inbound and outbound values
                                    const normalizedInbound = (app.inbound_wan / maxInbound) * 100;
                                    const normalizedOutbound = (app.outbound_wan / maxOutbound) * 100;

                                    return (
                                        <TopSingleAppComponent
                                            key={index}
                                            appName={app.application}
                                            inbound={formatBytes(app.inbound_wan)}
                                            outbound={formatBytes(app.outbound_wan)}
                                            inboundMarkerValue={Math.ceil(normalizedInbound)}
                                            outboundMarkerValue={Math.ceil(normalizedOutbound)}
                                        />
                                    );
                                }) : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingBottom: '2rem', paddingTop: '1rem', border: 1 }}>
                                {appsUsageData.length > 0 ? <TopAppsDonutChart label={"InBoud Usage"} dseries={inBoundWanArr} dLabels={applicationsArr} directValue={false}/> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingBottom: '2rem', paddingTop: '1rem', border: 1 }}>
                                {appsUsageData.length > 0 ?  <TopAppsDonutChart label={"OutBoud Usage"} dseries={OutBoundWanArr} dLabels={applicationsArr} directValue={false}/> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>
            </Paper>
        </>
    );
}
