import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import {
  Card,
  Grid,
  Paper, Box,
  Typography,
  Backdrop,
  CircularProgress,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import 'react-tabs/style/react-tabs.css';
import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../../../chart';
// components
import EdDown1SubCards from './EdDown1SubCards';
import { EdCard1RestCallForCard, EdCard1RestCallForGraphCard, EdCard1RestCallForGraphCardByDate } from "../EdCard1RestCall"
import EdgeDeviceInfo from '../../../EdgeDeviceInfoComponents/EdgeDeviceInfoDashboard/EdgeDeviceInfo';
// import InternalRechablityApiCall from '../InternalRechablityApiCall';
import TroubleshootComponent from '../TroubleshootComponents/TroubleshootComponent';
import timeArray from '../TimeData';


// sections

// ----------------------------------------------------------------------
const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: 24,
  },
});

export default function EdDown1Card1(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [ispParams, setIspParams] = useState({});
  const [jitterData, setJitterData] = useState([]);
  const [latencyData, setLatencyData] = useState([]);
  const [packetLossData, setPacketLossData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [upDownSeriesData, setUpDownSeriesData] = useState([]);

  const [timeData, setTimeData] = useState([]);
  const [statusTimeData, setStatusTimeData] = useState([]);

  const [inBandWidthData, setInBandWidthData] = useState([]);
  const [outBandWidthData, setOutBandWidthData] = useState([]);
  const [bandWidthTimeData, setBandWidthTimeData] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(true);

  const [openTroubleshoot, setOpenTroubleshoot] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState("");

  const custId = localStorage.getItem("cust_id");


  // Code to Written TO fetch single isp info and display
  const params = useParams();

  const intervalRef = useRef();

  const handleTimeChange = (event, newTimeValue) => {
    setOpenBackdrop(true);
    setSelectedDate(null);
    intervalRef.time = newTimeValue;
    // EdCard1RestCallForGraphCard(params.id, intervalRef.time, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setOpenBackdrop);
    _EdCard1RestCallForGraphCard();
    // _EdCard1BandWidthData();
  };

  const handleDateChange = (date) => {
    intervalRef.time = -1;
    setSelectedDate(date);
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      setFormattedDate(formattedDate);
    }
  };

  const _EdCard1RestCallForCard = async () => {
    EdCard1RestCallForCard(params.id, setIspParams);
  }

  useEffect(() => {
    _EdCard1RestCallForCard();
  }, []);


  const _EdCard1RestCallForGraphCardByDate = async () => {
    await EdCard1RestCallForGraphCardByDate(params.id, formattedDate, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop);
  }

  // const _EdCard1BandWidthDataByDate = async () => {
  //   await EdCard1BandWidthDataByDate(params.id, formattedDate, setInBandWidthData, setOutBandWidthData, setBandWidthTimeData);
  // }

  useEffect(() => {
    setOpenBackdrop(true);
    if (formattedDate !== "") {
      _EdCard1RestCallForGraphCardByDate();
      // _EdCard1BandWidthDataByDate();
    }
    // _EdCard1BandWidthDataByDate();
  }, [formattedDate])

  const _EdCard1RestCallForGraphCard = async () => {
    await EdCard1RestCallForGraphCard(params.id, intervalRef.time, setJitterData, setLatencyData, setPacketLossData, setStatusData, setTimeData, setStatusTimeData, setUpDownSeriesData, setOpenBackdrop);
  }

  // const _EdCard1BandWidthData = async () => {
  //   await EdCard1BandWidthData(params.id, intervalRef.time, setInBandWidthData, setOutBandWidthData, setBandWidthTimeData);
  // }

  useEffect(() => {
    intervalRef.time = timeArray[0].value;
    _EdCard1RestCallForGraphCard();
    // _EdCard1BandWidthData();
    _EdCard1RestCallForCard();
    // setInterval(() => {
    //   _EdCard1RestCallForGraphCard();
    //   _EdCard1RestCallForCard();
    //   _EdCard1BandWidthData();
    // }, 60 * 1000);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const state0 = [
    {
      name: 'Latency',
      type: 'area',
      fill: 'gradient',
      data: latencyData,
    },
    {
      name: 'Jitter',
      type: 'line',
      fill: 'solid',
      data: jitterData,
    },
  ];

  const chartLabels = timeData;

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: state0.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      type: "time",
      tickAmount: 12
    },
    yaxis: {
      title: {
        text: 'Mili Seconds'
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} ms`;
          }
          return y;
        },
      },
    },
  });

  const state1 = {
    series: [
      {
        name: 'PL Percent',
        data: packetLossData,
      },
    ],
    options:
    {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'time',
        tickAmount: 12,
        categories: timeData,
      },
      yaxis: {
        title: {
          text: 'Packet Loss(%)'
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: "100%"
            },
            legend: {
              show: false
            }
          }
        }
      ]
    },
  };

  const state2 = {
    series: [{
      name: "Status",
      data: statusData
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'stepline',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0
        },
      },
      xaxis: {
        type: 'time',
        tickAmount: 12,
        categories: statusTimeData,
      },
      yaxis: {
        tickAmount: 1,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              height: "100%"
            },
            legend: {
              show: false
            }
          }
        }
      ]
    },
  };

  const barState = {
    series: upDownSeriesData,
    options: {
      chart: {
        type: 'bar',
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {}
        },
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [''],
        labels: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {},
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} minutes`,
        },
        labels: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
        show: false,
      },
    },
  };

  // const state3 = [

  //   {
  //     name: 'Download',
  //     type: 'line',
  //     fill: 'solid',
  //     data: inBandWidthData, 
  //   },
  //   {
  //     name: 'Upload',
  //     type: 'area',
  //     fill: 'gradient',
  //     data: outBandWidthData,
  //   }

  // ];

  // const chartLabels3 = bandWidthTimeData;
  // const chartOptions3 = merge(BaseOptionChart(), {
  //   fill: { type: state3.map((i) => i.fill) },
  //   labels: chartLabels3,
  //   xaxis: {
  //     type: 'time',
  //     tickAmount: 12
  //   },
  //   tooltip: {
  //     shared: true,
  //     intersect: false,
  //     y: {
  //       formatter: (y) => {
  //         if (typeof y !== 'undefined') {
  //           return `${y.toFixed(4)} MB/s`;
  //         }
  //         return y;
  //       },
  //     },
  //   },
  // });

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <Typography position='absolute'><strong>Loading.....</strong></Typography>
      </Backdrop>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={15}>
          <div className={classes.titleStyle}>
            <Typography>
              {ispParams.location_name} - {ispParams.vendor_name} - {ispParams.device_ip}
            </Typography>
          </div>

          <EdDown1SubCards ispParams={ispParams} />

          <div className={classes.titleStyle}>
            <FormLabel id="demo-row-radio-buttons-group-label">Select Time</FormLabel>
            <Grid container>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={intervalRef.time ? intervalRef.time : timeArray[0].value}
                  onChange={handleTimeChange}
                >

                  {timeArray.map((data, index) => (
                    <FormControlLabel
                      key={index}
                      value={data.value}
                      control={<Radio />}
                      label={data.label}
                      // Disable "Last 2 Week" option unless "Last 1 Week" is selected
                      disabled={data.value === "336 HOUR" && (intervalRef.time !== "168 HOUR" && intervalRef.time !== "336 HOUR")}
                    />
                  ))}
                  {/* <FormControlLabel value={"-1"} control={<Radio />} label={"Custom Date"} disabled/> */}
                </RadioGroup>
              </Grid>
              {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Select Date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid> */}
            </Grid>
          </div>

          <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="outlined" elevation={15}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Latency/jitter" />
                  <Tab label="Packet loss" />
                  <Tab label="Up Time" />
                  {/* <Tab label="Utilization" /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Box paddingX={2}>
                    <ReactApexChart type="line" series={state0} options={chartOptions} height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box paddingX={2}>
                    <ReactApexChart options={state1.options} series={state1.series} type="area" height={350} />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box paddingX={2}>
                    <ReactApexChart options={barState.options} series={barState.series} type="bar" height={350} />
                  </Box>
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                  <Box paddingX={2}>
                    <ReactApexChart type="line" series={state3} options={chartOptions3} height={350} />
                  </Box>
                </TabPanel> */}
              </Card>
            </Grid>
            {openTroubleshoot && <TroubleshootComponent openTroubleshoot={openTroubleshoot} setOpenTroubleshoot={setOpenTroubleshoot} ispParams={ispParams} />}
          </Grid>
          {custId === "81" && <Grid container spacing={3} sx={{ px: 2, py: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card variant="outlined" elevation={15}>
                <EdgeDeviceInfo deviceIps={ispParams.device_ip} />
              </Card>
            </Grid>
          </Grid>}
        </Paper>
      </Grid>
    </>
  );
}
