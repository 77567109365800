import axios from 'axios';

// API CALL CODE HERE
const UpdateTourStatusAPICall = async (setTourboardStatus) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/update_tour_status.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "cust_id": localStorage.getItem("cust_id"),
    "tour_status": "1"
  });
 
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        localStorage.setItem("tour_status", 1);
        setTourboardStatus(1);
    }
    else {
        setTourboardStatus(-1);
    }
  }).catch(()=>{
    setTourboardStatus(-1);
  })
}

export default UpdateTourStatusAPICall;
