import axios from 'axios';




const UpdateLocationDataApiCall = async (userAllocatedData,  updateAssignStatus,user,setUpdateMessage,phrase) => {

  const path = localStorage.getItem("msp_status") === "1" ? "update_location_mapping_user_for_msp.php":"update_location_mapping_user.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("reg_cust_id"),
    "user_id": user.user_id,
    "user_mapped_data": userAllocatedData,
    "type":phrase
  });

  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "user_id": user.user_id,
    "user_mapped_data": userAllocatedData,
    "type":phrase
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;

    try {
        const response = await axios.post(createBaseURL, payload);

        if (response.data.code === 1) {
          updateAssignStatus(1);
          setUpdateMessage(response.data.message)

        } 
        else {
          setUpdateMessage(response.data.message)
          updateAssignStatus(-1);
        }
    } catch (error) {
      updateAssignStatus(-1);
    }
};

export default  UpdateLocationDataApiCall 
