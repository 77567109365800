// material
import {
    Grid,
} from '@mui/material';

// components
import ISPDashboardCards from '../ISPManagementTable/ISPManagementDashboard/ISPDashboardCards';
import MapComponents from './MapComponents';


export default function BusinessUnitLocationMapComponent() {
    return (
        <Grid>
          <Grid item container spacing={3}>
            <Grid item lg={12} sm={12} xs={12} md={12} xl={12} >
              <ISPDashboardCards />
            </Grid>
          </Grid>
          <Grid item container sx={{paddingTop:"40px"}}>
            <Grid item lg={12} md={12} sm={12}>
                <MapComponents />
            </Grid>
          </Grid>
        </Grid>
      );
}
