import React, { useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    TextField,
    MenuItem,
    FormHelperText,

} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { AddNewPortAPICall } from './AddNewPortAPICall';
import Iconify from '../../Iconify';


export default function AddPortsDialog(props) {

    const { app, appType, openAddDialog, setOpenAddDialog, setAddStatus, setAddOpen, setSeverStatus,setAddStatusMessage } = props;
    
    const [ports, setPorts] = useState([]);
    const [portType, setPortType] = useState(0); 
    const [portNumber, setPortNumber] = useState(app.port_number);
    // const [portCriticality, setPortCriticality] = useState(app.criticality);
    const [portCriticality, setPortCriticality] = useState('HIGH');
    const [portFunction, setPortFunction] = useState(app.functionality);

    const [portNumberHelperText, setPortNumberHelperText] = useState("");
    const [portFunctionHelperText, setPortFunctionHelperText] = useState("");

    const portNoRegex = /^(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
    const portFunctionRegex = /^[^"'-]*[A-Za-z0-9]$/;

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (!portNumber || !portNumber.match(portNoRegex)) {
            setPortNumberHelperText("Port Number Should be Valid");
            return;
        }

        if (!portFunction || !portFunction.match(portFunctionRegex)) {
            setPortFunctionHelperText("Port Type should be Valid");
            return;
        }

        AddNewPortAPICall(app.app_id, appType, portNumber, portType, portCriticality, portFunction, setAddStatus, setSeverStatus,setAddStatusMessage);
        setAddOpen(true);
        setOpenAddDialog(false);
    }



    const handleOnCancel = () => {
        setOpenAddDialog(false);
    }

    const handlePortTypeChange = (e) => {
        setPortType(e.target.value)
    };


    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "port_number":
                if (!e.target.value.match(portNoRegex)) {
                    setPortNumberHelperText("Port Number Should be Valid");
                } else {
                    setPortNumberHelperText("");
                }
                setPortNumber(e.target.value);
                break;

            case "port_function":
                if (!e.target.value.match(portFunctionRegex)) {
                    setPortFunctionHelperText("Port Function Should be Valid");
                } else {
                    setPortFunctionHelperText("");
                }
                setPortFunction(e.target.value);
                break;

            default:
                setPortNumber(e.target.value);
                break;
        }
    };

    return (
        <>
            <Dialog open={openAddDialog} onClose={handleOnCancel} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add Ports
                    <IconButton sx={{ float: 'right' }} onClick={handleOnCancel}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: "1px" }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Port Number"
                                id="port_number"
                                variant="outlined"
                                style={{ width: '400px' }}
                                value={portNumber}
                                helperText={portNumberHelperText}
                                error={!!portNumberHelperText}
                                onChange={handleOnChange}
                                required
                            />
                        </Grid>


                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="type-label" required>
                                    Port Type
                                </InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="porttype"
                                    label="Port Type"
                                    style={{ width: "400px" }}
                                    value={portType}
                                    onChange={handlePortTypeChange}
                                >
                                    <MenuItem value={0}>TCP</MenuItem>
                                    <MenuItem value={1}>UDP</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="criticality-label" required>
                                    Port Criticality
                                </InputLabel>
                                <Select
                                    labelId="criticality-label"
                                    id="port_criticality"
                                    label="Port Criticality"
                                    style={{ width: "400px" }}
                                    value={portCriticality}
                                    onChange={(e) => setPortCriticality(e.target.value)}
                                >
                                    <MenuItem value="HIGH">High</MenuItem>
                                    <MenuItem value="LOW">Low</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Port Functionality"
                                id="port_function"
                                variant="outlined"
                                style={{ width: '400px' }}
                                value={portFunction}
                                helperText={portFunctionHelperText}
                                error={!!portFunctionHelperText}
                                onChange={handleOnChange}
                                required
                            />
                        </Grid>

                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnCancel}>Cancel</Button>
                    <Button onClick={handleOnSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
