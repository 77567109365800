// @mui
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TextField } from '@mui/material';

export default function PauseEdgeDeviceDailog(props) {
  const { pauseValue, pauseDeviceIp, openPauseDialog, handlePauseDevice } = props;

  return (
    <>
      <Dialog open={openPauseDialog} onClose={()=>{handlePauseDevice(false)}} fullWidth maxWidth="sm" aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Link: {pauseDeviceIp}
          <IconButton onClick={()=>{handlePauseDevice(false)}} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ py: 3, px: 3 }}>
          <div>
            <div style={{ display: 'flex', margin:"20px" }}>
            {pauseValue === 1 && <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to Pause Device - {pauseDeviceIp} ? </strong></Typography> }
            {pauseValue === 0 && <Typography sx={{ color: '#827b7b', py:1 }}><strong> Are you sure to Resume Device - {pauseDeviceIp} ? </strong></Typography> }
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handlePauseDevice(false)}}>Cancel</Button>
          <Button onClick={()=>{handlePauseDevice(true)}} variant="contained">ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
