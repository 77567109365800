import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Divider, Box, IconButton } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import HomeIcon from '@mui/icons-material/Home';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@mui/styles';
import Leaflet from "leaflet"
import "leaflet/dist/leaflet.css"
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet"
import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"
import markerRetina from "leaflet/dist/images/marker-icon-2x.png"

import GetMarkersApiCall from "../ApiCalls/GetMarkersApiCall";
import { useGlobalState } from '../../../globalHooks/GlobalState';
import orangeMarker from '../../../Assets/orange_marker.png'
import redMarker from '../../../Assets/red_marker.png'

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

const defaultIcon = new Leaflet.Icon({
  iconUrl: markerIcon,
  iconSize: [20, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
})

const orangeIcon = new Leaflet.Icon({
  iconUrl: orangeMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});

const redIcon = new Leaflet.Icon({
  iconUrl: redMarker,
  iconSize: [35, 35],
  iconAnchor: [17, 34],
  popupAnchor: [1, -34],
});


const useStyles = makeStyles({
  boxPadding: {
    padding: '25px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
  },
  titleStyle: {
    padding: "10px",
  },
});

const OsmMapComponent = (props) => {
  const { setLocationId, selectedGroupIds, setSelectedGroupIds, setForceGroupDetailsRefresh, isFullScreen, setIsFullScreen, fullScreenChecking, setFullScreenChecking, selectedGroupNames, setSelectedGroupNames } = props;
  const [locationName, setLocationName] = useState("");
  const mapRef = useRef();
  const classes = useStyles();
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({})
  const [status, setStatus] = useState(false);
  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();
  const { buName, setBuName } = useGlobalState();
  const [groups, setGroups] = useState([]);
  const [selectedBuId, setSelectedBuId] = useState(-1);


  const zoom = 5;
  const containerStyle = {
    width: isFullScreen ? '210%' : '100%',
    height: isFullScreen ? '1500px' : '780px',
  }

  const _GetMarkers = () => {
    GetMarkersApiCall(selectedGroupIds, setMarkers, setCenter, setStatus, buState);
  }

  useEffect(() => {
    _GetMarkers();
  }, [selectedGroupIds, status, globalState, buState]);

  useEffect(() => {
    setStatus(false)
  }, [localStorage.getItem("reg_cust_id")])

  const mapClicked = async (event) => {
  }
  useEffect(() => {
    setSelectedGroupIds([])
  }, [globalState, buState])


  const markerClicked = (marker, index) => {
    setLocationId(marker.location_id);
    setLocationName(marker.location_name);
  }
  useEffect(() => {
    setLocationName("");
  }, [buName]);



  const markerDragEnd = (event, index) => {
  }

  const onHomeClick = () => {
    setLocationId("");
    setSelectedGroupIds([]);
    setLocationName("");
    setBuName("");
    setFullScreenChecking(true);
    setForceGroupDetailsRefresh((prev) => prev + 1);
    const marker = mapRef.current;
    if (marker) {
      marker.closePopup();
    }
    if (isFullScreen) {
      setSelectedGroupNames([]);
    }
  }
  useEffect(() => {
    if (buState !== selectedBuId) {
      setSelectedBuId(buState);
    }
    if (buState === -1) {
      setBuName("");
    }
  }, [buState]);


  const handleFullScreen = () => {
    const mapContainer = document.getElementsByClassName("leaflet-container")[0];
    if (!isFullScreen) {
      mapContainer.style.width = "210%";
      mapContainer.style.height = "1500px";
      mapContainer.style.zIndex = "1000";



    } else {
      mapContainer.style.width = "100%";
      mapContainer.style.height = "780px";
      


    }
    const marker = mapRef.current;
    marker.invalidateSize()
    setIsFullScreen(!isFullScreen);
    setFullScreenChecking(false);
  };




  return (
    <Paper elevation={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1} style={{ width: isFullScreen ? '210%' : '100%', backgroundColor: '#ffffff', zIndex: isFullScreen ? "1000" : 'inherit', position: 'relative', transition: "width 0.1s, height 0.1s" }}>
        <Box flex="1">
          <div className={classes.titleStyle} >
            <Typography variant='h6' sx={{ color: '#003366' }} component={'span'}>
              Sites-Geo Map {buName !== "" ? `/ ${buName} ` : ""} {locationName !== "" ? `/ ${locationName}` : ""}
            </Typography>
          </div>
        </Box>
        <Box className={classes.titleStyle}  >
          <IconButton variant="outlined" color="primary" onClick={onHomeClick}><HomeIcon /></IconButton>
        </Box>

        {/*  full screen icon  */}

        <Box className={classes.titleStyle}>
          <IconButton variant="outlined" color="primary" onClick={handleFullScreen} >
            {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>
        </Box>
        
      </Box>
      <Divider />
      {status && <MapContainer
        style={containerStyle}
        center={center}
        zoom={zoom}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        />

        <MapContent
          onClick={mapClicked}
        />
        {markers.map((marker, index) => (
          <MarkerContent
            key={index}
            locationId={marker.location_id}
            locationName={marker.location_name}
            status={marker.status}
            ispsUp={marker.isps_up}
            ispsDown={marker.isps_down}
            isps={marker.isp_wan_ids}
            position={marker.position}
            draggable={marker.draggable}
            onMarkerClick={event => markerClicked(marker, index)}
            onDragEnd={event => markerDragEnd(event, index)}
          />
        ))}
      </MapContainer>}
    </Paper>
  );
};

const MapContent = ({ onClick }) => {
  const map = useMapEvents({
    click: event => onClick(event)
  })
  return null;
}

const MarkerContent = (props) => {
  const markerRef = useRef();
  const navigate = useNavigate();
  const { locationId, locationName, status, ispsUp, ispsDown, isps, position, draggable, onMarkerClick, onDragEnd } = props;

  const handleForwardClick = () => {
    const locationData = {
      "location": locationName,
      "location_id": locationId,
      "isp_wan_ids": isps
    }

    if (ispsUp === 0 && ispsDown !== 0) {
      navigate("/dashboard/site-down", { state: { location: locationData } })
    }
    else {
      navigate("/dashboard/site-up", { state: { location: locationData } })
    }


  };

  function StatusIcon(status) {
    let icon;
    switch (status) {
      case 'Good':
        icon = defaultIcon;
        break;
      case 'Bad':
        icon = orangeIcon;
        break;
      case 'Down':
        icon = redIcon;
        break;
      default:
        icon = defaultIcon;
    }
    return icon;
  }

  return <Marker
    position={position}
    icon={StatusIcon(status)}
    draggable={draggable}
    eventHandlers={{
      click: event => onMarkerClick(event),
      dragend: () => onDragEnd(markerRef.current.getLatLng()),
    }}
    ref={markerRef}
  >
    <Popup>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div>
          <p><b>{locationName}</b></p>
          <Divider />
          <p>Links UP: {ispsUp}</p>
          <p>Links Down: {ispsDown}</p>
        </div>
        <Divider />
        <div style={{ display: 'flex'}} >
          <Typography>
            Open Site
          </Typography>
          <IconButton onClick={handleForwardClick} aria-label="forward">
            <LaunchIcon />
          </IconButton>
        </div>
      </div>
    </Popup>

  </Marker>
}

export default OsmMapComponent;
