// GetPortAndAuthStatusApiCall
import axios from 'axios';

// API CALL CODE HERE

const GetPortAndAuthStatusApiCall = async(deviceId, deviceIp, setInfoList) =>  {
  // const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_ssh_snmp_for_edge_device.php`;
  const path = localStorage.getItem("msp_status") === "1" ? "get_ssh_snmp_for_edge_device_msp.php" : "get_ssh_snmp_for_edge_device.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;
  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "device_id": deviceId,
    "device_ip" : deviceIp
  });
  const mspPayload =  JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id"),
    "device_id": deviceId,
    "device_ip" : deviceIp
  });
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(baseUrl, payload).then((response)=>{
    if(response.data.code === 1){
        
        setInfoList([response.data])
    }
    else{
        setInfoList([response.data.message]);
    }
  })
}

export default GetPortAndAuthStatusApiCall;
