import React, { useEffect, useState } from "react";
import {
    Paper,
    Box,
    Typography,
    Divider,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    FormGroup,
    Grid
} from '@mui/material';

import DonutChart from "../DonutChart";
import TopSingleDomainComponent from "./TopSingleDomainComponent";
import GetTopDomainUsageExporterAPICall from "./GetTopDomainUsageAPICall";

export default function TopDomainsInfoComponent(props) {

    const { deviceIps } = props;
    const [time, setTime] = useState(1);
    const [domainUsageData, setDomainUsageData] = useState([]);

    const [showPie, setShowPie] = useState(true);

    const [inBoundWanArr, setInBoudWanArr] = useState([]);
    const [OutBoundWanArr, setOutBoudWanArr] = useState([]);
    const [domains, setDomains] = useState([]);

    const _GetTopDomainUsageExporterAPICall = () => {
        GetTopDomainUsageExporterAPICall([deviceIps], time, setDomainUsageData, setInBoudWanArr, setOutBoudWanArr, setDomains);
    }

    const maxInbound = Math.max(...domainUsageData.map(domain => domain.inbound_wan), 0);
    const maxOutbound = Math.max(...domainUsageData.map(domain => domain.outbound_wan), 0);

    // Helper function to format bytes into MB or GB
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes < 1024 * 1024 * 1024) {
            return `${(bytes / (1024 * 1024)).toFixed(decimals)} MB`;
        }
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(decimals)} GB`;
    };


    useEffect(() => {
        _GetTopDomainUsageExporterAPICall();
    }, [deviceIps, time]);
    return (
        <>
            <Paper elevation={15}>
                <Box sx={{ height: "800px", overflowY: "auto" }} >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={{ padding: "1rem" }} variant="h6">Top Domains</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch defaultChecked
                                        onChange={() => { setShowPie(!showPie) }}
                                    />
                                }
                                label="Pie" />
                        </FormGroup>
                        <FormControl sx={{ paddingTop: "1rem", paddingLeft: "5rem" }}>
                            <Select
                                value={time}
                                sx={{
                                    maxHeight: "40px",
                                    minWidth: '10rem',
                                    marginRight: "1rem",
                                    border: "none",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: 'none'
                                    }
                                }}
                                onChange={(e) => { setTime(e.target.value) }}
                            >
                                <MenuItem value={-1} style={{ height: '50px' }}>Select</MenuItem>
                                <MenuItem value={1} style={{ height: '50px' }}>Last 1 Hour</MenuItem>
                                <MenuItem value={6} style={{ height: '50px' }}>Last 6 Hour</MenuItem>
                                <MenuItem value={12} style={{ height: '50px' }}>Last 12 Hour</MenuItem>
                                <MenuItem value={24} style={{ height: '50px' }}>Last 24 Hour</MenuItem>
                                {/* <MenuItem value={168} style={{ height: '50px' }}>Last 7 Days</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider />
                    <Grid container>
                        {!showPie && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ width: '100%' }}>
                                {domainUsageData.length > 0 ? domainUsageData.map((domain, index) => {
                                    const normalizedInbound = (domain.inbound_wan / maxInbound) * 100;
                                    const normalizedOutbound = (domain.outbound_wan / maxOutbound) * 100;
                                    return (
                                        <TopSingleDomainComponent
                                            key={index}
                                            domainName={domain.domain}
                                            inbound={formatBytes(domain.inbound_wan)}
                                            outbound={formatBytes(domain.outbound_wan)}
                                            inboundMarkerValue={Math.ceil(normalizedInbound)}
                                            outboundMarkerValue={Math.ceil(normalizedOutbound)}
                                        />
                                    );
                                }) : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingTop: '1rem', border: 1 }}>
                                {domainUsageData.length > 0 ? <DonutChart label={"InBoud Usage"} dseries={inBoundWanArr} dLabels={domains} directValue={false} /> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                        {showPie && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box sx={{ width: '100%', paddingTop: '1rem', border: 1 }}>
                                {domainUsageData.length > 0 ? <DonutChart label={"OutBoud Usage"} dseries={OutBoundWanArr} dLabels={domains} directValue={false} /> : <Typography sx={{ padding: "1rem" }}>No data available</Typography>}
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>
            </Paper>
        </>
    )

}