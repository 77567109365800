import axios from 'axios';

const GetTemplateApiCall = async ( setListstatus, setReportList) => {
// calling api get report
const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_report_templates_data.php`;


    const payload = JSON.stringify({
      "auth_token": localStorage.getItem("auth_token"),
      "client_id": localStorage.getItem("cust_id"),
 
    });

    return axios.post(baseUrl, payload).then((response) => {

      if (response.data.code === 1) {
        setReportList(response.data.report_template_data);
        setListstatus(1);
      }
      else {
        setReportList([]);
        setListstatus(-1);
      }
    })
  }
  
  export default GetTemplateApiCall;