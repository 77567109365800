import axios from 'axios';

// API CALL CODE HERE

export const LoginSelectedCustomerAPI = async (custId, email, authToken, setLoginStatus, navigate) => {

  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/validate_login_for_multi_customer_user.php`;

  const payload = JSON.stringify({
    "cust_id": custId,
    "email": email,
    "auth_token": authToken
  });

  await axios.post(baseUrl, payload)
    .then((response) => {
      if (response.data.code === 2) {
        const mspCustId = response.data.msp_status === 1 ? response.data.cust_id : -1;
        localStorage.setItem("cust_id", response.data.cust_id);
        localStorage.setItem("reg_cust_id", response.data.cust_id);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("auth_token", response.data.auth_token);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("onboard_status", response.data.onboard_status);
        localStorage.setItem("tour_status", response.data.tour_status);
        localStorage.setItem("msp_status", response.data.msp_status);
        localStorage.setItem("msp_cust_id", mspCustId);
        localStorage.setItem("user_map_status", response.data.user_map_status);
        localStorage.setItem("bu_status", response.data.bu_status);
        setLoginStatus(1);
        navigate('/dashboard/home', { replace: true });
      }
      if (response.data.code === -1) {
        setLoginStatus(-1);
      }
      if (response.data.code === 0) {
        setLoginStatus(-1);
        // setRemainigAttemps(response.data.attempts_left);
      }
    }).catch(() => {
      setLoginStatus(-1);
      throw new Error('Login Failed: network related')

    })
}

