import axios from 'axios';

// API CALL CODE HERE
const DeleteMspUserApiCall = async (userId, setDeleteStatus, setSeverStatus) => {

const deleteBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/delete_user.php`;


    const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "user_id": userId,
    });
    return axios.post(deleteBaseURL, payload).then((response) => {
        if (response.data.code === 1) {
            setDeleteStatus(1);
            setSeverStatus("success");
        }
        else {
            setDeleteStatus(-1);
            setSeverStatus("error");
        }
    }).catch(() => {
        setDeleteStatus(-1);
        setSeverStatus("error");
    })
}


export default DeleteMspUserApiCall;
