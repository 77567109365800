import React, { useState, useEffect, useRef } from 'react';
import { Paper, Typography, Box, Divider, Modal, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { pdf, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import GetSitesTotalDownTimeApiCall from '../ApiCalls/GetSitesTotalDownTimeApiCall';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    boxPadding: {
        padding: '25px',
    },
    boxItems: {
        display: 'flex',
        alignItems: 'center',
    },
    titleStyle: {
        padding: 24,
    },
});

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 20,
    },
    header: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        textAlign: 'right',
        fontSize: 12,
        marginBottom: 10,
    },
    tableTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: 5,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColHeader: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: '#f0f0f0',
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCellHeader: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: 5,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
        textAlign: 'left',
        paddingLeft: 5,
    },
});

const MyDocument = ({ siteDownTimeCountData, currentDate }) => (
    <Document>
        <Page size="A4" style={pdfStyles.page}>
            <View>
                <Text style={pdfStyles.header}>Site Down Time Report</Text>
                <Text style={pdfStyles.date}>Date: {currentDate}</Text>
            </View>
            <View>
                <Text style={pdfStyles.tableTitle}>Down Time Details</Text>
                <View style={pdfStyles.table}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.tableColHeader}>
                            <Text style={pdfStyles.tableCellHeader}>Location</Text>
                        </View>
                        <View style={pdfStyles.tableColHeader}>
                            <Text style={pdfStyles.tableCellHeader}>Down Time</Text>
                        </View>
                    </View>
                    {siteDownTimeCountData.map((item, index) => {
                        const [site, downtime] = Object.entries(item)[0];
                        return (
                            <View style={pdfStyles.tableRow} key={index}>
                                <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{site}</Text>
                                </View>
                                <View style={pdfStyles.tableCol}>
                                    <Text style={pdfStyles.tableCell}>{downtime}</Text>
                                </View>
                            </View>
                        );
                    })}
                </View>
            </View>
        </Page>
    </Document>
);

export default function TotalDownTimeComponent(props) {
    const { locationId, selectedGroupIds, currentDate } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();

    const classes = useStyles();
    const [siteDownTimeCount, setSiteDownTimeCount] = useState("");
    const [siteDownTimeCountData, setSiteDownTimeCountData] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const downloadLinkRef = useRef();

    useEffect(() => {
        GetSitesTotalDownTimeApiCall(locationId, selectedGroupIds, setSiteDownTimeCount, buState, setSiteDownTimeCountData);
    }, [siteDownTimeCount, locationId, selectedGroupIds, globalState, buState]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleDownloadClick = async () => {
        const doc = <MyDocument siteDownTimeCountData={siteDownTimeCountData} currentDate={currentDate} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Site_DownTime_Report.pdf';
        a.click();
        URL.revokeObjectURL(url);
    };
    return (
        <Paper elevation={8}>
            <div className={classes.titleStyle}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant='h6' sx={{ color: '#003366', cursor: 'pointer', textDecoration: 'underline' }} component={'span'} onClick={handleOpen}>
                            Total Site Down Time
                        </Typography>
                    </Grid>
                    <Grid item sx={{ cursor: 'pointer' }} onClick={handleDownloadClick}>
                        <DownloadIcon />
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <Box display="flex" justifyContent="center" alignItems="center" height={"70px"}>
                <Typography variant='h3' sx={{ color: '#FFA500' }}>
                    {siteDownTimeCount}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="right" sx={{ paddingRight: "20px" }}>
                <Typography variant='h12' sx={{ color: '#003366' }} component={'span'}>
                    On {currentDate}
                </Typography>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Sites that are down
                    </Typography>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <ul>
                            {siteDownTimeCountData && siteDownTimeCountData.map((item, index) => {
                                const [site, downtime] = Object.entries(item)[0];
                                return (
                                    <li key={index}>
                                        <strong>{site}</strong>: {downtime}
                                    </li>
                                );
                            })}
                        </ul>
                    </Typography>
                    <br />
                    <Grid container item display='flex' justifyContent='flex-end'>
                        <Button variant="contained" onClick={handleDownloadClick}>
                            Download
                        </Button>
                        <Button variant="text" onClick={handleClose}>Close</Button>
                    </Grid>
                </Box>
            </Modal>
        </Paper>
    );
}
