import axios from 'axios';


const GetApplicationTableData = async (appType, setAppData,buState) => {
  try {
    if (localStorage.getItem("user_map_status") === "1") {
      const mappedUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_application_details_for_mapped_user.php`;
      const payload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "app_type": appType
      });
      const response = await axios.post(mappedUrl, payload);
      if (response.data.code === 1) {
        setAppData(response.data.app_data);
      }
    } 
    else if (localStorage.getItem("user_map_status") === "-1") {
      if (localStorage.getItem("bu_status") === "1" && buState !== -1) {
        const alertBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_application_details_for_bu.php`;
        const payload = JSON.stringify({
          "auth_token": localStorage.getItem("auth_token"),
          "client_id": localStorage.getItem("reg_cust_id"),
          "app_type": appType,
          "bu_id": buState
        });
  
        return axios.post(alertBaseURL, payload).then((response) => {
          if (response.data.code === 1) {
            setAppData(response.data.app_data);

          } else {
            setAppData([]);
          }
        })
      }
      const path = localStorage.getItem("msp_status") === "1" ? "get_application_details_for_msp.php" : "get_application_details.php";
      const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

      const regPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "client_id": localStorage.getItem("cust_id"),
        "app_type": appType
      });

      const mspPayload = JSON.stringify({
        "auth_token": localStorage.getItem("auth_token"),
        "msp_client_id": localStorage.getItem("cust_id"),
        "reg_client_id": localStorage.getItem("reg_cust_id"),
        "msp_status": 1,
        "app_type": appType
      });

      const payload = localStorage.getItem("msp_status") === "1"? mspPayload : regPayload;

      const response = await axios.post(baseUrl, payload);
      if (response.data.code === 1) {
        setAppData(response.data.app_data);
      } else {
        setAppData([]);
      }
    }
  } catch (error) {
    // console.error("Error in API call:", error);
    setAppData([]);
  }
}

export default GetApplicationTableData;