// @mui
import {
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ISPBad1Card1 from './ISPBad1Card1';
import timeArray from '../TimeData';

// components
// ----------------------------------------------------------------------



export default function ISPBad1MainPage() {

  return (
    <>
      <Grid container spacing={1} sx={{ py: 1 }}>
        <ISPBad1Card1 />
      </Grid>
    </>
  );
}
