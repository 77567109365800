import React, { useEffect, useState } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import AddEdgeDeviceApiCall from './AddEdgeDeviceApiCall';
import GetEdgeDeviceInfoApi from './GetEdgeDeviceInfoApi';
import UpdateEdgeDeviceApiCall from './UpdateEdgeDeviceApiCall';
import GetEdgeDeviceVendorAndTypesApiCall from './GetEdgeDeviceVendorAndTypesApiCall';

export default function AddEdgeDeviceDialog(props) {
    const { value, openAddEdDailog, setOpenAddEdDailog, setAddEdStatus, setSeverStatus, setOpenAddEd } = props;

    const [locationName, setLocationName] = useState(value.location_name);
    const [locationId, setLocationId] = useState(value.location_id);
    const [deviceId, setDeviceId] = useState(-1);
    const [deviceIp, setDeviceIp] = useState("");
    const [snmpStr, setSnmpStr] = useState("");
    const [sshUserName, setSshUserName] = useState("");
    const [sshPassword, setSshPassword] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [sshPort, setSshPort] = useState("");
    const [apiPort, setApiPort] = useState("");
    const [deviceType, setDeviceType] = useState(0);
    const [deviceVendor, setDeviceVendor] = useState(0);

    const [typeList, setTypeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);


    const [checked, setChecked] = useState(false);

    useEffect(() => {
        GetEdgeDeviceInfoApi(locationId, setDeviceId, setDeviceIp, setSnmpStr, setSshUserName, setSshPassword, setApiKey, setSshPort, setApiPort, setDeviceVendor, setDeviceType);
        GetEdgeDeviceVendorAndTypesApiCall(setVendorList, setTypeList);
    }, [])
    const handleOnAddEd = async () => {
        if (!checked) {
            await AddEdgeDeviceApiCall(value.location_id, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setAddEdStatus, setSeverStatus, setOpenAddEd);
        }
        else {
            await UpdateEdgeDeviceApiCall(deviceId, locationId, deviceIp, snmpStr, sshUserName, sshPassword, apiKey, sshPort, apiPort, deviceVendor, deviceType, setAddEdStatus, setSeverStatus, setOpenAddEd);
        }
        setOpenAddEdDailog(false);
        setOpenAddEd(true);
        props.handleDataReLoad();

    }

    return (
        <>
            <Dialog open={openAddEdDailog} onClose={() => { setOpenAddEdDailog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Add/Update Edge Device
                    <IconButton onClick={() => { setOpenAddEdDailog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 6, px: 3 }}>
                    <form>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ py: 1 }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Location Name"
                                    id="location_name"
                                    value={locationName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setLocationName(e.target.value) }}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Edge Device IP"
                                    id="device_ip"
                                    value={deviceIp}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setDeviceIp(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SNMP String"
                                    id="snmp_str"
                                    value={snmpStr}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setSnmpStr(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="API Key"
                                    id="api_key"
                                    value={apiKey}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setApiKey(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH UserName"
                                    id="ssh_username"
                                    value={sshUserName}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setSshUserName(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH Password"
                                    type="password"
                                    id="ssh_password"
                                    value={sshPassword}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setSshPassword(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="SSH Port"
                                    id="ssh_port"
                                    value={sshPort}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setSshPort(e.target.value) }}
                                    required />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="API Port"
                                    id="api_port"
                                    value={apiPort}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={(e) => { setApiPort(e.target.value) }}
                                    required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="vendor-label-id">Vendor</InputLabel>
                                    <Select
                                        labelId="vendor-label-id"
                                        id="vendor"
                                        value={deviceVendor}
                                        label="Vendor"
                                        style={{ width: "400px" }}
                                        onChange={(e)=>{setDeviceVendor(e.target.value);}}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        {vendorList.map((value)=>(
                                            <MenuItem value={value.ed_vendor_id}>{value.vendor_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-label-id">Type</InputLabel>
                                    <Select
                                        labelId="type-label-id"
                                        id="type"
                                        value={deviceType}
                                        label="Type"
                                        style={{ width: "400px" }}
                                        onChange={(e)=>{setDeviceType(e.target.value);}}
                                    >
                                        <MenuItem value={0}>Select</MenuItem>
                                        {typeList.map((value)=>(
                                            <MenuItem value={value.type_id}>{value.type}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={() => { setChecked(!checked) }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    label="Check if u want to update." />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenAddEdDailog(false) }}>Cancel</Button>
                    <Button onClick={handleOnAddEd} variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
