import axios from 'axios';

// API CALL CODE HERE

const GetAllBusinessUnitDetailsApiCall = async (setRows) => {

  const path = localStorage.getItem("msp_status") === "1" ? "get_business_unit_for_msp.php" : "get_business_unit.php";
const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

  const regPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
  });
  const mspPayload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "msp_client_id": localStorage.getItem("cust_id"),
    "reg_client_id": localStorage.getItem("reg_cust_id")
  });

 
  const payload = localStorage.getItem("msp_status") === "1" ? mspPayload : regPayload;
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
      setRows(response.data.business_unit);
    }
    else {
      setRows([])
    }
  }).catch(()=>{
    setRows([])
  })
}

export default GetAllBusinessUnitDetailsApiCall;
