// @mui
import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// components
import Page from '../../../Page';
import EdGood1MainPage from './EdGood1MainPage';


export default function EdGood1() {
  const { id } = useParams()
  return (
    <>
      <Container maxWidth={false}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard/dashboard">
              NMS
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/dashboard">
              Link Management
            </Link>
            <Link underline="hover" color="inherit" href="/dashboard/edge-device-management">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" href={`/dashboard/isp-management/ed-good/${id}`}>
              Device Status
            </Link>
          </Breadcrumbs>
        </div>

        <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
          <EdGood1MainPage />
        </Grid>

      </Container>
    </>
  );
}
