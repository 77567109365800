import axios from 'axios';

// API CALL CODE HERE
const GetLocationsByZones = async (zones, setLocations) => {

const createBaseURL = `${process.env.REACT_APP_API_SERVER_URL}/get_locations_by_zone_names.php`;


  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id"),
    "zones": zones
  });
  // console.log(payload);
  return axios.post(createBaseURL, payload).then((response) => {
    if (response.data.code === 1) {
        setLocations(response.data.locations);
    }
    else {
      setLocations([]);
    }
  })
}

export default GetLocationsByZones;